
/** TODO: FUTURE COULD ADD CUSTOM ENVIRONMENTAL VARIABLES */
import {defaultEnvironment} from './default';
import {developmentEnvironment} from './development';
import {productionEnvironment} from './production';
import {testEnvironment} from './test';
import {uatEnvironment} from './uat';

const nodeEnv = process.env.REACT_APP_ENVIRONMENT;
const _ = require('lodash');

let environment;
switch(nodeEnv) {
  case 'test':
    environment = testEnvironment;
    break;
  case 'development':
    environment = developmentEnvironment;
    break;

    /** I NEED A UAT LIVE SERVER, and A DEVELOPMENT LIVE SERVER... HOW DO I RUN */

  case 'uat':  /** RUNS ON PRODUCTION VALUES */
    environment = uatEnvironment;
    break;
  case 'production':
    environment = productionEnvironment;
    break;
  default:
    environment = testEnvironment;
}

//Merge together "defaultEnvironment" and the NODE_ENV specific "environment"
const config = _.merge(defaultEnvironment, environment); /** Note Second Object Over Writes Matching Paths */

export {config};
