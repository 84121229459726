/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useState,
// useEffect
} from "react";
import Fire from "../config/fire"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  // CardFooter,
  // FormInput,
  // FormTextarea,
  // Badge,
  Button,
  Alert,
  // InputGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
// import {MixpanelSuper} from "../mixpanelSuper";
import "../assets/stars.css";
import "../assets/history.css";

const _ = require('lodash');

const History = () => {

  // Todo -- you need to make a dropdown variable for each array item that is imported
  // const [dropdown1, setdropdown1] = useState(false);

  // const [savedItems, setsavedItems] = useState([]);
  // console.log("saved Items",savedItems);
  // const [loading, setLoading] = useState();
  const [displayMessage
  // , setdisplayMessage
  ] = useState('');
  // const [dropdowns, setDropdowns ] = useState([]);
  const [localStars, setLocalStars] = useState([]);
  const [expanded, setExpanded] = useState([[true,false,false,false],[true],[true],[true]]);
  const  [listOne
  // , setListOne
  ] = useState([
    {
      date: "1-13-2021 at 16:11",
      id: "NfvxRmOLkaft3pXkI7Z5",
      prompt: '{"product":"ziploc.com","details":"A sustainable food storage method that keeps food fresh longer and helps families save money"}',
      stars: 0,
      ts: 1610579474863,
      type: "productDescription",
      value: ["Keep your produce fresh with this Ziploc Bag.\n" +
      "This bag is perfect for storing a variety of produce, including fruit, vegetables, meat and herbs. It's airtight seal keeps your produce fresh for longer and its clear design lets you see what's inside.\n" +
      "The bag has a handle so you can easily carry it around and it's made from a durable and sustainable material."],
    },
    {
      date: "1-13-2021 at 16:11",
      id: "NfvxRmOLkaft3pXkI7Z5",
      prompt: '{"product":"ziploc.com","details":"A sustainable food storage method that keeps food fresh longer and helps families save money"}',
      stars: 0,
      ts: 1610579474863,
      type: "productDescription",
      value: ["Keep your food fresh with the Ziploc Bag! 🍎\n" +
      "The Ziploc Bag is a reusable bag that is airtight and keeps your produce fresh for up to three times longer than using a regular grocery bag! It is made from sustainable materials and is clear so you can see exactly what you have inside.\n" +
      "The Ziploc Bag is a convenient and affordable way to keep your produce fresh and maintain a healthy lifestyle. \n" +
      "Perfect for any kitchen, the Ziploc Bag is"],
    },
    {
      date: "1-13-2021 at 16:11",
      id: "NfvxRmOLkaft3pXkI7Z5",
      prompt: '{"product":"ziploc.com","details":"A sustainable food storage method that keeps food fresh longer and helps families save money"}',
      stars: 0,
      ts: 1610579474863,
      type: "productDescription",
      value: ["If you're anything like me, your produce drawer is so full you can't see the bottom!\n" +
      "The whole family are always going in there grabbing stuff for lunch, snacks and meals. It's impossible to tell what's fresh and what's been sitting there for days.\n" +
      "The Ziploc Bag is airtight, so it keeps your produce fresh for longer. You can put it in the fridge, the freezer, and even in the microwave, so you can use it again and again"],
    },
    {
      date: "1-13-2021 at 16:11",
      id: "NfvxRmOLkaft3pXkI7Z5",
      prompt: '{"product":"ziploc.com","details":"A sustainable food storage method that keeps food fresh longer and helps families save money"}',
      stars: 0,
      ts: 1610579474863,
      type: "productDescription",
      value: ["The Ziploc Bag is the perfect way to keep produce fresh.\n" +
      "Pop your fresh produce in thea Ziploc Bag and keep it fresh for longer! 🍆\n" +
      "Clear, so you can see what's inside, the Ziploc Bag is the best way to keep your food fresh!"],
    },
]);

  const [listTwo
  // ,setListTwo
  ] = useState([
    {
      date: "1-13-2021 at 16:11",
      id: "NfvxRmOLkaft3pXkI7Z5",
      prompt: '{"product":"ziploc.com","details":"A sustainable food storage method that keeps food fresh longer and helps families save money"}',
      stars: 0,
      ts: 1610579474863,
      type: "productDescription",
      value: ["Keep your produce fresh with this Ziploc Bag.\n" +
      "This bag is perfect for storing a variety of produce, including fruit, vegetables, meat and herbs. It's airtight seal keeps your produce fresh for longer and its clear design lets you see what's inside.\n" +
      "The bag has a handle so you can easily carry it around and it's made from a durable and sustainable material."],
    },
  ]);

  const [listThree
  //, setListThree
  ] = useState([
    {
      date: "1-13-2021 at 16:11",
      id: "NfvxRmOLkaft3pXkI7Z5",
      prompt: '{"product":"ziploc.com","details":"A sustainable food storage method that keeps food fresh longer and helps families save money"}',
      stars: 0,
      ts: 1610579474863,
      type: "productDescription",
      value: ["Keep your produce fresh with this Ziploc Bag.\n" +
      "This bag is perfect for storing a variety of produce, including fruit, vegetables, meat and herbs. It's airtight seal keeps your produce fresh for longer and its clear design lets you see what's inside.\n" +
      "The bag has a handle so you can easily carry it around and it's made from a durable and sustainable material."],
    },
  ]);

  const [listFour
  // , setListFour
  ] = useState([
    {
      date: "1-13-2021 at 16:11",
      id: "NfvxRmOLkaft3pXkI7Z5",
      prompt: '{"product":"Ziploc","details":"A food container business that helps produce last longer and saves families money"}',
      stars: 0,
      ts: 1610579474863,
      type: "painAgitateSolution",
      value: ["PROBLEM:\n" +
      "Food waste is a big problem in Australia.\n\nThe average family spends $1,200 a year on food that ends up in the bin and when you consider the number of families in Australia, it’s a massive waste of money.\n\nAGITATE:\n" +
      "Some of us are better at using leftovers than others but food waste is a problem for us all.\n\nSOLVE:\n" +
      "Ziploc is the best way to save money and preserve your food.\n" +
      "\nThe airtight seal keeps food fresh for longer and prevents freezer burn and the easy-to-grip shapes make storing and stacking easy.\n" +
      "\nYou can buy Ziploc products in-store or online."],
    },
  ]);

  // const copyToClipboard = (text) => {
  //   MixpanelSuper.copySnippet('history_page');
  //   console.log('Copy to clipboard');
  //   navigator.clipboard.writeText(text);
  //   setdisplayMessage('Copied!');
  //   setTimeout(() => {
  //     setdisplayMessage(null);
  //   },3000);
  // }

  // const copyLinkToClipboard = (link) => {
  //   MixpanelSuper.copyShareSnippet('history_page')
  //   console.log('Custom link copied To clipboard');
  //   navigator.clipboard.writeText(link);
  //   setdisplayMessage('Sharable Link Copied!');
  //   setTimeout(() => {
  //     setdisplayMessage(null);
  //   },3000);
  // }

  // const changeDropdown = (dropdown, index) => {
  //   dropdown = !dropdown;
  //   let changeDrop = [...dropdowns];
  //   changeDrop[index] = dropdown;
  //   setDropdowns(changeDrop)
  // }

  const saveStars = (output, index, starCount) => {
    console.log("applied " + starCount +" to id "+index);
    let changeStars = [...localStars];
    changeStars[index] = starCount;
    setLocalStars(changeStars);

    return new Promise((resolve, reject) => {
      Fire.shared.saveStarRating(output.id, starCount, 'results')
      .finally(() => {
        resolve();
      })
    })

  }

  const expandSection = (index, exp, count) => {
    let expandTemp = [...expanded];
    expandTemp[count-1][index] = !exp;
    setExpanded(expandTemp);
    console.log('expanded',index)
    console.log('expanded',expanded)
  }

  const InputBlock = ({item}) => {
      console.log('Input Object:', item);
      let promptObj = JSON.parse(item.prompt);
      const inputsKeyHumanReadableMapping = { //TODO: NEED A CONFIG SO I CAN ADD THIS IN 1 PLACE
                  'product':'Product',
                  'businessUrl':'Business Url',
                  'businessIndustry':'Business Industry',
                  'details':'Details',
                  'product_name':'Product Name',
                  'product_description':'Product Description',
                  'product_details':'Product Details',
        };
        let printInputs = [];
        _.forEach(promptObj, (value, key)=>{
          if(key !== 'promptId'){
            if(key in inputsKeyHumanReadableMapping){
              printInputs.push([inputsKeyHumanReadableMapping[key], value]);
            }else{
              printInputs.push([key, value]);
            }
          }
        });
      if (printInputs !== []) {
        return(
            <span>
              {printInputs.map((value, index)=>(
                <div key={index}><strong>{value[0]}</strong>: {value[1]}</div>
              ))}
            </span>
        )
      } else {
        return (<span></span>)
      }
    }

  const ItemType = ({type}) => {
    console.log('Item Type: ', type);
    const categoryHumanReadableMapping = { //TODO: NEED A CONFIG SO I CAN ADD THIS IN 1 PLACE
      'attentionInterestDesireAction':'Attention Interest Desire Action',
      'facebookAd':'Facebook Ad',
      'headline':'Headlines',
      'productDescription':'Product Description',
      'painAgitateSolution':'Pain Agitate Solution',
    };
    if(type in categoryHumanReadableMapping){
      return (<span>{categoryHumanReadableMapping[type]}</span>);
    }else{
      return (<span>{type}</span>);
    }
  };

  const ItemLine = ({item}) => {
    console.log('ItemLine Value: ', item);
    return (<span className="larger">{item}<br/></span>)
  }

  const ExpandContract = ({value, idx, count}) => {
    console.log('expanded[idx]',expanded[count-1][idx])
    if (expanded[count-1][idx]) {
      return (
          <span>
            <p className="card-text mb-1"><GeneratedContentBlock value={value}/></p>
            <p className="card-text mb-1 red-close"><a onClick={() => expandSection(idx, expanded[count-1][idx],count)}>show less</a></p>
          </span>)
    } else {
      return (
          <span>
            <p className="card-text mb-1 shrunk-article"><GeneratedContentBlock value={value}/></p>
            <p className="card-text mb-1 bright"><a onClick={() => expandSection(idx, expanded[count-1][idx],count)}>show more</a></p>
          </span>)
    }
  }

  const GeneratedContentBlock = ({value}) => {
    console.log('GeneratedContentBlock Value: ', value);
      if (typeof value === 'string' && value !== undefined) {
        return (<span>{value.split('\n').map(function (item) {
          return (<ItemLine item={item}/>)
        })
        }</span>)
      } else if (typeof value == 'object') {
        return (<span>{value.map(function (item) {
          return (<GeneratedContentBlock value={item}/>)
        })}
          </span>)
      } else {
        return (<span></span>)
      }
  }

  const StarRating = ({rating,item,idx}) => {

    if(rating === 5){
      return <div className="rating"><span role="img" aria-label="Gold Star" onClick={() => saveStars(item, idx, 5)}>⭐</span><span role="img" aria-label="Gold Star" onClick={() => saveStars(item, idx, 4)}>⭐</span><span role="img" aria-label="Gold Star" onClick={() => saveStars(item, idx, 3)}>⭐</span><span role="img" aria-label="Gold Star" onClick={() => saveStars(item, idx, 2)}>⭐</span><span role="img" aria-label="Gold Star" onClick={() => saveStars(item, idx, 1)}>⭐</span></div>;
    } else if (rating === 4){
      return <div className="rating"><span role="img" aria-label="Grey Star" onClick={() => saveStars(item, idx, 5)}>☆</span><span role="img" aria-label="Gold Star" onClick={() => saveStars(item, idx, 4)}>⭐</span><span role="img" aria-label="Gold Star" onClick={() => saveStars(item, idx, 3)}>⭐</span><span role="img" aria-label="Gold Star" onClick={() => saveStars(item, idx, 2)}>⭐</span><span role="img" aria-label="Gold Star" onClick={() => saveStars(item, idx, 1)}>⭐</span></div>;
    } else if (rating === 3){
      return <div className="rating"><span role="img" aria-label="Grey Star" onClick={() => saveStars(item, idx, 5)}>☆</span><span role="img" aria-label="Grey Star" onClick={() => saveStars(item, idx, 4)}>☆</span><span role="img" aria-label="Gold Star" onClick={() => saveStars(item, idx, 3)}>⭐</span><span role="img" aria-label="Gold Star" onClick={() => saveStars(item, idx, 2)}>⭐</span><span role="img" aria-label="Gold Star" onClick={() => saveStars(item, idx, 1)}>⭐</span></div>;
    } else if (rating === 2){
      return <div className="rating"><span role="img" aria-label="Grey Star" onClick={() => saveStars(item, idx, 5)}>☆</span><span role="img" aria-label="Grey Star" onClick={() => saveStars(item, idx, 4)}>☆</span><span role="img" aria-label="Grey Star" onClick={() => saveStars(item, idx, 3)}>☆</span><span role="img" aria-label="Gold Star" onClick={() => saveStars(item, idx, 2)}>⭐</span><span role="img" aria-label="Gold Star" onClick={() => saveStars(item, idx, 1)}>⭐</span></div>;
    } else if (rating === 1){
      return <div className="rating"><span role="img" aria-label="Grey Star" onClick={() => saveStars(item, idx, 5)}>☆</span><span role="img" aria-label="Grey Star" onClick={() => saveStars(item, idx, 4)}>☆</span><span role="img" aria-label="Grey Star" onClick={() => saveStars(item, idx, 3)}>☆</span><span role="img" aria-label="Grey Star" onClick={() => saveStars(item, idx, 2)}>☆</span><span role="img" aria-label="Gold Star" onClick={() => saveStars(item, idx, 1)}>⭐</span></div>;
    } else{
      return <div className="rating"><span role="img" aria-label="Grey Star" onClick={() => saveStars(item, idx, 5)}>☆</span><span role="img" aria-label="Grey Star" onClick={() => saveStars(item, idx, 4)}>☆</span><span role="img" aria-label="Grey Star" onClick={() => saveStars(item, idx, 3)}>☆</span><span role="img" aria-label="Grey Star" onClick={() => saveStars(item, idx, 2)}>☆</span><span role="img" aria-label="Grey Star" onClick={() => saveStars(item, idx, 1)}>☆</span></div>;
    }
  };

  const ItemList = ({list,count}) => {
    return(<Row>
      {list.map((item, idx) => (
          <Col lg="12" sm="12" className="mb-2" key={idx}>
            <Card small className="card-post card-post--aside card-post--1">
              <CardBody className="history">
                <Row>
                  <Col lg="10" md="12" sm="12">
                    <span className="dark-gray">Gary Says</span>
                    <ExpandContract value={item.value} idx={idx} count={count}/>
                    <span className="text-muted">Text Prompts</span>
                    <p className="card-text mb-1"><InputBlock item={item}/></p>
                  </Col>
                  <Col lg="2" md="12" sm="12">

                    <p className="card-text mb-1"><b><ItemType type={item.type}/></b></p>

                    <StarRating rating={localStars[idx]} item={item} idx={idx}/>

                   {/* <Button theme="success" className="mb-2 mr-1">
                      Share
                    </Button>*/}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
      ))}
    </Row>)
  }

    return (
      <Container fluid className="main-content-container px-4 pt-4">
        <Row>
          <Col lg="3" md="1"></Col>
          <Col lg="6" md="10" sm="12" className="mb-4 align-items-center">
            { displayMessage && <Container fluid className="px-0" style={{ zIndex:99, position:'fixed', right:'0px', width:'300px'}}>
              <Alert className="mb-0">
                <i className="fa fa-info mx-2"></i> {displayMessage}
              </Alert>
              </Container>}

            {/* Page Header */}
            <Row noGutters className="">
              <PageTitle lg="12" sm="12" title="Write Product Descriptions that Convert" subtitle="GaryAI" className="text-sm-left" />
            </Row>

            <Row noGutters className="page-header py-4">
              <p className="readable mb-4">We decided to name our product after Gary Halbert [1938-2007] the greatest copywriter of all time. You can now add an AI version of Gary to your team - trained to write using the highest converting marketing copy across the internet.</p>
              <p className="readable mb-4">Each of Gary’s writing styles is trained on the best content of that specific type. All of the inputs follow a similar format but need to be as different as possible to spur Gary’s creativity and diversity of outputs. Let’s explore what this means for product descriptions.</p>
              <p className="readable mb-4">When you think of Ziploc, you probably think, “fresh, airtight, sustainable, clear, keeps produce fresh.”  Okay maybe not sustainable … but they are :P (I pulled that from their website).</p>
              <p className="readable mb-4">This is a great place to start creating product descriptions, here are a few. These were all generated with the exact same inputs.</p>


            </Row>



            {/*{loading && <img alt="" src={require("../images/loading.gif")} height="100" style={{marginLeft: "40%"}} ></img> }*/}

            <ItemList list={listOne} count={1}/>

            <Row noGutters className="page-header py-4">
              <p className="readable mb-4">These responses are generated with AI. That means while they sound like there are sentences from last years Ad campaign, Gary writes them all on the spot. You can test their uniqueness with Google.</p>
              <p className="readable mb-4">“If you're anything like me, your produce drawer is so full you can't see the bottom!”</p>
              <p className="readable mb-4">This sentence seems so familiar someone must have written this … right. </p>
              <p className="readable mb-4">But when you Google the sentence with or without quotes, there are no direct matches anywhere.</p>
              <p className="readable mb-4">This means you can use all of the content generated by GaryAI copyright free in your marketing campaign or website. Gary is writing unique copy for the first time.</p>
              <p className="readable mb-4"><b>It’s so much faster to edit than write</b></p>
              <p className="readable mb-4">With the previous copy as inspiration, my final product description is as follows</p>
            </Row>

            <ItemList list={listTwo} count={2}/>

            <Row noGutters className="page-header py-4 pt-5">
              <PageTitle sm="12" title="How to optimize inputs for the most creative writing" className="text-sm-left" />
            </Row>

            <Row noGutters className="page-header py-4">
              <p className="readable mb-4">TLDR: The more descriptive your inputs the more variety you get in your output.</p>
              <p className="readable mb-4">Best practice - combine all the features you have into a single run and see how Gary relates them to each other (like the examples above). Including a single feature will result in massive repetition.</p>
              <p className="readable mb-4">For example. Here’s a Ziploc product description with one feature … fresh.</p>
            </Row>

            <ItemList list={listThree} count={3}/>

            <Row noGutters className="py-4 center">
              <p className="readable mb-4">Gary must have picked up on the theme of Freezing -- this basically generates what could be considered 3 separate outputs for fresh. With less inputs the copy will repeat itself.</p>
            </Row>

            <Row noGutters className="page-header">
              <Button theme="primary" className="mb-2 mr-1">
                Generate Product Descriptions
              </Button>
            </Row>

            <Row noGutters className="page-header py-4 pt-5">
              <PageTitle sm="12" title="Also Checkout Pain Agitate Solution" className="text-sm-left" />
            </Row>

            <Row noGutters className="page-header py-4">
              <p className="readable mb-4">A quick way to transform your idea into a logical campaign flow.</p>
            </Row>

            <ItemList list={listFour} count={4}/>

            <Row noGutters className="page-header">
              <Button theme="primary" className="mb-2 mr-1 mt-5">
                Generate Pain Agitate Solutions
              </Button>
            </Row>

            <Row noGutters className="page-header py-4">
              <p className="readable mb-10"></p>
            </Row>

          </Col>
        </Row>
      </Container>
    );
  }


export default History;
