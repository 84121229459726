import { Mixpanel } from './mixpanel';

let actions = {
  // NEW - onboarding events
  trackScreenView: (screenName) => {
    Mixpanel.track(`viewed_screen_${screenName.toLowerCase()}`);
    console.log('track screen view', screenName);
  },
  trackButtonClick: (buttonName) => {
    Mixpanel.track(`clicked_${buttonName.toLowerCase()}`);
  },

  promptSuggestionSeen: (type) => {
    Mixpanel.track('prompt_suggestion_seen', { copy_type: type });
    Mixpanel.increment('prompt_suggestion_seen_count', 1);
    console.log('prompt_suggestion_seen - ' + type);
  },

  // OLD
  // TODO-- GENERATOR ACTIONS
  startGenerator: (type) => {
    Mixpanel.track('generator_started', { copy_type: type });
    Mixpanel.increment('generator_started_count', 1);
    console.log('Ran Start Generator - ' + type);
  },
  generateSnippet: (type) => {
    Mixpanel.track('snippet_generated', { copy_type: type });
    Mixpanel.increment('snippet_generated_count', 1);
    console.log('Generated a Snippet - ' + type);
  },
  copySnippet: (type) => {
    Mixpanel.track('snippet_copied', { copy_type: type });
    Mixpanel.increment('snippet_copied_count', 1);
    console.log('Copied a Snippet - ' + type);
  },
  copyShareSnippet: (type) => {
    Mixpanel.track('copy_snippet_url', { copy_type: type });
    Mixpanel.increment('copy_snippet_url_count', 1);
    console.log('Copied a Snippet Url - ' + type);
  },
  saveSnippet: (type) => {
    Mixpanel.track('snippet_saved', { copy_type: type });
    Mixpanel.increment('snippet_saved_count', 1);
    console.log('Saved a Snippet - ' + type);
  },

  // GENERATOR ERRORS

  noInputsError: (type) => {
    Mixpanel.track('no_inputs_error', { copy_type: type });
    Mixpanel.increment('no_inputs_error', 1);
    console.log('no_inputs_error - ' + type);
  },

  //TODO-- PAYMENT ACTIONS

  viewPaymentPrompt: (type) => {
    Mixpanel.track('add_payment_prompted', { copy_type: type });
  },

  selectedPlan: (plan) => {
    Mixpanel.track('selected_plan', { plan: plan });
  },

  // Choose plan
  // Click custom
  // Click basic
  // Click free

  // PAYMENT ERRORS

  /*failedPaymentError: (type) => {
        Mixpanel.track('stripe_exception', {})
    },*/
};

let devActions = {
  trackScreenView: (screenName) => {
    console.log('localhost dont track mixpanel event');
  },
  trackButtonClick: (buttonName) => {
    console.log('localhost dont track mixpanel event');
  },

  promptSuggestionSeen: (type) => {
    console.log('localhost dont track mixpanel event');
  },

  startGenerator: (type) => {
    console.log('localhost dont track mixpanel event');
  },
  generateSnippet: (type) => {
    console.log('localhost dont track mixpanel event');
  },
  copySnippet: (type) => {
    console.log('localhost dont track mixpanel event');
  },
  copyShareSnippet: (type) => {
    console.log('localhost dont track mixpanel event');
  },
  saveSnippet: (type) => {
    console.log('localhost dont track mixpanel event');
  },

  noInputsError: (type) => {
    console.log('localhost dont track mixpanel event');
  },

  viewPaymentPrompt: (type) => {
    console.log('localhost dont track mixpanel event');
  },

  selectedPlan: (plan) => {
    console.log('localhost dont track mixpanel event');
  },
};

// if this is a dev env, dont track
let currentPageUrl = window.location.href;
if (currentPageUrl.includes('localhost')) {
  actions = devActions;
}

export let MixpanelSuper = actions;
