const subscribedReducer = (state = false, action) => {
  switch(action.type){
    case 'SET_SUBSCRIBED':
      return true;
    case 'SET_UNSUBSCRIBED':
      return false;
    default:
      return state;
  }
}
export default subscribedReducer;
