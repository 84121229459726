import React from 'react';
import { Redirect } from 'react-router-dom';

// Layout Types
import { PublicLayout, DefaultLayout, AuthenticationLayout } from './layouts';

// Route Views
import BlogOverview from './views/BlogOverview';
import UserProfileLite from './views/UserProfileLite';
import AddNewProfile from './views/AddNewProfile';
import Profiles from './views/Profiles';
import AddNewPost from './views/AddNewPost';
import SavedItems from './views/SavedItems';
import Errors from './views/Errors';
import ComponentsOverview from './views/ComponentsOverview';
import Tables from './views/Tables';
import BlogPosts from './views/BlogPosts';
import SignIn from './views/SignIn';
import SharePage from './views/SharePage';
import SignUp from './views/SignUp';
import CustomPrompt from './views/CustomPrompt';
import History from './views/History';
import CopyOptions from './views/CopyOptions';
import StripeSubscriptions from './views/StripeSubscriptions';
import Article from './views/Article';
import Generate from './views/Generate';
import IntroVideo from './views/IntroVideo';
import { TryItFree } from './views/_TryItFree';
import { VerifyEmail } from './views/_VerifyEmail';
import { OnboardingPart1 } from './views/_OnboardingPart1';
import { OnboardingPart2 } from './views/_OnboardingPart2';
import { OnboardingPart3 } from './views/_OnboardingPart3';
import { PickPlan } from './views/_PickPlan';
import { PickPlan2 } from './views/_PickPlan2';
import { PlansSettings } from './views/_PlansSettings';
import { VerifyPlan } from './views/_VerifyingPlan';
import { Dashboard } from './views/_Dashboard';
import { CustomDashboard } from './views/_CustomDashboard';
import { CustomNew } from './views/_CustomNew';
import { AuthActionRedirect } from './views/_AuthAction';
import { Settings } from './views/_Settings2';
import GenerateCustom from './views/_GenerateCustom';
import GenerateV2 from './views/_GenerateV2';
import { SkipTrial } from './views/_SkipTrial';
import { SkipTrial2 } from './views/_SkipTrial2';
import { ChallengeUpsell } from './views/_ChallengeUpsell';
import { DocEditor } from './views/_DocEditor';
import { Documents } from './views/_MyDocs';
import { VerifyPaidTrial } from './views/_VerifyingPaidTrial';
import { CreateAccountAfterPayment } from './views/_CreateAccountAfterPayment';
import { SetupPart1 } from './views/_SetupPart1';
import { SetupPart2 } from './views/_SetupPart2';
import { LoadPlan } from './views/_LoadPlan';
import { VerifyPayment } from './views/_VerifyPayment';
import { ResetPassword } from './views/_ResetPassword';
import { ForgotPassword } from './views/_ForgotPassword';
import { AdminApp } from './views/_AdminApp';
import { LifetimeBuyerSurvey } from './views/_LifetimeBuyerSurvey';
import { SubscriptionCancelConfirmation } from './views/_SubscriptionCancelConfirmation';

console.log('routes.js');

export default [
  {
    // Previous developers marked these to be left here in top of array
    path: '/generate/:generator?/',
    layout: DefaultLayout,
    component: Generate,
  },
  {
    // Previous developers marked these to be left here in top of array
    path: '/templates/:generator?/',
    layout: DefaultLayout,
    component: GenerateV2,
  },
  {
    // Previous developers marked these to be left here in top of array
    path: '/snippet/share/:userId/:arrayIndex/:saveOrResultId/',
    layout: PublicLayout,
    component: SharePage,
  },
  {
    path: '/',
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/write" />,
  },
  {
    path: '/welcome',
    layout: DefaultLayout,
    component: IntroVideo,
  },
  {
    path: '/history',
    layout: DefaultLayout,
    component: History,
  },
  {
    path: '/write',
    layout: DefaultLayout,
    component: CopyOptions,
  },
  {
    path: '/saved',
    layout: DefaultLayout,
    component: SavedItems,
  },

  {
    path: '/login',
    layout: AuthenticationLayout,
    component: SignIn,
  },
  {
    path: '/register',
    layout: AuthenticationLayout,
    component: SignUp,
  },
  {
    path: '/settings',
    layout: DefaultLayout,
    component: Settings,
    // component: UserProfileLite,
  },
  {
    path: '/cancel-confirmation',
    layout: DefaultLayout,
    component: SubscriptionCancelConfirmation,
    // component: UserProfileLite,
  },
  {
    path: '/settings-2',
    layout: DefaultLayout,
    component: UserProfileLite,
  },
  {
    path: '/profiles/',
    layout: DefaultLayout,
    component: Profiles,
  },
  {
    path: '/pricing',
    layout: DefaultLayout,
    component: StripeSubscriptions,
  },
  {
    path: '/plans',
    layout: DefaultLayout,
    component: PlansSettings,
  },
  {
    path: '/reset-password',
    layout: AuthenticationLayout,
    component: ResetPassword,
  },
  {
    path: '/forgot-password',
    layout: AuthenticationLayout,
    component: ForgotPassword,
  },

  // for new onboarding & requiring cc
  {
    path: '/try-it-free',
    layout: AuthenticationLayout,
    component: TryItFree,
  },

  {
    path: '/verify-email',
    layout: AuthenticationLayout,
    component: VerifyEmail,
  },

  {
    path: '/onboarding-1',
    layout: AuthenticationLayout,
    component: OnboardingPart1,
  },

  {
    path: '/onboarding-2',
    layout: AuthenticationLayout,
    component: OnboardingPart2,
  },

  {
    path: '/onboarding-3',
    layout: AuthenticationLayout,
    component: OnboardingPart3,
  },

  {
    path: '/pick-a-plan',
    layout: AuthenticationLayout,
    component: PickPlan2,
    // component: PickPlan, //29 and 49
  },

  {
    path: '/activating-plan',
    layout: AuthenticationLayout,
    component: VerifyPlan,
  },
  {
    path: '/skip-trial',
    layout: AuthenticationLayout,
    // component: SkipTrial,
    component: SkipTrial2,
  },

  {
    path: '/offer',
    layout: AuthenticationLayout,
    component: ChallengeUpsell,
  },

  {
    path: '/dashboard',
    layout: DefaultLayout,
    component: Dashboard,
  },

  {
    path: '/doc',
    layout: AuthenticationLayout,
    component: DocEditor,
  },
  {
    path: '/documents',
    layout: DefaultLayout,
    component: Documents,
  },
  // firebase emails include this link by default
  {
    path: '/auth-action',
    layout: AuthenticationLayout,
    component: AuthActionRedirect,
  },
  {
    path: '/custom',
    layout: DefaultLayout,
    component: CustomDashboard,
  },

  {
    path: '/custom-new',
    layout: DefaultLayout,
    component: CustomNew,
  },

  {
    path: '/generate-custom',
    layout: DefaultLayout,
    component: GenerateCustom,
  },
  {
    path: '/internal-admin',
    layout: DefaultLayout,
    component: AdminApp,
  },

  // for onboarding test where we do pay $1 for trial

  {
    path: '/verify-trial',
    layout: AuthenticationLayout,
    component: VerifyPaidTrial,
  },

  // for ask for CC right on app load.
  //  '/load-plan',
  //  '/verify-plan',
  //  '/set-up-account'
  {
    path: '/load-plan',
    layout: AuthenticationLayout,
    component: LoadPlan,
  },
  {
    path: '/verify-payment',
    layout: AuthenticationLayout,
    component: VerifyPayment,
  },

  // verify-plan
  {
    path: '/set-up-account',
    layout: AuthenticationLayout,
    component: CreateAccountAfterPayment,
  },

  {
    path: '/setup-1',
    layout: AuthenticationLayout,
    component: SetupPart1,
  },
  {
    path: '/setup-2',
    layout: AuthenticationLayout,
    component: SetupPart2,
  },
  {
    path: '/lifetime-survey',
    layout: AuthenticationLayout,
    component: LifetimeBuyerSurvey,
  },

  // previously, removed due to openai concerns
  // OLD V1....
  {
    path: '/custom-old',
    layout: DefaultLayout,
    component: CustomPrompt,
  },
  // old
  // {
  //   path: "/facebook",
  //   layout: DefaultLayout,
  //   component: FacebookAds
  // },
  // items from template below
  // {
  //   path: '/article/write-product-descriptions-that-convert',
  //   layout: PublicLayout,
  //   component: Article,
  // },
  // {
  //   path: "/profile/add",
  //   layout: DefaultLayout,
  //   component: AddNewProfile
  // },
  // {
  //   path: "/blog-overview",
  //   layout: DefaultLayout,
  //   component: BlogOverview
  // },

  // {
  //   path: "/add-new-post",
  //   layout: DefaultLayout,
  //   component: AddNewPost
  // },
  // {
  //   path: "/errors",
  //   layout: DefaultLayout,
  //   component: Errors
  // },

  {
    path: '/components-overview',
    layout: DefaultLayout,
    component: ComponentsOverview,
  },
  // {
  //   path: "/tables",
  //   layout: DefaultLayout,
  //   component: Tables
  // },
  // {
  //   path: "/blog-posts",
  //   layout: DefaultLayout,
  //   component: BlogPosts
  // },
];
