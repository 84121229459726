import React, { useEffect, useState } from 'react';
import { setUser } from '../actions';
import toast, { Toaster } from 'react-hot-toast';
import { Mixpanel } from '../mixpanel';
import { MixpanelSuper } from '../mixpanelSuper';
import { Container, Row, Col, Button } from 'shards-react';
import firebase from 'firebase';
import { config } from '../environments/config';
import PageTitle from '../components/common/PageTitle';
import { connect } from 'react-redux';
import { increment } from '../actions';
import { sendOtherSlackNotification } from '../utils/slackNotifications';

// Notes
// TODO
// Add Cover Photo of Bundle
// Add Video
// Add Stripe product
// add stripe purchase logic [x]
// add firebase logic
// add mixpanel accepted_challenge

export const ChallengeUpsell = () => {
  const [userData, setUserData] = useState({});
  const [userEmail, setUserEmail] = useState('');
  const [userId, setUserId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [planName, setPlanName] = useState();
  const [didUserStartAnnualPlanYet, setDidUserStartAnnualPlanYet] = useState(
    false
  );
  const [didUserSelectStarterPlan, setDidUserSelectStarterPlan] = useState();
  const _ = require('lodash');
  let apiAddress = _.get(config, 'backend.apiAddress');
  let currentPageUrl = window.location.href;
  if (currentPageUrl.includes('localhost')) {
    console.log('LOCALHOST');
    apiAddress = 'http://localhost:3001';
  }

  let isThisMobileDevice = window.navigator.userAgent.indexOf('Mobile') > -1;
  // console.log('WIDTH;', window.screen.width);
  let isThisAMobileScreen = window.innerWidth < 768;
  // alert(window.innerWidth);
  console.log('isThisAMobileScreen', isThisAMobileScreen);
  let paddingOnSides = isThisAMobileScreen ? '10px' : '25vw';
  console.log('paddingOnSides', paddingOnSides);

  const handleAcceptChallengeClick = async () => {
    console.log('handleAcceptChallengeClick');
    setIsLoading(true);
    let endpoint = apiAddress + '/api/v2/stripe/add-one-time-purchase';
    let response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: userId,
        userEmail: userData.e,
        stripeSessionId: userData.stripeSessionId,
      }),
    });
    let data = await response.json();
    console.log('data', data);
    if (data.status === 'success') {
      toast.success('Success');
      // update firebase userId with order
      let userRef = firebase
        .firestore()
        .collection('users')
        .doc(userId);

      await userRef.update({
        boughtOneClickChallenge: true,
        boughtOneClickChallengeDate: new Date(),
      });
      console.log('userRef updated with purchase ');
      // await sendOtherSlackNotification(
      //   `${userData.e} has accepted the challenge and should pay now! @dom`
      // );
      setIsLoading(false);

      return (window.location.href =
        '/dashboard?trialStarted=true&challenge=true');
    } else {
      toast.error(
        'Something went wrong. Please contact support and skip this page (link at bottom).'
      );
      await sendOtherSlackNotification(
        `Client side: ${userData.e} tried accepting the challenge but hit an error...`
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log('Challenge useEffect');
    console.log('waiting for firebase');
    MixpanelSuper.trackScreenView('challengeupsell');

    var url = new URL(window.location.href);
    let stripeSessionId = url.searchParams.get('session_id');
    let planNameInUrl = url.searchParams.get('p');

    if (planNameInUrl) {
      console.log('planNameInUrl', planNameInUrl);
      setPlanName(planNameInUrl);
      // if planNameInUrl contains the string annual - dont show monthly options!

      if (planNameInUrl.includes('annual')) {
        console.log('this is an annual plan');
        setDidUserStartAnnualPlanYet(true);
      }
      // if they selected a pro plan - dont show starter options
      if (planNameInUrl.includes('starter')) {
        setDidUserSelectStarterPlan(true);
      } else {
        setDidUserSelectStarterPlan(false);
      }
    }

    setIsLoading(true);
    // wait for firebase
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // console.log('user', user);
        setUserEmail(user.email);
        setUserId(user.uid);

        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then(function(doc) {
            if (doc.exists) {
              let data = doc.data();
              console.log('Challenge userData:', data);

              setUserData(data);
              setIsLoading(false);
            }
          });
      } else {
        console.log('user is not signed in');
        setIsLoading(false);
        toast.error('user not signed in');
      }
    });
  }, []);

  const mobileHeader = () => {
    return (
      <>
        <div
          style={{ width: '100%', backgroundColor: '#dc486b', minHeight: 70 }}
        >
          <center>
            <h6 style={{ padding: 10, color: 'white', paddingBottom: 0 }}>
              <strong>
                IMPORTANT: Do Not Close This Window, Do Not Click The "Back"
                Button
              </strong>
            </h6>
            <p style={{ color: 'white', paddingTop: 0, paddingBottom: 10 }}>
              Clicking your "back" button can result in your order being
              double-billed.
            </p>
          </center>
        </div>

        <Row
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: paddingOnSides,
            marginRight: paddingOnSides,
            paddingTop: 10,
          }}
        >
          <Col lg={12}>
            <Row
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: 10,
              }}
            >
              <img
                src={require('../images/stop-sign.png')}
                style={{
                  height: 50,
                }}
              />
            </Row>
            <Row>
              <h6 style={{ fontWeight: 'bolder', textAlign: 'center' }}>
                <span style={{ color: '#dc486b' }}>Wait!</span> Take advantage
                of this SPECIAL Bonus offer
                <br />
              </h6>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  const desktopHeader = () => {
    return (
      <>
        <div
          style={{ width: '100%', backgroundColor: '#dc486b', minHeight: 70 }}
        >
          <center>
            <h5 style={{ paddingTop: 10, color: 'white' }}>
              <strong>
                IMPORTANT: Do Not Close This Window, Do Not Click The "Back"
                Button
              </strong>
            </h5>
            <p style={{ color: 'white' }}>
              Clicking your "back" button can result in your order being
              double-billed.
            </p>
          </center>
        </div>

        <Row
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: paddingOnSides,
            marginRight: paddingOnSides,
            paddingTop: 50,
          }}
        >
          <Col lg="2">
            <img
              src={require('../images/stop-sign.png')}
              style={{ height: 70 }}
            />
          </Col>
          <Col lg="10">
            <h5 style={{ fontWeight: 'bolder', textAlign: 'center' }}>
              <span style={{ color: '#dc486b' }}>Wait!</span> Take advantage of
              this SPECIAL Bonus offer <br />
            </h5>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Container
      fluid
      style={{
        width: isThisAMobileScreen ? window.innerWidth : '100%',
      }}
    >
      <div>
        <Toaster />
      </div>

      {isThisMobileDevice ? mobileHeader() : desktopHeader()}

      {/* i frame and button */}
      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: paddingOnSides,
          marginRight: paddingOnSides,
          paddingTop: 20,
        }}
      >
        <iframe
          width="100%"
          height="400"
          src="https://www.youtube.com/embed/n9bn21UpTJY?controls=0&modestbranding=1&showinfo=0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        {isLoading ? (
          <div>
            <img
              src={require('../images/loading.gif')}
              style={{ height: 50 }}
            />
          </div>
        ) : (
          <Button
            style={{ margin: 20, height: 70, padding: '15 20 15 20' }}
            onClick={() => {
              MixpanelSuper.trackButtonClick('purchase_challenge');
              handleAcceptChallengeClick();
            }}
          >
            <span style={{ fontSize: 20 }}>
              Yes! Join the One Click Away Challenge
            </span>
            <p style={{ color: 'lightgrey' }}>
              <br></br>Clicking this button will charge your card $100 USD
            </p>
          </Button>
        )}
      </Row>
      {/* TODO? Add no thanks link here too?*/}

      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: paddingOnSides,
          marginRight: paddingOnSides,
          paddingTop: 20,
        }}
      >
        <h4
          style={{ paddingTop: 10, textAlign: 'center', fontWeight: 'bolder' }}
        >
          Here's EVERYTHING You'll Get Access To When You Join The One Click
          Away Challenge
        </h4>
        <img
          src={require('../images/OCABundle.png')}
          style={{ width: '100%', marginBottom: 30, borderRadius: 5 }}
        />
        <img
          src={require('../images/OCAStack.png')}
          style={{ width: '100%' }}
        />
        <h2 style={{ color: 'red', marginTop: 20 }}>
          Total Value:{' '}
          <span style={{ textDecoration: 'line-through' }}>$3,520</span>
        </h2>
      </Row>

      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: paddingOnSides,
          marginRight: paddingOnSides,
          paddingTop: 20,
        }}
      >
        <h2
          style={{
            textAlign: 'center',
            fontWeight: 'bolder',
            paddingTop: 50,
          }}
        >
          Join The One Click Away Challenge
        </h2>
      </Row>

      <Row
        style={{
          marginLeft: paddingOnSides,
          marginRight: paddingOnSides,
          display: 'block',
          backgroundColor: 'white',
          borderRadius: 20,
          justifyContent: 'center',
          padding: 30,
          boxShadow: '0px 0px 5px #888888',
        }}
      >
        <center>
          <p style={{ color: 'black', opacity: 0.8, fontSize: 20 }}>
            <strong>Huge 97% Discount</strong>
          </p>

          <strong style={{ color: '#007bff', fontSize: 24 }}>
            Only $100 Today!
          </strong>
        </center>
        <center>
          {isLoading ? (
            <div>
              <img
                src={require('../images/loading.gif')}
                style={{ height: 50 }}
              />
            </div>
          ) : (
            <Button
              style={{ margin: 20, height: 70, padding: '15 20 15 20' }}
              onClick={() => {
                MixpanelSuper.trackButtonClick('purchase_challenge');
                handleAcceptChallengeClick();
              }}
            >
              <span style={{ fontSize: 20 }}>
                Yes! Join the One Click Away Challenge
              </span>
              <p style={{ color: 'lightgrey' }}>
                <br></br>Clicking this button will charge your card $100 USD
              </p>
            </Button>
          )}
        </center>
      </Row>

      {/* Satisfaction */}
      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: paddingOnSides,
          marginRight: paddingOnSides,
          paddingTop: 20,
        }}
      >
        <h4
          style={{
            textAlign: 'center',
            fontWeight: 'bolder',
            paddingTop: 50,
          }}
        >
          Satisfaction Guaranteed
        </h4>

        <Row
          style={{ backgroundColor: 'white', borderRadius: 20, paddingTop: 20 }}
        >
          <Col
            md={4}
            style={{
              padding: 20,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={require('../images/MoneyBack.png')}
              style={{ width: '100%' }}
            />
          </Col>
          <Col
            md={8}
            style={{
              padding: '20 20 20 10',
              marginLeft: 0,
            }}
          >
            <p style={{ fontSize: '1rem' }}>
              Your order is backed by our{' '}
              <strong style={{ fontWeight: 'bolder' }}>
                unconditional 30-day money-back guarantee
              </strong>
              . If you’re not happy with the challenge or GaryAI for any reason
              (ANY reason at all) simply send message us within 30 days, and
              we’ll refund every penny of your investment -- no questions asked!
              <br></br>
              <br></br>
            </p>
          </Col>
        </Row>
      </Row>

      <Row
        style={{
          paddingTop: 50,
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <center>
          <a
            href="/dashboard?trialStarted=true&challenge=false"
            style={{ textDecoration: 'underline', fontSize: '1.2rem' }}
            onClick={() => {
              MixpanelSuper.trackButtonClick('rejected_challenge');
            }}
          >
            No thanks, I dont want to join the One Click Away Challenge at a
            HUGE discount! Take me to my dashboard.
          </a>
          <br></br>
          <em style={{ fontSize: '1.2rem' }}>
            (That means I’ll lose the chance to join this challenge forever).
          </em>
        </center>
      </Row>
      <div style={{ height: 100 }}></div>
    </Container>
  );
};
