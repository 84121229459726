import counterReducer from "./counter";
import loggedReducer from "./isLogged";
import {combineReducers} from "redux";
import subscribedReducer from "./isSubscribed";
import userReducer from "./user";
import periodReducer from "./periodEnds";
import creditsReducer from "./credits";
import firebaseUserReducer from "./firebaseUser";

const allReducers = combineReducers({
  counter: counterReducer,
  isLogged: loggedReducer,
  credits:creditsReducer,
  isSubscribed:subscribedReducer,
  daysRemaining:periodReducer,
  user:userReducer,
  firebaseUserData:firebaseUserReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined
  }

  return allReducers(state, action)
}

export default rootReducer;
