const firebaseUserReducer = (state = {empty:'empty'}, action) => {
  //TODO: Can I just track the entire firebase user object here? Probably dont want to update here. Still need api to reduce usage counter
  switch(action.type){
    case 'SET_FIREBASE_USER_DATA':
      return state = action.payload; //TODO: Pass Users Information here
    case 'UNSET_FIREBASE_USER_DATA':
      return state = {empty:'empty'};
    default:
      return state;
  }
}
export default firebaseUserReducer;
