import React, { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import {
  Card,
  Container,
  Row,
  Col,
  ListGroupItem,
  Form,
  FormInput,
  Button,
  ListGroup,
  Badge,
} from 'shards-react';
import { MixpanelSuper } from '../mixpanelSuper';
import firebase from 'firebase';

export const LifetimeBuyerSurvey = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState(false);

  const [businessName, setbusinessName] = React.useState('');
  const [businessSite, setbusinessSite] = React.useState('');
  // const [isLoading, setIsLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');

  const continueOnboarding = async () => {
    setIsLoading(true);
    console.log('continueOnboarding ');

    // update firestore userdoc value with businessName and businessS
    try {
      setIsLoading(false);
      console.log('Success! Go to next screen.');
      window.location.href = '/dashboard?trialStarted=true';
    } catch (e) {
      console.log(e);
      toast.error(`Error: ${e.message}`);
      setIsLoading(false);
    }
  };

  let isThisMobileDevice = window.navigator.userAgent.indexOf('Mobile') > -1;
  console.log('isThisMobileDevice', isThisMobileDevice);

  useEffect(() => {
    // executed only once
    console.log('useEffect lifetime Buyer Survey');
    console.log('OnboardingPart1 useEffect');

    // onboarding-1?verified=true
    console.log(window.location.href);
    var url = new URL(window.location.href);
    var vInUrl = url.searchParams.get('verified');
    console.log('waiting for firebase');
    MixpanelSuper.trackScreenView('onboarding_1');
    // wait for firebase
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        console.log('On Onboarding - Firebase ready and identified user');
        console.log(user.email);
        setEmail(user.email);

        setIsLoading(false);
      }
    });
  }, []);

  return isLoading ? (
    <div>
      <div>
        <Toaster />
      </div>
      <center style={{ marginTop: 100 }}>
        {!errorMessage && (
          <img alt="" src={require('../images/loading.gif')} height="120"></img>
        )}

        {errorMessage && (
          <div>
            <Row
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '30px',
              }}
            >
              <span>Step</span>

              <Badge
                style={{
                  backgroundColor: 'rgb(233, 240, 253)',
                  color: 'rgb(0, 123, 255)',
                  margin: 5,
                }}
              >
                1
              </Badge>

              <Badge
                style={{
                  backgroundColor: '#2F7DF6',
                  color: 'white',
                  margin: 5,
                }}
              >
                2
              </Badge>

              <Badge
                style={{
                  backgroundColor: '#D4DBE2',
                  color: '#AFB0B0',
                  margin: 5,
                }}
              >
                3
              </Badge>
            </Row>
            <h3>Something went wront</h3>
            <p>Please go back or contact support.</p>
            <Button
              size="sm"
              theme="primary"
              className="mb-2 mr-1"
              onClick={() => {
                window.$crisp.push(['do', 'chat:open']);
              }}
            >
              Get help
            </Button>
          </div>
        )}
      </center>
    </div>
  ) : (
    <Container fluid>
      <div>
        <Toaster />
      </div>
      <div style={{ margin: 30 }}>
        <center>
          <Row
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '30px',
            }}
          >
            <span>Step</span>

            <Badge
              style={{
                backgroundColor: 'rgb(233, 240, 253)',
                color: 'rgb(0, 123, 255)',
                margin: 5,
              }}
            >
              1
            </Badge>

            <Badge
              style={{
                backgroundColor: 'rgb(233, 240, 253)',
                color: 'rgb(0, 123, 255)',
                margin: 5,
              }}
            >
              2
            </Badge>

            <Badge
              style={{
                backgroundColor: '#2F7DF6',
                color: 'white',
                margin: 5,
              }}
            >
              3
            </Badge>
          </Row>
          <h4 className="m-0">Last thing, tell us about your goals</h4>
        </center>
      </div>

      <Container style={{ width: isThisMobileDevice ? '100%' : '80%' }}>
        {/* <Onboarding1Component /> */}
        <ListGroup flush>
          <ListGroupItem className="p-3">
            {true && (
              <Row>
                <Col>
                  <iframe
                    title="typeform"
                    src={
                      'https://garyai.typeform.com/to/nTwl0onk?email=' + email
                    }
                    width="100%"
                    height={500}
                    // height="100%"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen={true}
                  ></iframe>
                </Col>
              </Row>
            )}
          </ListGroupItem>
        </ListGroup>
        {isLoading ? (
          <button className={'mb-2 btn btn-sm btn-white mr-1'}>
            Loading... {'   '}
            <img
              alt=""
              src={require('../images/loading.gif')}
              height="20"
            ></img>
          </button>
        ) : (
          // {true && (
          <>
            <Row
              style={{
                //align center

                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '30px',
              }}
            >
              <center>
                <Button onClick={async () => await continueOnboarding()}>
                  Go to next screen
                </Button>
              </center>
            </Row>
            <Row
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <center>
                <span>
                  Please answer quick questions. It helps us personalize your
                  experience and make you more successful.
                </span>
              </center>
            </Row>
          </>
        )}
      </Container>
    </Container>
  );
};
