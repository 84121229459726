import React, {
  useState,
  // useEffect
} from 'react';
import { Container, Row, Col, Card, CardBody, Button } from 'shards-react';
// import Fire from '../config/fire';
import PageTitle from '../components/common/PageTitle';
import firebase from 'firebase';
import '../assets/stripe.css';
import { MixpanelSuper } from '../mixpanelSuper';

import { config } from '../environments/config';
import '../assets/upgrade.css';
import '../assets/payment.css';

const _ = require('lodash');

const apiAddress = _.get(config, 'backend.apiAddress');

const { loadStripe } = require('@stripe/stripe-js');
const {
  CardElement,
  Elements,
  useElements,
  useStripe,
  // MyCheckoutForm,
  // ElementsConsumer
} = require('@stripe/react-stripe-js');

const StripeSubscriptions = () => {
  const CheckoutForm = () => {
    const [isPaymentLoading, setPaymentLoading] = useState(false);
    // const [stripeCustomerId, setStripeCustomerId] = useState('');
    // const [stripeCustomerIdPending, setStripeCustomerIdPending] = useState(false);
    const [subscriptionIdPending, setSubscriptionIdPending] = useState(false);
    // const [stripeSubscriptionData, setStripeSubscriptionData] = useState({});
    const [
      priceId,
      // , setPriceId
    ] = useState('Bronze');
    // const [paymentMethodId, setPaymentMethodId] = useState(undefined);
    const [userHasSelectedPlanToBuy, setuserHasSelectedPlanToBuy] = useState(
      false
    );
    // const [stripeSubscription, setStripeSubscription] = useState({});
    // const [loading, setLoading] = useState(false);
    const [userHasSubscribed, setUserHasSubscribed] = useState(false);

    /** START GAT-19 Single Stripe API Call */
    let stripeSubscription = {};

    const getOrCreateCustomerAndSubscribe = ({ paymentMethodId, priceId }) => {
      return new Promise((resolve, reject) => {
        /** Added these 2 variables vvv because it seems to call the API twice?  Why is that?  Doesnt seem to be a problem so Ignore for now. */
        if (_.isEmpty(stripeSubscription) && subscriptionIdPending === false) {
          setSubscriptionIdPending(true);
          // Maybe I need to wait longer for load?
          /** NOTE ** Throws error if page is not loaded before calling.  I added a delay but its not good to wait fixed time */
          firebase
            .auth()
            .currentUser.getIdToken(/* forceRefresh */ true)
            .then((idToken) => {
              const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${_.get(
                    config,
                    'backend.bearerToken'
                  )}`,
                  firebaseidtoken: idToken,
                },
                body: JSON.stringify({
                  paymentMethodId: paymentMethodId,
                  priceId: priceId,
                }),
              };
              fetch(
                `${apiAddress}/v1/stripe/get-or-create-customer-and-subscribe`,
                requestOptions
              )
                .then((response) => {
                  if (response.status === 406) {
                    window.alert(
                      'Error Creating Stripe Customer, Please Try Again Later.'
                    );
                    // window.location.pathname = '/write';
                    reject();
                  }

                  if (response.status === 500) {
                    window.alert(
                      'Server Failure Creating Stripe Customer Please Try Again Later.'
                    );
                    // window.location.pathname = '/write';
                    reject();
                  }
                  return response.json();
                })
                .then((subscription) => {
                  // console.log('Stripe Customer Id', result.customer.id);
                  // console.log('Stripe Customer', result.customer);
                  stripeSubscription = subscription;
                  setSubscriptionIdPending(false);
                  resolve(subscription);
                });
            })
            .catch((e) => {
              console.log('Error occurred accessing our server', e);
              setSubscriptionIdPending(false);
              reject(e);
            });
        } else {
          console.log('Stripe Subscription already set');
          setSubscriptionIdPending(false);
          resolve(stripeSubscription);
        }
      });
    };

    /** END START GAT-19 Single Stripe API Call */

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = (event) => {
      setPaymentLoading(true);
      console.log('handle submit:');
      // Block native form submission.
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);

      // Use your card Element with other Stripe.js APIs
      stripe
        .createPaymentMethod({
          type: 'card',
          card: cardElement,
        })
        .then((returnedPaymentMethod) => {
          if (returnedPaymentMethod.error !== undefined) {
            window.alert('Error:' + returnedPaymentMethod.error.message);
            console.log(
              'Create Subscription [err]',
              returnedPaymentMethod.error
            );
          } else {
            // setPaymentMethodId(returnedPaymentMethod); //THESE ARE NOT USED // TODO: Dont use state variables for variables in scope you can just pass along.
            console.log('[PaymentMethod]', returnedPaymentMethod);
            /** GET OR CREATE CUSTOMER */
            getOrCreateCustomerAndSubscribe({
              // customerId:result.customer.id, //stripeCustomerId is supposed to update but why not just pass it right?
              paymentMethodId: returnedPaymentMethod.paymentMethod.id, //TODO: Concerned that these variables hav ethe same name in global and in create subscription function
              priceId: priceId, //Is this the right way to do this?
            })
              .then((subscriptionResult) => {
                if (
                  _.get(subscriptionResult, 'plan.product', undefined) ===
                  undefined
                ) {
                  // window.alert('Error: ' + subscriptionResult); //HAD TO REMOVE THIS BECAUSE THIS IS CALLED TWICE!!! NEED Function To Not Fire!
                  console.log('Create Subscription Error:', subscriptionResult);
                } else {
                  setPaymentLoading(false);
                  setUserHasSubscribed(true);
                  // window.alert("SUBSCRIPTION CREATED"); //This was firing even when response was error... why shouldn't catch fire?
                  console.log('subscription results:', subscriptionResult);
                  console.log(
                    'Create Subscription Success:',
                    subscriptionResult.subscription
                  );
                  intercomSetPaid();
                  // LeadDyno.recordPurchase(); //THIS CANT WORK IN REACT BC LeadDyno is imported via script tags in header - Says there's a stripe integration though.
                  try {
                    window.fbq('track', 'Purchase');
                    console.log('Purchase tag fired');
                  } catch (e) {
                    console.log('Purchase tag did not fire because ', e);
                  }

                  try {
                    window.$crisp.push([
                      'set',
                      'session:event',
                      ['user:purchase'],
                    ]);
                  } catch (e) {
                    console.log('Cant fire crisp purchase event because ', e);
                  }
                }
              })
              .catch((err) => {
                /** Catch Create Subscription Error */
                setPaymentLoading(false);
                window.alert('Error: Creating Subscription');
                console.log('Create Subscription [err]', err);
              });
          }
        })
        .catch((err) => {
          setPaymentLoading(false);
          window.alert('Error: ');
          /** Catch Create Payment Method */
          console.log('Create Payments [err]', err);
        });
    };

    const selectPlan = (value) => {
      console.log('user selected a plan... ', value);
      MixpanelSuper.selectedPlan(value);
      setuserHasSelectedPlanToBuy(value);
    };

    const intercomSetPaid = () => {
      try {
        window.Intercom('boot', {
          app_id: _.get(config, 'intercom.appId'),
          plan_type: userHasSelectedPlanToBuy,
        });
        console.log('set paid plan');
      } catch (e) {
        console.log('There was an issue with intercom: ', e);
      }
    };

    const launchIntercom = () => {
      try {
        window.Intercom('boot', {
          app_id: _.get(config, 'intercom.appId'),
          enterprise: true,
        });
        // window.Intercom('showNewMessage', 'I would like to discuss an Enterprise plan, my phone number # ... ');
        console.log('requested enterprise plan');
      } catch (e) {
        console.log('There was an issue with intercom: ', e);
      }
    };

    return (
      <Container>
        {!userHasSelectedPlanToBuy && (
          <Row noGutters className="page-header py-4">
            <PageTitle
              title="Choose a plan"
              subtitle="Pricing"
              md="12"
              className="ml-sm-auto mr-sm-auto"
            />
          </Row>
        )}

        {/* Plan options */}
        {!userHasSelectedPlanToBuy && (
          <Row>
            <Col lg="4">
              <Card small className="card-post mb-4">
                <CardBody style={{ textAlign: 'left' }}>
                  <h5 className="card-title">Free 7 Day Trial</h5>
                  <p className="card-text text-dark">Perfect for testing</p>
                  <h3 className="card-title">FREE</h3>
                  <p className="card-text text-dark">
                    No Credit Card Required{' '}
                  </p>
                  <p>
                    <i className="blue-check fas fa-check"> </i> 7 days of free
                    access
                  </p>
                  <p>
                    <i className="blue-check fas fa-check"> </i> 50 runs
                  </p>
                  <p>
                    <i className="blue-check fas fa-check"> </i> 24/7 email
                    support
                  </p>
                </CardBody>

                <Button
                  className=""
                  size="sm"
                  theme="secondary"
                  outline
                  disabled
                >
                  <span className="readable">Free trial</span>
                </Button>
              </Card>
            </Col>

            <Col lg="4">
              <Card small className="card-post mb-4">
                <CardBody style={{ textAlign: 'left' }}>
                  <h5 className="card-title">Marketer</h5>
                  <p className="card-text text-dark">
                    Perfect for a saavy marketer or business owner
                  </p>
                  <h3 className="card-title">$29/month</h3>
                  <p className="card-text text-dark">billed monthly</p>
                  <p>
                    <i className="blue-check fas fa-check"> </i> 200 runs per
                    month
                  </p>
                  <p>
                    <i className="blue-check fas fa-check"> </i> Access all copy
                    generators
                  </p>
                  <p>
                    <i className="blue-check fas fa-check"> </i> Build custom
                    generators{' '}
                  </p>
                  <p>
                    <i className="blue-check fas fa-check"> </i> 24/7 email
                    support
                  </p>
                </CardBody>

                <Button
                  size="sm"
                  theme="primary"
                  onClick={() => selectPlan('29.99')}
                >
                  <span className="readable">Upgrade</span>
                </Button>
              </Card>
            </Col>
            <Col lg="4">
              <Card small className="card-post mb-4">
                <CardBody style={{ textAlign: 'left' }}>
                  <h5 className="card-title">Enterprise</h5>
                  <p className="card-text text-dark">
                    Empower your entire team with a unified voice
                  </p>
                  <h3 className="card-title">Custom</h3>
                  <p className="card-text text-dark">billed monthly</p>
                  <p>
                    <i className="blue-check fas fa-check"> </i> unlimited runs
                    per month
                  </p>
                  <p>
                    <i className="blue-check fas fa-check"> </i> Access all copy
                    generators
                  </p>
                  {/* <p><i className="blue-check fas fa-check"> </i> Build custom generators </p> */}
                  <p>
                    <i className="blue-check fas fa-check"> </i> collaboration
                    tools
                  </p>
                  <p>
                    <i className="blue-check fas fa-check"> </i> chrome
                    extension
                  </p>
                  <p>
                    <i className="blue-check fas fa-check"> </i> 24/7 email
                    support
                  </p>
                </CardBody>

                <Button
                  size="sm"
                  theme="secondary"
                  outline
                  onClick={() => launchIntercom}
                >
                  <span className="readable">Contact Us</span>
                </Button>
              </Card>
            </Col>

            <Row style={{ margin: 'auto' }}>
              <em>Satisfaction guaranteed! Upgrade or cancel anytime!</em>
            </Row>
          </Row>
        )}
        {isPaymentLoading && (
          <img
            alt=""
            src={require('../images/loading.gif')}
            height="100"
            style={{ marginLeft: '40%' }}
          ></img>
        )}
        {/* Payment form */}
        {userHasSelectedPlanToBuy && !userHasSubscribed && (
          <div style={{ padding: '3rem' }}>
            <div style={{ maxWidth: '500px', margin: '0 auto' }}>
              <h3>Secure checkout</h3>
              <p>You will be billed $29.99 per month</p>
              <form
                onSubmit={handleSubmit}
                style={{ display: 'block', width: '100%' }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <CardElement
                    className="card"
                    options={{ style: { base: { backgroundColor: 'white' } } }}
                  />
                  <button
                    className="pay-button"
                    type="submit"
                    disabled={isPaymentLoading}
                  >
                    {isPaymentLoading ? 'Loading...' : 'Pay'}
                  </button>
                </div>
              </form>
              <span>
                <i className="fa fa-lock"></i>
                <em> Secure Payments by Stripe</em>
              </span>
            </div>
          </div>
        )}
        {userHasSubscribed && (
          <Row noGutters className="page-header py-4">
            <PageTitle
              title="You Have Subscribed"
              subtitle="Welcome"
              md="12"
              className="ml-sm-auto mr-sm-auto"
            />
          </Row>
        )}
        {userHasSubscribed && (
          <Row>
            <Col lg="4">
              <Card small className="card-post mb-4">
                <CardBody style={{ textAlign: 'center' }}>
                  <h3 className="card-title">Success!</h3>
                  <h5 className="card-title">Let's get writing</h5>
                </CardBody>

                <Button
                  size="sm"
                  theme="primary"
                  onClick={() => {
                    window.location.href = '/write';
                  }}
                >
                  Go to Templates
                </Button>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    );
  };

  const stripePromise = loadStripe(_.get(config, 'stripe.publicKey'));

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default StripeSubscriptions;
