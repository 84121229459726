// 1356302 is Gary AI friends ( people who havent given credit card info yet )
// /api/v2/mc/list-change

let apiAddress = 'https://garyaiapi.herokuapp.com';
let currentPageUrl = window.location.href;
if (currentPageUrl.includes('localhost')) {
  console.log('LOCALHOST');
  apiAddress = 'http://localhost:3001';
}

// LEADS
const addToList = async (userEmail, firstName, lastName) => {
  try {
    console.log('addToList');
    // post to server
    let endpoint = apiAddress + '/api/v2/mc/list-change';
    let response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userEmail: userEmail,
        listChangeType: 'add',
        firstName: firstName,
        lastName: lastName,
      }),
    });
    let data = await response.json();
    console.log('data', data);

    if (data.status === 'success') {
      console.log('success');
    } else {
      console.log('error');
    }
  } catch (e) {
    console.log('error with addToList', e);
  }
};

const removeFromList = async (userEmail) => {
  try {
    console.log('removeFromList');
    // post to server
    let endpoint = apiAddress + '/api/v2/mc/list-change';
    let response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userEmail: userEmail,
        listChangeType: 'remove',
      }),
    });
    let data = await response.json();
    console.log('data', data);

    if (data.status === 'success') {
      console.log('success');
    } else {
      console.log('error');
    }
  } catch (e) {
    console.log('error with addToList', e);
  }
};

// CUSTOMERS

// /mc/customer-list-change
const addToPayingCustomerList = async (userEmail, firstName, lastName) => {
  try {
    console.log('addToPayingCustomerList');
    // post to server
    let endpoint = apiAddress + '/api/v2/mc/customer-list-change';
    let response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userEmail: userEmail,
        listChangeType: 'add',
        firstName: firstName,
        lastName: lastName,
      }),
    });
    let data = await response.json();
    console.log('data', data);

    if (data.status === 'success') {
      console.log('success');
    } else {
      console.log('error');
    }
  } catch (e) {
    console.log('error with addToList', e);
  }
};
const removeFromPayingCustomerList = async (userEmail) => {
  try {
    console.log('removeFromList');
    // post to server
    let endpoint = apiAddress + '/api/v2/mc/customer-list-change';
    let response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userEmail: userEmail,
        listChangeType: 'remove',
      }),
    });
    let data = await response.json();
    console.log('data', data);

    if (data.status === 'success') {
      console.log('success');
    } else {
      console.log('error');
    }
  } catch (e) {
    console.log('error with addToList', e);
  }
};

export {
  addToList,
  removeFromList,
  addToPayingCustomerList,
  removeFromPayingCustomerList,
};
