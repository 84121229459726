import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  ListGroupItem,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  Button,
  ButtonGroup,
  FormTextarea,
  Alert,
  Modal,
  ModalBody,
  ModalHeader,
} from 'shards-react';
import {
  copyToClipboard,
  handleCopyGenerationResponse2,
} from '../utils/generatorPageHelpers';
import { sendSlackNotificationForCopyGeneration } from '../utils/slackNotifications';

import Fire from '../config/fire';
import PageTitle from '../components/common/PageTitle';

import { MixpanelSuper } from '../mixpanelSuper';

import firebase from 'firebase';
import { config } from '../environments/config';
import '../assets/upgrade.css';
import '../assets/generate.css';
import { countNewLines } from '../utils/utils';
import toast, { Toaster } from 'react-hot-toast';

import { useSelector, useDispatch } from 'react-redux';

const _ = require('lodash');

let apiAddress = _.get(config, 'backend.apiAddress');
let currentPageUrl = window.location.href;

if (currentPageUrl.includes('localhost')) {
  console.log('LOCALHOST');
  apiAddress = 'http://localhost:3001';
}

console.log('generate to: ', apiAddress);

const GenerateCustom = ({ match: { params } }) => {
  const [generator, setGenerator] = useState('loading');
  const [pageTitle, setPageTitle] = useState('Custom Generator');
  const [selectedCustomGenerator, setSelectedCustomGenerator] = useState();
  // const [generatorExamples, setGeneratorExamples] = useState([]);
  const [generatorExists, setGeneratorExists] = useState(false);
  const [copyTypeUnderscore, setCopyTypeUnderscore] = useState(
    'default-generator'
  );
  const [copyTypeCamelCase, setCopyTypeCamelCase] = useState(
    'default-generator'
  );
  const [lastGeneratedPrompts, setLastGeneratedPrompts] = useState({});

  const [remainingCredits, setRemainingCredits] = useState(50);

  const [remainingDays, setRemainingDays] = useState(30);
  const [remaingDaysLabel, setRemaingDaysLabel] = useState(
    'days left in your free trial.'
  );
  const [
    subscriptionDetailsHaveLoaded,
    setsubscriptionDetailsHaveLoaded,
  ] = useState(false);

  const [isSubscriber, setIsSubscriber] = useState(false);
  const [firebaseUserData, setFirebaseUserData] = useState({});

  const [showVideo, setShowVideo] = useState(false);
  const [showHelper, setShowHelper] = useState(true);

  const [localStars, setLocalStars] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorModalText, setShowErrorModalText] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const [usersCustomCopyOptions, setUsersCustomCopyOptions] = useState([]);
  const [selectedCustomCopyOption, setSelectedCustomCopyOption] = useState(
    null
  );

  const intercomTrackCredits = (left) => {
    if (true) {
      return new Promise((resolve, reject) => {
        try {
          window.Intercom('boot', {
            app_id: _.get(config, 'intercom.appId'),
            remainingCredits: remainingCredits,
          });
          console.log('set remaining credits');
          resolve();
        } catch (e) {
          console.log('There was an issue with intercom: ', e);
          reject(e);
        }
      });
    }
  };

  const copyLinkToClipboard = (link) => {
    MixpanelSuper.copyShareSnippet('generate_page');
    console.log('Custom link copied To clipboard');
    navigator.clipboard.writeText(link);
    setdisplayMessage('Sharable Link Copied!');
    setTimeout(() => {
      setdisplayMessage(null);
    }, 3000);
  };

  const checkSubscription = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function(idToken) {
          const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${_.get(config, 'backend.bearerToken')}`,
              firebaseidtoken: idToken,
            },
            // body: JSON.stringify()
          };
          fetch(
            `${apiAddress}/v1/stripe/get-and-update-subscription`,
            requestOptions
          )
            .then((response) => {
              // console.log("response", response);
              if (response.status === 406) {
                window.alert('');
                window.location.reload();
              }

              if (response.status === 500) {
                window.alert("Sorry we can't process your request right now.");
                window.location.reload();
              }
              if (response.status === 402) {
                return Promise.reject('Payment required to continue');
              }
              return response.json();
            })
            .then((data) => {
              resolve(data);
              console.log('Successful Subscription Check');
            });
        })
        .catch((e) => {
          reject(e);
          console.log('Error occured while accessing current firebase user', e);
        });
    });
  };

  const setFirebaseUserToStateRedux = (userData) => {
    /** NO LONGER REDUX */
    setFirebaseUserData(userData); //dispatch(setFirebaseUserData(userData)); /** Redux */
  };

  const setCreditsRedux = (userData) => {
    /** NO LONGER REDUX */
    let credits =
      _.get(userData, 'smu', 0) -
      _.get(userData, 'pu', 0) +
      _.get(userData, 'ec', 0);
    if (!credits) {
      credits = 0;
    }
    setRemainingCredits(credits);
  };

  const setDaysRemainingRedux = (userData) => {
    /** NO LONGER REDUX */
    let timeNow = Date.now();
    //If no subscription
    let se = _.get(userData, 'se', undefined);
    let te = _.get(userData, 'te', undefined);
    let days;
    if (se) {
      if (se * 1000 >= timeNow) {
        /** Note. Stripe in seconds not MS */
        days = Math.ceil((se * 1000 - timeNow) / 86400000);
      } else {
        days = 0;
      }
    } else if (te) {
      if (te >= timeNow) {
        days = Math.ceil((te - timeNow) / 86400000);
      } else {
        days = 0;
      }
    } else {
      days = '?';
    }
    setRemainingDays(days); //dispatch(setDaysRemaining(days)); /** Redux */
  };

  const setSubscribedRedux = (userData) => {
    /** NO LONGER REDUX */
    let se = _.get(userData, 'se', undefined);
    if (se > Date.now()) {
      setIsSubscriber(true); // dispatch(setSubscribed);  /** Redux */
      setRemaingDaysLabel('days left in your subscription period');
    }
    // this.props.setSubscribed(userData);
  };

  const showUserDetails = async (recursive = true) => {
    /** TODO: DO I NEED TO DO THIS EVERY TIME IF USING REDUX?????
     * Alternatively could I decrement the counter and assume
     * they're only using on 1 tab/ it wont be the end of the world if they refresh
     * */
    Fire.shared.getUserDetails().then((userData) => {
      if (!userData) {
        if (recursive) {
          setTimeout(function() {
            showUserDetails(false);
          }, 50); /** Retries Once */
        }
      } else {
        intercomTrackCredits(remainingCredits);
        /** THIS IS THE MESSIEST FUCKING LOGIC. */
        /** TODO: REMOVE AFTER ADDING REDUX */
        setFirebaseUserToStateRedux(userData); /** NO LONGER REDUX */
        if (userData) {
          setCreditsRedux(userData);
          /** NO LONGER REDUX */
          setDaysRemainingRedux(userData);
          /** NO LONGER REDUX */
          setSubscribedRedux(userData);
          /** NO LONGER REDUX */
          setsubscriptionDetailsHaveLoaded(true);
        }

        if (remainingCredits <= 0) {
          checkSubscription().then((data) => {
            /** Check if they have a subscription it's just not set in DB yet. */
            if (recursive) {
              /** Only retry once */
              showUserDetails(false);
            }
          });
        }
      }
    });
  };

  const generators = {
    'value-propositions': {
      //GEN: hero Text and H2
      pageTitle: 'Alternative Value Propositions',
      mixpanelLegacyUnderScore: 'value_propositions',
      mixpanelLegacyCamel: 'valuePropositions',
      introWistia: '', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-product.svg'),
      prompts: [
        {
          name: 'action',
          title: '(What) Action or product:',
          // inputInstructions:'Enter your products name',
          placeHolder: 'Shopping at local retail hardware stores',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'value proposition',
          title: 'Value Proposition.  The Above Helps people...:',
          inputInstructions:
            'Finish this sentence.  The above action or product helps people...',
          placeHolder: 'find the right tool by handling multiple brands',
          textArea: false,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'Hardware Store',
          prompts: [
            'shopping at local retail hardware stores',
            'find the right tool by handling multiple brands',
          ],
        },
      ],
    },
    'what-who-why': {
      //GEN: hero Text and H2
      pageTitle: "Extract and Determine Your Project's What, Who & Why",
      mixpanelLegacyUnderScore: 'what_who_why',
      mixpanelLegacyCamel: 'whatWhoWhy',
      introWistia: '', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-product.svg'),
      prompts: [
        {
          name: 'company',
          title: 'Company or Project Name:',
          // inputInstructions:'Enter your products name',
          placeHolder: 'The Home Depot',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'description',
          title: 'Describe your company, we are:',
          inputInstructions: '1-2 sentences describing what you company does',
          placeHolder:
            'a massive retail hardware store for home improvement projects',
          textArea: true,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'WeWork',
          prompts: [
            'WeWork',
            'a co-working space company that rents flexible seating and private offices to companies of all sizes',
          ],
        },
      ],
    },
    'hero-message-and-subheadline': {
      //GEN:50 hero Text and H2
      pageTitle: 'Hero Message & Sub-Headline',
      mixpanelLegacyUnderScore: 'hero_message_and_subheadline',
      mixpanelLegacyCamel: 'heroMessageAndSubheadline',
      introWistia: '', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-product.svg'),
      prompts: [
        {
          name: 'company',
          title: 'Company or Project Name:',
          // inputInstructions:'Enter your products name',
          placeHolder: 'FCP Euro',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'what',
          title: '(What) What your company "Is":',
          inputInstructions:
            'A sentence describing the function of your company or product',
          placeHolder: 'we sell quality foreign replacement car parts',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'whom',
          title: '(Who) We serve:',
          inputInstructions: '1-2 personas you serve',
          placeHolder: 'auto shops and DIY car enthusiasts',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'why',
          title: '(Why) We exist because:',
          inputInstructions: '1-2 reasons why this exist in the world',
          placeHolder:
            'because anyone can fix their own car and auto shops cost a lot',
          textArea: false,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'Gary AI',
          prompts: [
            'Gary AI',
            'we create auto generated copy writing',
            'we serve marketers and entrepreneurs',
            'we exist because creativity takes time and experience. We outsource those tasks to a computer model.',
          ],
        },
      ],
    },
    'website-intro-paragraph': {
      //GEN: hero Text and H2
      pageTitle: 'Website Intro Paragraph',
      mixpanelLegacyUnderScore: 'website_intro_paragraph',
      mixpanelLegacyCamel: 'websiteIntroParagraph',
      introWistia: '', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-product.svg'),
      prompts: [
        {
          name: 'company',
          title: 'Company or Project Name:',
          // inputInstructions:'Enter your products name',
          placeHolder: 'The Home Depot',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'what',
          title: '(What) What your company "Is":',
          inputInstructions:
            'A sentence describing the function of your company or product',
          placeHolder:
            'a retail hardware store for DIY home improvement projects',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'whom',
          title: '(Who) We serve:',
          inputInstructions: '1-2 personas you serve',
          placeHolder: 'home owners and contractors',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'why',
          title: '(Why) We exist because:',
          inputInstructions: '1-2 reasons why this exist in the world',
          placeHolder:
            'because home owners and contractors need convenient access to large quantities of hardware in one place',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'hero message',
          title: 'Website Hero Message:',
          inputInstructions:
            'use the hero message generator, select or edit your favorite output.',
          placeHolder: 'Get the Right Tools for the Job',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'sub-headline',
          title: 'Sub-Headline:',
          inputInstructions:
            'use the hero message generator, select or edit your favorite sub-headline.',
          placeHolder:
            "With over 2,000 stores in the U.S., we're ready to help you get the right tools for every home improvement project.",
          textArea: false,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'The Home Depot',
          prompts: [
            'The Home Depot',
            'a retail hardware store for DIY home improvement projects',
            'home owners and contractors',
            'because home owners and contractors need convenient access to large quantities of hardware in one place',
            'Get the Right Tools for the Job',
            "With over 2,000 stores in the U.S., we're ready to help you get the right tools for every home improvement project.",
          ],
        },
      ],
    },
    'customer-welcome-email': {
      //GEN: hero Text and H2
      pageTitle: 'Customer Welcome Email',
      mixpanelLegacyUnderScore: 'customer_welcome_email',
      mixpanelLegacyCamel: 'customerWelcomeEmail',
      introWistia: '', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-product.svg'),
      prompts: [
        {
          name: 'company',
          title: 'Company or Project Name:',
          // inputInstructions:'Enter your products name',
          placeHolder: 'The Home Depot',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'what',
          title: '(What) What your company "Is":',
          inputInstructions:
            'A sentence describing the function of your company or product',
          placeHolder:
            'a retail hardware store for DIY home improvement projects',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'whom',
          title: '(Who) We serve:',
          inputInstructions: '1-2 personas you serve',
          placeHolder: 'home owners and contractors',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'why',
          title: '(Why) We exist because:',
          inputInstructions: '1-2 reasons why this exist in the world',
          placeHolder:
            'because home owners and contractors need convenient access to large quantities of hardware in one place',
          textArea: false,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'The Home Depot',
          prompts: [
            'The Home Depot',
            'a retail hardware store for DIY home improvement projects',
            'home owners and contractors',
            'because home owners and contractors need convenient access to large quantities of hardware in one place',
          ],
        },
      ],
    },

    'product-descriptions': {
      pageTitle: 'Generate Product Descriptions',
      mixpanelLegacyUnderScore: 'product_descriptions',
      mixpanelLegacyCamel: 'productDescriptions',
      introWistia: '5rpt6b3ddg', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-product.svg'),
      prompts: [
        {
          name: 'name',
          title: 'Product name:',
          // inputInstructions:'Enter your products name',
          placeHolder: 'Vanilla bean candle',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'features',
          title: 'Product features:',
          inputInstructions:
            'Enter a comma separated list of 2-5 specific product characteristics',
          placeHolder: 'vanilla candle, handmade, 25 hour burn time',
          textArea: false,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'Candle',
          prompts: [
            'vanilla bean candle',
            'vanilla candle, handmade, 25 hour burn time',
          ],
        },
        {
          name: 'Computer Monitor',
          prompts: ['computer monitor', '27 inches, 1080p, great picture'],
        },
        {
          name: 'Tax Software',
          prompts: ['personal tax software', 'fast, convenient, secure'],
        },
      ],
    },
    'facebook-ads': {
      pageTitle: 'Facebook Ads',
      mixpanelLegacyUnderScore: 'facebook_ads',
      mixpanelLegacyCamel: 'facebookAds',
      introWistia: 'vbqjzd128j', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-2.svg'),
      prompts: [
        {
          name: 'industry',
          title: 'What industry is your business?',
          inputInstructions: 'Enter your industry',
          placeHolder: 'beauty ecommerce website',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'product',
          title: 'What is this ad promoting?',
          inputInstructions: 'Enter a specific product name or promotion',
          placeHolder: 'anti-aging skin cream',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'company',
          title: "What's your domain?",
          // inputInstructions:'Base domain name & extension only',
          placeHolder: 'glow.com',
          textArea: false,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'GaryAI',
          prompts: [
            'AI copywriting website',
            'autogenerated Facebook ads',
            'garyai.com',
          ],
        },
        {
          name: "L'oreal Paris",
          prompts: [
            'beauty ecommerce website',
            'color vibrancy purple shampoo for color treated hair',
            'lorealparisusa.com',
          ],
        },
        {
          name: 'Ace Hardware',
          prompts: [
            'brick and mortar hardware store',
            'new cordless air compressor',
            'acehardware.com',
          ],
        },
      ],
    },
    pas: {
      pageTitle: 'Pain Agitate Solution Formula',
      mixpanelLegacyUnderScore: 'pain_agitate_solution',
      mixpanelLegacyCamel: 'painAgitateSolution',
      introWistia: 'wj8dimasb4', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-pas.svg'),
      prompts: [
        {
          name: 'company',
          title: 'Company or domain name:',
          // inputInstructions:'company\'s public name or base domain name & extension only',
          placeHolder: 'Hotels.com',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'description',
          title: 'Description:',
          inputInstructions:
            'Enter 1-2 sentences describing your product and the value it provides',
          placeHolder: 'A website that helps you find hotels faster and easier',
          textArea: true,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'Jeep',
          prompts: [
            'Jeep',
            'A SUV that helps you fit more family in the car and go on more adventures',
          ],
        },
        {
          name: 'GaryAI',
          prompts: [
            'GaryAI.com',
            'A website that helps you write better ad copy',
          ],
        },
        {
          name: 'HelloFresh',
          prompts: [
            'HelloFresh.com',
            'A website that helps families eat healthier by delivering nutritious meal kits every week',
          ],
        },
      ],
    },
    aida: {
      pageTitle: 'Attention Interest Desire Action Formula',
      mixpanelLegacyUnderScore: 'attention_interest_desire_action',
      mixpanelLegacyCamel: 'attentionInterestDesireAction',
      introWistia: 'wj8dimasb4', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-aida.svg'),
      prompts: [
        {
          name: 'company',
          title: 'Company or domain name:',
          // inputInstructions:'company\'s public name or base domain name & extension only',
          placeHolder: 'Hotels.com',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'description',
          title: 'Description:',
          inputInstructions:
            'Enter 1-2 sentences describing your product and the value it provides',
          placeHolder: 'A website that helps you find hotels faster and easier',
          textArea: true,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        // {
        //   name: 'Colgate',
        //   prompts: ['Colgate', 'A teeth whitener that turns yellow teeth into pearly whites within days.']
        // },
        // {
        //   name: 'GaryAI',
        //   prompts: ['GaryAI.com', 'A website that uses AI to help you write better ad copy']
        // },
        // {
        //   name: 'AdAgency.com',
        //   prompts: ['AdAgency.com', 'A digital marketing agency that helps restaurants generate leads with Facebook ads.']
        // }
      ],
    },
    headlines: {
      pageTitle: 'Generate Headlines',
      mixpanelLegacyUnderScore: 'headlines',
      mixpanelLegacyCamel: 'headlines',
      introWistia: 'vbqjzd128j', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-2.svg'),
      prompts: [
        {
          name: 'product',
          title: 'What kind of product are you promoting?',
          inputInstructions:
            'Enter 1 sentence describing your product and the value it provides',
          placeHolder: 'Website building tool, Dog brush to remove hair, etc',
          textArea: false,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'Golf Clubs',
          prompts: ['newest golf clubs'],
        },
        {
          name: 'Desk Chair',
          prompts: ['comfortable office desk chair'],
        },
        {
          name: 'No Code Tool',
          prompts: ['a no code website building tool'],
        },
      ],
    },
    'blog-post-title-ideas': {
      pageTitle: 'Generate Blog Post Titles',
      mixpanelLegacyUnderScore: 'blog_post_titles',
      mixpanelLegacyCamel: 'blogPostTitles',
      introWistia: 'vbqjzd128j', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-2.svg'),
      prompts: [
        {
          name: 'keyword',
          title: 'What is your blog post about?',
          placeHolder: 'keto diet recipes',
          textArea: false,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'Start a business',
          prompts: ['Start a business'],
        },
        {
          name: 'Facebook marketing tips',
          prompts: ['Facebook marketing tips'],
        },
        {
          name: 'Summer dresses',
          prompts: ['Summer dresses'],
        },
      ],
    },
    'blog-post-topic-ideas': {
      pageTitle: 'Generate Blog Post Ideas',
      mixpanelLegacyUnderScore: 'blog_post_topic_ideas',
      mixpanelLegacyCamel: 'blogPostTopicIdeas',
      introWistia: 'vbqjzd128j', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-2.svg'),
      prompts: [
        {
          name: 'keyword',
          title: 'What is something your customers would search?',
          placeHolder: 'keto supplements',
          textArea: false,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'Start a business',
          prompts: ['Start a business'],
        },
        {
          name: 'Facebook marketing tips',
          prompts: ['Facebook marketing tips'],
        },
        {
          name: 'Summer dresses',
          prompts: ['Summer dresses'],
        },
      ],
    },
    'viral-video-ideas': {
      pageTitle: 'Generate Viral Video Ideas',
      mixpanelLegacyUnderScore: 'viral_video_ideas',
      mixpanelLegacyCamel: 'viralVideoIdeas',
      introWistia: 'vbqjzd128j', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-2.svg'),
      prompts: [
        {
          name: 'audience',
          title: 'Who is your audience?',
          placeHolder: 'business owners, football players, etc',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'topic',
          title: 'Whats your audiences desired result?',
          placeHolder: 'grow their business, throw the ball further, etc',
          textArea: false,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'Fortnite',
          prompts: ['gamers', 'get better at fortnite'],
        },
        {
          name: 'Starbucks',
          prompts: ['working professionals', 'find a new coffee flavor'],
        },
      ],
    },
    'keyword-ideas': {
      pageTitle: 'Generate Keyword Ideas',
      mixpanelLegacyUnderScore: 'keyword_ideas',
      mixpanelLegacyCamel: 'keywordIdeas',
      introWistia: 'vbqjzd128j', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-2.svg'),
      prompts: [
        {
          name: 'keyword',
          title: 'What is your target keyword?',
          placeHolder: 'keto diet, waterproof headphones, etc',
          textArea: false,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'Stop hair loss',
          prompts: ['Stop hair loss'],
        },
        {
          name: 'Car insurance chicago',
          prompts: ['Car insurance chicago'],
        },
      ],
    },
    'viral-contest-ideas': {
      pageTitle: 'Generate Viral Contest Ideas',
      mixpanelLegacyUnderScore: 'contest_ideas',
      mixpanelLegacyCamel: 'contestIdeas',
      introWistia: 'vbqjzd128j', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-2.svg'),
      prompts: [
        {
          name: 'company',
          title: 'Company name:',
          placeHolder: 'Apple',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'product',
          title: 'What product do you want to promote?',
          placeHolder: 'Wireless headphones',
          textArea: false,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'Apple',
          prompts: ['Apple', 'Wireless headphones'],
        },
        {
          name: 'GaryAI',
          prompts: ['GaryAI', 'AI powered copywriting platform'],
        },
      ],
    },
    loading: {
      pageTitle: 'Loading...',
      prompts: [],
      examples: [],
    },
    notFound: {
      pageTitle: 'Generator Not Found',
      prompts: [],
      examples: [],
    },
    empty: {
      pageTitle: 'Please Specify A Generator',
      prompts: [],
      examples: [],
    },
    // 'pas':{
    //   'prompts':['company','description']
    // },
    // 'facebook':{
    //   'prompts':['company','description']
    // },
    // 'productDescriptions':{
    //   'prompts':['company','description']
    // },
  };

  const [copyResults, setcopyResults] = useState([]);
  const [hasResults, sethasResults] = useState(false);
  const [loading, setloading] = useState(false);
  const [displayMessage, setdisplayMessage] = useState('');
  const [likedItems, setlikedItems] = useState([]);
  const [showUpgradeModal, setshowUpgradeModal] = useState(false);

  let handleLoad = (params) => {
    let gen = _.get(
      params,
      'generator',
      'empty'
    ); /** TODO: BUG: Route does not work if value not set - does not default to empty */
    if (Object.keys(generators).includes(gen)) {
      // setGenerator(gen);
      setcopyResults([]);
      // setPageTitle(generators[gen].pageTitle);
      // setGeneratorExists(true);
      // setGeneratorExamples(_.get(generators,`${gen}`,[]));
      setShowVideo(false);
      setShowHelper(true);
    } else {
      setGenerator('notFound');
      setPageTitle('Generator Not Found');
    }

    setCopyTypeUnderscore(gen);
    if (generators[gen].mixpanelLegacyUnderScore !== undefined) {
      setCopyTypeUnderscore(generators[gen].mixpanelLegacyUnderScore);
    } else if (gen !== 'loading') {
      setCopyTypeUnderscore(gen);
    }

    setCopyTypeCamelCase(gen);
    if (generators[gen].mixpanelLegacyCamel !== undefined) {
      setCopyTypeCamelCase(generators[gen].mixpanelLegacyCamel);
    } else if (gen !== 'loading') {
      setCopyTypeCamelCase(gen);
    }
  };

  const getUsersCustomCopyGenerators = async (userId) => {
    console.log('getUsersCustomCopyGenerators for', userId);
    // setIsLoading(true);

    if (!userId) {
      console.log('no userId');
      return;
    }
    // get all documents from firebase collection and set them to customCopyOptions
    const customCopyOptions = [];
    const customCopyOptionsRef = firebase
      .firestore()
      .collection('customPrompts')
      .doc(userId)
      .collection('saved');

    const snapshot = await customCopyOptionsRef.get();
    snapshot.forEach((doc) => {
      let data = doc.data();
      data.id = doc.id;
      customCopyOptions.push(data);
    });
    console.log('setting userCustomCopyOptions >> ', customCopyOptions);

    if (customCopyOptions.length === 0) {
      console.log('No custom options.');
      toast((t) => (
        <span>
          You don't have any custom content generators yet.
          <br />
          <br />
          <center>
            <Button
              theme="accent"
              onClick={() =>
                (window.location.href = 'https://app.garyai.com/custom')
              }
            >
              Go create one
            </Button>
          </center>
        </span>
      ));
    } else {
      // sort by the ts timestamp to have most recent first
      customCopyOptions.sort((a, b) => {
        return b.ts - a.ts;
      });
    }
    setUsersCustomCopyOptions(customCopyOptions);
    return customCopyOptions;
    // setIsLoading(false);
  };

  const changeCurrentCustomGenerator = async (
    newGeneratorId,
    arrayOfCopyOptions = []
  ) => {
    console.log('changeCurrentCustomGenerator for', newGeneratorId);
    setSelectedCustomCopyOption(newGeneratorId); // update dropdown..
    let arrayOfCustomGenerators;
    // if it was passed as argument, use that since the state update wasnt ready.
    if (arrayOfCopyOptions.length === 0) {
      arrayOfCustomGenerators = usersCustomCopyOptions;
    } else {
      arrayOfCustomGenerators = arrayOfCopyOptions;
    }
    console.log('>> looking arrayOfCustomGenerators', arrayOfCustomGenerators);
    // see if this id is in usersCustomCopyOptions
    const found = arrayOfCustomGenerators.find(
      (item) => item.id === newGeneratorId
    );

    if (found) {
      console.log('found item:', found);
      setPageTitle('Custom: ' + (found.name || found.title));
      setSelectedCustomGenerator(found);
    } else {
      console.log('not found');
      return;
    }
  };

  useEffect(() => {
    console.log('GenerateCustom UseEffect');
    firebase.auth().onAuthStateChanged(function(user) {
      console.log('changed');
      if (user) {
        console.log('generate useEffect, have user details');

        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then(async function(doc) {
            if (doc.exists) {
              let data = doc.data();
              console.log('User Document data:', data);
              setUserDetails(data);
              // get the custom copy options for the dropdown....
              console.log('start get custom');
              let copyOptions = await getUsersCustomCopyGenerators(data.uid);
              console.log('Got custom');

              let currentUrlString = window.location.href;
              let currentUrl = new URL(currentUrlString);
              let urlId = currentUrl.searchParams.get('id');
              console.log('urlId:', urlId);
              if (urlId) {
                console.log('valid urlId:', urlId);
                changeCurrentCustomGenerator(urlId, copyOptions);
              }
            }
          });
      }
    });

    MixpanelSuper.trackScreenView('generatecustom');

    // old logic
    Fire.shared.checkAndWaitForFirebaseLoad().then(() => {
      if (generator != _.get(params, 'generator', 'none')) {
        console.log('use effect loaded');
        handleLoad(params);
        showUserDetails();
      }
    });
  }, []);

  const saveResultsAndHandleResponse = (data, requestPrompts) => {
    // this function handles UI to display results, and saves all results to firebase.
    if (typeof data == 'string') {
      data = [data];
    }

    showUserDetails(); //TODO: ****************** USE DATA RETURNED FROM API INSTEAD OF CALLING FIREBASE AGAIN.
    /** NOTE: Data Input Should Be An Array */
    handleCopyGenerationResponse2(
      data,
      copyResults,
      sethasResults,
      setcopyResults,
      setloading
    );
  };

  // handleCopyGenerationV2
  const handleCustomCopyGeneration = async (requestDetails) => {
    console.log('handleCustomCopyGeneration', requestDetails);
    const { firebaseJwtToken, requestBody, requestPrompts } = requestDetails;

    // server expects: copyTitle, examples, userData

    let generateEndpoint = `${apiAddress}/api/v2/generate-custom`;
    const response = await fetch(generateEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${_.get(config, 'backend.bearerToken')}`,
        firebaseidtoken: firebaseJwtToken,
      },
      body: JSON.stringify(requestBody),
    });
    console.log('response', response);

    if (response.status === 200) {
      const resp = await response.json();
      console.log('resp', resp);
      let copyArray = resp.data;
      console.log('copyArray', copyArray);
      // set copy results.
      saveResultsAndHandleResponse(copyArray, requestPrompts);
    } else {
      // handle error
      console.log('Response.status != 200... handle error');
      console.log('response statusText', response.statusText);
      console.log('response status', response.status);
      // if no valid subscription...
      if (response.status === 402) {
        // toast.error(`You need an active trial or subscription to do that`);
        toast.error(
          (t) => (
            <span>
              Oops. You will need to pick a plan or start a free trial do that.
              Please:
              <br />
              <br />
              <center>
                <Button
                  theme="accent"
                  onClick={() =>
                    (window.location.href =
                      'https://app.garyai.com/pick-a-plan')
                  }
                >
                  Go to plans
                </Button>
              </center>
            </span>
          ),
          { duration: 9000 }
        );
      } else {
        toast.error(`Error ${response.status}: ` + response.statusText);
      }
      setloading(false);
    }
  };

  const generateCopyV2 = async () => {
    if (!selectedCustomGenerator) {
      toast.error('Please select a custom generator');
      return;
    }
    setloading(true);
    console.log('selectedCustomGenerator', selectedCustomGenerator);
    let pageTitleReplacedWithUnderscores = pageTitle.replace(/ /g, '_');
    console.log(
      'mixpanel event name: customv2_' + pageTitleReplacedWithUnderscores
    );
    MixpanelSuper.generateSnippet(
      'customv2_' + pageTitleReplacedWithUnderscores
    );
    // console.log('make new request to', apiAddress);
    setShowHelper(false);
    setShowVideo(false);
    setloading(true);

    // legacy variable
    let requestPrompts = {
      title: selectedCustomGenerator.title,
      examples: selectedCustomGenerator.examples,
    };

    // get JWT token from firebase. pass true to force refresh.
    const firebaseJwtToken = await firebase.auth().currentUser.getIdToken();
    const userId = await firebase.auth().currentUser.uid;
    const userDocRef = await firebase
      .firestore()
      .collection('users')
      .doc(userId);

    const userDoc = await userDocRef.get();
    const userData = userDoc.data();
    console.log('userData', userData);
    console.log('firebaseJwtToken', firebaseJwtToken);

    const requestBody = {
      copyTitle: selectedCustomGenerator.title,
      examples: selectedCustomGenerator.examples,
      userId: userId,
      userData: userData,
      prompts: requestPrompts,
      customCopyGeneratorId: selectedCustomGenerator.id,
    };

    console.log('Request body:', requestBody);

    if (userDetails.forceCc) {
      console.log('This is a user that signed up with forceCc.. Use API v2');

      try {
        await handleCustomCopyGeneration({
          requestBody,
          firebaseJwtToken,
          requestPrompts,
        });
      } catch (e) {
        console.log('e', e);
        toast.error(`Error: ${e.message}`);
        setloading(false);
      }

      // send slack notification
      try {
        let message = `${userData.e} generated a custom copy to ${pageTitle}`;

        console.log('slackmessage sending:', message);
        await sendSlackNotificationForCopyGeneration(message);
      } catch (e) {
        console.log(
          'Could not send a slack notification about this generation because ',
          e
        );
      }
    } else {
      console.log('This is an old user. generate copy with old API');
      toast.error(
        'Hi there. You have a legacy account. Please contact support for this feature'
      );
      setloading(false);
    }
  };

  /** For saving results to saved table.  Eventually we will have 1 table "results" and it will be updates with tags like "saved" "rating", and it already has a field for "original" response */
  const saveResultToFirebase = (
    id,
    index,
    copyTypeUnderscoreVar,
    copyTypeCamelCaseVar,
    lastGeneratedPromptsVar
  ) => {
    //TODO: RETURN SAVED ID AND SAVE SOMEWHERE IN THE DOM SO YOU CAN SAVE NEW VALUES TO IT/ UPDATE
    let output = document.getElementById(id).value;
    MixpanelSuper.saveSnippet(copyTypeUnderscoreVar);
    console.log('saveResultToFirebase');
    // setsaveCopyResultInProgress(true)

    if (Array.isArray(output)) {
      console.log('This is an array');
      const stringifyOutput = output[0];
      output = stringifyOutput;
    }

    const resultToSave = {
      type: 'custom',
      title: selectedCustomGenerator.title,
      id: selectedCustomCopyOption,
      value: output,
      prompt: JSON.stringify(lastGeneratedPromptsVar),
    };
    console.log('selectedCustomGenerator.title', selectedCustomGenerator.title);
    console.log('resultToSave', resultToSave);

    return new Promise((resolve, reject) => {
      Fire.shared.addSavedCopyResult(resultToSave).then((res) => {
        console.log('res >> ', res);
        // setsaveCopyResultInProgress(false)
        setdisplayMessage('Added to your saved items');
        // Push this index item to an array, so we can visually display liked items already
        if (likedItems.length === 0) {
          setlikedItems([index]);
        } else {
          setlikedItems((likedItems) => [...likedItems, index]);
        }

        setTimeout(() => {
          setdisplayMessage(null);
        }, 3000);
      });
    });
  };

  /** STAR RATINGS */
  const saveStars = (output, index, starCount) => {
    console.log('applied ' + starCount + ' to id ' + index);
    let changeStars = [...localStars];
    changeStars[index] = starCount;
    setLocalStars(changeStars);

    return new Promise((resolve, reject) => {
      Fire.shared
        .saveStarRating(output.id, starCount, 'results')
        .finally(() => {
          resolve();
        });
    });
  };

  const StarRating = ({ rating, item, idx }) => {
    // console.log(rating)
    // console.log(rating === 5)

    if (rating === 5) {
      return (
        <div className="rating">
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 5)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 4)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 3)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 2)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 1)}
          >
            ⭐
          </span>
        </div>
      );
    } else if (rating === 4) {
      return (
        <div className="rating">
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 5)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 4)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 3)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 2)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 1)}
          >
            ⭐
          </span>
        </div>
      );
    } else if (rating === 3) {
      return (
        <div className="rating">
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 5)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 4)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 3)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 2)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 1)}
          >
            ⭐
          </span>
        </div>
      );
    } else if (rating === 2) {
      return (
        <div className="rating">
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 5)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 4)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 3)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 2)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 1)}
          >
            ⭐
          </span>
        </div>
      );
    } else if (rating === 1) {
      return (
        <div className="rating">
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 5)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 4)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 3)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 2)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 1)}
          >
            ⭐
          </span>
        </div>
      );
    } else {
      return (
        <div className="rating">
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 5)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 4)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 3)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 2)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 1)}
          >
            ☆
          </span>
        </div>
      );
    }
  };

  const SetVideoTrue = () => {
    setShowVideo(true);
    setShowHelper(false);
  };

  const SetVideoFalse = () => {
    setShowVideo(false);
    setShowHelper(true);
  };

  return (
    <Container fluid className="main-content-container px-4">
      <div>
        <Toaster />
      </div>

      {displayMessage && (
        <Container
          fluid
          className="px-0"
          style={{
            zIndex: 99,
            position: 'fixed',
            right: '0px',
            width: '300px',
          }}
        >
          <Alert className="mb-0">
            <i className="fa fa-info mx-2"></i> {displayMessage}
          </Alert>
        </Container>
      )}

      <Modal open={showErrorModal}>
        <ModalHeader>Oops. Something went wrong.</ModalHeader>
        <ModalBody>
          <span>
            Error Occured. Please try again later or contact support if this
            issue persists. Message: {showErrorModalText}
          </span>
          <br></br>
          <Button
            style={{ marginTop: 20 }}
            onClick={() => {
              setShowErrorModal(false);
            }}
          >
            Ok
          </Button>
        </ModalBody>
      </Modal>

      <Row noGutters className="page-header py-4">
        <PageTitle
          title={pageTitle}
          subtitle="Create Content"
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
        <span style={{ display: 'none' }}>
          {copyTypeUnderscore}
          {copyTypeCamelCase}doesnt update unless in the dom
        </span>
      </Row>

      <Row>
        <Col lg="4" className="pb-5">
          {/* Inputs to generate copy */}
          <Card
            small
            style={{
              marginBottom: '10px',
              paddingBottom: '20px',
              boxShadow: '0px 0px 10px #e6e6e6',
            }}
          >
            {/* <PromptInputForm/> */}
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    <p>Custom Content Type:</p>

                    {/* <p>selectedCustomCopyOption: {selectedCustomCopyOption}</p> */}
                    <FormSelect
                      id="feInputState"
                      value={selectedCustomCopyOption}
                      onChange={(e) => {
                        changeCurrentCustomGenerator(e.target.value);
                      }}
                    >
                      <option>Choose...</option>

                      {usersCustomCopyOptions.map((item, idx) => (
                        <option key={idx} value={item.id}>
                          {item.name || item.title || 'Untitled ' + item.id}
                        </option>
                      ))}
                    </FormSelect>
                    <br />
                    <br />
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
            {!loading && remainingCredits > 0 && (
              <Button
                theme="accent"
                className="fade-in"
                onClick={generateCopyV2}
              >
                Generate
              </Button>
            )}

            {loading && remainingCredits > 0 && (
              <Button theme="secondary" className="disabled">
                Generate
              </Button>
            )}
            {remainingCredits <= 0 && (
              <Button
                theme="info"
                onClick={() => {
                  window.location.href = '/settings?upgradeInfo=true';
                }}
              >
                <i style={{ color: '#FFFFFF' }} className="material-icons">
                  grade
                </i>{' '}
                Upgrade to Generate
              </Button>
            )}
          </Card>
        </Col>
        {copyResults.length > 0 && (
          <Col lg="8" className="pb-5">
            {/* List of results */}
            {copyResults.map((copyResult, index) => (
              <Card
                small
                className="mb-4"
                key={index}
                style={{ boxShadow: '0px 0px 10px #e6e6e6' }}
              >
                <ListGroup flush>
                  <ListGroupItem className="p-3">
                    <Row>
                      <Col>
                        <Form>
                          <Row form>
                            <Col md="12" className="form-group">
                              {index === 0 && (
                                <FormTextarea
                                  // id={'resultTextArea0'}
                                  id={'resultTextArea' + index}
                                  // rows={countNewLines(copyResult)}
                                />
                              )}
                              {index !== 0 && (
                                <FormTextarea
                                  id={'resultTextArea' + index}
                                  rows={countNewLines(copyResult)}
                                  defaultValue={copyResult}
                                />
                              )}
                            </Col>
                          </Row>

                          <Row>
                            <Col md="8">
                              {/* <Button
                                outline
                                theme="primary"
                                className="mb-2 mr-1"
                                onClick={
                                  () => generateCopyV2()
                                  // generateCopyWithDefaults(copyResult.prompt)
                                }
                              >
                                Generate More Like This
                              </Button> */}
                            </Col>
                            <Col md="4" style={{ textAlign: 'right' }}>
                              <ButtonGroup size="sm">
                                <Button
                                  theme="white"
                                  onClick={() =>
                                    copyToClipboard(
                                      'resultTextArea' + index,
                                      copyTypeUnderscore,
                                      setdisplayMessage
                                    )
                                  }
                                >
                                  Copy
                                </Button>
                                {likedItems.includes(index) && (
                                  <Button theme="secondary">Saved</Button>
                                )}
                                {!likedItems.includes(index) && (
                                  <Button
                                    theme="white"
                                    onClick={() =>
                                      saveResultToFirebase(
                                        'resultTextArea' + index,
                                        index,
                                        copyTypeUnderscore,
                                        copyTypeCamelCase,
                                        lastGeneratedPrompts
                                      )
                                    }
                                  >
                                    Save
                                  </Button>
                                )}
                                <Button
                                  theme="white"
                                  onClick={() =>
                                    copyLinkToClipboard(
                                      `https://app.garyai.com/snippet/share/${copyResult.uid}/0/${copyResult.id}/`
                                    )
                                  }
                                >
                                  Share
                                </Button>
                              </ButtonGroup>
                              {/* <br></br>
                              <div
                                style={{
                                  paddingTop: '5px',
                                  textAlign: 'right !important',
                                }}
                              >
                                <StarRating
                                  rating={localStars[index]}
                                  item={copyResult}
                                  idx={index}
                                />
                              </div> */}
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            ))}
            <Row>
              {loading ? (
                <img
                  alt=""
                  src={require('../images/loading.gif')}
                  height="30"
                ></img>
              ) : (
                <Button
                  theme="accent"
                  className="fade-in"
                  onClick={generateCopyV2}
                  style={{ marginLeft: '10px' }}
                >
                  Generate more
                </Button>
              )}
            </Row>
          </Col>
        )}

        {copyResults.length === 0 && loading && (
          <Col lg="8" className="pb-5">
            {/* loading image */}
            <center>
              <img
                alt=""
                src={require('../images/loading.gif')}
                height="70"
              ></img>
            </center>
          </Col>
        )}

        {!showVideo && copyResults.length == 0 && showHelper && (
          <Col lg="8" className="pb-5">
            {/* Inputs to generate copy */}
            <div style={{ textAlign: 'center' }}>
              <Col>
                <h5>Need help?</h5>
                <p>Watch this video to ensure the most intelligent results.</p>
                <Button
                  outline
                  className="readable"
                  theme="dark"
                  onClick={SetVideoTrue}
                >
                  <i className="fa fa-play" aria-hidden="true"></i> Play Video
                </Button>
              </Col>
            </div>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default GenerateCustom;
