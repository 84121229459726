import React, { useEffect, useState } from 'react';

import toast, { Toaster } from 'react-hot-toast';
import { Mixpanel } from '../mixpanel';
import {
  Container,
  Row,
  Col,
  ListGroupItem,
  Button,
  ListGroup,
} from 'shards-react';

import firebase from 'firebase';
import { config } from '../environments/config';
import { sendOtherSlackNotification } from '../utils/slackNotifications';
import { MixpanelSuper } from '../mixpanelSuper';
import {
  removeFromList,
  addToPayingCustomerList,
} from '../utils/mailchimpHelpers';

export const VerifyPlan = () => {
  const [isLoading, setIsLoading] = useState(true);

  let isThisMobileDevice = window.navigator.userAgent.indexOf('Mobile') > -1;
  console.log('isThisMobileDevice', isThisMobileDevice);

  useEffect(() => {
    console.log('VerifyPlan useEffect');

    console.log(window.location.href);
    var url = new URL(window.location.href);

    let stripeSessionId = url.searchParams.get('session_id');
    let planName = url.searchParams.get('plan_name');

    if (planName) {
      console.log('planName is in url', planName);
    }

    MixpanelSuper.trackScreenView('verify_plan');

    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        console.log('On Verify Plan - Firebase ready and identified user');

        if (stripeSessionId) {
          console.log('Session id in url');
          console.log('update firebase user with stripe session id');
          // update firestore
          let updatedObject = {
            stripeSessionId: stripeSessionId,
            paid: true,
            purchaseStartTime: new Date(),
            purchaseStartTimeReadable: new Date().toLocaleString(),
            stripeBillingEmail: user.email, // in case they change their email later...
          };
          if (planName) {
            updatedObject.planName = planName;
          }
          //
          // if plan name contains pro
          if (planName.includes('pro')) {
            updatedObject.smu = 777;
          }
          console.log('updatedObject', updatedObject);

          firebase
            .firestore()
            .collection('users')
            .doc(user.uid)
            .update(updatedObject)
            .then(function() {
              console.log('Success. Document updated with plan details!');
              // toast.success(
              //   'Success! Your plan has been verified. Redirecting you now...'
              // );
              // send slack message
              try {
                let newSignUpMessage = `New paid trial started: ${user.email}. Mobile?: ${isThisMobileDevice}`;
                if (planName) {
                  newSignUpMessage += ` Plan: ${planName}`;
                }
                sendOtherSlackNotification(newSignUpMessage);
              } catch (e) {
                console.log('Couldnt send slack message', e);
              }

              // fire fb pixel
              try {
                window.fbq('track', 'StartTrial', {
                  value: '29.99',
                  currency: 'USD',
                  predicted_ltv: '29.99',
                });
              } catch (e) {
                console.log(
                  'Could not fire ViewContent pixel event because',
                  e
                );
              }

              try {
                // set session data.
                window.$crisp.push([
                  'set',
                  'session:data',
                  ['entered_cc', 'true'],
                ]);
                // set event
                window.$crisp.push([
                  'set',
                  'session:event',
                  ['user:trial_start'],
                ]);
              } catch (e) {
                console.log('Unable to push to  crisp', e);
              }

              try {
                // remove from free trial email list
                removeFromList(user.email);
                // Known bug - user.fn and user.ln is not defined in mailchimp. TODO add firebase request to get this data later
                addToPayingCustomerList(user.email, user.fn, user.ln);
                console.log(
                  'mailchimp remove from one list and added to the other'
                );
              } catch (e) {
                console.log('error with mailchimp funcitons', e);
              }

              // setTimeout(() => {
              //   window.location.href = '/offer?p=' + planName;
              // }, 500);

              // Re adding the redirect to skip-tria... performs better than the other offer.
              setTimeout(() => {
                window.location.href = '/skip-trial?p=' + planName;
              }, 500);

              // old redirect to dashboard
              // setTimeout(() => {
              //   window.location.href = '/dashboard?trialStarted=true';
              // }, 1500);
            })
            .catch(function(error) {
              console.error('Error updating document: ', error);
              toast.error('Something went wrong: ' + error);
              setIsLoading(false);
            });
        } else {
          console.log('No session id in url');
          toast.error('Something went wrong: No session id in url');
          setIsLoading(false);
        }
      } else {
        toast.error('Unable to identify you. Please try again.');
      }
    });
  }, []);

  return (
    <Container fluid>
      <div>
        <Toaster />
      </div>
      <div style={{ margin: 30 }}>
        {/* <center>
          <img
            style={{
              width: '70px',
              height: '70px',
              padding: 8,
              backgroundColor: 'white',
              borderRadius: 12,
              marginBottom: 10,
            }}
            src={require('../images/gradientIcon.png')}
          ></img>

          <h4 className="m-0">Activating Account</h4>
          <p>Please waiting while we get things set up. </p>
        </center> */}
      </div>

      <Container style={{ width: isThisMobileDevice ? '75%' : '40%' }}>
        {isLoading && (
          <center>
            <img
              src={require('../images/loading.gif')}
              style={{ height: 100, margin: 30 }}
            />

            {/* <p>Verifying purchase, please wait.</p> */}
          </center>
        )}
      </Container>
    </Container>
  );
};
