import React, { useEffect, useState } from 'react';
import { setUser } from '../actions';
import toast, { Toaster } from 'react-hot-toast';
import { Mixpanel } from '../mixpanel';
import { MixpanelSuper } from '../mixpanelSuper';
import { Container, Row, Col, Button } from 'shards-react';
import firebase from 'firebase';
import { config } from '../environments/config';
import PageTitle from '../components/common/PageTitle';
import { connect } from 'react-redux';
import { increment } from '../actions';
import { sendOtherSlackNotification } from '../utils/slackNotifications';

// Notes

// V1
// if they select starter monthly, upsell end trial + upgrade to annual starter [x]
// if they selected any annual plan, hide the annual option for now [x]

// if they selected pro monthly, upsell end trial + upgrade to annual pro [x]
// if they select pro annual, just show pro upgrade [ TO DO]

// V2
// if they select starter monthly, upsell end trial + end trial & upgrade annual
// if they select starter annual, upsell end trial + end trial & upgrade to PRO ANNUAL
// if they select PRO monthly, upsell end trial + end trial & upgrade to PRO ANNUAL
// if they select PRO annual, upsell end trial only.

export const SkipTrial = () => {
  const [userData, setUserData] = useState({});
  const [userEmail, setUserEmail] = useState('');
  const [userId, setUserId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [planName, setPlanName] = useState();
  const [didUserStartAnnualPlanYet, setDidUserStartAnnualPlanYet] = useState(
    false
  );
  const [didUserSelectStarterPlan, setDidUserSelectStarterPlan] = useState();
  const _ = require('lodash');
  let apiAddress = _.get(config, 'backend.apiAddress');
  let currentPageUrl = window.location.href;
  if (currentPageUrl.includes('localhost')) {
    console.log('LOCALHOST');
    apiAddress = 'http://localhost:3001';
  }

  let isThisMobileDevice = window.navigator.userAgent.indexOf('Mobile') > -1;
  // console.log('WIDTH;', window.screen.width);
  let isThisAMobileScreen = window.innerWidth < 768;
  // alert(window.innerWidth);
  console.log('isThisAMobileScreen', isThisAMobileScreen);
  let paddingOnSides = isThisAMobileScreen ? '10px' : '25vw';
  console.log('paddingOnSides', paddingOnSides);

  // for ending trial early
  const handleSkipTrialClick = async () => {
    console.log('handleSkipTrialClick');
    setIsLoading(true);
    let endpoint = apiAddress + '/api/v2/stripe/update-trial-subscription';
    let response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: userId,
        userEmail: userData.e,
        stripeSessionId: userData.stripeSessionId,
      }),
    });
    let data = await response.json();
    console.log('data', data);
    if (data.status === 'success') {
      toast.success('Success');
      // update firebase userId with skipped trial
      let userRef = firebase
        .firestore()
        .collection('users')
        .doc(userId);

      await userRef.update({
        skippedTrial: true,
        skippedTrialDate: new Date(),
      });
      console.log('userRef updated with skipped trial ');

      await sendOtherSlackNotification(
        `${userData.e} has skipped free trial (${planName}) and should pay now! @dom`
      );
      setIsLoading(false);
      // return window.location.href = '/';
      return (window.location.href = '/dashboard?trialStarted=true&skip=true');
    } else {
      toast.error('Something went wrong');
      await sendOtherSlackNotification(
        `${userData.e} tried skipping free trial but hit an error...`
      );
      setIsLoading(false);
    }
  };

  // for ending trial early and upgrading to annual plan
  const handleSkipTrialAndChangePlanClick = async (oldPlanName) => {
    console.log('handleSkipTrialAndChangePlanClick', oldPlanName);

    setIsLoading(true);
    let endpoint =
      apiAddress + '/api/v2/stripe/update-and-change-trial-subscription';
    let response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: userId,
        userEmail: userData.e,
        stripeSessionId: userData.stripeSessionId,
        oldPlanName: oldPlanName,
      }),
    });
    let data = await response.json();
    console.log('data', data);
    if (data.status === 'success') {
      toast.success('Success');
      // update firebase userId with skipped trial
      let userRef = firebase
        .firestore()
        .collection('users')
        .doc(userId);

      await userRef.update({
        skippedTrial: true,
        skippedTrialDate: new Date(),
        switchedToAnnual: true,
      });
      console.log('userRef updated with skipped trial ');

      await sendOtherSlackNotification(
        `${userData.e} has skipped free trial (and upgraded to annual for ${oldPlanName}) and should pay now! @dom`
      );
      setIsLoading(false);
      // return window.location.href = '/';
      return (window.location.href = '/dashboard?trialStarted=true&skip=true');
    } else {
      toast.error('Something went wrong');
      await sendOtherSlackNotification(
        `${userData.e} tried skipping free trial but hit an error...`
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log('Settings useEffect');
    console.log('waiting for firebase');
    MixpanelSuper.trackScreenView('skiptrial');

    var url = new URL(window.location.href);
    let stripeSessionId = url.searchParams.get('session_id');
    let planNameInUrl = url.searchParams.get('p');

    if (planNameInUrl) {
      console.log('planNameInUrl', planNameInUrl);
      setPlanName(planNameInUrl);
      // if planNameInUrl contains the string annual - dont show monthly options!

      if (planNameInUrl.includes('annual')) {
        console.log('this is an annual plan');
        setDidUserStartAnnualPlanYet(true);
      }
      // if they selected a pro plan - dont show starter options
      if (planNameInUrl.includes('starter')) {
        setDidUserSelectStarterPlan(true);
      } else {
        setDidUserSelectStarterPlan(false);
      }
    }

    setIsLoading(true);
    // wait for firebase
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // console.log('user', user);
        setUserEmail(user.email);
        setUserId(user.uid);

        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then(function(doc) {
            if (doc.exists) {
              let data = doc.data();
              console.log('skiptrial userData:', data);

              setUserData(data);
              setIsLoading(false);
            }
          });
      } else {
        console.log('user is not signed in');
        setIsLoading(false);
        toast.error('user not signed in');
      }
    });
  }, []);

  const mobileHeader = () => {
    return (
      <>
        <div
          style={{ width: '100%', backgroundColor: '#dc486b', minHeight: 70 }}
        >
          <center>
            <h6 style={{ padding: 10, color: 'white', paddingBottom: 0 }}>
              <strong>
                IMPORTANT: Do Not Close This Window, Do Not Click The "Back"
                Button
              </strong>
            </h6>
            <p style={{ color: 'white', paddingTop: 0, paddingBottom: 10 }}>
              Clicking your "back" button can result in your order being
              double-billed.
            </p>
          </center>
        </div>

        <Row
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: paddingOnSides,
            marginRight: paddingOnSides,
            paddingTop: 10,
          }}
        >
          <Col lg={12}>
            <Row
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: 10,
              }}
            >
              <img
                src={require('../images/stop-sign.png')}
                style={{
                  height: 50,
                }}
              />
            </Row>
            <Row>
              <h6 style={{ fontWeight: 'bolder', textAlign: 'center' }}>
                <span style={{ color: '#dc486b' }}>Wait!</span> Your Order Is
                Not Complete Yet. <br />
                Please Watch This Important Message Below Now
              </h6>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  const desktopHeader = () => {
    return (
      <>
        <div
          style={{ width: '100%', backgroundColor: '#dc486b', minHeight: 70 }}
        >
          <center>
            <h5 style={{ paddingTop: 10, color: 'white' }}>
              <strong>
                IMPORTANT: Do Not Close This Window, Do Not Click The "Back"
                Button
              </strong>
            </h5>
            <p style={{ color: 'white' }}>
              Clicking your "back" button can result in your order being
              double-billed.
            </p>
          </center>
        </div>

        <Row
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: paddingOnSides,
            marginRight: paddingOnSides,
            paddingTop: 50,
          }}
        >
          <Col lg="2">
            <img
              src={require('../images/stop-sign.png')}
              style={{ height: 70 }}
            />
          </Col>
          <Col lg="10">
            <h5 style={{ fontWeight: 'bolder', textAlign: 'center' }}>
              <span style={{ color: '#dc486b' }}>Wait!</span> Your Order Is Not
              Complete Yet. <br />
              Please Watch This Important Message Below Now
            </h5>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Container
      fluid
      style={{
        width: isThisAMobileScreen ? window.innerWidth : '100%',
      }}
    >
      <div>
        <Toaster />
      </div>

      {isThisMobileDevice ? mobileHeader() : desktopHeader()}

      {/* Red banner */}
      {/* <div style={{ width: '100%', backgroundColor: '#dc486b', minHeight: 70 }}>
        <center>
          <h5 style={{ paddingTop: 10, color: 'white' }}>
            <strong>
              IMPORTANT: Do Not Close This Window, Do Not Click The "Back"
              Button
            </strong>
          </h5>
          <p style={{ color: 'white' }}>
            Clicking your "back" button can result in your order being
            double-billed.
          </p>
        </center>
      </div> */}
      {/* End of red banner */}

      {/* Main CTA */}
      {/* <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: paddingOnSides,
          marginRight: paddingOnSides,
          paddingTop: isThisAMobileScreen ? 10 : 50,
        }}
      >
        {!isThisAMobileScreen && (
          <Col lg="2">
            <img
              src={require('../images/stop-sign.png')}
              style={{ height: 70 }}
            />
          </Col>
        )}
        <Col lg={isThisAMobileScreen ? 12 : 2}>
          {isThisAMobileScreen ? (
            <>
              <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
                <img
                  src={require('../images/stop-sign.png')}
                  style={{
                    height: 50,
                  }}
                />
              </Row>
              <Row>
                <h6 style={{ fontWeight: 'bolder', textAlign: 'center' }}>
                  <span style={{ color: '#dc486b' }}>Wait!</span> Your Order Is
                  Not Complete Yet. <br />
                  Please Watch This Important Message Below Now
                </h6>
              </Row>
            </>
          ) : (
            <h5 style={{ fontWeight: 'bolder', textAlign: 'center' }}>
              <span style={{ color: '#dc486b' }}>Wait!</span> Your Order Is Not
              Complete Yet. <br />
              Please Watch This Important Message Below Now
            </h5>
          )}
        </Col>
      </Row> */}

      {/* i frame and button */}
      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: paddingOnSides,
          marginRight: paddingOnSides,
          paddingTop: 20,
        }}
      >
        <iframe
          width="100%"
          height="400"
          src="https://www.youtube.com/embed/hJtEGeuGjgI"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        {isLoading ? (
          <div>
            <img
              src={require('../images/loading.gif')}
              style={{ height: 50 }}
            />
          </div>
        ) : (
          <Button
            style={{ margin: 20, height: 50 }}
            onClick={() => {
              // scroll to element id 'gift-offer';
              window.scrollTo({
                top: document.getElementById('gift-offer').offsetTop,
                behavior: 'smooth',
              });
            }}
          >
            Yes I want the free gifts
          </Button>
        )}
      </Row>
      {/* do this first message */}
      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: paddingOnSides,
          marginRight: paddingOnSides,
          paddingTop: 20,
        }}
      >
        <p style={{ textAlign: 'center' }}>
          <strong style={{ color: 'purple' }}>DO THIS FIRST:</strong> Please
          watch this important GaryAI member update message in full right now.
          Your access details will appear on the next step at the end of the
          video.
        </p>
      </Row>

      {/* I want to offer Headline */}
      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: paddingOnSides,
          marginRight: paddingOnSides,
          paddingTop: 20,
        }}
      >
        <h4
          style={{ paddingTop: 10, textAlign: 'center', fontWeight: 'bolder' }}
        >
          I Want To Share TWO Special Gifts<br></br>
          <span style={{ color: '#007bff' }}>(Worth $2,994)</span> With You For
          <br></br>Upgrading Your Account Right Away
        </h4>
        <em style={{ paddingTop: 10, paddingBottom: 20, fontSize: '1.25em' }}>
          (Only Available On This Page For New GaryAI Users)
        </em>

        <p style={{ textAlign: 'left', fontSize: '1.25em' }}>
          <strong style={{ fontWeight: 'bolder' }}>
            These two gifts are the ULTIMATE shortcut to getting incredible
            results with GaryAI even more quickly and easily…
          </strong>
          <br></br>
          <br></br>
          These gifts represent the culmination of my best thinking on
          copywriting, as well as the most successful templates that have ever
          been used with GaryAI.<br></br>
          <br></br>
          And you can’t find these anywhere else publicly.<br></br>
          <br></br>
          <strong style={{ fontWeight: 'bolder' }}>
            They’re ONLY available here on this page
          </strong>{' '}
          (if you skip or close this page, they’ll be gone forever).
        </p>
      </Row>

      {/* Gift 1 - Image card */}
      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: paddingOnSides,
          marginRight: paddingOnSides,
          paddingTop: 20,
        }}
      >
        <div
          style={{
            backgroundColor: '#6D60B0',
            width: '100%',
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
            marginBottom: 0,
            paddingBottom: 0,
          }}
        >
          <center
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h4 style={{ color: 'white', marginTop: 20 }}>
              Gift 1: Copywriting Secrets Masterclass
            </h4>

            <img
              style={{
                width: '80%',
                marginTop: -35,
                paddingBottom: 20,
              }}
              src={require('../images/secrets-books.png')}
            />
          </center>
          <div
            style={{
              backgroundColor: 'white',
              padding: 30,
              borderBottomLeftRadius: 20,
              borderBottomRightRadius: 20,
            }}
          >
            <center>
              <h4
                style={{
                  paddingLeft: isThisAMobileScreen ? 10 : 50,
                  paddingRight: isThisAMobileScreen ? 10 : 50,
                  fontWeight: 'bolder',
                }}
              >
                Discover the{' '}
                <span style={{ textDecoration: 'underline' }}>
                  timeless strategies
                </span>{' '}
                and{' '}
                <span style={{ textDecoration: 'underline' }}>
                  best-kept secrets
                </span>{' '}
                of copywriting masters in this modular training…
              </h4>
            </center>

            <div
              style={{
                paddingLeft: isThisAMobileScreen ? 10 : 50,
                paddingRight: isThisAMobileScreen ? 10 : 50,
                textAlign: 'left',
              }}
            >
              <p style={{ fontSize: '1.25em' }}>
                This is my complete masterclass on everything about copywriting
                marketing to skyrocket your growth… for any type of business, in
                any niche… in the shortest amount of time possible!<br></br>
                <br></br>This is my complete masterclass on copywriting and
                viral marketing campaigns that'll skyrocket your email list,
                generate a spike of new customers, and bring extra profits for
                your business.<br></br>
                <br></br>These are strategies and tactics that{' '}
                <span style={{ textDecoration: 'underline' }}>
                  work for any type of business, in any niche…
                </span>
                <br></br>
                <br></br>
                And they’re proven to{' '}
                <span style={{ textDecoration: 'underline' }}>
                  produce results <strong>FAST!</strong>
                </span>
              </p>
              <h5 style={{ fontWeight: 'bolder' }}>
                This 7-module masterclass covers…
              </h5>

              {/* list of whats included */}
              <ul style={{ paddingLeft: 0, paddingTop: 10 }}>
                <li
                  style={{
                    listStyleType: 'none',
                    fontSize: '1.25em',
                  }}
                >
                  <span role="img" aria-label="checkmark">
                    ✅
                  </span>
                  <span style={{ paddingLeft: 10 }}>
                    <strong>Expert copywriting fundamentals</strong>
                  </span>
                </li>
                <li
                  style={{
                    listStyleType: 'none',
                    fontSize: '1.25em',
                    paddingTop: 15,
                  }}
                >
                  <span role="img" aria-label="checkmark">
                    ✅
                  </span>
                  <span style={{ paddingLeft: 10 }}>
                    <strong>How to craft offers that sell like hell</strong>
                  </span>
                </li>
                <li
                  style={{
                    listStyleType: 'none',
                    fontSize: '1.25em',
                    paddingTop: 15,
                  }}
                >
                  <span role="img" aria-label="checkmark">
                    ✅
                  </span>
                  <span style={{ paddingLeft: 10 }}>
                    <strong>
                      Emotional triggers that motivate people to buy NOW
                    </strong>
                  </span>
                </li>
                <li
                  style={{
                    listStyleType: 'none',
                    fontSize: '1.25em',
                    paddingTop: 15,
                  }}
                >
                  <span role="img" aria-label="checkmark">
                    ✅
                  </span>
                  <span style={{ paddingLeft: 10 }}>
                    <strong>Ultimate shortcut to increasing sales</strong>
                  </span>
                </li>
                <li
                  style={{
                    listStyleType: 'none',
                    fontSize: '1.25em',
                    paddingTop: 15,
                  }}
                >
                  <span role="img" aria-label="checkmark">
                    ✅
                  </span>
                  <span style={{ paddingLeft: 10 }}>
                    <strong>Plug N Play secrets to go viral</strong>
                  </span>
                </li>
                <li
                  style={{
                    listStyleType: 'none',
                    fontSize: '1.25em',
                    paddingTop: 15,
                  }}
                >
                  <span role="img" aria-label="checkmark">
                    ✅
                  </span>
                  <span style={{ paddingLeft: 10 }}>
                    <strong>And more… much MUCH more!</strong>
                  </span>
                </li>
              </ul>

              <p style={{ fontSize: '1.25em', paddingTop: 10 }}>
                Until now, I shared this masterclass with a small group of
                coaching clients and they paid up to $2,000 to learn these
                time-tested secrets, and evergreen strategies and tactics from
                me…
              </p>

              <center>
                <h5 style={{ color: '#03c303', fontWeight: 'bolder' }}>
                  And today you can get it for absolutely FREE!
                </h5>
              </center>
            </div>
          </div>
        </div>
      </Row>
      <center>
        <p style={{ fontSize: '1.25em', paddingTop: 35 }}>
          And this is just the first gift, but there’s more...
        </p>
      </center>

      {/* Gift 2 - Image card */}
      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: paddingOnSides,
          marginRight: paddingOnSides,
          paddingTop: 20,
        }}
      >
        <div
          style={{
            backgroundColor: '#8F45A9',
            width: '100%',
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
            marginBottom: 0,
            paddingBottom: 0,
          }}
        >
          <center
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h4 style={{ color: 'white', marginTop: 20 }}>
              Gift 2: Top 7 Ready-To-Go Pro Templates
            </h4>
          </center>
          <div
            style={{
              backgroundColor: 'white',
              padding: 30,
              borderBottomLeftRadius: 20,
              borderBottomRightRadius: 20,
            }}
          >
            <center>
              <h4
                style={{
                  paddingLeft: isThisAMobileScreen ? 10 : 50,
                  paddingRight: isThisAMobileScreen ? 10 : 50,
                  fontWeight: 'bolder',
                }}
              >
                Import some of the{' '}
                <span style={{ textDecoration: 'underline' }}>
                  most profitable GaryAI templates
                </span>{' '}
                ever created by top marketers right into your account{' '}
                <span style={{ textDecoration: 'underline' }}>
                  in a single click!
                </span>
              </h4>
            </center>

            <div
              style={{
                paddingLeft: isThisAMobileScreen ? 10 : 50,
                paddingRight: isThisAMobileScreen ? 10 : 50,
                textAlign: 'left',
              }}
            >
              <p style={{ fontSize: '1.25em' }}>
                I can’t believe this is happening…<br></br>
                <br></br>But 7 of our most brilliant GaryAI users have agreed to
                share their premium custom templates ever with you… so that
                you’ll{' '}
                <strong style={{ fontWeight: 'bolder' }}>
                  import from their “real-life tested” content generator right
                  into your account!
                </strong>
                in a single click.
                <br></br>
                <br></br>Let me be clear:
                <br></br>
                <br></br>
                These are NOT simple “case studies” that go through GaryAI.
                Instead, I’ll be giving you the
                <span style={{ textDecoration: 'underline' }}>
                  actual premium custom templates that generated thousands of
                  new leads and sales.
                </span>{' '}
                and brought massive sales for the marketers who used them.
                <br></br>
                <br></br>
              </p>
              <h5 style={{ fontWeight: 'bolder' }}>
                I’m talking about results like…
              </h5>

              {/* list of whats included */}
              <ul style={{ paddingLeft: 0, paddingTop: 10 }}>
                <li
                  style={{
                    listStyleType: 'none',
                    fontSize: '1.25em',
                  }}
                >
                  <span role="img" aria-label="checkmark">
                    ✅
                  </span>
                  <span style={{ paddingLeft: 10 }}>
                    <strong>
                      Sales copy that{' '}
                      <strong style={{ fontWeight: 'bolder' }}>
                        sold 6,400 books
                      </strong>{' '}
                      as a first-time author
                    </strong>
                  </span>
                </li>

                <li
                  style={{
                    listStyleType: 'none',
                    fontSize: '1.25em',
                    paddingTop: 15,
                  }}
                >
                  <span role="img" aria-label="checkmark">
                    ✅
                  </span>
                  <span style={{ paddingLeft: 10 }}>
                    <strong>
                      Growing a blog from zero to{' '}
                      <strong style={{ fontWeight: 'bolder' }}>
                        140k visitors/mo
                      </strong>{' '}
                      with 7 blog posts written in a weekend
                    </strong>
                  </span>
                </li>
                <li
                  style={{
                    listStyleType: 'none',
                    fontSize: '1.25em',
                    paddingTop: 15,
                  }}
                >
                  <span role="img" aria-label="checkmark">
                    ✅
                  </span>
                  <span style={{ paddingLeft: 10 }}>
                    <strong>
                      Getting{' '}
                      <strong style={{ fontWeight: 'bolder' }}>
                        50% increase in conversion rate
                      </strong>{' '}
                      for a niche Ecommerce store with new product descriptions
                      and meta tags
                    </strong>
                  </span>
                </li>
                <li
                  style={{
                    listStyleType: 'none',
                    fontSize: '1.25em',
                    paddingTop: 15,
                  }}
                >
                  <span role="img" aria-label="checkmark">
                    ✅
                  </span>
                  <span style={{ paddingLeft: 10 }}>
                    <strong>
                      An agency doubling client load{' '}
                      <strong style={{ fontWeight: 'bolder' }}>
                        while automating nearly every aspect
                      </strong>{' '}
                      of the service
                    </strong>
                  </span>
                </li>
              </ul>

              <p style={{ fontSize: '1.25em', paddingTop: 10 }}>
                Now, for the first time ever, you can go beyond reading others’
                success stories…<br></br>
                <br></br>
                Instead, you can import these highly successful templates right
                into your account, and use them to get similar results for YOUR
                business.<br></br>
                <br></br>
                This is simply PRICELESS…
              </p>

              <center>
                <h5 style={{ color: '#03c303', fontWeight: 'bolder' }}>
                  But you’ll get it for absolutely FREE when you take advantage
                  of this special offer.
                </h5>
              </center>
            </div>
          </div>
        </div>
      </Row>

      {/* I want to offer Headline */}
      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: paddingOnSides,
          marginRight: paddingOnSides,
          paddingTop: 20,
        }}
      >
        <h4
          style={{
            textAlign: 'center',
            fontWeight: 'bolder',
            paddingTop: 50,
          }}
        >
          Get Instant & FREE Access To<br></br>
          These Special Gifts Today!<br></br>
          <span style={{ color: '#007bff' }}>(Worth $2,994)</span>
        </h4>

        <p style={{ textAlign: 'left', fontSize: '1.25em', paddingTop: 30 }}>
          <strong style={{ fontWeight: 'bolder' }}>
            Just skip the trial now and start your full GaryAI journey right
            away,{' '}
          </strong>
          and
          <span style={{ textDecoration: 'underline' }}>
            {' '}
            I’ll give you instant access to these TWO very special gifts.
          </span>
          <br></br>
          <br></br>
          And if you go with the yearly option on your preferred plan, I’ll
          offer you a DOUBLE-DISCOUNT.<br></br>
          <br></br>
          Plus, it’s NOT a discount on your first year only. You’ll be
          grandfathered into that special price forever as long as you keep your
          GaryAI account active. So, you’ll continue to save hundreds year after
          year.<br></br>
        </p>
      </Row>

      <h5
        style={{
          textAlign: 'center',
          fontWeight: 'bolder',
          paddingTop: 50,
        }}
        //set the id to "gift-offer"
        id="gift-offer"
      >
        Please pick the best option for you below:<br></br>
      </h5>
      <center>
        <p>
          Skip the trial to and unlock the full power of GaryAI now and get
          these incredible bonuses
        </p>
      </center>

      <Row
        style={{
          justifyContent: 'center',
        }}
      >
        {/* starter monthly */}
        {didUserSelectStarterPlan && !didUserStartAnnualPlanYet && (
          <Col
            md={5}
            style={{
              backgroundColor: 'white',
              borderRadius: 5,
              margin: 10,
              padding: 20,
              borderWidth: 0,
              borderStyle: 'solid',
              borderColor: '#1972F5',
              boxShadow: '0px 1px 4px 0px rgba(109, 110, 111, 0.38)',
            }}
          >
            <p
              style={{
                backgroundColor: '#1972f536',
                borderRadius: 5,
                width: 125,
                textAlign: 'center',
                color: '#1972F5',
              }}
            >
              Starter Monthly
            </p>
            <h3 className="card-title">
              $29
              <span
                style={{
                  color: 'lightgrey',
                  fontSize: 20,
                  marginTop: -8,
                  paddingLeft: 5,
                }}
              >
                /mo
              </span>
            </h3>
            <strong style={{ color: 'lightgrey' }}>billed monthly</strong>

            <p style={{ paddingTop: 30, fontSize: '1rem' }}>
              <i className="check fas fa-check" style={{ color: '#39c439' }}>
                {' '}
              </i>{' '}
              <strong>Gift #1:</strong> Copywriting Masterclass (worth $1,997)
            </p>

            <p style={{ paddingBottom: 50, fontSize: '1rem' }}>
              <i className="check fas fa-check" style={{ color: '#39c439' }}>
                {' '}
              </i>{' '}
              <strong>Gift #2:</strong> Top 7 Ready-To-Go Pro Content Templates
              (worth $997)
            </p>

            {isLoading ? (
              <div>
                <img
                  src={require('../images/loading.gif')}
                  style={{ height: 50 }}
                />
              </div>
            ) : (
              <Button
                outline
                style={{ width: '100%' }}
                onClick={async () => {
                  MixpanelSuper.trackButtonClick('skip_trial_button_monthly');
                  await handleSkipTrialClick();
                  try {
                    window.fbq('track', 'Purchase', {
                      value: '29.99',
                      currency: 'USD',
                    });
                  } catch (e) {
                    console.log(
                      'Could not fire Purchase pixel event because',
                      e
                    );
                  }
                }}
              >
                Upgrade Now
                <i className="material-icons mr-1" style={{ marginLeft: 5 }}>
                  arrow_forward
                </i>
              </Button>
            )}
          </Col>
        )}
        {/* Pro monthly */}
        {!didUserSelectStarterPlan && !didUserStartAnnualPlanYet && (
          <Col
            md={5}
            style={{
              backgroundColor: 'white',
              borderRadius: 5,
              margin: 10,
              padding: 20,
              borderWidth: 0,
              borderStyle: 'solid',
              borderColor: '#1972F5',
              boxShadow: '0px 1px 4px 0px rgba(109, 110, 111, 0.38)',
            }}
          >
            <p
              style={{
                backgroundColor: '#1972f536',
                borderRadius: 5,
                width: 125,
                textAlign: 'center',
                color: '#1972F5',
              }}
            >
              Pro Monthly
            </p>
            <h3 className="card-title">
              $49
              <span
                style={{
                  color: 'lightgrey',
                  fontSize: 20,
                  marginTop: -8,
                  paddingLeft: 5,
                }}
              >
                /mo
              </span>
            </h3>
            <strong style={{ color: 'lightgrey' }}>billed monthly</strong>

            <p style={{ paddingTop: 30, fontSize: '1rem' }}>
              <i className="check fas fa-check" style={{ color: '#39c439' }}>
                {' '}
              </i>{' '}
              <strong>Gift #1:</strong> Copywriting Masterclass (worth $1,997)
            </p>

            <p style={{ paddingBottom: 50, fontSize: '1rem' }}>
              <i className="check fas fa-check" style={{ color: '#39c439' }}>
                {' '}
              </i>{' '}
              <strong>Gift #2:</strong> Top 7 Ready-To-Go Pro Content Templates
              (worth $997)
            </p>

            {isLoading ? (
              <div>
                <img
                  src={require('../images/loading.gif')}
                  style={{ height: 50 }}
                />
              </div>
            ) : (
              <Button
                outline
                style={{ width: '100%' }}
                onClick={async () => {
                  MixpanelSuper.trackButtonClick('skip_trial_button_monthly');
                  await handleSkipTrialClick();
                  try {
                    window.fbq('track', 'Purchase', {
                      value: '49.99',
                      currency: 'USD',
                    });
                  } catch (e) {
                    console.log(
                      'Could not fire Purchase pixel event because',
                      e
                    );
                  }
                }}
              >
                Upgrade Now
                <i className="material-icons mr-1" style={{ marginLeft: 5 }}>
                  arrow_forward
                </i>
              </Button>
            )}
          </Col>
        )}
        {/* starter annual for when a user already picked it and is just skipping trial */}
        {didUserStartAnnualPlanYet && didUserSelectStarterPlan && (
          <Col
            md={5}
            style={{
              backgroundColor: 'white',
              borderRadius: 5,
              margin: 10,
              padding: 20,

              borderStyle: 'solid',
              borderColor: '#1972F5',
              boxShadow: '0px 1px 4px 0px rgba(109, 110, 111, 0.38)',
            }}
          >
            <Row>
              <Col xs={6}>
                <p
                  style={{
                    backgroundColor: '#1972f536',
                    borderRadius: 5,
                    width: 125,
                    textAlign: 'center',
                    color: '#1972F5',
                  }}
                >
                  Starter Annual
                </p>
              </Col>
              <Col xs={6}>
                {/* starter annual double discount -- $240 (normally 359) */}
                {/* pro annual double discount $392 (normally 599) */}
              </Col>
            </Row>
            <span style={{ color: 'red', textDecoration: 'line-through' }}>
              <strong style={{ color: 'black' }}>$359</strong>
              <span style={{ color: 'black' }}>/year</span>
            </span>
            <h3 className="card-title">
              Only $240
              <span
                style={{
                  color: 'lightgrey',
                  fontSize: 20,
                  marginTop: -8,
                  paddingLeft: 5,
                }}
              >
                /year
              </span>
            </h3>
            <strong style={{ color: 'lightgrey' }}>billed yearly</strong>
            <strong style={{ color: 'grey' }}> (4 months free)</strong>

            <p style={{ paddingTop: 10, fontSize: '1rem' }}>
              <i className="check fas fa-check" style={{ color: '#39c439' }}>
                {' '}
              </i>{' '}
              <strong>Gift #1:</strong> Copywriting Masterclass (worth $1,997)
            </p>

            <p style={{ fontSize: '1rem' }}>
              <i className="check fas fa-check" style={{ color: '#39c439' }}>
                {' '}
              </i>{' '}
              <strong>Gift #2:</strong> Top 7 Ready-To-Go Pro Content Templates
              (worth $997)
            </p>
            <p style={{ paddingBottom: 3, fontSize: '1rem' }}>
              <i className="check fas fa-check" style={{ color: '#39c439' }}>
                {' '}
              </i>{' '}
              <strong>Special Double-Discount:</strong>
              (4 months free)
            </p>

            {isLoading ? (
              <div>
                <img
                  src={require('../images/loading.gif')}
                  style={{ height: 50 }}
                />
              </div>
            ) : (
              <Button
                type="button"
                style={{ width: '100%' }}
                onClick={async () => {
                  MixpanelSuper.trackButtonClick('skip_trial_button_annual');
                  await handleSkipTrialClick();
                  // change plan to annual and skip trial
                  // await handleSkipTrialAndChangePlanClick(planName);
                  try {
                    window.fbq('track', 'Purchase', {
                      value: '240.99',
                      currency: 'USD',
                    });
                  } catch (e) {
                    console.log(
                      'Could not fire Purchase pixel event because',
                      e
                    );
                  }
                }}
              >
                Upgrade Now
                <i className="material-icons mr-1" style={{ marginLeft: 5 }}>
                  arrow_forward
                </i>
              </Button>
            )}
          </Col>
        )}
        {/* annual starter - for when a user is end trial AND upgrade */}
        {!didUserStartAnnualPlanYet && didUserSelectStarterPlan && (
          <Col
            md={5}
            style={{
              backgroundColor: 'white',
              borderRadius: 5,
              margin: 10,
              padding: 20,

              borderStyle: 'solid',
              borderColor: '#1972F5',
              boxShadow: '0px 1px 4px 0px rgba(109, 110, 111, 0.38)',
            }}
          >
            <Row>
              <Col xs={6}>
                <p
                  style={{
                    backgroundColor: '#1972f536',
                    borderRadius: 5,
                    width: 125,
                    textAlign: 'center',
                    color: '#1972F5',
                  }}
                >
                  Starter Annual
                </p>
              </Col>
              <Col xs={6}>
                {/* starter annual double discount -- $240 (normally 359) */}
                {/* pro annual double discount $392 (normally 599) */}
              </Col>
            </Row>
            <span style={{ color: 'red', textDecoration: 'line-through' }}>
              <strong style={{ color: 'black' }}>$359</strong>
              <span style={{ color: 'black' }}>/year</span>
            </span>
            <h3 className="card-title">
              Only $240
              <span
                style={{
                  color: 'lightgrey',
                  fontSize: 20,
                  marginTop: -8,
                  paddingLeft: 5,
                }}
              >
                /year
              </span>
            </h3>
            <strong style={{ color: 'lightgrey' }}>billed yearly</strong>
            <strong style={{ color: 'grey' }}> (4 months free)</strong>

            <p style={{ paddingTop: 10, fontSize: '1rem' }}>
              <i className="check fas fa-check" style={{ color: '#39c439' }}>
                {' '}
              </i>{' '}
              <strong>Gift #1:</strong> Copywriting Masterclass (worth $1,997)
            </p>

            <p style={{ fontSize: '1rem' }}>
              <i className="check fas fa-check" style={{ color: '#39c439' }}>
                {' '}
              </i>{' '}
              <strong>Gift #2:</strong> Top 7 Ready-To-Go Pro Content Templates
              (worth $997)
            </p>
            <p style={{ paddingBottom: 3, fontSize: '1rem' }}>
              <i className="check fas fa-check" style={{ color: '#39c439' }}>
                {' '}
              </i>{' '}
              <strong>Special Double-Discount:</strong>
              (4 months free)
            </p>

            {isLoading ? (
              <div>
                <img
                  src={require('../images/loading.gif')}
                  style={{ height: 50 }}
                />
              </div>
            ) : (
              <Button
                type="button"
                style={{ width: '100%' }}
                onClick={async () => {
                  MixpanelSuper.trackButtonClick(
                    'skip_trial_and_change_button_annual'
                  );
                  // change plan to annual and skip trial
                  await handleSkipTrialAndChangePlanClick(planName);
                  try {
                    window.fbq('track', 'Purchase', {
                      value: '240.99',
                      currency: 'USD',
                    });
                  } catch (e) {
                    console.log(
                      'Could not fire Purchase pixel event because',
                      e
                    );
                  }
                }}
              >
                Upgrade Now
                <i className="material-icons mr-1" style={{ marginLeft: 5 }}>
                  arrow_forward
                </i>
              </Button>
            )}
          </Col>
        )}
        {/*  annual pro upsell */}
        {!didUserStartAnnualPlanYet && !didUserSelectStarterPlan && (
          <Col
            md={5}
            style={{
              backgroundColor: 'white',
              borderRadius: 5,
              margin: 10,
              padding: 20,

              borderStyle: 'solid',
              borderColor: '#1972F5',
              boxShadow: '0px 1px 4px 0px rgba(109, 110, 111, 0.38)',
            }}
          >
            <Row>
              <Col xs={6}>
                <p
                  style={{
                    backgroundColor: '#1972f536',
                    borderRadius: 5,
                    width: 125,
                    textAlign: 'center',
                    color: '#1972F5',
                  }}
                >
                  Pro Annual
                </p>
              </Col>
              <Col xs={6}></Col>
            </Row>
            <span style={{ color: 'red', textDecoration: 'line-through' }}>
              <strong style={{ color: 'black' }}>$599</strong>
              <span style={{ color: 'black' }}>/year</span>
            </span>
            <h3 className="card-title">
              Only $392
              <span
                style={{
                  color: 'lightgrey',
                  fontSize: 20,
                  marginTop: -8,
                  paddingLeft: 5,
                }}
              >
                /year
              </span>
            </h3>
            <strong style={{ color: 'lightgrey' }}>billed yearly</strong>
            <strong style={{ color: 'grey' }}> (four months free)</strong>
            {/* <strong style={{ color: 'grey' }}> (equal to only $32/mo)</strong> */}

            <p style={{ paddingTop: 10, fontSize: '1rem' }}>
              <i className="check fas fa-check" style={{ color: '#39c439' }}>
                {' '}
              </i>{' '}
              <strong>Gift #1:</strong> Copywriting Masterclass (worth $1,997)
            </p>

            <p style={{ fontSize: '1rem' }}>
              <i className="check fas fa-check" style={{ color: '#39c439' }}>
                {' '}
              </i>{' '}
              <strong>Gift #2:</strong> Top 7 Ready-To-Go Pro Content Templates
              (worth $997)
            </p>
            <p style={{ paddingBottom: 3, fontSize: '1rem' }}>
              <i className="check fas fa-check" style={{ color: '#39c439' }}>
                {' '}
              </i>{' '}
              <strong>Special Double-Discount:</strong>
              (4 months free)
            </p>

            {isLoading ? (
              <div>
                <img
                  src={require('../images/loading.gif')}
                  style={{ height: 50 }}
                />
              </div>
            ) : (
              <Button
                type="button"
                style={{ width: '100%' }}
                onClick={async () => {
                  MixpanelSuper.trackButtonClick(
                    'skip_trial_and_change_button_annual'
                  );
                  // change plan to annual and skip trial
                  await handleSkipTrialAndChangePlanClick(planName);
                  try {
                    window.fbq('track', 'Purchase', {
                      value: '329.99',
                      currency: 'USD',
                    });
                  } catch (e) {
                    console.log(
                      'Could not fire Purchase pixel event because',
                      e
                    );
                  }
                }}
              >
                Upgrade Now
                <i className="material-icons mr-1" style={{ marginLeft: 5 }}>
                  arrow_forward
                </i>
              </Button>
            )}
          </Col>
        )}

        {/* show pro end trial and pro upgrade to annual */}
        {didUserStartAnnualPlanYet && !didUserSelectStarterPlan && (
          <Col
            md={5}
            style={{
              backgroundColor: 'white',
              borderRadius: 5,
              margin: 10,
              padding: 20,

              borderStyle: 'solid',
              borderColor: '#1972F5',
              boxShadow: '0px 1px 4px 0px rgba(109, 110, 111, 0.38)',
            }}
          >
            <Row>
              <Col xs={6}>
                <p
                  style={{
                    backgroundColor: '#1972f536',
                    borderRadius: 5,
                    width: 125,
                    textAlign: 'center',
                    color: '#1972F5',
                  }}
                >
                  Pro Annual
                </p>
              </Col>
              <Col xs={6}></Col>
            </Row>
            <span style={{ color: 'red', textDecoration: 'line-through' }}>
              <strong style={{ color: 'black' }}>$599</strong>
              <span style={{ color: 'black' }}>/year</span>
            </span>
            <h3 className="card-title">
              Only $392
              <span
                style={{
                  color: 'lightgrey',
                  fontSize: 20,
                  marginTop: -8,
                  paddingLeft: 5,
                }}
              >
                /year
              </span>
            </h3>
            <strong style={{ color: 'lightgrey' }}>billed yearly</strong>
            <strong style={{ color: 'grey' }}> (four months free)</strong>
            {/* <strong style={{ color: 'grey' }}> (equal to only $32/mo)</strong> */}

            <p style={{ paddingTop: 10, fontSize: '1rem' }}>
              <i className="check fas fa-check" style={{ color: '#39c439' }}>
                {' '}
              </i>{' '}
              <strong>Gift #1:</strong> Copywriting Masterclass (worth $1,997)
            </p>

            <p style={{ fontSize: '1rem' }}>
              <i className="check fas fa-check" style={{ color: '#39c439' }}>
                {' '}
              </i>{' '}
              <strong>Gift #2:</strong> Top 7 Ready-To-Go Pro Content Templates
              (worth $997)
            </p>
            <p style={{ paddingBottom: 3, fontSize: '1rem' }}>
              <i className="check fas fa-check" style={{ color: '#39c439' }}>
                {' '}
              </i>{' '}
              <strong>Special Double-Discount:</strong>
              (4 months free)
            </p>

            {isLoading ? (
              <div>
                <img
                  src={require('../images/loading.gif')}
                  style={{ height: 50 }}
                />
              </div>
            ) : (
              <Button
                type="button"
                style={{ width: '100%' }}
                onClick={async () => {
                  MixpanelSuper.trackButtonClick('skip_trial_button_annual');
                  // change plan to annual and skip trial
                  await handleSkipTrialAndChangePlanClick(planName);
                  try {
                    window.fbq('track', 'Purchase', {
                      value: '392.99',
                      currency: 'USD',
                    });
                  } catch (e) {
                    console.log(
                      'Could not fire Purchase pixel event because',
                      e
                    );
                  }
                }}
              >
                Upgrade Now
                <i className="material-icons mr-1" style={{ marginLeft: 5 }}>
                  arrow_forward
                </i>
              </Button>
            )}
          </Col>
        )}
      </Row>

      {/* Satisfaction */}
      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: paddingOnSides,
          marginRight: paddingOnSides,
          paddingTop: 20,
        }}
      >
        <h4
          style={{
            textAlign: 'center',
            fontWeight: 'bolder',
            paddingTop: 50,
          }}
        >
          Satisfaction Guarantee &<br></br>
          Cancel Anytime
        </h4>

        <Row
          style={{ backgroundColor: 'white', borderRadius: 20, paddingTop: 20 }}
        >
          <Col
            md={4}
            style={{
              padding: 20,
              // align contents to the center of the column
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={require('../images/MoneyBack.png')}
              style={{ width: '100%' }}
            />
          </Col>
          <Col md={8} style={{ padding: '20 20 20 10', marginLeft: 0 }}>
            <p style={{ fontSize: '1rem' }}>
              This upgrade purchase is backed by our{' '}
              <strong style={{ fontWeight: 'bolder' }}>
                unconditional 30-day money-back guarantee
              </strong>
              . If you’re not happy with these bonuses or GaryAI for any reason
              (ANY reason at all) simply send message us within 30 days, and
              we’ll refund every penny of your investment -- no questions asked!
              <br></br>
              <br></br>
              <strong style={{ fontWeight: 'bolder' }}>
                You can cancel your monthly/yearly plan
              </strong>{' '}
              anytime - no long-term commitments required.
            </p>
          </Col>
        </Row>
      </Row>

      <center style={{ margin: 50 }}>
        <h5 style={{ color: '#03c303', fontWeight: 'bolder' }}>
          Upgrade your GaryAI account confidently
        </h5>
      </center>

      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: paddingOnSides,
          marginRight: paddingOnSides,
          paddingTop: 20,
        }}
      >
        <h4
          style={{
            textAlign: 'center',
            fontWeight: 'bolder',
            paddingTop: 50,
          }}
        >
          Reviews<br></br>
        </h4>
      </Row>

      <Row
        style={{
          width: '100%',
          // backgroundColor: 'red',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* <div style={{marginLeft: '25}}>

        </div> */}
        <div
          style={{
            width: '100%',
            padding: 20,
          }}
        >
          <center>
            <img
              src={require('../images/Capterra.png')}
              style={{
                width: isThisAMobileScreen ? '60%' : '30%',
              }}
            />
          </center>
        </div>
        <div style={{ width: '100%', padding: 20 }}>
          <center>
            <img
              src={require('../images/rating.png')}
              style={{ width: '50%' }}
            />
          </center>
        </div>
        <img
          src={require('../images/reviews1.png')}
          style={{ width: '100%' }}
        />
        <img
          src={require('../images/reviews2.png')}
          style={{ width: '100%', paddingTop: 30 }}
        />
      </Row>

      <Row
        style={{
          paddingTop: 50,
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* TODO Add logic */}
        <center>
          <a
            href="/dashboard?trialStarted=true&skip=false"
            style={{ textDecoration: 'underline', fontSize: '1.2rem' }}
            onClick={() => {
              MixpanelSuper.trackButtonClick('rejected_skiptrial_offer');
            }}
          >
            No thanks, I'll stick with my 5 day free trial for now.
          </a>
          <br></br>
          <em style={{ fontSize: '1.2rem' }}>
            (That means I’ll lose the chance to get these two special bonuses
            listed above forever).
          </em>
        </center>
      </Row>
      <div style={{ height: 100 }}></div>
    </Container>
  );
};
