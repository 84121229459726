/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from 'react';
import { Mixpanel } from '../mixpanel';

import {
  Card,
  Container,
  Row,
  Col,
  ListGroupItem,
  Form,
  FormInput,
  Button,
} from 'shards-react';

import fire from '../config/fire';
import firebase from 'firebase';
import PageTitle from '../components/common/PageTitle';

import { config } from '../environments/config';
import { setFirebaseUserData, setUser } from '../actions';
import { connect } from 'react-redux';
import { sendOtherSlackNotification } from '../utils/slackNotifications';
const _ = require('lodash');

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.signup = this.signup.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      accessCode: 'JAN20',
      loading: false,
      referralId: '',
    };
  }

  componentDidMount() {
    this.checkForEmailInUrlParameters();
  }

  setFirebaseUserToState = (u) => {
    console.log('setFirebaseUserToState', u);
    this.props.setFirebaseUserData(u); /** Redux */
  };

  setUserToState = (u) => {
    console.log('setUserToState', u);
    this.props.setUser(u); /** Redux */
  };

  saveGeneralUserInfoUponSignup = () => {
    return new Promise((resolve, reject) => {
      console.log('saveGeneralUserInfoUponSignup');

      let userData = {
        first: this.state.firstName,
        last: this.state.lastName,
        email: this.state.email,
        accessCode: this.state.accessCode,
        trafficSource: 'facebook', //pre-determined, based on accessCode
      };

      if (this.state.referralId !== '') {
        userData['rid'] = this.state.referralId;
      }

      console.log('userData > ', userData);
      fire.shared
        .saveGeneralUserInfo(userData)
        .then((res) => {
          console.log('Stored new user ', res);
          //TODO: SAVE USER DATA TO REDUX
          this.setFirebaseUserToState(_.get(res, 'userData', {}));

          resolve();
        })
        .catch((e) => {
          reject();
        });
    });
  };

  initiateThirdPartyServices = () => {
    return new Promise((resolve, reject) => {
      console.log(
        'Initiate third party services (fullstory & intercom) for new user'
      );
      Promise.all([this.initiateMixpannel(), this.initiateIntercom()])
        .then(() => {
          resolve();
        })
        .catch((e) => {
          console.log('Initiate third party services error:', e);
          reject(e);
        });
    });
  };
  initiateMixpannel = () => {
    return new Promise((resolve, reject) => {
      try {
        Mixpanel.identify(this.state.email);
        Mixpanel.track('successful_signup', {
          user_unique_id: this.state.email,
        });
        const signupDate = new Date();
        console.log(signupDate);

        Mixpanel.people.set({
          $email: this.state.email, // only reserved properties need the $
          $first_name: this.state.firstName,
          $last_name: this.state.lastName,
          $name: this.state.firstName + ' ' + this.state.lastName,
          access_code: this.state.accessCode,
          sign_up_date: signupDate, // Send dates in ISO timestamp format (e.g. "2020-01-02T21:07:03Z")
          registration_method: 'email',
          generator_started_count: 0,
          snippet_generated_count: 0,
          snippet_copied_count: 0,
          snippet_saved_count: 0,
          login_count: 0,
          plan_type: 'free',
        });
        console.log('Set mixpanel for new user');
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  };
  initiateIntercom = () => {
    return new Promise((resolve, reject) => {
      // interom
      try {
        window.Intercom('boot', {
          app_id: _.get(config, 'intercom.appId'),
          email: this.state.email,
          name: this.state.firstName + ' ' + this.state.lastName,
          plan_type: 'free_trial',
        });
        console.log('Booted intercom for new user');
        resolve();
      } catch (e) {
        console.log('There was an issue with intercom: ', e);
        reject(e);
      }
    });
  };
  signup(e) {
    e.preventDefault();
    console.log('this.state.firstName = ', this.state.firstName);
    console.log('this.state.lastName = ', this.state.lastName);
    console.log('this.state.email = ', this.state.email);
    console.log('this.state.password = ', this.state.password);
    console.log('this.state.confirmPassword = ', this.state.confirmPassword);
    console.log('this.state.accessCode = ', this.state.accessCode);

    /** Do passwords match? and not empty */
    //let validEmailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    let accessCodes = _.get(config, 'accessCodes');

    if (this.state.password !== this.state.confirmPassword) {
      /** Password Error */
      // this.state.password = "";
      // this.state.confirmPassword = "";
      window.alert("Your passwords don't match.  please try again.");
    } else if (this.state.password === '') {
      window.alert('Please enter a valid password');
    } else if (this.state.email === '') {
      window.alert('Please enter an email address.');
      // }else if(!validEmailPattern.test(this.state.email)){
      //   // this.state.email = ""; // Do or don't reset?
      //   window.alert("Please enter a valid email address.");
    } else if (!accessCodes.includes(this.state.accessCode)) {
      // this.state.accessCode = "";
      window.alert('Please provide a valid access code.');
    } else {
      // show loading
      this.setState({
        loading: true,
      });

      firebase
        .auth()
        .createUserWithEmailAndPassword(this.state.email, this.state.password)
        .then(async (u) => {
          console.log('newly created user. set this to state:', u);
          this.setUserToState(u);

          //Facebook pixel
          try {
            window.fbq('track', 'CompleteRegistration');
          } catch (e) {
            console.log('Could not fire pixel event on new signup because', e);
          }

          // send slack message
          let newSignUpMessage = `New signup: ${this.state.firstName} ${
            this.state.lastName
          } ( ${this.state.email} ). Url Params: ${this.getAllUrlParameters()}`;

          await sendOtherSlackNotification(newSignUpMessage);

          try {
            //Set crisp (live chat user)
            console.log('Crisp set');
            window.$crisp.push(['set', 'user:email', this.state.email]);
            window.$crisp.push([
              'set',
              'user:nickname',
              [`${this.state.firstName} ${this.state.lastName}`],
            ]);
            window.$crisp.push(['set', 'session:event', ['user:welcome']]);
            // set session data.
            window.$crisp.push([
              'set',
              'session:data',
              ['entered_cc', 'false'],
            ]);
          } catch (e) {
            console.log('Unable to set crisp', e);
          }

          Promise.all([
            this.initiateThirdPartyServices(),
            this.saveGeneralUserInfoUponSignup(),
          ])
            .then(() => {
              window.location.pathname = '/write';
            })
            .catch((e) => {
              console.log(
                'Something went wrong with initiateThirdPartyServices or saveGeneralUserInfoUponSignup. Details:',
                e
              );
              window.location.pathname = '/write';
            });

          // try{
          //   this.initiateThirdPartyServices()
          // }catch(e){
          //   console.log('Something went wrong with initiateThirdPartyServices. Details:', e);
          // }
          //
          // try{
          //   this.saveGeneralUserInfoUponSignup()
          // }catch(e){
          //   console.log('Something went wrong with saveGeneralUserInfoUponSignup. Details: ', e)
          // }

          //NOt sure if it's going to wait for these 2 functions to finsih before redirecting.  How can I promise both of them and return errors.

          /** // DISABLED EMAIL VERIFICATION
          let user = firebase.auth().currentUser; //TODO: If u == user if so I can delete this or rename u which is returned by create account.
          user.sendEmailVerification().then(function() {
            if (window.confirm('Account created! Verification email sent. Please confirm your email to continue. (Be sure to check spam folder if needed)')) {
              window.location.pathname = '/login';
            }
          }).catch(function(error) {
            console.log(error);
            window.confirm('Account created! Verification email failed to sent. Click OK to login and we\'ll sort this out on our end.')
          });
          */
        })
        .catch((err) => {
          console.log(err);
          window.alert(err);
        });
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  getAllUrlParameters = () => {
    try {
      let queryString = window.location.search;
      let urlParams = new URLSearchParams(queryString);
      let allUrlParams = {};
      for (let p of urlParams) {
        allUrlParams[p[0]] = p[1];
      }
      console.log('getall url params', allUrlParams);
      //convert to string
      let allUrlParamsString = JSON.stringify(allUrlParams);
      // replace all double quotes with ^
      allUrlParamsString = allUrlParamsString.replace(/"/g, '^');
      console.log('allUrlParamsString', allUrlParamsString);
      console.log('type of allUrlParamsString', typeof allUrlParamsString);
      return allUrlParamsString;
    } catch (e) {
      console.log('Error getting url parameters', e);
      return '';
    }
  };

  checkForEmailInUrlParameters = () => {
    // On the register page, when it loads, check for email and set state
    const urlParams = new URLSearchParams(window.location.search);
    const emailValue = urlParams.get('email');
    const referralId = urlParams.get('rid');
    // console.log('urlParams', urlParams);
    // console.log('emailValue', emailValue);
    if (emailValue) {
      console.log('Setting state with email value', emailValue);
      this.setState({ email: emailValue });
    }
    if (referralId) {
      console.log('Setting state with Referral Id value', referralId);
      this.setState({ referralId: referralId });
    }
  };

  render() {
    return (
      <Container fluid className="">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <Col lg="4"></Col>
          <PageTitle
            sm="4"
            title="Get started for free!"
            subtitle="Welcome"
            className="text-sm-center text-lg-center"
          />
        </Row>

        <Row>
          <Col lg="4"></Col>
          <Col lg="4" md="6">
            <Card small>
              <ListGroupItem className="" style={{ border: 'none' }}>
                <Row>
                  <Col>
                    <Form>
                      <Row form>
                        <Col className="form-group">
                          <label htmlFor="feFirstName">First Name</label>
                          <FormInput
                            id="feFirstName"
                            type="text"
                            defaultValue=""
                            placeholder="First Name"
                            name="firstName"
                            onChange={this.handleChange}
                          />
                        </Col>
                      </Row>

                      <Row form>
                        <Col className="form-group">
                          <label htmlFor="feLastName">Last Name</label>
                          <FormInput
                            id="feLastName"
                            type="text"
                            defaultValue=""
                            placeholder="Last Name"
                            name="lastName"
                            onChange={this.handleChange}
                          />
                        </Col>
                      </Row>

                      <Row form>
                        <Col className="form-group">
                          <label htmlFor="feEmailAddress">Email</label>
                          <FormInput
                            id="feEmailAddress"
                            type="email"
                            value={this.state.email}
                            placeholder="Email"
                            name="email"
                            onChange={this.handleChange}
                          />
                        </Col>
                      </Row>

                      <Row form>
                        <Col className="form-group">
                          <label htmlFor="fePassword">Password</label>
                          <FormInput
                            id="fePassword"
                            type="password"
                            name="password"
                            placeholder="Password"
                            defaultValue=""
                            onChange={this.handleChange}
                          />
                        </Col>
                      </Row>

                      <Row form>
                        <Col className="form-group">
                          <label htmlFor="feConfirmPassword">
                            Confirm Password
                          </label>
                          <FormInput
                            id="feConfirmPassword"
                            type="password"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            defaultValue=""
                            onChange={this.handleChange}
                          />
                        </Col>
                      </Row>

                      <Row form style={{ display: 'none' }}>
                        <Col className="form-group">
                          <label htmlFor="feAccessCode">Access Code</label>
                          <FormInput
                            id="feAccessCode"
                            type="text"
                            defaultValue="JAN20"
                            placeholder="T3RG79"
                            name="accessCode"
                            onChange={this.handleChange}
                          />
                        </Col>
                      </Row>
                      <br></br>

                      {!this.state.loading && (
                        <Button
                          type="submit"
                          style={{ width: '100%' }}
                          onClick={this.signup}
                        >
                          Create My Account
                        </Button>
                      )}
                      {this.state.loading && (
                        <img
                          alt="loading spiral gif"
                          src={require('../images/loading.gif')}
                          height="50"
                        ></img>
                      )}
                      <br></br>
                      <br></br>
                      <p>
                        Already have an account yet?{' '}
                        <a href="/login">Log in here</a>.
                      </p>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    credits: state.credits,
    isSubscribed: state.isSubscribed,
    daysRemaining: state.daysRemaining,
    user: state.user,
  };
};

const mapDispatchToProps = {
  setUser,
  setFirebaseUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
