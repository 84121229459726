import React, { useEffect, useState } from 'react';
import { setUser } from '../actions';
import toast, { Toaster } from 'react-hot-toast';
import { Mixpanel } from '../mixpanel';
import { MixpanelSuper } from '../mixpanelSuper';
import {
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
  FormInput,
  Modal,
  ModalBody,
  ModalHeader,
  ButtonGroup,
  InputGroup,
  InputGroupAddon,
  Card,
  CardBody,
  CardFooter,
  Badge,
} from 'shards-react';
import firebase from 'firebase';
import { config } from '../environments/config';
import PageTitle from '../components/common/PageTitle';
import { connect } from 'react-redux';
import { increment } from '../actions';
import GenerateV2ForEditor from './_GenerateV2ForEditor';

const createAccount = async () => {
  console.log('createAccount');
};

export const DocEditor = () => {
  const [userData, setUserData] = useState({});
  const [userEmail, setUserEmail] = useState('');
  const [userId, setUserId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [cancellationIsLoading, setCancellationIsLoading] = useState(false);
  const [cancellationStatusMessage, setCancellationStatusMessage] = useState(
    false
  );
  const [showCopyOptionsList, setShowCopyOptionsList] = useState(true);
  const [showMiniGenerator, setShowMiniGenerator] = useState(true);
  const [selectedGenerator, setSelectedGenerator] = useState({});
  const [documentTitle, setDocumentTitle] = useState('Untilted document');
  const [documentBody, setDocumentBody] = useState('');
  const [documentId, setDocumentId] = useState('');
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [saveButtonIsLoading, setSaveButtonIsLoading] = useState(false);
  const [showUpgradeRequiredModal, setShowUpgradeRequiredModal] = useState(
    false
  );

  const _ = require('lodash');
  let apiAddress = _.get(config, 'backend.apiAddress');
  let currentPageUrl = window.location.href;
  if (currentPageUrl.includes('localhost')) {
    console.log('LOCALHOST');
    apiAddress = 'http://localhost:3001';
  }

  let isThisMobileDevice = window.navigator.userAgent.indexOf('Mobile') > -1;

  const copyOptionsForEditor = [
    {
      title: 'AIDA',
      body:
        'Stop your readers in their tracks with the Attention Interest Desire Action formula!',
      iconImage: require('../images/icons/pencil.png'),
      urlSlug: '/generate/aida',
      key: 'aida',
      version: 1,
    },
    {
      title: 'Product Descriptions',
      body:
        'Write high converting product descriptions for your ecommerce store!',
      iconImage: require('../images/icons/cart.png'),
      urlSlug: '/generate/product-descriptions',
      key: 'product-descriptions',
      version: 1,
    },
    {
      title: 'Facebook Ads',
      body:
        'Write compelling ad copy to drive more conversions and higher ROI!',
      iconImage: require('../images/icons/fb.png'),
      urlSlug: '/generate/facebook-ads',
      key: 'facebook-ads',
      version: 1,
    },
    {
      title: 'Pain Agitate Solution',
      body:
        'Write compelling copy with the legendary Pain Agitate Solution copy formula',
      iconImage: require('../images/icons/pain.png'),
      urlSlug: '/generate/pas',
      key: 'pas',
      version: 1,
    },
    {
      title: 'Content Improver',
      body:
        'Improve an existing piece of content to make it more engaging, interesting and persuasive!',
      icon: 'brush',
      urlSlug: '/templates/improver',
      showNewBadge: true,
      key: 'improver',
      version: 2,
    },
    {
      title: 'Paragraph Writer',
      body: 'Write captivating paragraphs that your readers will love!',
      icon: 'short_text',
      urlSlug: '/templates/paragraph',
      showNewBadge: true,
      key: 'paragraph',
      version: 2,
    },
    {
      title: 'Alternative Value Propositions',
      body:
        'Quickly generate multiple reasons people may use your service or product',
      urlSlug: '/generate/value-propositions',
      iconImage: require('../images/icons/keyword.png'),
      version: 1,
    },
    {
      title: 'Viral Video Ideas',
      body: 'Get viral video ideas for any business or product instantly!',
      urlSlug: '/generate/viral-video-ideas',
      iconImage: require('../images/icons/video.png'),
      // showProOnly: true
      key: 'viral-video-ideas',
      version: 1,
    },
    {
      title: 'Blog Post Title Ideas',
      body: 'Make the most of your blog posts with this epic title generator.',
      urlSlug: '/generate/blog-post-title-ideas',
      iconImage: require('../images/icons/type.png'),
      // showProOnly: true
      key: 'blog-post-title-ideas',
      version: 1,
    },
    {
      title: 'Blog Post Topics',
      body:
        'Find great topics to blog about that your customers will actually be searching for!',
      urlSlug: '/generate/blog-post-topic-ideas',
      iconImage: require('../images/icons/lightbulb.png'),
      // showProOnly: true
      key: 'blog-post-topic-ideas',
      version: 1,
    },
    {
      title: 'Headlines',
      body:
        'High converting headlines to maximize your web pages, emails and social posts!',
      iconImage: require('../images/icons/web.png'),
      urlSlug: '/generate/headlines',
      // showProOnly: true
      key: 'headlines',
      version: 1,
    },
    {
      title: 'Keyword Ideas',
      body:
        'Easily find keywords your customers are searching for with this keyword ideas tool.',
      urlSlug: '/generate/keyword-ideas',
      iconImage: require('../images/icons/keyword.png'),
      // showProOnly: true
      key: 'keyword-ideas',
      version: 1,
    },
    {
      title: 'Viral Contest Ideas',
      body: "Get social media contest ideas that'll spread like wildfire!",
      urlSlug: '/generate/viral-contest-ideas',
      iconImage: require('../images/icons/trophy.png'),
      // showProOnly: true
      version: 1,
    },

    {
      title: 'What, Who, Why',
      body:
        'Easily extract your project or companies key attributes from simple language.',
      urlSlug: '/generate/what-who-why',
      iconImage: require('../images/icons/keyword.png'),
      // showProOnly: true
      key: 'what-who-why',
      version: 1,
    },

    {
      title: 'Website Hero Message & Sub-Headline',
      body: 'Use your What, Who & Why to start building out a website',
      urlSlug: '/generate/hero-message-and-subheadline',
      iconImage: require('../images/icons/keyword.png'),
      // showProOnly: true
      key: 'hero-message-and-subheadline',
      version: 1,
    },
    {
      title: 'Website Intro Paragraph',
      body:
        'Use your What, Who, Why & Hero Message to craft a related intro paragraph.',
      urlSlug: '/generate/website-intro-paragraph',
      iconImage: require('../images/icons/keyword.png'),
      // showProOnly: true
      key: 'website-intro-paragraph',
      version: 1,
    },
    {
      title: 'Customer Welcome Email',
      body:
        'Use your What, Who & Why to craft a welcome email for new sign ups.',
      urlSlug: '/generate/customer-welcome-email',
      iconImage: require('../images/icons/keyword.png'),
      // showProOnly: true
      key: 'customer-welcome-email',
      version: 1,
    },
    // {
    //   title: 'Custom Content',
    //   body:
    //     'What if you could train our cutting-edge AI to write custom copy for ANY medium? Well, now you can!',
    //   urlSlug: '/custom',
    //   iconImage: require('../images/icons/wrench.png'),
    //   showNewBadge: true,
    //   key: 'custom-content',
    // },

    // {
    //   title: 'Something else?',
    //   body: "Request something else and we'll build it for you in 48 hours",
    //   intercom: true,
    //   iconImage: require('../images/icons/mail.png'),
    //   urlSlug: 'mailto:hello@garyai.com',
    // },
    // {
    //   title: "Value Propositions",
    //   body:
    //     "People don't buy products. They buy solutions to their problems. Write value propositions that sell! Great for product descriptions, landing pages and more!",
    //   // urlSlug: "/facebook"
    // },
    // {
    //   title: "Features to benefits",
    //   body:
    //     "People don't buy products",
    //   // urlSlug: "/facebook"
    // },
    // {
    //   title: "PAG",
    //   body:
    //     "People don't buy products",
    //   // urlSlug: "/facebook"
    // },

    // {
    //   title: "Campaign Ideas",
    //   body:
    //     "Generate campaign ideas like Buy one get one free, free shipping, etc.",
    //   // urlSlug: "/facebook"
    // },
  ];

  const getAllUsersDocs = async () => {
    let userDocs = [];
    let docsRef = firebase
      .firestore()
      .collection('docs')
      .doc(userId)
      .collection('all');
    const snapshot = await docsRef.get();
    snapshot.forEach((doc) => {
      userDocs.push(doc.data());
    });
    return userDocs;
  };

  const createDoc = async () => {
    console.log('createDoc');
    let currentTimestamp = Date.now();

    firebase
      .firestore()
      .collection('docs')
      .doc(userId)
      .collection('all')
      .add({
        uid: userId,
        ts: currentTimestamp,
        created: currentTimestamp,
        modified: currentTimestamp,
        title: documentTitle,
        body: documentBody,
        owner: userId,
      })
      .then(function(docRef) {
        console.log('Document written with ID: ', docRef.id);
        toast.success('Document created!');
        setDocumentId(docRef.id);
      });
  };

  const changeDocTitle = async (title) => {
    console.log('changeDocTitle');
    if (!documentId) {
      toast.error('No doc title');
    }
    let currentTimestamp = Date.now();
    // change document title in firebase
    firebase
      .firestore()
      .collection('docs')
      .doc(userId)
      .collection('all')
      .doc(documentId)
      .update({
        title: documentTitle,
        modified: currentTimestamp,
      })
      .then(function() {
        console.log('Document successfully updated!');
        toast.success('Saved');
      });
  };

  const saveDocumentChanges = async (body) => {
    console.log('saveDocumentChanges');
    setSaveButtonIsLoading(true);
    if (!documentId) {
      toast.error('No doc body');
    }
    let currentTimestamp = Date.now();

    firebase
      .firestore()
      .collection('docs')
      .doc(userId)
      .collection('all')
      .doc(documentId)
      .update({
        title: documentTitle,
        body: documentBody,
        modified: currentTimestamp,
      })
      .then(function() {
        console.log('Document successfully updated!');
        toast.success('Saved');
        setShowSaveButton(false);
        setSaveButtonIsLoading(false);
      });
    setSaveButtonIsLoading(false);
  };

  const updateEmailVerifiedStatus = async () => {
    try {
      let userId = firebase.auth().currentUser.uid;
      console.log('update for userId', userId);
      const result = await firebase
        .firestore()
        .collection('users')
        .doc(userId)
        .update({
          verifiedEmail: true,
        })
        .catch(function(error) {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log('err', errorCode);
          console.log('errMessage', errorMessage);
          toast.error(`Error: ${errorMessage}`);
        });
      console.log('result', result);
      return result;
    } catch (e) {
      console.log('error', e);
      return 'error';
    }
  };
  const getDocumentDetails = async (thisUserId, docId) => {
    setIsLoading(true);
    console.log('getDocData');
    let docData = {};
    let docsRef = firebase
      .firestore()
      .collection('docs')
      .doc(thisUserId)
      .collection('all')
      .doc(docId);
    const snapshot = await docsRef.get();
    docData = snapshot.data();
    console.log('docData', docData);
    setDocumentTitle(docData.title);
    setDocumentBody(docData.body);
    setIsLoading(false);
    return docData;
  };

  useEffect(() => {
    console.log('Settings useEffect');
    console.log('waiting for firebase');
    MixpanelSuper.trackScreenView('doc');

    setIsLoading(true);
    // is there an id url param?

    // check url param for id
    let urlParams = new URLSearchParams(window.location.search);
    let documentId = urlParams.get('id');
    if (documentId) {
      console.log('id in the url params: ', documentId);
      setDocumentId(documentId);
      // getDocData(id);
    } else {
      console.log('no id in url params');
    }

    // wait for firebase
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // console.log('user', user);
        setUserEmail(user.email);
        setUserId(user.uid);

        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then(function(doc) {
            if (doc.exists) {
              let data = doc.data();
              console.log('Docs screen userData:', data);
              if (data.smu <= 776) {
                toast('This is a feature of the Pro plan', {
                  icon: '🔒',
                });
                setShowUpgradeRequiredModal(true);
              }

              setUserData(data);
              setIsLoading(false);
              if (documentId) {
                getDocumentDetails(user.uid, documentId);
              }
            }
          });
      } else {
        console.log('user is not signed in');
        setIsLoading(false);
        toast.error('user not signed in');
      }
    });
  }, []);

  return (
    <div
      style={{
        backgroundColor: 'red',
        height: '100vh',
        width: '100vw',
      }}
    >
      <div>
        <Toaster />
      </div>
      <Modal open={showUpgradeRequiredModal}>
        <ModalBody>
          <center>
            <i
              className="material-icons mr-1"
              style={{
                paddingTop: 1,
                fontSize: 30,
                color: '#039855',
                backgroundColor: '#D1FADF',
                padding: '12px',
                borderRadius: '50%',
              }}
            >
              lock
            </i>

            <h6 style={{ marginTop: 10, marginBottom: 20 }}>
              Write 5x Faster With Documents
            </h6>
            <p>
              Upgrade to Pro to get access to Documents and all of the other pro
              features!
            </p>

            <Button
              theme="primary  "
              style={{ marginRight: 20, marginTop: 20 }}
              onClick={() =>
                // change current url to settings
                (window.location.href = '/settings?upgradeInfo=true')
              }
            >
              Learn more
            </Button>
            <br></br>
          </center>
        </ModalBody>
      </Modal>

      <Row>
        <Col
          lg={8}
          style={{ backgroundColor: 'white', height: '100vh', paddingTop: 40 }}
        >
          {/* <p>Editor</p> */}
          <Row style={{ paddingLeft: 10 }}>
            <Col md={1} style={{ maxWidth: 60 }}>
              <i
                className="material-icons"
                style={{
                  border: '1px solid lightgrey',
                  fontSize: '1.8rem',
                  padding: 4,
                  borderRadius: 20,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (showSaveButton) {
                    // alert they change ur doc first
                    let res = window.confirm(
                      'You have unsaved changes. Are you sure you want to leave?'
                    );
                    if (res) {
                      window.history.back();
                    }
                  } else {
                    // go back to previous
                    window.history.back();
                  }
                }}
              >
                fullscreen_exit
              </i>
            </Col>

            <Col
              md={4}
              style={{
                // backgroundColor: 'red',
                paddingLeft: 4,
                paddingTop: 10,
              }}
            >
              {/* <p>Doc Title</p> */}
              <input
                type="text"
                placeholder="Untitled document"
                value={documentTitle}
                style={{
                  // marginLeft: isThisMobileDevice ? 0 : 10,
                  // width: isThisMobileDevice ? '100%' : '400px',
                  // marginRight: 3,
                  // marginBottom: 5,
                  // overflow: 'hidden',
                  // change the opacity of the placeholder text
                  // opacity: documentTitle.length > 0 ? 1 : 0.7,

                  // borderBottom: '1px solid lightgrey',
                  // marginLeft: isThisMobileDevice ? 0 : 10,
                  // width: isThisMobileDevice ? '100%' : '400px',
                  marginRight: 3,
                  marginBottom: 5,
                  boxShadow: '0px 0px 1px #667085',
                  border: 'none',
                  padding: 7,
                  overflow: 'hidden',
                  // change the opacity of the placeholder text
                  opacity: 0.75, //copyTitle.length > 0 ? 1 : 0.7,
                }}
                onChange={(e) => {
                  e.preventDefault();
                  // setCopyTitle(e.target.value);

                  console.log('title:', e.target.value);
                  setDocumentTitle(e.target.value);
                  setShowSaveButton(true);
                }}
              />
            </Col>
            <Col md={7} style={{ paddingLeft: 10 }}>
              {showSaveButton && (
                <Button
                  style={{ position: 'absolute', right: 0 }}
                  onClick={() => {
                    saveDocumentChanges();
                  }}
                >
                  {saveButtonIsLoading ? 'Saving...' : 'Save'}
                </Button>
              )}
              {!showSaveButton && !isLoading && (
                <Button
                  theme={'white'}
                  disabled
                  style={{ position: 'absolute', right: 0 }}
                  onClick={() => {
                    // saveDocumentChanges();
                  }}
                >
                  Save
                </Button>
              )}
              {isLoading && (
                <div style={{ position: 'absolute', right: 0 }}>
                  <img
                    src={require('../images/loading.gif')}
                    style={{ height: 30 }}
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: 70,
              paddingRight: 20,
              height: '90%',
              paddingTop: 20,
            }}
          >
            <textarea
              style={{
                width: '100%',
                height: '90%',
                border: 'none',
                outline: 'none',
                padding: 14,
                boxShadow: '0px 0px 1px #667085',
              }}
              onChange={(e) => {
                e.preventDefault();
                setDocumentBody(e.target.value);
                setShowSaveButton(true);
              }}
              value={documentBody}
              placeholder="Start typing some great content here..."
            />
          </Row>
        </Col>

        <Col lg={4} style={{ backgroundColor: '#F4F4F6', height: '100vh' }}>
          {/* <p>Assistant</p> */}
          <Row style={{ paddingTop: 20 }}>
            {/* Back button */}
            {showMiniGenerator && (
              <Button
                theme={'white'}
                style={{ marginLeft: 15 }}
                onClick={() => {
                  console.log('back button clicked');
                  setShowMiniGenerator(false);
                  // setSelectedGenerator(null)
                  setShowCopyOptionsList(true);
                }}
              >
                Back
              </Button>
            )}

            <Button
              outline
              style={{
                marginLeft: 'auto',
                marginRight: 25,
              }}
            >
              Close Assistant
            </Button>

            {showCopyOptionsList && (
              <Row style={{ padding: 24 }}>
                {copyOptionsForEditor.map((copyType, idx) => (
                  <Col lg="12" key={idx} style={{ marginBottom: 12 }}>
                    <Card
                      small
                      // className="card-post mb-4"
                      style={{ boxShadow: '0px 0px 1px #667085' }}
                    >
                      <Row style={{ paddingLeft: 12, paddingRight: 12 }}>
                        {copyType.iconImage && (
                          <img
                            alt=""
                            style={{ height: '20px' }}
                            src={copyType.iconImage}
                          ></img>
                        )}

                        {/* new: use icons instead of custom images. TODO convert the rest. */}
                        {copyType.icon && (
                          <i
                            className="material-icons"
                            style={{
                              height: 20,
                              width: 20,

                              fontSize: 13,
                              paddingTop: 5,
                              fontWeight: 'bold',
                              borderRadius: '50%',
                              textAlign: 'center',
                              backgroundColor: 'rgb(233, 240, 253)',
                              color: 'rgb(0, 123, 255)',
                              marginLeft: '10px',
                            }}
                          >
                            {copyType.icon}
                          </i>
                        )}
                        <strong style={{ paddingLeft: 8 }}>
                          {copyType.title}
                        </strong>
                        <p
                          className="card-text text-muted"
                          style={{ marginBottom: 0 }}
                        >
                          {copyType.body}
                        </p>
                        <div className="my-auto ml-auto">
                          <Button
                            size="sm"
                            theme="white"
                            onClick={() => {
                              console.log(
                                // 'copyType.urlSlug:',
                                // copyType.urlSlug
                                'KEY',
                                copyType.key
                              );
                              setShowCopyOptionsList(false);
                              // setShowMiniGenerator(copyType.urlSlug);
                              setShowMiniGenerator(copyType.key);
                              setSelectedGenerator(copyType);
                            }}
                          >
                            Open
                          </Button>
                        </div>
                      </Row>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}
          </Row>
          {showMiniGenerator && (
            <Row style={{ padding: 24 }}>
              <Row
                style={{ paddingLeft: 24, paddingRight: 24, paddingBottom: 24 }}
              >
                {selectedGenerator.iconImage && (
                  <img
                    alt=""
                    style={{ height: '20px' }}
                    src={selectedGenerator.iconImage}
                  ></img>
                )}

                {/* new: use icons instead of custom images. TODO convert the rest. */}
                {selectedGenerator.icon && (
                  <i
                    className="material-icons"
                    style={{
                      height: 20,
                      width: 20,

                      fontSize: 13,
                      paddingTop: 5,
                      fontWeight: 'bold',
                      borderRadius: '50%',
                      textAlign: 'center',
                      backgroundColor: 'rgb(233, 240, 253)',
                      color: 'rgb(0, 123, 255)',
                      marginLeft: '10px',
                    }}
                  >
                    {selectedGenerator.icon}
                  </i>
                )}
                <strong style={{ paddingLeft: 8 }}>
                  Write with <strong>{selectedGenerator.title}</strong>
                </strong>
                <p className="card-text text-muted" style={{ marginBottom: 0 }}>
                  {selectedGenerator.body}
                </p>
              </Row>

              {selectedGenerator.key && (
                <GenerateV2ForEditor
                  params={selectedGenerator} // showMiniGenerator
                  // generatorName={showMiniGenerator}
                />
              )}
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};
