import React, { useEffect } from 'react';
import { setUser } from '../actions';

import toast, { Toaster } from 'react-hot-toast';
import {
  Container,
  Row,
  Col,
  ListGroupItem,
  Button,
  ListGroup,
  Badge,
} from 'shards-react';

import firebase from 'firebase';
import { MixpanelSuper } from '../mixpanelSuper';

const Onboarding2Component = () => {
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const continueOnboarding = async () => {
    setIsLoading(true);
    console.log('continueOnboarding ');

    if (selectedItems.length === 0) {
      toast.error('Please select at least one');
      setIsLoading(false);
      return 'error';
    }

    // update firestore userdoc value with these values
    try {
      let userId = firebase.auth().currentUser.uid;
      console.log('update for userId', userId);
      const result = await firebase
        .firestore()
        .collection('users')
        .doc(userId)
        .update({
          contentTypes: selectedItems,
        })
        .catch(function(error) {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log('err', errorCode);
          console.log('errMessage', errorMessage);
          toast.error(`Error: ${errorMessage}`);
        });
      console.log('result', result);

      setIsLoading(false);
      console.log('Success! Go to next screen.');
      window.location.href = '/pick-a-plan';
      // later use onboarding-3 and verify with captcha
      // Mixpanel.track('Onboarding 1 - Business Name and Site');
      // Mixpanel.people.set({
      //   businessName: businessName,
      //   businessSite: businessSite,
      // });
      // }
    } catch (e) {
      console.log(e);
      toast.error(`Error: ${e.message}`);
      setIsLoading(false);
    }
  };

  return (
    <ListGroup flush>
      <ListGroupItem
        className="p-3"
        style={{
          boxShadow: '0px 1px 4px 0px rgba(109, 110, 111, 0.38)',
        }}
      >
        <Row>
          <Col>
            <Row form>
              {/* this is not very dry code. should clean up later */}
              <Col md="4" className="form-group">
                <center>
                  <button
                    class={
                      selectedItems.includes('blog')
                        ? 'mb-2 btn btn-outline-primary mr-2'
                        : 'mb-2 btn btn-sm btn-white mr-1'
                    }
                    style={{ width: 120 }}
                    onClick={() => {
                      console.log('on click');
                      if (selectedItems.includes('blog')) {
                        setSelectedItems(
                          selectedItems.filter((item) => item !== 'blog')
                        );
                      } else {
                        setSelectedItems([...selectedItems, 'blog']);
                      }
                    }}
                  >
                    Blog
                  </button>
                </center>
              </Col>
              <Col md="4" className="form-group">
                <center>
                  <button
                    class={
                      selectedItems.includes('social')
                        ? 'mb-2 btn btn-outline-primary mr-2'
                        : 'mb-2 btn btn-sm btn-white mr-1'
                    }
                    style={{ width: 120 }}
                    onClick={() => {
                      console.log('on click');
                      if (selectedItems.includes('social')) {
                        setSelectedItems(
                          selectedItems.filter((item) => item !== 'social')
                        );
                      } else {
                        setSelectedItems([...selectedItems, 'social']);
                      }
                    }}
                  >
                    Social Posts
                  </button>
                </center>
              </Col>
              <Col md="4" className="form-group">
                <center>
                  <button
                    class={
                      selectedItems.includes('email')
                        ? 'mb-2 btn btn-outline-primary mr-2'
                        : 'mb-2 btn btn-sm btn-white mr-1'
                    }
                    style={{ width: 120 }}
                    onClick={() => {
                      console.log('on click');
                      if (selectedItems.includes('email')) {
                        setSelectedItems(
                          selectedItems.filter((item) => item !== 'email')
                        );
                      } else {
                        setSelectedItems([...selectedItems, 'email']);
                      }
                    }}
                  >
                    Email
                  </button>
                </center>
              </Col>
            </Row>
            <Row form>
              {/* this is not very dry code. should clean up later */}
              <Col md="4" className="form-group">
                <center>
                  <button
                    class={
                      selectedItems.includes('web')
                        ? 'mb-2 btn btn-outline-primary mr-2'
                        : 'mb-2 btn btn-sm btn-white mr-1'
                    }
                    style={{ width: 120 }}
                    onClick={() => {
                      console.log('on click');
                      if (selectedItems.includes('web')) {
                        setSelectedItems(
                          selectedItems.filter((item) => item !== 'web')
                        );
                      } else {
                        setSelectedItems([...selectedItems, 'web']);
                      }
                    }}
                  >
                    Website Content
                  </button>
                </center>
              </Col>
              <Col md="4" className="form-group">
                <center>
                  <button
                    class={
                      selectedItems.includes('ads')
                        ? 'mb-2 btn btn-outline-primary mr-2'
                        : 'mb-2 btn btn-sm btn-white mr-1'
                    }
                    style={{ width: 120 }}
                    onClick={() => {
                      console.log('on click');
                      if (selectedItems.includes('ads')) {
                        setSelectedItems(
                          selectedItems.filter((item) => item !== 'ads')
                        );
                      } else {
                        setSelectedItems([...selectedItems, 'ads']);
                      }
                    }}
                  >
                    Ads
                  </button>
                </center>
              </Col>
              <Col md="4" className="form-group">
                <center>
                  <button
                    class={
                      selectedItems.includes('other')
                        ? 'mb-2 btn btn-outline-primary mr-2'
                        : 'mb-2 btn btn-sm btn-white mr-1'
                    }
                    style={{ width: 120 }}
                    onClick={() => {
                      console.log('on click');
                      if (selectedItems.includes('other')) {
                        setSelectedItems(
                          selectedItems.filter((item) => item !== 'other')
                        );
                      } else {
                        setSelectedItems([...selectedItems, 'other']);
                      }
                    }}
                  >
                    Other
                  </button>
                </center>
              </Col>
            </Row>
            <center>
              {isLoading ? (
                <button className={'mb-2 btn btn-sm btn-white mr-1'}>
                  Loading... {'   '}
                  <img
                    alt=""
                    src={require('../images/loading.gif')}
                    height="20"
                  ></img>
                </button>
              ) : (
                <Button
                  disabled={selectedItems.length === 0}
                  onClick={() => continueOnboarding()}
                >
                  Continue
                </Button>
              )}
            </center>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  );
};

export const OnboardingPart2 = () => {
  let isThisMobileDevice = window.navigator.userAgent.indexOf('Mobile') > -1;
  console.log('isThisMobileDevice', isThisMobileDevice);

  useEffect(() => {
    console.log('OnboardingPart2 useEffect');
    MixpanelSuper.trackScreenView('onboarding_2');
  }, []);

  return (
    <Container fluid>
      <div>
        <Toaster />
      </div>
      <div style={{ margin: 30 }}>
        <center>
          <Row
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '30px',
            }}
          >
            <span>Step</span>

            <Badge
              style={{
                backgroundColor: 'rgb(233, 240, 253)',
                color: 'rgb(0, 123, 255)',
                cursor: 'pointer',
                margin: 5,
              }}
            >
              1
            </Badge>

            <Badge
              style={{
                backgroundColor: 'rgb(233, 240, 253)',
                color: 'rgb(0, 123, 255)',
                cursor: 'pointer',
                margin: 5,
              }}
            >
              2
            </Badge>

            <Badge
              style={{
                backgroundColor: '#2F7DF6',
                color: 'white',
                cursor: 'pointer',
                margin: 5,
              }}
            >
              3
            </Badge>
          </Row>
          <h4 className="m-0">How do you plan to use GaryAI?</h4>
          <p>Select all that apply. You can change this later</p>
        </center>
      </div>

      <Container style={{ width: isThisMobileDevice ? '100%' : '40%' }}>
        <Onboarding2Component />
      </Container>
    </Container>
  );
};
