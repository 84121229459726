// https://hooks.slack.com/services/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
const sendSlackNotificationForCopyGeneration = (message) => {
  const requestOptions = {
    body: '{"text":" ' + message + '"}',
    method: 'POST',
  };

  const webhookUrl =
    'https://hooks.slack.com/services/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'; // GaryAI Slack channel: User Generations channel
  // old //'https://hooks.slack.com/services/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';
  fetch(webhookUrl, requestOptions).then((response) => {
    console.log('Slack response', response);
  });
};
const sendSlackNotificationToSupport = async (message) => {
  try {
    console.log('send slack message', message);
    const requestOptions = {
      body: '{"text":" ' + message + '"}',
      method: 'POST',
    };
    const webhookUrl =
      'https://hooks.slack.com/services/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'; //GaryAI slack channel: z_support-notifs channel

    fetch(webhookUrl, requestOptions).then((response) => {
      console.log('Slack response', response);
    });
  } catch (e) {
    console.log('error with sendOtherSlackNotification', e);
  }
};

const sendOtherSlackNotification = async (message) => {
  try {
    console.log('send slack message', message);
    const requestOptions = {
      body: '{"text":" ' + message + '"}',
      method: 'POST',
    };
    const webhookUrl =
      'https://hooks.slack.com/services/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'; //GaryAI slack channel: user-notifs channel
    // const domAndBrandonSlackWorkspaceWebhook =
    //   'https://hooks.slack.com/services/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';

    fetch(webhookUrl, requestOptions).then((response) => {
      console.log('Slack response', response);
    });
  } catch (e) {
    console.log('error with sendOtherSlackNotification', e);
  }
};
export {
  sendSlackNotificationForCopyGeneration,
  sendOtherSlackNotification,
  sendSlackNotificationToSupport,
};
