import React, { useEffect, useState } from 'react';

import toast, { Toaster } from 'react-hot-toast';
import { Mixpanel } from '../mixpanel';
import {
  Container,
  Row,
  Col,
  ListGroupItem,
  Button,
  ListGroup,
} from 'shards-react';

import firebase from 'firebase';
import { config } from '../environments/config';
import { sendOtherSlackNotification } from '../utils/slackNotifications';
import { MixpanelSuper } from '../mixpanelSuper';

export const VerifyPaidTrial = () => {
  const [isLoading, setIsLoading] = useState(true);
  const _ = require('lodash');
  let apiAddress = _.get(config, 'backend.apiAddress');
  let currentPageUrl = window.location.href;
  if (currentPageUrl.includes('localhost')) {
    console.log('LOCALHOST');
    apiAddress = 'http://localhost:3001';
  }

  let isThisMobileDevice = window.navigator.userAgent.indexOf('Mobile') > -1;
  console.log('isThisMobileDevice', isThisMobileDevice);

  const lookupCustomerViaServer = async (sessionId) => {
    try {
      let endpoint = apiAddress + '/api/v2/stripe/get-customer-by-session-id';
      let response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sessionId: sessionId,
        }),
      });
      let data = await response.json();
      console.log('data', data);

      if (data.status === 'success') {
        console.log('success');
        console.log('data.session', data.session);
        return data.session;
      } else {
        console.log('error');
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    console.log('VerifyPaidTrial useEffect');

    console.log(window.location.href);
    var url = new URL(window.location.href);

    let stripeSessionId = url.searchParams.get('session_id');
    MixpanelSuper.trackScreenView('verify_paid_trial');

    const handleLoad = async (stripeSessionId) => {
      let customerEmail;

      if (stripeSessionId) {
        let session = await lookupCustomerViaServer(stripeSessionId);

        // console.log('customer', session.customer_email);
        customerEmail = session.customer_email;
        console.log('customerEmail', customerEmail);
        // customer.email
        try {
          let newSignUpMessage = `New $1 Payment Trial started. ${customerEmail}.... Mobile?: ${isThisMobileDevice}`;
          sendOtherSlackNotification(newSignUpMessage);
        } catch (e) {
          console.log('Couldnt send slack message', e);
        }
      } else {
        console.log('no stripeSessionId');
        // send slack message
        try {
          let newSignUpMessage = `New $1 Payment Trial started. SessionId Not Known.... Mobile?: ${isThisMobileDevice}`;
          sendOtherSlackNotification(newSignUpMessage);
        } catch (e) {
          console.log('Couldnt send slack message', e);
        }
      }

      // Fire fb pixel
      try {
        window.fbq('track', 'Purchase', {
          value: '1.00',
          currency: 'USD',
        });
        console.log('fb purchase fired');
      } catch (e) {
        console.log('Could not fire Purchase pixel event because', e);
      }

      let redirectUrl = '/set-up-account';

      if (stripeSessionId && customerEmail) {
        redirectUrl =
          '/set-up-account?ss=' + stripeSessionId + '&email=' + customerEmail;
      } else {
        if (stripeSessionId) {
          redirectUrl = '/set-up-account?ss=' + stripeSessionId;
        }
        if (customerEmail) {
          redirectUrl = '/set-up-account?email=' + customerEmail;
        }
      }

      window.location.href = redirectUrl;
    };

    handleLoad(stripeSessionId).catch(console.error);

    // firebase.auth().onAuthStateChanged(function(user) {
    //   if (user) {
    //     console.log('On Verify Plan - Firebase ready and identified user');

    //     if (stripeSessionId) {
    //       console.log('Session id in url');
    //       console.log('update firebase user with stripe session id');
    //       // update firestore
    //       let updatedObject = {
    //         stripeSessionId: stripeSessionId,
    //         paid: true,
    //         purchaseStartTime: new Date(),
    //         purchaseStartTimeReadable: new Date().toLocaleString(),
    //         stripeBillingEmail: user.email, // in case they change their email later...
    //       };
    //       if (planName) {
    //         updatedObject.planName = planName;
    //       }
    //       //
    //       // if plan name contains pro
    //       if (planName.includes('pro')) {
    //         updatedObject.smu = 777;
    //       }
    //       console.log('updatedObject', updatedObject);

    //       firebase
    //         .firestore()
    //         .collection('users')
    //         .doc(user.uid)
    //         .update(updatedObject)
    //         .then(function() {

    //           } catch (e) {
    //             console.log('Unable to push to  crisp', e);
    //           }
    //           setTimeout(() => {
    //             // window.location.href = '/offer?p=' + planName;
    //             // grab session id and get email
    //             window.location.href = '/set-up-account';
    //           }, 500);
    //         })
    //         .catch(function(error) {
    //           console.error('Error updating document: ', error);
    //           toast.error('Something went wrong: ' + error);
    //           setIsLoading(false);
    //         });
    //     } else {
    //       console.log('No session id in url');
    //       toast.error('Something went wrong: No session id in url');
    //       setIsLoading(false);
    //     }
    //   } else {
    //     toast.error('Unable to identify you. Please try again.');
    //   }
    // });
  }, []);

  return (
    <Container fluid>
      <div>
        <Toaster />
      </div>
      <div style={{ margin: 30 }}></div>

      <Container style={{ width: isThisMobileDevice ? '75%' : '40%' }}>
        {isLoading && (
          <center>
            <img
              src={require('../images/loading.gif')}
              style={{ height: 100, margin: 30 }}
            />

            {/* <p>Verifying purchase, please wait.</p> */}
          </center>
        )}
      </Container>
    </Container>
  );
};
