export default function() {
  return [
    {
      title: 'Home',
      to: '/dashboard',
      htmlBefore: '<i class="material-icons">home</i>',
      htmlAfter: '',
    },
    {
      title: 'Templates',
      to: '/write',
      htmlBefore: '<i class="material-icons">edit</i>',
      htmlAfter: '',
      subNav: [
        ///////// COMMENT OUT FOR NOW BECAUSE WANT PEOPLE TO USE AIDA FIRST AND I CANNOT PROGRAMATICALLY REMOVE FOR NEW USERS EASILY
        // {
        //   title: "Facebook Ads",
        //   to: "/generate/facebook-ads",
        //   htmlBefore: '<i class="material-icons">navigate_next</i>',
        //   htmlAfter: "",
        // },
        // {
        //   title: "Headlines",
        //   to: "/generate/headlines",
        //   htmlBefore: '<i class="material-icons">navigate_next</i>',
        //   htmlAfter: "",
        // },
        // {
        //   title: "Product Descriptions",
        //   to: "/generate/product-descriptions",
        //   htmlBefore: '<i class="material-icons">navigate_next</i>',
        //   htmlAfter: "",
        // },
        // {
        //   title: "Pain Agitate Solution",
        //   to: "/generate/pas",
        //   htmlBefore: '<i class="material-icons">navigate_next</i>',
        //   htmlAfter: "",
        // },
        // {
        //   title: "Attention Interest Desire Action",
        //   to: "/generate/aida",
        //   htmlBefore: '<i class="material-icons">navigate_next</i>',
        //   htmlAfter: "",
        // },
        // // {
        // //   title: " Custom Copy",
        // //   to: "/custom",
        // //   htmlBefore: '<span class="badge badge-secondary badge-pill" style="background-color:#cacedb;">Pro</span>',
        // //   htmlAfter: "",
        // // }
      ],
    },
    {
      title: 'Custom',
      to: '/custom',
      htmlBefore: '<i class="material-icons">extension</i>',
      htmlAfter: '',
    },

    {
      title: 'Documents',
      to: '/documents',
      htmlBefore: '<i class="material-icons">folder</i>',
      htmlAfter: '',
    },

    {
      title: 'Saved',
      to: '/saved',
      htmlBefore: '<i class="material-icons">bookmark</i>',
      htmlAfter: '',
    },
    {
      title: 'History',
      to: '/history',
      htmlBefore: '<i class="material-icons">schedule</i>',
      htmlAfter: '',
    },
    // {
    //   title: 'Settings',
    //   htmlBefore: '<i class="material-icons">settings</i>',
    //   to: '/settings',
    //   // red dot in span tag if they need to verify email
    //   // htmlAfter:
    //   //   '<span id="settingsMessageIcon" style="display:noblne; height:30px; width:30px; padding:3px 8px 3px 8px; margin-left:5px; border-radius:50%; background-color:#ff00008f; color:white">1</span>',
    // },

    // {
    //   title: "Types ",
    //   htmlBefore: '<i class="material-icons">vertical_split</i>',
    //   to: "/write",
    // },

    // Items from below are not needed and from starting template
    // {
    //   title: "Blog Dashboard",
    //   to: "/blog-overview",
    //   htmlBefore: '<i class="material-icons">edit</i>',
    //   htmlAfter: ""
    // },
    // {
    //   title: "Blog Posts",
    //   htmlBefore: '<i class="material-icons">vertical_split</i>',
    //   to: "/blog-posts",
    // },
    // {
    //   title: "Add New Post",
    //   htmlBefore: '<i class="material-icons">note_add</i>',
    //   to: "/add-new-post",
    // },
    // {
    //   title: "Forms & Components",
    //   htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/components-overview",
    // },
    // {
    //   title: "Tables",
    //   htmlBefore: '<i class="material-icons">table_chart</i>',
    //   to: "/tables",
    // },
    // {
    //   title: "User Profile",
    //   htmlBefore: '<i class="material-icons">person</i>',
    //   to: "/user-profile-lite",
    // },
    // {
    //   title: "Errors",
    //   htmlBefore: '<i class="material-icons">error</i>',
    //   to: "/errors",
    // }
  ];
}
