import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, FormInput, ListGroup, ListGroupItem, Button } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Fire from "../config/fire"

const UserProfileLite = () => {
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [email, setemail] = useState('');
  const [loading, setloading] = useState(false);


  const promptIntercomMessage = (e) => {
    console.log('promptIntercomMessage');
    e.preventDefault();
    window.Intercom('showNewMessage', 'Replace this text with what you help request ');
  }

  const loadUserSettings = () => {
    console.log('loadUserSettings');
    setloading(true)
      Fire.shared.getUserDetails()
        .then(res => {
          // console.log("User details here: ", res)
          try {
            if (res['fn'] && res['ln'] && res['e']) {
              setlastName(res['ln'])
              setfirstName(res['fn'])
              setemail(res['e'])
              setloading(false);
            };
          }catch(e){
            console.log('Snapshot returned empty data. Error', e)
          }

        })
  }
  useEffect(() => {
    Fire.shared.checkAndWaitForFirebaseLoad().then(()=> {
      loadUserSettings()
    });
  }, [])

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title="Settings" subtitle="Account" md="12" className="ml-sm-auto mr-sm-auto" />
      </Row>
      {loading && <img alt="" src={require("../images/loading.gif")} height="100" style={{marginLeft: "40%"}} ></img> }
      {!loading &&
        <ListGroup flush>
          <ListGroupItem className="p-3">
            <Row style={{margin: "auto", }}>
              <Col>
                <Form>
                  <Row form>
                    {/* First Name */}
                      <Col md="6" className="form-group">
                        <label htmlFor="feFirstName">First Name</label>
                          <FormInput
                            id="feFirstName"
                            className="disabled"
                            placeholder="First Name"
                            value={firstName}
                          />
                      </Col>
                      {/* Last Name */}
                      <Col md="6" className="form-group">
                        <label htmlFor="feLastName">Last Name</label>
                        <FormInput
                          id="feLastName"
                          placeholder="Last Name"
                          value={lastName}
                          className="disabled"
                        />
                      </Col>
                      </Row>
                      <Row form>
                        {/* Email */}
                        <Col md="6" className="form-group">
                          <label htmlFor="feEmail">Email</label>
                          <FormInput
                            type="email"
                            id="feEmail"
                            placeholder="Email Address"
                            value={email}
                            className="disabled"
                          />
                        </Col>
                        <Col md="6" className="form-group">
                        </Col>
                        <em style={{paddingLeft: "7px"}}>Need to change your email, name, or password? <strong><button style={{textDecoration: "underline", color: "blue", cursor:"pointer", border:"none", backgroundColor: 'rgba(52, 52, 52, 0.0)' }} onClick={promptIntercomMessage}>Contact support</button></strong></em>
                        <br></br>
                      </Row>
                      <br></br>
                      <Row form>
                        <Col md="12" className="form-group">
                          <strong>Plans & Pricing</strong>
                          <br></br>
                          <Button onClick={() => { window.location.href='/pricing' }}>Select a plan</Button>
                        </Col>
                      </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      }
    </Container>
  )
};

export default UserProfileLite;
