import React, { useEffect } from 'react';
import { setFirebaseUserData, setUser } from '../actions';
import toast, { Toaster } from 'react-hot-toast';

import {
  Card,
  Container,
  Row,
  Col,
  ListGroupItem,
  Form,
  FormInput,
  Button,
  ListGroup,
  FormGroup,
  FormSelect,
  FormCheckbox,
} from 'shards-react';
import { Mixpanel } from '../mixpanel';
import { MixpanelSuper } from '../mixpanelSuper';
import firebase from 'firebase';
import { config } from '../environments/config';
import { connect } from 'react-redux';

import { sendSlackNotificationToSupport } from '../utils/slackNotifications';
export const ForgotPassword = () => {
  let isThisMobileDevice = window.navigator.userAgent.indexOf('Mobile') > -1;
  console.log('isThisMobileDevice', isThisMobileDevice);

  // JS HERE
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [resetEmailSent, setResetEmailSent] = React.useState(false);
  const [userFullFb, setUserFullFb] = React.useState({});

  const validateFields = () => {
    console.log('validateFields');

    if (email.length < 1) {
      toast.error('Email is required');
      return false;
    }

    return true;
  };

  const sendPasswordResetEmail = async () => {
    console.log('sendPasswordResetEmail');
    let validFields = validateFields();
    if (!validFields) {
      return;
    }

    setIsLoading(true);
    try {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          setIsLoading(false);
          // Password reset email sent!
          toast.success(
            'Password reset email sent. Please check your email and click the link to reset your password.'
          );
          setResetEmailSent(true);
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          toast.error(errorMessage);
          setIsLoading(false);
        });

      await sendSlackNotificationToSupport(
        `Password reset requested for: ${email}`
      );
    } catch (e) {
      console.log('caught error sending email', e);
      setIsLoading(false);
    }
  };

  const inputsHandler = (e) => {
    const { name, value } = e.target;

    if (name === 'email') {
      setEmail(value);
    }
  };

  useEffect(() => {
    MixpanelSuper.trackScreenView('forgot_password');

    setUserFullFb({});
    console.log('userFullFb', userFullFb);
  }, []);

  return (
    <div
      style={{
        backgroundColor: isThisMobileDevice ? null : '#fff',
        height: '100vh',
      }}
    >
      <div>
        <Toaster />
      </div>

      <div
        style={{
          textAlign: 'center',
          paddingTop: 150,
          paddingBottom: 40,
        }}
      >
        <h4 className="m-0">
          <strong>Forgot Password?</strong>
        </h4>
      </div>

      {!resetEmailSent && (
        <Row noGutters style={{}}>
          <Col lg="4"></Col>
          <Col lg="4" md="6">
            <Card small>
              <ListGroupItem className="" style={{ border: 'none' }}>
                <Row>
                  <Col>
                    <Form>
                      <Row form>
                        <Col className="form-group">
                          <label htmlFor="feEmailAddress">Email</label>
                          <FormInput
                            id="email"
                            type="email"
                            name="email"
                            onChange={inputsHandler}
                          />
                          <p style={{ padding: 7, marginBottom: 0 }}>
                            Type the address linked to your account and we'll
                            send you password reset instructions. They might end
                            up in spam so please check there as well
                          </p>
                        </Col>
                      </Row>

                      {isLoading ? (
                        <button className={'mb-2 btn btn-sm btn-white mr-1'}>
                          Loading... {'   '}
                          <img
                            alt=""
                            src={require('../images/loading.gif')}
                            height="20"
                          ></img>
                        </button>
                      ) : (
                        <Button
                          onClick={async (e) => {
                            console.log(email);
                            await sendPasswordResetEmail();
                          }}
                        >
                          Send Reset Instructions
                        </Button>
                      )}

                      <br></br>
                      <br></br>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </Card>
          </Col>
        </Row>
      )}

      {resetEmailSent && (
        <Row noGutters style={{}}>
          <Col lg="4"></Col>
          <Col lg="4" md="6">
            <Card small>
              <ListGroupItem className="" style={{ border: 'none' }}>
                <Row>
                  <Col>
                    <Form>
                      <h3>Email sent to {email}</h3>
                      <p>Click the link in the email to reset your password.</p>
                      <p>
                        Be sure you use the email you signed up with, and check
                        spam folder. It may take a minute or two to arrive.
                      </p>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};
