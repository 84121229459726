import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  ListGroupItem,
  Form,
  FormGroup,
  FormInput,
  Button,
  ButtonGroup,
  FormTextarea,
  Alert,
  Modal,
  ModalBody,
  ModalHeader,
  Badge,
} from 'shards-react';
import {
  copyToClipboard,
  handleCopyGenerationResponse,
} from '../utils/generatorPageHelpers';
import { sendSlackNotificationForCopyGeneration } from '../utils/slackNotifications';

import Fire from '../config/fire';
import PageTitle from '../components/common/PageTitle';

import { MixpanelSuper } from '../mixpanelSuper';

import firebase from 'firebase';
import { config } from '../environments/config';
import '../assets/upgrade.css';
import '../assets/generate.css';
import { countNewLines } from '../utils/utils';
import toast, { Toaster } from 'react-hot-toast';

import { useSelector, useDispatch } from 'react-redux';

const _ = require('lodash');

let apiAddress = _.get(config, 'backend.apiAddress');
let currentPageUrl = window.location.href;

if (currentPageUrl.includes('localhost')) {
  console.log('LOCALHOST');
  apiAddress = 'http://localhost:3001';
  // console.log = function() {};
} else {
  // hide all console logs
  // console.log = function() {};
}

console.log('generate to: ', apiAddress);

const GenerateV2 = ({ match: { params } }) => {
  /** REDUX vvv */
  // const credits = useSelector(state => state.credits);
  // const isSubscribed = useSelector(state => state.isSubscribed);
  // const daysRemaining = useSelector(state => state.daysRemaining);
  // const firebaseuserData = useSelector(state => state.firebaseuserData);
  // const dispatch = useDispatch();
  /** REDUX ^^^ */

  //NEW VARIABLES
  const [generator, setGenerator] = useState(
    'loading'
  ); /** STRING NAME, How Do I set Default? */
  const [pageTitle, setPageTitle] = useState('');
  const [pageDescription, setPageDescription] = useState('');
  // const [generatorExamples, setGeneratorExamples] = useState([]);
  const [generatorExists, setGeneratorExists] = useState(false);
  const [copyTypeUnderscore, setCopyTypeUnderscore] = useState(
    'default-generator'
  );
  const [copyTypeCamelCase, setCopyTypeCamelCase] = useState(
    'default-generator'
  );
  const [lastGeneratedPrompts, setLastGeneratedPrompts] = useState({});

  const [remainingCredits, setRemainingCredits] = useState(50); // Hard coding this stil, but we really shouldnt, see related issue here https://garyai.atlassian.net/browse/GAT-41?atlOrigin=eyJpIjoiOTdkOGUyZDg4NDBkNDExNDg5OWYwYTE0ZGQ2ZDQ1YjYiLCJwIjoiaiJ9
  // const [remainingCredits, setRemainingCredits] = useState(50);
  const [remainingDays, setRemainingDays] = useState(30);
  const [remaingDaysLabel, setRemaingDaysLabel] = useState(
    'days left in your free trial.'
  );
  const [
    subscriptionDetailsHaveLoaded,
    setsubscriptionDetailsHaveLoaded,
  ] = useState(false);
  const [userEmail, setuserEmail] = useState('');
  const [usersName, setusersName] = useState('');

  const [isSubscriber, setIsSubscriber] = useState(false);
  const [firebaseUserData, setFirebaseUserData] = useState({});

  const [showVideo, setShowVideo] = useState(false);
  const [showHelper, setShowHelper] = useState(true);

  const [localStars, setLocalStars] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorModalText, setShowErrorModalText] = useState('');
  const [userDetails, setUserDetails] = useState({});

  const [
    showPromptImprovementSuggestion,
    setShowPromptImprovementSuggestion,
  ] = useState(false);
  const [fieldToImprove, setFieldToImprove] = useState('inputs');

  const [showPromptBuilderModal, setShowPromptBuilderModal] = useState(false);
  const [promptBuilderValues, setPromptBuilderValues] = useState({});
  const [showGeneralInputTipModal, setShowGeneralInputTipModal] = useState(
    false
  );

  const [showCopyToolTip, setShowCopyToolTip] = useState(false);
  const [showFavoriteToolTip, setShowFavoriteToolTip] = useState(false);
  const [showProUpsell, setShowProUpsell] = useState(true);

  const intercomTrackCredits = (left) => {
    if (true) {
      return new Promise((resolve, reject) => {
        try {
          window.Intercom('boot', {
            app_id: _.get(config, 'intercom.appId'),
            remainingCredits: remainingCredits,
          });
          console.log('set remaining credits');
          resolve();
        } catch (e) {
          console.log('There was an issue with intercom: ', e);
          reject(e);
        }
      });
    }
  };

  const copyLinkToClipboard = (link) => {
    MixpanelSuper.copyShareSnippet('generate_page');
    console.log('Custom link copied To clipboard');
    navigator.clipboard.writeText(link);
    setdisplayMessage('Sharable Link Copied!');
    setTimeout(() => {
      setdisplayMessage(null);
    }, 3000);
  };

  const fillPromptWithValues = (promptsArray) => {
    for (let i = 0; i < promptsArray.length; i++) {
      if (promptsArray[i]) {
        document.getElementById('inputParameter' + i).value =
          promptsArray[
            i
          ]; /** NOTE: This works for both text area and text inputs */
      }
    }
  };

  const checkSubscription = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function(idToken) {
          const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${_.get(config, 'backend.bearerToken')}`,
              firebaseidtoken: idToken,
            },
            // body: JSON.stringify()
          };
          fetch(
            `${apiAddress}/v1/stripe/get-and-update-subscription`,
            requestOptions
          )
            .then((response) => {
              // console.log("response", response);
              if (response.status === 406) {
                window.alert('');
                window.location.reload();
              }

              if (response.status === 500) {
                window.alert("Sorry we can't process your request right now.");
                window.location.reload();
              }
              if (response.status === 402) {
                return Promise.reject('Payment required to continue');
              }
              return response.json();
            })
            .then((data) => {
              resolve(data);
              console.log('Successful Subscription Check');
            });
        })
        .catch((e) => {
          reject(e);
          console.log('Error occured while accessing current firebase user', e);
        });
    });
  };

  /**
   * NOTES: ? TODO: IDK how to make this variable not change as routes change.  is there a global state or session storage I can cache to?
   * */
  /** showUserDetails being replaced by REDUX. */
  /** TODO: is it possible to abstract these functions to a helper file if I use them in multiple files.  I could pass in "dispatch"
   * TODO: Note they are functional components here and class components on the write page. "CopyOptions page"*/
  const setFirebaseUserToStateRedux = (userData) => {
    /** NO LONGER REDUX */
    setFirebaseUserData(userData); //dispatch(setFirebaseUserData(userData)); /** Redux */
  };

  const setCreditsRedux = (userData) => {
    /** NO LONGER REDUX */
    let credits =
      _.get(userData, 'smu', 0) -
      _.get(userData, 'pu', 0) +
      _.get(userData, 'ec', 0);
    if (!credits) {
      credits = 0;
    }
    setRemainingCredits(credits); //dispatch(setCredits(credits)); /** Redux */
  };

  const setDaysRemainingRedux = (userData) => {
    /** NO LONGER REDUX */
    let timeNow = Date.now();
    //If no subscription
    let se = _.get(userData, 'se', undefined);
    let te = _.get(userData, 'te', undefined);
    let days;
    if (se) {
      if (se * 1000 >= timeNow) {
        /** Note. Stripe in seconds not MS */
        days = Math.ceil((se * 1000 - timeNow) / 86400000);
      } else {
        days = 0;
      }
    } else if (te) {
      if (te >= timeNow) {
        days = Math.ceil((te - timeNow) / 86400000);
      } else {
        days = 0;
      }
    } else {
      days = '?';
    }
    setRemainingDays(days); //dispatch(setDaysRemaining(days)); /** Redux */
  };

  const setSubscribedRedux = (userData) => {
    /** NO LONGER REDUX */
    let se = _.get(userData, 'se', undefined);
    if (se > Date.now()) {
      setIsSubscriber(true); // dispatch(setSubscribed);  /** Redux */
      setRemaingDaysLabel('days left in your subscription period');
    }
    // this.props.setSubscribed(userData);
  };

  const showUserDetails = async (recursive = true) => {
    /** TODO: DO I NEED TO DO THIS EVERY TIME IF USING REDUX?????
     * Alternatively could I decrement the counter and assume
     * they're only using on 1 tab/ it wont be the end of the world if they refresh
     * */
    Fire.shared.getUserDetails().then((userData) => {
      if (!userData) {
        if (recursive) {
          setTimeout(function() {
            showUserDetails(false);
          }, 50); /** Retries Once */
        }
      } else {
        intercomTrackCredits(remainingCredits);
        /** THIS IS THE MESSIEST FUCKING LOGIC. */
        /** TODO: REMOVE AFTER ADDING REDUX */
        setFirebaseUserToStateRedux(userData); /** NO LONGER REDUX */
        if (userData) {
          setCreditsRedux(userData);
          /** NO LONGER REDUX */
          setDaysRemainingRedux(userData);
          /** NO LONGER REDUX */
          setSubscribedRedux(userData);
          /** NO LONGER REDUX */
          setsubscriptionDetailsHaveLoaded(true);
        }

        if (remainingCredits <= 0) {
          checkSubscription().then((data) => {
            /** Check if they have a subscription it's just not set in DB yet. */
            if (recursive) {
              /** Only retry once */
              showUserDetails(false);
            }
          });
        }
      }
    });
  };
  // loyalty icon for pas

  //TODO: THIS TYPE OF JSON COULD BE IN A SEPERATE CONFIG FILE THATS EASY TO UPDATE
  // TODO: Add replace feature where I can have a list of items to replace like the paragraph symbol. (replace after regex divide)
  const generators = {
    finisher: {
      pageTitle: 'Tiktok Finisher',
      pageDescription: 'Go viral on tiktok!',
      mixpanelLegacyUnderScore: 'tiktok_finisher',
      mixpanelLegacyCamel: 'tiktokFinisher',
      introWistia: '', //'wj8dimasb4', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-pas.svg'),
      prompts: [
        {
          name: 'content',
          title: 'Content:',
          placeHolder:
            'Im sorry, I know this is a serious interview. I cant help but notice that your tie is really nice.',
          textArea: true,
          defaultValue: '',
        },
      ],
      examples: [],
    },
    funnyInfluencerStory: {
      pageTitle: 'Tiktok Funny Influencer Story',
      pageDescription: 'Crazy stories for going viral on tiktok!',
      mixpanelLegacyUnderScore: 'funny_influencer_story',
      mixpanelLegacyCamel: 'funnyInfluencerStory',
      introWistia: '',
      introYoutube: '',
      showHelperImage: require('../images/empty-pas.svg'),
      prompts: [
        {
          name: 'content',
          title: 'Content:',
          placeHolder: 'funny story of meeting midget at strip club',
          textArea: true,
          defaultValue: '',
        },
      ],
      examples: [],
    },
    improver: {
      pageTitle: 'Content Improver',
      pageDescription: 'Improve your existing content to make it more engaging',
      mixpanelLegacyUnderScore: 'content_improver',
      mixpanelLegacyCamel: 'contentImprover',
      introWistia: '', //'wj8dimasb4', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-pas.svg'),
      prompts: [
        {
          name: 'content',
          title: 'Content:',
          // inputInstructions:'company\'s public name or base domain name & extension only',
          placeHolder:
            'This is a long piece of text that you want to rewrite and improve!',
          textArea: true,
          defaultValue: '',
        },
        // {
        //   name: 'reword',
        //   title: 'Reword this content to...',
        //   inputInstructions: 'ex: be shorter, be more suitable for facebook.',
        //   placeHolder: 'be a few setences shorter',
        //   textArea: false,
        //   defaultValue: '',
        // },
        // {
        //   name: 'Tone',
        //   title: 'Tone:',
        //   inputInstructions: 'Funny, witty, sales',
        //   placeHolder: 'Funny',
        //   textArea: false,
        //   defaultValue: '',
        // },
      ],
      examples: [],
    },
    paragraph: {
      pageTitle: 'Paragraph Writer',
      pageDescription:
        'Write captivating paragraphs that your readers will love!',
      mixpanelLegacyUnderScore: 'content_improver',
      mixpanelLegacyCamel: 'contentImprover',
      introWistia: '', //'wj8dimasb4', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-pas.svg'),
      prompts: [
        {
          name: 'topic',
          title: 'What is your paragraph about?',
          // inputInstructions:'company\'s public name or base domain name & extension only',
          placeHolder:
            'Why every restaurant should have post instagram stories',
          textArea: true,
          defaultValue: '',
        },
        {
          name: 'keywords',
          title: 'Keywords to include:',
          // inputInstructions: 'ex: be shorter, be more suitable for facebook.',
          placeHolder: 'engagement, followers',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'tone',
          title: 'Tone of voice:',
          inputInstructions: 'ex: Funny, witty, sales',
          placeHolder: 'Professional',
          textArea: false,
          defaultValue: '',
        },
      ],
      examples: [],
    },
    aida: {
      pageTitle: 'Attention Interest Desire Action Formula',
      mixpanelLegacyUnderScore: 'attention_interest_desire_action',
      mixpanelLegacyCamel: 'attentionInterestDesireAction',
      introWistia: 'wj8dimasb4', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-aida.svg'),
      prompts: [
        {
          name: 'company',
          title: 'Company or domain name:',
          // inputInstructions:'company\'s public name or base domain name & extension only',
          placeHolder: 'Hotels.com',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'description',
          title: 'Description:',
          inputInstructions:
            'Enter 1-2 sentences describing your product and the value it provides',
          placeHolder: 'A website that helps you find hotels faster and easier',
          textArea: true,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        // {
        //   name: 'Colgate',
        //   prompts: ['Colgate', 'A teeth whitener that turns yellow teeth into pearly whites within days.']
        // },
        // {
        //   name: 'GaryAI',
        //   prompts: ['GaryAI.com', 'A website that uses AI to help you write better ad copy']
        // },
        // {
        //   name: 'AdAgency.com',
        //   prompts: ['AdAgency.com', 'A digital marketing agency that helps restaurants generate leads with Facebook ads.']
        // }
      ],
    },
    loading: {
      pageTitle: 'Loading...',
      prompts: [],
      examples: [],
    },
    notFound: {
      pageTitle: 'Generator Not Found',
      prompts: [],
      examples: [],
    },
    empty: {
      pageTitle: 'Please Specify A Generator',
      prompts: [],
      examples: [],
    },
  };

  const [copyResults, setcopyResults] = useState([]);
  const [hasResults, sethasResults] = useState(false);

  const [loading, setloading] = useState(false);
  const [displayMessage, setdisplayMessage] = useState('');
  const [likedItems, setlikedItems] = useState([]);
  const [showUpgradeModal, setshowUpgradeModal] = useState(false);

  let handleLoad = (params) => {
    let gen = _.get(
      params,
      'generator',
      'empty'
    ); /** TODO: BUG: Route does not work if value not set - does not default to empty */
    if (Object.keys(generators).includes(gen)) {
      setGenerator(gen);
      setcopyResults([]);
      setPageTitle(generators[gen].pageTitle);
      setPageDescription(generators[gen].pageDescription);
      setGeneratorExists(true);
      // setGeneratorExamples(_.get(generators,`${gen}`,[]));
      setShowVideo(false);
      setShowHelper(true);
    } else {
      setGenerator('notFound');
      setPageTitle('Generator Not Found');
    }

    /** Break Through: You cannot immediatly use a useState variable that you just changed. */
    setCopyTypeUnderscore(gen);
    if (generators[gen].mixpanelLegacyUnderScore !== undefined) {
      setCopyTypeUnderscore(generators[gen].mixpanelLegacyUnderScore);
    } else if (gen !== 'loading') {
      setCopyTypeUnderscore(gen);
    }

    setCopyTypeCamelCase(gen);
    if (generators[gen].mixpanelLegacyCamel !== undefined) {
      setCopyTypeCamelCase(generators[gen].mixpanelLegacyCamel);
    } else if (gen !== 'loading') {
      setCopyTypeCamelCase(gen);
    }
  };

  useEffect(() => {
    console.log('GenerateV2 UseEffect');
    // setTimeout(() => {
    //   setRemainingCredits(300);
    // }, 3000);

    firebase.auth().onAuthStateChanged(function(user) {
      console.log('changed');
      if (user) {
        console.log('generate useEffect, have user details');

        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then(function(doc) {
            if (doc.exists) {
              let data = doc.data();
              console.log('User Document data:', data);
              setUserDetails(data);
            }
          });
      }
    });

    MixpanelSuper.trackScreenView('generateV2');

    // old logic
    Fire.shared.checkAndWaitForFirebaseLoad().then(() => {
      if (generator != _.get(params, 'generator', 'none')) {
        console.log('use effect loaded');
        handleLoad(params);
        showUserDetails();
      }
    });
  }, []);

  const saveResultsAndHandleResponse = (data, requestPrompts) => {
    // this function handles UI to display results, and saves all results to firebase.
    if (typeof data == 'string') {
      data = [data];
    }

    showUserDetails(); //TODO: ****************** USE DATA RETURNED FROM API INSTEAD OF CALLING FIREBASE AGAIN.
    /** NOTE: Data Input Should Be An Array */
    handleCopyGenerationResponse(
      data,
      copyResults,
      sethasResults,
      setcopyResults,
      setloading
    );
  };

  const handleCopyGenerationV2 = async (requestDetails) => {
    const { firebaseJwtToken, requestBody, requestPrompts } = requestDetails;

    console.log('handleCopyGenerationV2. requestPrompts: ', requestPrompts);
    console.log('POST BODY', requestBody);
    // make request to api
    let generateEndpoint = `${apiAddress}/api/v2/generate`;
    const response = await fetch(generateEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${_.get(config, 'backend.bearerToken')}`,
        firebaseidtoken: firebaseJwtToken,
      },
      body: JSON.stringify(requestBody),
    });
    console.log('response', response);
    // response.status === 200 ? console.log('success') : console.log('error');
    // response.status 401 is unauthorized -- and data.message will include details. (Invalid bearer creendials)

    if (response.status === 200) {
      const resp = await response.json();
      console.log('resp', resp);
      let copyArray = resp.data.data;
      console.log('copyArray', copyArray);
      // set copy results.
      saveResultsAndHandleResponse(copyArray, requestPrompts);

      if (resp.promptScore && resp.promptScore.status === 'success') {
        // console.log('resp.promptScore', resp.promptScore);
        let promptScoreNumber = resp.promptScore.result;
        console.log('promptScoreNumber >>> ', promptScoreNumber);
        if (promptScoreNumber <= 5) {
          console.log('BAD PROMPT!!!!');
          setFieldToImprove(resp.promptScore.field);
          // show prompt improvement suggestion
          setShowPromptImprovementSuggestion(true);
          MixpanelSuper.promptSuggestionSeen(generator);
        }
      }
    } else {
      // handle error
      console.log('Response.status != 200... handle error');
      console.log('response statusText', response.statusText);
      console.log('response status', response.status);
      // if no valid subscription...
      if (response.status === 402) {
        // toast.error(`You need an active trial or subscription to do that`);
        toast.error(
          (t) => (
            <span>
              Oops. You will need to pick a plan or start a free trial do that.
              Please:
              <br />
              <br />
              <center>
                <Button
                  theme="accent"
                  onClick={() =>
                    (window.location.href =
                      'https://app.garyai.com/pick-a-plan')
                  }
                >
                  Go to plans
                </Button>
              </center>
            </span>
          ),
          { duration: 9000 }
        );
      } else {
        toast.error(`Error ${response.status}: ` + response.statusText);
      }
      // alert(`Error ${response.status}: ` + response.statusText);
      setloading(false);
    }
  };

  const generateCopyV2 = async () => {
    // this function handles the generation of the copy.
    let currentGenerator = generators[generator];
    let prompts = currentGenerator.prompts;
    let promptsLength = prompts.length;
    let requestPrompts = {};
    for (let i = 0; i < promptsLength; i++) {
      let inputValue = document.getElementById('inputParameter' + i).value; //NOTE: Hope this works for both text area and text inputs
      if (inputValue === '') {
        // window.alert(`Input "${prompts.title}" Must Not Be Empty.`); TODO: prompt.title comes up as undefined
        window.alert(`Form Fields Must Not Be Empty.`);
        MixpanelSuper.noInputsError(copyTypeUnderscore);
        return;
      }
      // valuesArray.push(inputValue);
      requestPrompts[prompts[i].name] = inputValue;
    }
    setLastGeneratedPrompts(requestPrompts);

    MixpanelSuper.generateSnippet(copyTypeUnderscore); //NOTE: Should still be the same if on the same generator page for generate more like this.
    console.log('make new request to', apiAddress);
    setShowHelper(false);
    setShowVideo(false);
    setloading(true);

    // get JWT token from firebase. pass true to force refresh.
    const firebaseJwtToken = await firebase.auth().currentUser.getIdToken();
    const userId = await firebase.auth().currentUser.uid;
    const userDocRef = await firebase
      .firestore()
      .collection('users')
      .doc(userId);

    // get user data from firebase
    const userDoc = await userDocRef.get();
    const userData = userDoc.data();
    console.log('userData', userData);
    console.log('firebaseJwtToken', firebaseJwtToken);

    const requestBody = {
      generator: generator,
      prompts: requestPrompts,
      userId: userId,
      userData: userData,
    };

    console.log('Request body:', requestBody);

    if (userDetails.forceCc) {
      console.log('This is a user that signed up with forceCc.. Use API v2');

      try {
        await handleCopyGenerationV2({
          requestBody,
          firebaseJwtToken,
          requestPrompts,
        });
      } catch (e) {
        console.log('e', e);
        toast.error(`Error: ${e.message}`);
        setloading(false);
      }

      // send slack notifications
      try {
        let promptString = JSON.stringify(requestBody.prompts);
        var newchar = "'";
        promptString = promptString.split('"').join(newchar);

        let linkToFirebase =
          'https://console.firebase.google.com/project/copywriting-db92b/firestore/data/~2Fusers~2F' +
          userId;
        let message =
          userData.e +
          ' just generated ' +
          requestBody.generator +
          ' content with prompt below (' +
          '<' +
          linkToFirebase +
          '|details>' +
          '): ```' +
          promptString +
          '```';

        // console.log('slackmessage sending:', message);
        await sendSlackNotificationForCopyGeneration(message);
      } catch (e) {
        console.log(
          'Could not send a slack notification about this generation because ',
          e
        );
      }
    } else {
      console.log('This is an old user. generate copy with old API');
      try {
        generateCopy();
      } catch (e) {
        console.log('caught error', e);
        toast.error('Error' + e.message);
        setloading(false);
      }
    }
  };

  // old / deprecate this eventually
  const generateCopy = () => {
    // get all the variable names by id in array and match to variable name in object
    let currentGenerator = generators[generator];
    let prompts = currentGenerator.prompts;
    let promptsLength = prompts.length;
    // let valuesArray = [];
    let requestPrompts = {};
    for (let i = 0; i < promptsLength; i++) {
      let inputValue = document.getElementById('inputParameter' + i).value; //NOTE: Hope this works for both text area and text inputs
      if (inputValue === '') {
        // window.alert(`Input "${prompts.title}" Must Not Be Empty.`); TODO: prompt.title comes up as undefined
        window.alert(`Form Fields Must Not Be Empty.`);
        MixpanelSuper.noInputsError(copyTypeUnderscore);
        return;
      }
      // valuesArray.push(inputValue);
      requestPrompts[prompts[i].name] = inputValue;
    }
    setLastGeneratedPrompts(requestPrompts);
    let requestBody = {
      generator: generator,
      prompts: requestPrompts,
    };

    console.log('request body:', requestBody);
    MixpanelSuper.generateSnippet(copyTypeUnderscore); //NOTE: Should still be the same if on the same generator page for generate more like this.

    console.log('make new request to', apiAddress);
    setShowHelper(false);
    setShowVideo(false);
    setloading(true);
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function(idToken) {
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${_.get(config, 'backend.bearerToken')}`,
            firebaseidtoken: idToken,
          },
          body: JSON.stringify(requestBody),
        };

        fetch(
          `${apiAddress}/v1/garys-innermost-thoughts/generate`,
          requestOptions
        )
          .then((response) => {
            // console.log("response", response);
            try {
              // double strings in json stringify is causing issues
              let promptString = JSON.stringify(requestBody.prompts);
              var newchar = "'";
              promptString = promptString.split('"').join(newchar);
              const slackMessage = `[${remainingCredits},${remainingDays}] ${_.get(
                firebaseUserData,
                'fn',
                'undefined'
              )} ${_.get(firebaseUserData, 'ln', 'undefined')} ( ${_.get(
                firebaseUserData,
                'e',
                'undefined'
              )} ) just generated copy for ${
                requestBody.generator
              } with prompt: ${promptString}`; // 'useremail just generated copyType. prompt ='
              console.log('slackmessage sending:', slackMessage);
              sendSlackNotificationForCopyGeneration(slackMessage);
            } catch (e) {
              console.log(
                'Could not send a slack notification about this generation because ',
                e
              );
            }
            if (response.status === 406) {
              window.alert(
                "Your input text seems to contain sensitive, politically charged, or vulgar content.  Please rephrase your inputs.  If you believe you've received this message in error please screen shot your prompts and contact our team via the feedback link."
              );
              window.location.reload();
            }

            if (response.status === 500) {
              window.alert("Sorry we can't process your request right now.");
              window.location.reload();
            }
            if (response.status === 402) {
              setshowUpgradeModal(true);
              setloading(false);
              MixpanelSuper.viewPaymentPrompt(copyTypeCamelCase);
              // Break next promise
              return Promise.reject('Payment required to continue');
            }
            return response.json();
          })
          // expecting array in next function, so parsing object and turning into array
          .then((data) => {
            console.log('data object', data);
            if (data.code && data.code === 400) {
              toast.error(
                'Sorry, request failed because you are not a customer. Please pick a plan'
              );
              setloading(false);
              return null;
            }
            console.log('data here', data.data);
            /** TODO: Use data responses to update global variables like remaining credits.  Can I pass that to a wrapping class? (up to main nav) */
            saveResultsAndHandleResponse(data.data, requestPrompts);
          })
          .catch((error) => {
            console.log('Error: ', error);
            setloading(false);
            // Dont show error modal if its a server error saying payment required - seperate modal for that
            if (
              !error
                .toString()
                .toLowerCase()
                .includes('payment')
            ) {
              setShowErrorModal(true);
              setShowErrorModalText(error.toString());
            }
          });
      })
      .catch((e) => {
        console.log('Error occured while accessing current firebase user', e);
      });
  };

  const generateCopyWithDefaults = (defaultPrompts) => {
    defaultPrompts = JSON.parse(defaultPrompts);

    let currentGenerator = generators[generator];
    let prompts = currentGenerator.prompts;
    let promptsLength = prompts.length;

    for (let i = 0; i < promptsLength; i++) {
      document.getElementById('inputParameter' + i).value =
        defaultPrompts[prompts[i].name]; //Assuming using same generator
    }
    // generateCopy();
    generateCopyV2();
  };

  /** For saving results to saved table.  Eventually we will have 1 table "results" and it will be updates with tags like "saved" "rating", and it already has a field for "original" response */
  const saveResultToFirebase = (
    id,
    index,
    copyTypeUnderscoreVar,
    copyTypeCamelCaseVar,
    lastGeneratedPromptsVar
  ) => {
    //TODO: RETURN SAVED ID AND SAVE SOMEWHERE IN THE DOM SO YOU CAN SAVE NEW VALUES TO IT/ UPDATE
    let output = document.getElementById(id).value;
    MixpanelSuper.saveSnippet(copyTypeUnderscoreVar);
    console.log('saveResultToFirebase');
    // setsaveCopyResultInProgress(true)

    if (Array.isArray(output)) {
      console.log('This is an array');
      const stringifyOutput = output[0];
      output = stringifyOutput;
    }
    const resultToSave = {
      type: copyTypeCamelCaseVar,
      value: output,
      prompt: JSON.stringify(lastGeneratedPromptsVar),
    };

    return new Promise((resolve, reject) => {
      Fire.shared.addSavedCopyResult(resultToSave).then((res) => {
        console.log('res >> ', res);
        // setsaveCopyResultInProgress(false)
        setdisplayMessage('Added to your saved items');
        // Push this index item to an array, so we can visually display liked items already
        if (likedItems.length === 0) {
          setlikedItems([index]);
        } else {
          setlikedItems((likedItems) => [...likedItems, index]);
        }

        setTimeout(() => {
          setdisplayMessage(null);
        }, 3000);
      });
    });
  };

  /** STAR RATINGS */
  const saveStars = (output, index, starCount) => {
    console.log('applied ' + starCount + ' to id ' + index);
    let changeStars = [...localStars];
    changeStars[index] = starCount;
    setLocalStars(changeStars);

    return new Promise((resolve, reject) => {
      Fire.shared
        .saveStarRating(output.id, starCount, 'results')
        .finally(() => {
          resolve();
        });
    });
  };

  const StarRating = ({ rating, item, idx }) => {
    // console.log(rating)
    // console.log(rating === 5)

    if (rating === 5) {
      return (
        <div className="rating">
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 5)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 4)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 3)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 2)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 1)}
          >
            ⭐
          </span>
        </div>
      );
    } else if (rating === 4) {
      return (
        <div className="rating">
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 5)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 4)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 3)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 2)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 1)}
          >
            ⭐
          </span>
        </div>
      );
    } else if (rating === 3) {
      return (
        <div className="rating">
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 5)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 4)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 3)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 2)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 1)}
          >
            ⭐
          </span>
        </div>
      );
    } else if (rating === 2) {
      return (
        <div className="rating">
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 5)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 4)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 3)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 2)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 1)}
          >
            ⭐
          </span>
        </div>
      );
    } else if (rating === 1) {
      return (
        <div className="rating">
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 5)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 4)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 3)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 2)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 1)}
          >
            ⭐
          </span>
        </div>
      );
    } else {
      return (
        <div className="rating">
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 5)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 4)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 3)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 2)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 1)}
          >
            ☆
          </span>
        </div>
      );
    }
  };

  const SetVideoTrue = () => {
    setShowVideo(true);
    setShowHelper(false);
  };

  const SetVideoFalse = () => {
    setShowVideo(false);
    setShowHelper(true);
  };

  return (
    <Container fluid className="main-content-container px-4">
      <div>
        <Toaster />
      </div>

      {displayMessage && (
        <Container
          fluid
          className="px-0"
          style={{
            zIndex: 99,
            position: 'fixed',
            right: '0px',
            width: '300px',
          }}
        >
          <Alert className="mb-0">
            <i className="fa fa-info mx-2"></i> {displayMessage}
          </Alert>
        </Container>
      )}

      <Modal open={showUpgradeModal}>
        <ModalHeader>Please Upgrade Your Account</ModalHeader>
        <ModalBody>
          <span>
            <span role="img" aria-label="hand waving">
              👋
            </span>{' '}
            &nbsp; Hey there! We really appreciate you using our product, but in
            order to continue usage you need to select a plan. Thanks in advance
            for the support!
          </span>
          <br></br>
          <br></br>
          {remainingCredits > 40 && (
            <span>
              <span role="img" aria-label="clock emoji">
                ⏰
              </span>{' '}
              &nbsp; Didn't have time to use your credits? Contact us about via
              intercom in the bottom right about adding 2 days to your free
              trial.<br></br>
              <br></br>
            </span>
          )}
          <Button
            onClick={() => {
              window.location.href = '/pricing';
            }}
          >
            Select a plan
          </Button>
        </ModalBody>
      </Modal>

      <Modal open={showErrorModal}>
        <ModalHeader>Oops. Something went wrong.</ModalHeader>
        <ModalBody>
          <span>
            Error Occured. Please try again later or contact support if this
            issue persists. Message: {showErrorModalText}
          </span>
          <br></br>
          <Button
            style={{ marginTop: 20 }}
            onClick={() => {
              setShowErrorModal(false);
            }}
          >
            Ok
          </Button>
        </ModalBody>
      </Modal>

      <Row noGutters className="page-header py-4">
        <Col
          sm="1"
          className="d-flex align-items-center"
          style={{
            zIndex: 99,
            maxWidth: '60px',
          }}
        >
          {pageTitle && (
            <i
              className="material-icons"
              style={{
                height: 50,
                width: 50,
                padding: 10,
                fontSize: 28,
                fontWeight: 'bold',
                borderRadius: '50%',
                textAlign: 'center',
                backgroundColor: 'rgb(233, 240, 253)',
                color: 'rgb(0, 123, 255)',
                // gradient colors:
                // color: 'white',
                // background:
                //   'linear-gradient(120deg,#d1aad7,#86bff2 84.87%,#bbdef2 99.88%)',
              }}
            >
              brush
            </i>
          )}
        </Col>
        <Col sm="11" style={{ paddingLeft: 10 }}>
          <h3
            className="page-title"
            style={{ fontSize: '1.3rem', paddingTop: 5 }}
          >
            {pageTitle}
          </h3>

          <p style={{ paddingTop: 5, color: '#667085', marginBottom: 0 }}>
            {pageDescription}
          </p>
          <span style={{ display: 'none' }}>
            {copyTypeUnderscore}
            {copyTypeCamelCase}
            {/* doesnt update unless in the dom */}
          </span>
        </Col>
      </Row>

      <Row>
        <Col lg="5" className="pb-5">
          {generatorExists && (
            <Card
              small
              style={{
                padding: 24,
                boxShadow: '0px 0px 1px #667085',
                // PROPER DROPDSHADOW
              }}
            >
              {/* <PromptInputForm/> */}

              <Form style={{ padding: 1 }}>
                {generators[generator].prompts.map((generatorPrompt, index) => (
                  <FormGroup
                    style={{ padding: 1 }}
                    key={'inputParameterFormGroup' + index}
                  >
                    <label htmlFor="type">{generatorPrompt.title}</label>

                    {generatorPrompt.textArea && (
                      <FormTextarea
                        id={'inputParameter' + index}
                        // type="text"
                        rows="3"
                        defaultValue={generatorPrompt.defaultValue}
                        placeholder={generatorPrompt.placeHolder}
                      />
                    )}
                    {!generatorPrompt.textArea && (
                      <FormInput
                        id={'inputParameter' + index}
                        type="text"
                        defaultValue={generatorPrompt.defaultValue}
                        placeholder={generatorPrompt.placeHolder}
                      />
                    )}
                    {generatorPrompt.inputInstructions && (
                      <span
                        style={{
                          fontSize: '12px',
                          color: '#93A2BF',
                          paddingLeft: 2,
                        }}
                      >
                        {generatorPrompt.inputInstructions}
                      </span>
                    )}
                  </FormGroup>
                ))}
              </Form>
              {generators[generator].examples.length > 0 && (
                <em
                  style={{
                    marginBottom: 15,
                    color: '#93A2BF',
                    opacity: 0.75,
                    backgroundColor: '#F8FAFC',
                    padding: 6,
                    borderRadius: '4px',
                    border: '1px solid #ECF0F5',
                  }}
                >
                  View example:
                  {generators[generator].examples.map(
                    (generatorExamples, index) => (
                      <span>
                        <a
                          style={{
                            paddingLeft: '4px',
                            // fontStyle: 'italic',
                            whiteSpace: 'nowrap',
                          }}
                          href="#"
                          onClick={() =>
                            fillPromptWithValues(generatorExamples.prompts)
                          }
                        >
                          {generatorExamples.name}
                        </a>
                        {index + 1 < generators[generator].examples.length && (
                          <span>,</span>
                        ) /** If not end of list */}
                      </span>
                    )
                  )}
                </em>
              )}

              {!loading && remainingCredits > 0 && (
                <Button
                  theme="accent"
                  className="fade-in"
                  onClick={generateCopyV2}
                >
                  Generate
                </Button>
              )}
              {/* {!loading && remainingCredits > 0 && (
                <Button
                  theme="accent"
                  className="fade-in"
                  onClick={generateCopy}
                >
                  Generate v1
                </Button>
              )} */}
              {loading && remainingCredits > 0 && (
                <Button theme="secondary" className="disabled">
                  Generate
                </Button>
              )}
              {remainingCredits <= 0 && (
                <div style={{ width: '100%' }}>
                  <Button
                    theme="info"
                    style={{ width: '100%' }}
                    onClick={() => {
                      window.location.href = '/settings?upgradeInfo=true';
                    }}
                  >
                    <i style={{ color: '#FFFFFF' }} className="material-icons">
                      grade
                    </i>{' '}
                    Upgrade to Generate
                  </Button>
                  <center style={{ paddingTop: 12 }}>
                    <em>
                      Please upgrade. Youve used all of your credits allowed for
                      your plan
                    </em>
                  </center>
                </div>
              )}
            </Card>
          )}

          {showProUpsell && userDetails && userDetails.smu <= 776 && (
            <div
              style={{
                // textAlign: 'center',
                marginTop: 20,
                color: '#93A2BF',
                // maxWidth: 300,
                padding: 12,
                opacity: 0.8,
                backgroundColor: '#FAFBFD',
                borderRadius: '4px',
                border: '1px solid #ECF0F5',
              }}
            >
              <span
                style={{
                  backgroundColor: 'white',
                  cursor: 'pointer',
                  borderRadius: 20,
                  position: 'absolute',
                  // top: 10,
                  right: 30,
                }}
                onClick={() => {
                  console.log('clicked hide pro upsell!');
                  setShowProUpsell(false);
                }}
              >
                x
              </span>
              <h6>
                Write 5x faster with <span>Docs</span> (Pro)
              </h6>
              <p>
                - Google docs-like editor for long-form content (blog posts){' '}
                <br></br>- Super Fast Insert <br></br>- Unlimited credits
                <br></br>
                {/* - Free Gift: 7 Custom Templates */}
              </p>
              <Button
                theme={'white'}
                onClick={() => {
                  window.location.href = '/settings?upgradeInfo=true';
                }}
              >
                Learn more
              </Button>
            </div>
          )}
        </Col>

        {!showVideo && copyResults.length == 0 && showHelper && (
          <Col lg="7" className="pb-5" style={{ padding: 1 }}>
            {/* Inputs to generate copy */}

            <div
              style={{
                textAlign: 'center',
                margin: 'auto',
                color: '#93A2BF',
                maxWidth: 300,
                padding: 24,
                opacity: 0.8,
                backgroundColor: '#FAFBFD',
                borderRadius: '4px',
                border: '1px solid #ECF0F5',
                // border dashed?
                // opacity: 0.6,
                // border: '2px dashed rgb(147, 162, 191)',
                // borderRadius: '4px',
              }}
            >
              <h5 style={{ color: '#93A2BF' }}>Video tutorial</h5>
              <p>Format your inputs this way for the best results...</p>
              <Button
                outline
                className="readable"
                theme="dark"
                style={{ color: '#93A2BF', border: '1px solid #93A2BF' }}
                onClick={SetVideoTrue}
              >
                <i className="fa fa-play" aria-hidden="true"></i> Play Video
              </Button>
            </div>
          </Col>
        )}

        <Col lg="7">
          {showVideo &&
            copyResults.length == 0 &&
            (generators[generator].introWistia ||
              generators[generator].introYoutube) && (
              <Card small className="mb-4">
                {/*WISTIA JS*/}
                {/*<script src="//fast.wistia.com/embed/medias/j38ihh83m5.jsonp" async></script>*/}
                {/*<script src="//fast.wistia.com/assets/external/E-v1.js" async></script>*/}
                {/*<div className="wistia_embed wistia_async_j38ihh83m5" style={{width:"100%", aspectRatio: "620/349", backgroundColor:"pink", margin:"auto"}}>&nbsp;</div>*/}

                {/*YOUTUBE*/}
                {/*<iframe style={{width:"100%", aspectRatio: "620/349", backgroundColor:"#2b7bff", margin:"auto"}}*/}
                {/*src="https://www.youtube.com/embed/tgbNymZ7vqY">*/}
                {/*</iframe>*/}
                {generators[generator].introYoutube && (
                  <span className="">
                    <Button
                      outline
                      style={{ align: 'left' }}
                      className="readable pb-2"
                      theme="light"
                      onClick={SetVideoFalse}
                    >
                      <i className="fas fa-times"></i>
                    </Button>
                    <iframe
                      style={{
                        width: '100%',
                        aspectRatio: '620/349',
                        backgroundColor: '#f2f4f6',
                        margin: 'auto',
                      }}
                      src={`https://www.youtube.com/embed/${generators[generator].introYoutube}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </span>
                )}

                {/*<iframe width="560" height="315" src="https://www.youtube.com/embed/XV8uxCgKzb0" frameBorder="0"*/}
                {/*        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
                {/*        allowFullScreen></iframe>*/}

                {/*WISTIA FALL BACK*/}
                {generators[generator].introWistia && (
                  <span>
                    <Button
                      outline
                      style={{ align: 'left' }}
                      className="readable pb-2"
                      theme="light"
                      onClick={SetVideoFalse}
                    >
                      <i className="fas fa-times"></i>
                    </Button>
                    <iframe
                      src={`//fast.wistia.net/embed/iframe/${generators[generator].introWistia}`}
                      allowTransparency="true"
                      frameBorder="0"
                      scrolling="no"
                      className="wistia_embed"
                      name="wistia_embed"
                      allowFullScreen
                      mozallowfullscreen
                      webkitallowfullscreen
                      oallowfullscreen
                      msallowfullscreen
                      style={{
                        width: '100%',
                        height: '37em',
                        backgroundColor: '#f2f4f6',
                        margin: 'auto',
                      }}
                    ></iframe>{' '}
                    {/*aspectRatio: "620/349",*/}
                    <script
                      src="//fast.wistia.net/assets/external/E-v1.js"
                      async
                    ></script>
                  </span>
                )}
              </Card>
            )}

          {/* Description builder here */}
          <Modal
            open={showPromptBuilderModal}
            style={{ backgroundColor: '#F4F5F7' }}
          >
            <ModalHeader>Description Builder</ModalHeader>
            <ModalBody style={{ backgroundColor: '#F4F5F7' }}>
              <span style={{ marginBottom: 20 }}>
                Gary gives better results when you provide a good description.
                Let us help you build one by answer the following....
              </span>
              <br></br>
              <Form style={{ marginTop: 20 }}>
                {' '}
                <FormGroup>
                  <label for="prompt">What is your product?</label>
                  <FormInput
                    type="text"
                    name="what"
                    onChange={(e) => {
                      setPromptBuilderValues({
                        ...promptBuilderValues,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                  <p
                    style={{ color: '#667085', paddingTop: 5, paddingLeft: 3 }}
                  >
                    Ex: A website, a marketing agency, an ebook, etc.
                  </p>
                </FormGroup>
                <FormGroup>
                  <label for="prompt">Who is it for?</label>
                  <FormInput
                    type="text"
                    name="who"
                    autoComplete="off"
                    onChange={(e) => {
                      setPromptBuilderValues({
                        ...promptBuilderValues,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                  <p
                    style={{ color: '#667085', paddingTop: 5, paddingLeft: 3 }}
                  >
                    Ex: Women under 40, small business owners, parents, etc.
                  </p>
                </FormGroup>
                <FormGroup>
                  <label for="prompt">What is the desired outcome?</label>
                  <FormInput
                    type="text"
                    name="how"
                    onChange={(e) => {
                      setPromptBuilderValues({
                        ...promptBuilderValues,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                  <p
                    style={{ color: '#667085', paddingTop: 5, paddingLeft: 3 }}
                  >
                    Ex: Lose weight without exercising, increase sales revenue,
                    etc.
                  </p>
                </FormGroup>
              </Form>

              <Button
                theme="white"
                style={{ marginRight: 20 }}
                onClick={() => setShowPromptBuilderModal(false)}
              >
                Cancel
              </Button>

              <Button
                theme="primary"
                disabled={
                  !promptBuilderValues.what ||
                  !promptBuilderValues.who ||
                  !promptBuilderValues.how
                }
                onClick={() => {
                  console.log('promptBuilderValues', promptBuilderValues);
                  var stringPrompt =
                    promptBuilderValues.what +
                    ' for ' +
                    promptBuilderValues.who +
                    ' who want to ' +
                    promptBuilderValues.how;
                  console.log('stringPrompt', stringPrompt);
                  setShowPromptBuilderModal(false);

                  fillPromptWithValues([null, stringPrompt]);
                  setShowPromptImprovementSuggestion(false);
                  toast.success(
                    'New description set. Click generate to see it in action'
                  );
                  MixpanelSuper.trackButtonClick('descriptionPromptBuilder');
                }}
              >
                Continue
              </Button>
            </ModalBody>
          </Modal>

          {/* showGeneralInputTipModal */}
          <Modal open={showGeneralInputTipModal}>
            {/* <ModalHeader></ModalHeader> */}
            <ModalBody>
              <center>
                <i
                  className="material-icons mr-1"
                  style={{
                    paddingTop: 1,
                    fontSize: 30,
                    color: '#039855',
                    backgroundColor: '#D1FADF',
                    padding: '12px',
                    borderRadius: '50%',
                  }}
                >
                  info
                </i>

                <h6 style={{ marginTop: 10, marginBottom: 20 }}>
                  Follow the same format as the examples!
                </h6>
                <p>
                  Please read the helper text by the Generate button. Gary will
                  give you better results if you write something similar to the
                  examples
                </p>

                <div
                  style={{
                    backgroundColor: '#039855',
                    padding: 2,
                    borderRadius: 10,
                  }}
                >
                  <img
                    src={require('../images/follow-tips.gif')}
                    style={{
                      width: '100%',
                      borderRadius: 6,
                    }}
                  />
                </div>
                <Button
                  theme="white"
                  style={{ marginRight: 20, marginTop: 20 }}
                  onClick={() => setShowGeneralInputTipModal(false)}
                >
                  Got it
                </Button>
                <br></br>
              </center>
            </ModalBody>
          </Modal>

          {/* prompt improvement suggestion */}
          {showPromptImprovementSuggestion && (
            <Card
              small
              className="mb-4"
              style={{
                boxShadow: '0px 0px 10px #e6e6e6',
                // boxShadow: '0px 0px 0px #e6e6e6',
                backgroundColor: '#FFFAEB',
              }}
            >
              <Button
                outline
                style={{
                  align: 'right',
                  color: '#BE5E25',
                  position: 'absolute',
                  right: '0',
                  top: '0',
                  zIndex: 100,
                }}
                className="readable pb-2"
                theme="light"
                onClick={() => {
                  setShowPromptImprovementSuggestion(false);
                }}
              >
                X
              </Button>
              <ListGroup flush>
                <ListGroupItem
                  className="p-3"
                  style={{ backgroundColor: '#FFFAEB' }}
                >
                  <Row>
                    <Col md="12">
                      <Badge
                        pill
                        theme="primary"
                        style={{
                          backgroundColor: '#DC6803',
                          marginRight: 10,
                        }}
                      >
                        Suggestion
                      </Badge>
                      <strong style={{ color: '#BE5E25' }}>
                        Your results could be better. Try improving what you
                        entered in your {fieldToImprove}.
                      </strong>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="8"></Col>
                    <Col md="4" style={{ textAlign: 'right' }}>
                      <ButtonGroup size="sm">
                        <Button
                          theme="white"
                          onClick={() => {
                            // generator aida and pas have descriptions, so use description builder for those. otherwise show general tutorial gif.
                            if (generator === 'aida' || generator === 'pas') {
                              setShowPromptBuilderModal(true);
                            } else {
                              setShowGeneralInputTipModal(true);
                            }
                          }}
                        >
                          Show me how
                        </Button>
                        {/* <Button
                          theme="white"
                          onClick={() => setShowGeneralInputTipModal(true)}
                        >
                          Show me how 2
                        </Button> */}
                      </ButtonGroup>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          )}

          {/* List of results */}
          {copyResults.map((copyResult, index) => (
            <Card
              small
              className="mb-4"
              key={index}
              style={{
                boxShadow: '0px 0px 1px #667085',
                // PROPER
                padding: '12px 16px 12px 16px',
              }}
            >
              <Form>
                <Row form>
                  {/* Description */}
                  <Col md="12" className="form-group">
                    {index === 0 && (
                      <FormTextarea id={'resultTextArea' + index} rows={5} />
                    )}
                    {index !== 0 && (
                      <FormTextarea
                        id={'resultTextArea' + index}
                        rows={3}
                        defaultValue={copyResult.value[0]}
                      />
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col md="8">
                    {!(remainingCredits <= 0) && (
                      <Button
                        outline
                        theme="primary"
                        className="mb-2 mr-1"
                        onClick={() => {
                          try {
                            generateCopyWithDefaults(copyResult.prompt);
                          } catch (e) {
                            console.log('error', e);
                            toast.error('Error' + e);
                          }
                        }}
                      >
                        Generate More Like This
                      </Button>
                    )}
                  </Col>
                  <Col md="4" style={{ textAlign: 'right' }}>
                    {/* Tool tips for buttons that appear on hover */}
                    <span
                      className={'favorite-tool-tip-' + index}
                      style={{
                        visibility:
                          showFavoriteToolTip === index ? 'visible' : 'hidden',
                        backgroundColor: '#101828',
                        color: 'white',
                        height: 'auto',
                        width: 'auto',
                        padding: '5px 8px 5px 8px',
                        borderRadius: '5px',
                        position: 'absolute',
                        top: '-35px',
                        left: '50px',
                      }}
                    >
                      {' '}
                      Save to favorites
                    </span>
                    <span
                      className={'copy-tool-tip-' + index}
                      style={{
                        visibility:
                          showCopyToolTip === index ? 'visible' : 'hidden',
                        backgroundColor: '#101828',
                        color: 'white',
                        height: 'auto',
                        width: 'auto',
                        padding: '5px 8px 5px 8px',
                        borderRadius: '5px',
                        position: 'absolute',
                        top: '-35px',
                        left: '4px',
                      }}
                    >
                      {' '}
                      Copy to clipboard
                    </span>

                    <Button
                      theme="white"
                      onMouseEnter={() => {
                        setShowCopyToolTip(index);
                      }}
                      onMouseLeave={() => {
                        setShowCopyToolTip(false);
                      }}
                      onClick={() =>
                        copyToClipboard(
                          'resultTextArea' + index,
                          copyTypeUnderscore,
                          setdisplayMessage
                        )
                      }
                    >
                      <i className="material-icons">content_copy</i>
                    </Button>
                    {likedItems.includes(index) && (
                      <Button theme="white" style={{ marginLeft: 8 }}>
                        <i
                          className="material-icons"
                          style={{
                            color: 'red',
                          }}
                        >
                          favorite
                        </i>
                      </Button>
                    )}
                    {!likedItems.includes(index) && (
                      <Button
                        theme="white"
                        style={{ marginLeft: 8 }}
                        onMouseEnter={() => {
                          setShowFavoriteToolTip(index);
                        }}
                        onMouseLeave={() => {
                          setShowFavoriteToolTip(false);
                        }}
                        onClick={() =>
                          saveResultToFirebase(
                            'resultTextArea' + index,
                            index,
                            copyTypeUnderscore,
                            copyTypeCamelCase,
                            lastGeneratedPrompts
                          )
                        }
                      >
                        <i className="material-icons">favorite_border</i>
                      </Button>
                    )}

                    <Button
                      theme="white"
                      style={{ marginLeft: 8 }}
                      onClick={() =>
                        copyLinkToClipboard(
                          `https://app.garyai.com/snippet/share/${
                            userDetails.uid
                          }/0/${copyResult.id || copyResult.o_id}/`
                        )
                      }
                    >
                      Share
                    </Button>
                    <br></br>
                    <div
                      style={{
                        paddingTop: '5px',
                        textAlign: 'right !important',
                      }}
                    >
                      <StarRating
                        rating={localStars[index]}
                        item={copyResult}
                        idx={index}
                      />
                    </div>
                  </Col>
                </Row>
              </Form>
            </Card>
          ))}
          {copyResults.length > 0 && (
            <div>
              {!loading && remainingCredits > 0 && (
                <Button outline theme="accent" onClick={generateCopyV2}>
                  Generate More
                </Button>
              )}
              {loading && remainingCredits > 0 && (
                <Button theme="white" className="disabled">
                  Generate More
                </Button>
              )}
              {remainingCredits <= 0 && (
                <Button
                  theme="info"
                  onClick={() => {
                    window.location.href = '/settings?upgradeInfo=true';
                  }}
                >
                  <i style={{ color: '#FFFFFF' }} className="material-icons">
                    grade
                  </i>
                  Upgrade to Generate More
                </Button>
              )}
            </div>
          )}

          {loading && (
            <img
              alt=""
              src={require('../images/loading.gif')}
              height="100"
              style={{ marginLeft: '40%' }}
            ></img>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default GenerateV2;
