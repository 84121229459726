import React, { useEffect, useState } from 'react';
import { setUser } from '../actions';
import { MixpanelSuper } from '../mixpanelSuper';
import {
  Card,
  Container,
  Row,
  Col,
  ListGroupItem,
  Form,
  FormInput,
  Button,
  ListGroup,
  FormGroup,
  FormSelect,
  FormCheckbox,
} from 'shards-react';

import firebase from 'firebase';
import { config } from '../environments/config';
import PageTitle from '../components/common/PageTitle';
import { connect } from 'react-redux';
import { increment } from '../actions';

export const AuthActionRedirect = () => {
  // const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  let isThisMobileDevice = window.navigator.userAgent.indexOf('Mobile') > -1;
  console.log('isThisMobileDevice', isThisMobileDevice);

  useEffect(() => {
    console.log('AuthActionRedirect useEffect');
    // log current url
    console.log(window.location.href);
    var url = new URL(window.location.href);
    var continueUrl = url.searchParams.get('continueUrl');
    console.log('continueUrl', continueUrl);

    var mode = url.searchParams.get('mode');
    MixpanelSuper.trackScreenView('AuthAction');

    if (mode === 'verifyEmail') {
      console.log('mode is verifyEmail');
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          console.log('user here', user);
          firebase
            .firestore()
            .collection('users')
            .doc(user.uid)
            .get()
            .then(function(doc) {
              if (doc.exists) {
                let data = doc.data();
                console.log('authAction userData:', data);
                if (!data.businessName) {
                  console.log(
                    'they dont have business name, go to onboarinng step 1'
                  );
                  window.location.href = '/onboarding-1?verified=true';
                }
                if (!data.contentTypes) {
                  // they dont have content types, go to step 2.
                  console.log('they dont have content types, go to step 2');
                  window.location.href = '/onboarding-2';
                }

                // they have business name and content types, go to dashboard
                console.log(
                  'they have business name and content types, go to settings'
                );
                window.location.href = '/settings?verified=true';
              }
            });
        } else {
          console.log('No user');
        }
      });

      // TODO -- See if they onboarded already( and gave business site info... If they did, skip that page and go to main app.)

      window.location.href = '/onboarding-1?verified=true';
    }

    if (mode === 'resetPassword') {
      console.log('mode is resetPassword');
      let actionCode = url.searchParams.get('oobCode');
      window.location.href = '/reset-password?oobCode=' + actionCode;
    }
    if (continueUrl) {
      //redirect the user to continue url
      window.location.href = continueUrl;
    }
  }, []);

  return (
    <Container fluid>
      <div style={{ margin: 30 }}>
        <center>
          {/* Image */}
          <img
            style={{
              width: '70px',
              height: '70px',
              padding: 8,
              backgroundColor: 'white',
              borderRadius: 12,
              marginBottom: 10,
            }}
            src={require('../images/gradientIcon.png')}
          ></img>

          <h4 className="m-0">GaryAI</h4>
        </center>
      </div>

      <Container style={{ width: isThisMobileDevice ? '75%' : '40%' }}>
        <center>
          {isLoading && (
            <img
              src={require('../images/loading.gif')}
              style={{ height: 100, margin: 30 }}
            />
          )}

          {/* <p>
                    Dev: hard code{' '}
                    <a href="/onboarding-1?verified=true">next</a>
                  </p>  */}
        </center>
      </Container>
    </Container>
  );
};
