/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from 'react';
import { setUser } from '../actions';

import { Mixpanel } from '../mixpanel';
import {
  Card,
  Container,
  Row,
  Col,
  ListGroupItem,
  Form,
  FormInput,
  Button,
} from 'shards-react';

// import fire from '../config/fire'
import firebase from 'firebase';
import { config } from '../environments/config';
import PageTitle from '../components/common/PageTitle';
import { connect } from 'react-redux';
import { increment } from '../actions';
const qs = require('qs');
const _ = require('lodash');

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      email: '',
      password: '',
    };
  }

  handleLoad() {
    console.log('this.props:');
    console.log(_.get(this, 'window.location.search'));
    let mode = _.get(
      qs.parse(_.get(this, 'window.location.search'), {
        ignoreQueryPrefix: true,
      }),
      'mode',
      null
    );
    let actionCode = _.get(
      qs.parse(_.get(this, 'window.location.search'), {
        ignoreQueryPrefix: true,
      }),
      'oobCode',
      null
    );
    // let continueUrl = _.get(qs.parse(_.get(this,"props.location.search"),{ ignoreQueryPrefix: true }),"continueUrl", null);
    // let apiKey = _.get(qs.parse(_.get(this,"props.location.search"),{ ignoreQueryPrefix: true }),"apiKey", null);
    // let lang = _.get(qs.parse(_.get(this,"props.location.search"),{ ignoreQueryPrefix: true }),"lang", "en");

    switch (mode) {
      /** TODO: Add these 2 cases for reset and recover email */
      // case 'resetPassword':
      //   // Display reset password handler and UI.
      //   this.handleResetPassword(actionCode, continueUrl, lang).then((resp) => {
      //     resolve(resp);
      //   }).catch((err) => {
      //     reject(err);
      //   });
      //   break;
      // case 'recoverEmail':
      //   // Display email recovery handler and UI.
      //   this.handleRecoverEmail(actionCode, lang).then((resp) => {
      //     resolve(resp);
      //   }).catch((err) => {
      //     reject(err);
      //   });
      //   break;
      case 'verifyEmail':
        // Display email verification handler and UI.
        firebase
          .auth()
          .applyActionCode(actionCode)
          .then((resp) => {
            window.confirm('Your email has been successfully authenticated.');
          })
          .catch((error) => {
            if (
              window.confirm(
                'Your verification is no longer valid. Login again to be sent a new verification email.'
              )
            ) {
              window.location.pathname = '/login';
            }
            console.error(error);
          });
        break;
      default:
        console.log('No Page Load User Management');
    }
  }

  componentDidMount() {
    console.log('Sign In Page Loaded');
    window.addEventListener('load', this.handleLoad);
  }

  /** TODO: This requires multiple pages and asking for more information.  The example is for some sort of Single page app but I'm not sure how I'm supposed to pause and collect more info.*/
  // handleResetPassword(actionCode, continueUrl, lang){
  //   return new Promise((resolve,reject)=>{
  //     let accountEmail;
  //     // Verify the password reset code is valid.
  //     firebase.auth().verifyPasswordResetCode(actionCode).then(function(email) {
  //       let accountEmail = email;
  //
  //       // TODO: Show the reset screen with the user's email and ask the user for
  //       // the new password.
  //
  //       // Save the new password.
  //       firebase.auth().confirmPasswordReset(actionCode, newPassword).then(function(resp) {
  //         // Password reset has been confirmed and new password updated.
  //
  //         // TODO: Display a link back to the app, or sign-in the user directly
  //         // if the page belongs to the same domain as the app:
  //         // auth.signInWithEmailAndPassword(accountEmail, newPassword);
  //
  //         // TODO: If a continue URL is available, display a button which on
  //         // click redirects the user back to the app via continueUrl with
  //         // additional state determined from that URL's parameters.
  //       }).catch(function(error) {
  //         // Error occurred during confirmation. The code might have expired or the
  //         // password is too weak.
  //       });
  //     }).catch(function(error) {
  //       // Invalid or expired action code. Ask user to try to reset the password
  //       // again.
  //     });
  //     });
  // }

  /** TODO: This requires multiple pages and asking for more information.  The example is for some sort of Single page app but I'm not sure how I'm supposed to pause and collect more info.*/
  // handleRecoverEmail(actionCode, lang){
  //   return new Promise((resolve,reject)=>{
  //     // Localize the UI to the selected language as determined by the lang
  //     // parameter.
  //     let restoredEmail = null;
  //     // Confirm the action code is valid.
  //     auth.checkActionCode(actionCode).then(function(info) {
  //       // Get the restored email address.
  //       restoredEmail = info['data']['email'];
  //
  //       // Revert to the old email.
  //       return auth.applyActionCode(actionCode);
  //     }).then(function() {
  //       // Account email reverted to restoredEmail
  //
  //       // TODO: Display a confirmation message to the user.
  //
  //       // You might also want to give the user the option to reset their password
  //       // in case the account was compromised:
  //       auth.sendPasswordResetEmail(restoredEmail).then(function() {
  //         // Password reset confirmation sent. Ask user to check their email.
  //       }).catch(function(error) {
  //         // Error encountered while sending password reset code.
  //       });
  //     }).catch(function(error) {
  //       // Invalid code.
  //     });
  //   });
  // }

  handleVerifyEmail(u, actionCode, continueUrl = '', lang = 'en') {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .applyActionCode(actionCode)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          u.user
            .sendEmailVerification()
            .then(() => {
              if (
                window.confirm(
                  'Your verification is no longer valid. A new verification email has been sent. Please click that link and try again.'
                )
              ) {
                window.location.pathname = '/login';
              }
            })
            .catch((error) => {
              console.log(error);
              window.confirm(
                'An Error Occurred: Your verification is no longer valid and we failed to send a new verification email. Please contact us.'
              );
            });
          reject(error);
        });
    });
  }

  handleUserManagement(u) {
    return new Promise((resolve, reject) => {
      console.log('this.props:');
      console.log(this.props); /** Sometimes works, sometimes undefined... */
      let mode = _.get(
        qs.parse(_.get(this, 'props.location.search'), {
          ignoreQueryPrefix: true,
        }),
        'mode',
        null
      );
      let actionCode = _.get(
        qs.parse(_.get(this, 'props.location.search'), {
          ignoreQueryPrefix: true,
        }),
        'oobCode',
        null
      );
      let continueUrl = _.get(
        qs.parse(_.get(this, 'props.location.search'), {
          ignoreQueryPrefix: true,
        }),
        'continueUrl',
        null
      );
      // let apiKey = _.get(qs.parse(_.get(this,"props.location.search"),{ ignoreQueryPrefix: true }),"apiKey", null);
      let lang = _.get(
        qs.parse(_.get(this, 'props.location.search'), {
          ignoreQueryPrefix: true,
        }),
        'lang',
        'en'
      );
      switch (mode) {
        /** TODO: Add these 2 cases for reset and recover email */
        // case 'resetPassword':
        //   // Display reset password handler and UI.
        //   this.handleResetPassword(actionCode, continueUrl, lang).then((resp) => {
        //     resolve(resp);
        //   }).catch((err) => {
        //     reject(err);
        //   });
        //   break;
        // case 'recoverEmail':
        //   // Display email recovery handler and UI.
        //   this.handleRecoverEmail(actionCode, lang).then((resp) => {
        //     resolve(resp);
        //   }).catch((err) => {
        //     reject(err);
        //   });
        //   break;
        case 'verifyEmail_DISABLED_FOR_NOW':
          // Display email verification handler and UI.
          if (!u.user.emailVerified) {
            this.handleVerifyEmail(u, actionCode, continueUrl, lang)
              .then((resp) => {
                resolve(resp);
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            resolve(true);
          }
          break;
        default:
          console.log('No User Management');
          resolve(true);
      }
    });
  }

  setUserToState = (u) => {
    this.props.setUser(u); /** Redux */
  };

  login(e) {
    e.preventDefault();
    firebase
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then((u) => {
        //Save to state
        this.setUserToState(u);

        const userEmail = this.state.email;
        console.log('User Just Signed In:');
        console.log(u);
        this.handleUserManagement(u)
          .then((resp) => {
            let updatedUser = firebase.auth().currentUser; //.then(()=>{}).catch((error)=>{});
            console.log('updated user');
            console.log(updatedUser);

            console.log('handle user management response');
            console.log(resp);

            // Does the user obj get updated after verifying?

            if (
              !u.user.emailVerified &&
              'EMAIL VERIFICATION' == 'IS DISABLED FOR NOW'
            ) {
              firebase
                .auth()
                .signOut()
                .then(() => {
                  window.alert(
                    `Please check your email and click the verification link sent to ${userEmail} in order to login.`
                  );
                })
                .catch((error) => {
                  console.log(error);
                  window.alert(
                    `Please check your email and click the verification link sent to ${userEmail} in order to login.`
                  );
                });
            } else {
              console.log('Successful Login');
              console.log(`Email Verified: ${u.user.emailVerified}`);
              console.log(u);
              Mixpanel.identify(this.state.email);
              Mixpanel.track('successful_login', {
                user_unique_id: this.state.email,
                login_method: 'email',
                account_plan_type: 'free',
              });
              // set state and redirect to in-app
              window.Intercom('boot', {
                app_id: _.get(config, 'intercom.appId'),
                email: this.state.email,
              });

              try {
                //Set crisp (live chat user)
                console.log('Crisp set.');
                window.$crisp.push(['set', 'user:email', this.state.email]);
              } catch (e) {
                console.log('Unable to set crisp', e);
              }

              window.location.pathname = '/write';
            }
          })
          .catch((error) => {
            //REMOVE EMAIL VERIFICATION FOR NOW.
            console.log('handle user management error');
            console.error(error);
          });
      })
      .catch((err) => {
        Mixpanel.track('failed_login', {
          user_unique_id: this.state.email,
        });
        console.log(err);
        window.alert(err);
      });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <Container fluid className="">
        {/* Page Header */}

        <Row noGutters className="page-header py-4">
          <Col lg="4"></Col>
          <PageTitle
            sm="4"
            title="Sign In"
            subtitle="Welcome"
            className="text-sm-center text-lg-center"
          />
        </Row>

        <Row noGutters>
          <Col lg="4"></Col>
          <Col lg="4" md="6">
            <Card small>
              <ListGroupItem className="" style={{ border: 'none' }}>
                <Row>
                  <Col>
                    <Form>
                      <Row form>
                        <Col className="form-group">
                          <label htmlFor="feEmailAddress">Email</label>
                          <FormInput
                            id="feEmailAddress"
                            type="email"
                            placeholder="Email"
                            name="email"
                            onChange={this.handleChange}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <label htmlFor="fePassword">Password</label>
                          <FormInput
                            id="fePassword"
                            type="password"
                            name="password"
                            placeholder="Password"
                            onChange={this.handleChange}
                          />
                        </Col>
                      </Row>
                      <br></br>
                      <Button onClick={this.login}>Login</Button>
                      <br></br>
                      <br></br>

                      <>
                        <hr
                          style={{
                            borderTop: '1px solid grey(0,0%,80%)',
                            height: 1,
                          }}
                        />
                      </>
                      <Row
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <a href="/forgot-password">Forgot Password?</a>
                        <span style={{ margin: '0 10px', opacity: 0.5 }}>
                          |
                        </span>
                        <a href="/try-it-free">Create an Account</a>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    credits: state.credits,
    isSubscribed: state.isSubscribed,
    daysRemaining: state.daysRemaining,
    user: state.user,
  };
};

const mapDispatchToProps = {
  setUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
