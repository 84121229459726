import React, { useEffect, useState } from 'react';
import { setUser } from '../actions';
import { MixpanelSuper } from '../mixpanelSuper';
import {
  Card,
  Container,
  Row,
  Col,
  ListGroupItem,
  Form,
  FormInput,
  Button,
  ListGroup,
  Modal,
  ModalBody,
  ModalHeader,
  CardBody,
  CardFooter,
  Badge,
} from 'shards-react';

import firebase from 'firebase';
import { config } from '../environments/config';
import PageTitle from '../components/common/PageTitle';
import { connect } from 'react-redux';
import { increment } from '../actions';
import toast, { Toaster } from 'react-hot-toast';

export const CustomDashboard = () => {
  const [email, setEmail] = useState('');
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showVideo, setShowVideo] = useState(false);
  const [customCopyOptions, setCustomCopyOptions] = useState(false);
  const [hasNoCustomCopyOptions, setHasNoCustomCopyOptions] = useState(false);

  let isThisMobileDevice = window.navigator.userAgent.indexOf('Mobile') > -1;
  console.log('isThisMobileDevice', isThisMobileDevice);

  const getUsersCustomCopyGenerators = async (userId) => {
    console.log('getUsersCustomCopyGenerators for', userId);
    setIsLoading(true);

    if (!userId) {
      console.log('no userId');
      return;
    }
    // get all documents from firebase collection and set them to customCopyOptions
    const customCopyOptions = [];
    const customCopyOptionsRef = firebase
      .firestore()
      .collection('customPrompts')
      .doc(userId)
      .collection('saved');

    const snapshot = await customCopyOptionsRef.get();
    snapshot.forEach((doc) => {
      let data = doc.data();
      data.id = doc.id;
      customCopyOptions.push(data);
    });
    // console.log('customCopyOptions >> ', customCopyOptions);

    if (customCopyOptions.length === 0) {
      setHasNoCustomCopyOptions(true);
    } else {
      // sort by the ts timestamp to have most recent first
      customCopyOptions.sort((a, b) => {
        return b.ts - a.ts;
      });
    }
    setCustomCopyOptions(customCopyOptions);
    setIsLoading(false);
  };

  useEffect(() => {
    console.log('DashbCustomDashboardoard useEffect');

    MixpanelSuper.trackScreenView('custom_dashboard');

    // get current firebase user
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        console.log('user is signed in');
        console.log(user);
        setEmail(user.email);

        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then(function(doc) {
            if (doc.exists) {
              let data = doc.data();
              console.log('Document data:', data);
              setUserData(data);
              getUsersCustomCopyGenerators(data.uid);
            }
          });
      }
    });
  }, []);

  return (
    <Container fluid>
      {/* Page Header */}
      <div>
        <Toaster />
      </div>
      <Row
        noGutters
        className="page-header py-6"
        style={{ marginTop: 30, paddingLeft: 15 }}
      >
        <PageTitle
          sm="4"
          title="Custom "
          className="text-sm-left"
          subtitle="Teach Gary to write anything, in your custom format"
        />
      </Row>

      {/* Row with a create new button on the right side */}
      <Row>
        <Col lg="10"></Col>
        <Col lg="2">
          <Button
            onClick={() => {
              window.location.href = '/custom-new';
            }}
            size="md"
            className="mr-2 mb-2"
            style={{
              marginRight: 10,
            }}
          >
            Create New
          </Button>
        </Col>
      </Row>

      {/* Welcome Video Modal */}
      <Modal open={showVideo} className={'modal-lg'}>
        <ModalHeader>Welcome Video</ModalHeader>
        <ModalBody>
          <iframe
            width="100%"
            height="500"
            src="https://www.youtube.com/embed/KyYq2ZjtwSk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>

          <p>
            Watch in seperate tab{' '}
            <a
              href="https://www.youtube.com/watch?v=KyYq2ZjtwSk"
              target="_blank"
            >
              here
            </a>
          </p>
          <center>
            <button
              className="mb-2 btn btn-primary mr-2"
              onClick={() => {
                setShowVideo(false);
              }}
            >
              Close video
            </button>
          </center>
        </ModalBody>
      </Modal>

      {/* welcome content */}
      <Container
        style={{
          marginTop: 40,
        }}
      >
        <ListGroup flush>
          <ListGroupItem
            className="p-3"
            style={{
              borderWidth: 1,
              borderColor: '#e6e6e6',
              borderStyle: 'solid',
              // dropshadow
              boxShadow: '0px 0px 10px #e6e6e6',
              backgroundColor: '#0E172F',
            }}
          >
            <Row>
              <Col>
                <h4 style={{ color: 'white' }}>
                  Hey {userData.fn && userData.fn} 👋
                </h4>
                <p style={{ color: 'white' }}>
                  Watch these videos below to learn how to use custom content
                  generators!
                </p>
              </Col>
            </Row>
            <Row style={{ paddingBottom: 25 }}>
              <Col
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  paddingTop: 10,
                  width: 200,
                  cursor: 'pointer',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#354364',
                    padding: 29,
                    borderRadius: 5,
                  }}
                >
                  <center
                    onClick={() => {
                      window.open('https://youtu.be/Yn6wM-BBdrU', '_blank');
                    }}
                  >
                    <img
                      src={require('../images/playIcon.png')}
                      style={{ height: 45, paddingBottom: 5, color: 'white' }}
                    />
                    <h6 style={{ color: '#E4E6E9' }}>Getting started video</h6>
                  </center>
                </div>
              </Col>

              <Col
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  paddingTop: 10,
                  width: 200,
                  cursor: 'pointer',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#354364',
                    padding: 29,
                    borderRadius: 5,
                  }}
                >
                  <center
                    onClick={() => {
                      setTimeout(function() {
                        window.open('https://youtu.be/v-J0Le2lI3Q', 'three');
                      }, 500);
                    }}
                  >
                    <img
                      src={require('../images/playIcon.png')}
                      style={{ height: 45, paddingBottom: 5 }}
                    />
                    <h6 style={{ color: '#E4E6E9' }}>How to create FB posts</h6>
                  </center>
                </div>
              </Col>

              <Col
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  width: 200,
                  cursor: 'pointer',
                  paddingTop: 10,
                }}
              >
                <div
                  style={{
                    backgroundColor: '#354364',
                    padding: 29,
                    borderRadius: 5,
                  }}
                >
                  <center
                    onClick={() => {
                      setTimeout(function() {
                        window.open('https://youtu.be/9SzG80tXLao', 'three');
                      }, 500);
                    }}
                  >
                    <img
                      src={require('../images/playIcon.png')}
                      style={{ height: 45, paddingBottom: 5 }}
                    />

                    <h6 style={{ color: '#E4E6E9' }}>
                      Generate viral video ideas
                    </h6>
                  </center>
                </div>
              </Col>
              <Col
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  width: 200,
                  cursor: 'pointer',
                  paddingTop: 10,
                }}
              >
                <div
                  style={{
                    backgroundColor: '#354364',
                    padding: 29,
                    borderRadius: 5,
                  }}
                >
                  <center
                    onClick={() => {
                      window.$crisp.push(['do', 'chat:open']);
                    }}
                  >
                    <img
                      src={require('../images/chatIcon2.png')}
                      style={{ height: 45, paddingBottom: 5 }}
                    />

                    <h6 style={{ color: '#E4E6E9' }}>Ask support a question</h6>
                  </center>
                </div>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Container>

      <Container style={{ marginTop: 40 }}>
        {isLoading ? (
          <div style={{ width: '100%' }}>
            <center>
              <img
                src={require('../images/loading.gif')}
                style={{ height: 70 }}
              />
            </center>
          </div>
        ) : (
          <Row>
            {/* Map throuigh customCopyOptions and dispaly a card for each */}
            {customCopyOptions.length > 0 &&
              customCopyOptions.map((customCopyOption, idx) => {
                return (
                  <Col lg="4" key={'idx'}>
                    <Card
                      small
                      className="card-post mb-4"
                      style={{ boxShadow: '0px 0px 10px #e6e6e6' }}
                    >
                      <CardBody>
                        {/* <Badge
                          pill
                          theme="primary"
                          style={{
                            position: 'absolute',
                            right: '10px',
                            top: '10px',
                          }}
                        >
                          New
                        </Badge> */}

                        {/* Friendly name is different from copy title (which is used in the beg of the prompt) */}
                        <h5 className="card-title">
                          {customCopyOption.name || customCopyOption.title}
                        </h5>
                        {/* if there is a description show it in p tag */}
                        {customCopyOption.description && (
                          <p className="card-text text-muted">
                            {customCopyOption.description}
                          </p>
                        )}

                        <p className="card-text text-muted">
                          Created{' '}
                          {
                            // convert to readable time and remove everything in string after ,
                            new Date(customCopyOption.ts)
                              .toLocaleString()
                              .split(',')[0]
                          }
                        </p>
                      </CardBody>
                      <CardFooter className="d-flex">
                        <div className="my-auto ml-auto">
                          <Button
                            size="sm"
                            outline
                            theme="dark"
                            href={'/generate-custom?id=' + customCopyOption.id}
                          >
                            Start
                          </Button>
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>
                );
              })}
          </Row>
        )}
      </Container>
    </Container>
  );
};
