import React, { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import {
  Card,
  Container,
  Row,
  Col,
  ListGroupItem,
  Form,
  FormInput,
  Button,
  ListGroup,
  Badge,
} from 'shards-react';
import { config } from '../environments/config';
import firebase from 'firebase';
import { sendSlackNotificationToSupport } from '../utils/slackNotifications';

export const AdminApp = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [customerEmail, setCustomerEmail] = React.useState('');
  const [customerResults, setCustomerResults] = React.useState(null);
  const [stripeCustomerResults, setStripeCustomerResults] = React.useState(
    null
  );
  const [creditChangeLoading, setSetCreditChangeLoading] = React.useState(
    false
  );
  const [allStripeData, setAllStripeData] = React.useState(false);
  const [updateSuccess, setUpdateSuccess] = React.useState(false);
  const _ = require('lodash');
  let apiAddress = _.get(config, 'backend.apiAddress');
  let currentPageUrl = window.location.href;
  if (currentPageUrl.includes('localhost')) {
    console.log('LOCALHOST');
    apiAddress = 'http://localhost:3001';
  }

  let isThisMobileDevice = window.navigator.userAgent.indexOf('Mobile') > -1;
  console.log('isThisMobileDevice', isThisMobileDevice);

  const lookUpStripeCustomer = async () => {
    setIsLoading(true);
    console.log('lookupCustomerFromServer');
    console.log(`customerEmail: ${customerEmail}`);

    // in case previous search results are still showing
    setCustomerResults(null);
    try {
      const result = await fetch(
        apiAddress +
          `/api/v2/stripe/get-customer?customerEmail=${customerEmail}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const data = await result.json();
      console.log('data', data);
      sendSlackNotificationToSupport(
        `${email} just looked up a user in admin tool. Stripe Query: ${customerEmail}. Result status ${data.status}`
      );
      console.log('API RESPONSE HERE', data);
      if (data.status === 'error') {
        toast.error(`Error in request:${data.message}`, {
          duration: 7000,
        });
        setIsLoading(false);
        return 'error';
      }
      if (data.data) {
        console.log('data.data.data', data.data.data);
        if (data.data.data && data.data.data.length === 0) {
          toast.error(
            `Query ran. No stripe results found for ${customerEmail}`,
            {
              duration: 7000,
            }
          );
        } else {
          toast.success(`Query Ran. Results found!`);
          setStripeCustomerResults(data.data.data);

          console.log('data hereee -->  ', data);
          setAllStripeData(data);
        }
        setIsLoading(false);
        return 'success';
      }
    } catch (e) {
      console.log(e);
      toast.error(`Error caught: ${e.message}`);
      setIsLoading(false);
    }
  };
  const giveLifetimeAccess = async (customersUserId, customer) => {
    if (
      !window.confirm(
        `Are you sure you want to give lifetime access to ${customer.e}?`
      )
    ) {
      return;
    }

    setSetCreditChangeLoading(true);
    console.log('changeCredits');
    console.log(`customerId: ${customersUserId}`);

    console.log('update for customersUserId', customersUserId);

    const result = await firebase
      .firestore()
      .collection('users')
      .doc(customersUserId)
      .update({
        lifetimeAccess: true,
      })
      .catch(function(error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log('err', errorCode);
        console.log('errMessage', errorMessage);
        toast.error(`Error: ${errorMessage}`);
      });
    console.log('result', result);
    toast.success(`User updated!`);
    setUpdateSuccess(true);
    // send slack notif
    sendSlackNotificationToSupport(
      `${email} just updated a user in admin tool. Customers: ${customer.e} now has lifetime access`
    );
    setSetCreditChangeLoading(false);
    return 'success';
  };

  const setPaidFlag = async (customersUserId, customer) => {
    if (
      !window.confirm(
        `Are you sure you want to give paid access to ${customer.e}?`
      )
    ) {
      return;
    }
    setSetCreditChangeLoading(true);
    console.log('changeCredits');
    console.log(`customerId: ${customersUserId}`);

    console.log('update for customersUserId', customersUserId);

    const result = await firebase
      .firestore()
      .collection('users')
      .doc(customersUserId)
      .update({
        paid: true,
      })
      .catch(function(error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log('err', errorCode);
        console.log('errMessage', errorMessage);
        toast.error(`Error: ${errorMessage}`);
      });
    console.log('result', result);
    toast.success(`User updated!`);
    setUpdateSuccess(true);
    // send slack notif
    sendSlackNotificationToSupport(
      `${email} just updated a user in admin tool. Customers: ${customer.e} now has paid feature access`
    );
    setSetCreditChangeLoading(false);
    return 'success';
  };

  const changeCredits = async (customersUserId, amount, customer) => {
    setSetCreditChangeLoading(true);
    console.log('changeCredits');
    console.log(`customerId: ${customersUserId}`);
    console.log(`amount: ${amount}`);
    let previousSmu;

    console.log('update for customersUserId', customersUserId);
    // update user firebase record for smu value to 777

    // firebase
    //   .firestore()
    //   .collection('users')
    //   .doc(user.uid)
    //   .get()
    //   .then(function(doc) {
    //     if (doc.exists) {
    //       let data = doc.data();
    //       console.log('current userData:', data);

    //       if (data.smu) {
    //         previousSmu = data.smu;
    //       }
    //     }
    //   });

    const result = await firebase
      .firestore()
      .collection('users')
      .doc(customersUserId)
      .update({
        smu: amount,
      })
      .catch(function(error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log('err', errorCode);
        console.log('errMessage', errorMessage);
        toast.error(`Error: ${errorMessage}`);
      });
    console.log('result', result);
    toast.success(`User updated!`);
    setUpdateSuccess(true);
    // send slack notif
    sendSlackNotificationToSupport(
      `${email} just updated a user in admin tool. Customers: ${customer.e}. Previous SMU: ${customer.smu}. New SMU: ${amount} (Pro Access)`
    );
    setSetCreditChangeLoading(false);
    return 'success';
  };

  const lookUpUser = async () => {
    try {
      console.log('lookUpUser for:', customerEmail);
      // in case previous search results are still showing
      setStripeCustomerResults(null);
      let results = [];
      // look up user doc in firestore and store results in results array
      const querySnapshot = await firebase
        .firestore()
        .collection('users')
        .where('e', '==', customerEmail)
        .get();
      querySnapshot.forEach((doc) => {
        results.push(doc.data());
      });

      console.log('results', results);
      sendSlackNotificationToSupport(
        `${email} just looked up a user in admin tool. DB Query: ${customerEmail}. Results.length: ${results.length}`
      );
      if (results.length > 0) {
        console.log('results', results);
        return results;
      }
      toast.error(`No db results found for customer ${customerEmail}`, {
        duration: 7000,
      });
      return 'error';
    } catch (e) {
      console.log('error', e);
      return 'error';
    }
  };

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'customerEmail') {
      setCustomerEmail(value);
    }
  };

  useEffect(() => {
    // wait for firebase
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        console.log('Firebase ready and identified user');
        console.log(user.email);
        setEmail(user.email);

        setIsLoading(false);
      } else {
        console.log('no user identified on this device.');
      }
    });
  }, []);

  return isLoading ? (
    <div>
      <div>
        <Toaster />
      </div>
      <center style={{ marginTop: 100 }}>
        {!errorMessage && (
          <img alt="" src={require('../images/loading.gif')} height="120"></img>
        )}
      </center>
    </div>
  ) : (
    <Container fluid>
      <div>
        <Toaster />
      </div>
      <div style={{ margin: 30 }}>
        <center>
          <Row
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '30px',
            }}
          >
            <span>Internal</span>
          </Row>
          <h4 className="m-0">Admin Tools</h4>
        </center>
      </div>

      <Container style={{ width: '100%' }}>
        <Col style={{ backgroundColor: 'white', padding: 30, borderRadius: 8 }}>
          <Form>
            <Row form>
              <Col md="12" className="form-group">
                <label htmlFor="customerEmail">Customer Email</label>
                <FormInput
                  id="customerEmail"
                  name="customerEmail"
                  type="text"
                  value={customerEmail}
                  placeholder="joe@gmail.com"
                  onChange={inputsHandler}
                />
              </Col>
            </Row>
            {isLoading ? (
              <button className={'mb-2 btn btn-sm btn-white mr-1'}>
                Loading... {'   '}
                <img
                  alt=""
                  src={require('../images/loading.gif')}
                  height="20"
                ></img>
              </button>
            ) : (
              <>
                <Button
                  onClick={async () => {
                    if (customerEmail === '') {
                      toast.error('Please enter a customer email');
                      return;
                    }
                    let response = await lookUpUser();
                    if (response !== 'error') {
                      console.log('response', response);
                      setCustomerResults(response);
                    }
                  }}
                >
                  Search in database
                </Button>
                {/* <br></br> */}
                <p style={{ paddingTop: 20 }}>or</p>
                {/* <br></br> */}
                <Button
                  theme="info"
                  onClick={async () => {
                    if (customerEmail === '') {
                      toast.error('Please enter a customer email');
                      return;
                    }

                    let response = await lookUpStripeCustomer();
                    // if (response !== 'error') {
                    //   console.log('response', response);
                    //   setCustomerResults(response);
                    // }
                  }}
                >
                  Search in Stripe
                </Button>
              </>
            )}
          </Form>
        </Col>
      </Container>

      {customerResults && (
        <Container style={{ width: '90%' }}>
          <Col style={{ backgroundColor: 'white', padding: 30, marginTop: 10 }}>
            <h4>Database Results</h4>
            <table
              style={{
                //black border
                border: '1px solid black',
                //border-collapse
                borderCollapse: 'collapse',
              }}
            >
              <thead
                style={{
                  //black border
                  border: '1px solid black',
                }}
              >
                <tr
                  style={{
                    //black border
                    border: '1px solid black',
                  }}
                >
                  <th>Customer Email</th>
                  <th>Customer ID</th>
                  <th>Customer Monthly Credits </th>
                  <th>Customer data </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody
                style={{
                  //black border
                  border: '1px solid black',
                }}
              >
                {customerResults.map((customer) => {
                  return (
                    <tr
                      style={{
                        border: '1px solid black',
                      }}
                    >
                      <td
                        style={{
                          border: '1px solid black',
                        }}
                      >
                        {customer.e}
                      </td>
                      <td
                        style={{
                          border: '1px solid black',
                        }}
                      >
                        {customer.uid}
                      </td>
                      <td
                        style={{
                          border: '1px solid black',
                        }}
                      >
                        {customer.smu}
                      </td>
                      <td
                        style={{
                          border: '1px solid black',
                          wordWrap: 'break-word',
                        }}
                      >
                        <textarea style={{ width: '450px', height: 200 }}>
                          {JSON.stringify(customer)}
                        </textarea>
                      </td>
                      <td>
                        {!creditChangeLoading && !updateSuccess && (
                          <Button
                            theme="info"
                            onClick={async () => {
                              console.log('customer clicked', customer);
                              changeCredits(customer.uid, 777, customer);
                            }}
                          >
                            Change Credits To PRO Account
                          </Button>
                        )}
                        {!creditChangeLoading && !updateSuccess && (
                          <Button
                            theme="primary"
                            onClick={async () => {
                              console.log('customer clicked', customer);
                              giveLifetimeAccess(customer.uid, customer);
                            }}
                          >
                            Grant Lifetime Access
                          </Button>
                        )}
                        {!creditChangeLoading && !updateSuccess && (
                          <Button
                            style={{ marginTop: 10 }}
                            theme="warning"
                            onClick={async () => {
                              console.log('customer clicked', customer);
                              setPaidFlag(customer.uid, customer);
                            }}
                          >
                            Set as Paid Customer
                          </Button>
                        )}

                        {updateSuccess && (
                          <Button
                            style={{ marginTop: 10 }}
                            theme="success"
                            onClick={async () => {
                              console.log('you alread did it');
                            }}
                          >
                            Success
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Col>
        </Container>
      )}

      {stripeCustomerResults && (
        <Container style={{ width: '90%' }}>
          <Col style={{ backgroundColor: 'white', padding: 30, marginTop: 10 }}>
            <h4>Stripe Results</h4>
            <table
              style={{
                border: '1px solid black',
              }}
            >
              <thead>
                <tr
                  style={{
                    border: '1px solid black',
                  }}
                >
                  <th>Stripe ID</th>
                  <th>Stripe Email</th>
                  <th>Created At</th>
                  <th>Paid for lifetime Access?</th>
                  <th>Paid for a subscription?</th>
                  <th>Stripe Customer Data </th>
                </tr>
              </thead>
              <tbody>
                {stripeCustomerResults.map((customer) => {
                  return (
                    <tr>
                      <td
                        style={{
                          border: '1px solid black',
                        }}
                      >
                        {customer.id}
                      </td>
                      <td
                        style={{
                          border: '1px solid black',
                        }}
                      >
                        {' '}
                        {customer.email}
                      </td>
                      <td
                        style={{
                          border: '1px solid black',
                        }}
                      >
                        {new Date(customer.created * 1000).toLocaleString()}

                        {new Date(customer.created * 1000).toLocaleTimeString(
                          'en-US'
                        )}
                        <span>(USA)</span>
                      </td>
                      <td
                        style={{
                          border: '1px solid black',
                        }}
                      >
                        {allStripeData &&
                        allStripeData.purchases &&
                        allStripeData.purchases.data.length > 0 ? (
                          // TODO THIS JUST CHECKS FOR SUCCESSFUL PAYMENT, Not sure if its actually lifeitme access
                          <p>YES</p>
                        ) : (
                          <p>NO</p>
                        )}
                      </td>
                      <td
                        style={{
                          border: '1px solid black',
                        }}
                      >
                        {/* make sure allStripeData has subs property and is not defined and is an array over 0 */}
                        {allStripeData &&
                        allStripeData.subs &&
                        allStripeData.subs.data.length > 0 ? (
                          <>
                            <p>YES</p>
                            {/* plan amount here */}
                            <p>{allStripeData.subs.data[0].plan.amount}</p>
                          </>
                        ) : (
                          <p>NO</p>
                        )}
                      </td>
                      <td
                        style={{
                          border: '1px solid black',

                          wordWrap: 'break-word',
                        }}
                      >
                        <textarea style={{ width: '450px', height: 200 }}>
                          {JSON.stringify(customer)}
                        </textarea>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Col>
        </Container>
      )}
    </Container>
  );
};
