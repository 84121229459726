const defaultEnvironment = {
  firebaseKeys: {
    apiKey: 'AIzaSyCr_EoK-MMbnMDvLlrDXO2QJpVMqpp13D8',
    authDomain: 'copywriting-db92b.firebaseapp.com',
    projectId: 'copywriting-db92b',
    storageBucket: 'copywriting-db92b.appspot.com',
    messagingSenderId: '944989906641',
    appId: '1:944989906641:web:5da3c3fe5d26d5545647f0',
    measurementId: 'G-3Z15ENP02R',
  },
  backend: {
    apiAddress: 'https://garyaiapi.herokuapp.com',
    // "apiAddress": "https://api.garyai.com",
    bearerToken:
      'BPUaMaUcXE51SsEq4lEQi3GUEg9ND3wezQLdtR4zcva8nc4V8FSKBpBmSNE064UV',
  },
  stripe: {
    publicKey:
      'pk_test_51I3jveCJ1SjiOJA4EGkrwzPoVmiognAr1CUajQ3JFreXprRvqibZYgE9xnxICv46lmxcrij6c2l54LHmR8VmPuNi00En0Puprf',
  },
  intercom: {
    appId: 'q5938gof',
  },
  accessCodes: [
    'HTB7IY',
    'FRE86N',
    'YES453',
    'JI4K2P',
    'N6Q17N',
    'FPCKW9',
    'DIMH92',
    'WKLDV1',
    'FLS8E1',
    'ZPI4RL',
    'FB7',
    'JAN20',
  ],
};

export { defaultEnvironment };
