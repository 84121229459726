/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {
  useState,
  useEffect,
  // Component, PropTypes
} from 'react';
import firebase from 'firebase';
import PageTitle from '../components/common/PageTitle';

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Badge,
  Button,
  // ListGroup, ListGroupItem, Form, FormTextarea
} from 'shards-react';
import { config } from '../environments/config';
// import ShareSchema from '../components/seo/indexSchema';

// const qs = require('qs');
const _ = require('lodash');

// const apiAddress = 'http://localhost:3001';
// const apiAddress = 'https://api.garyai.com'

const apiAddress = _.get(config, 'backend.apiAddress');

const SharePage = ({ match: { params } }) => {
  try {
    // console.log('params', params);
    // const [pageLoaded, setPageLoaded] = useState(false);
    const [inputs, setInputs] = useState([
      'product',
      'keyboard to sooth fingers',
    ]);
    const [inputList, setinputList] = useState(false);
    const [showHide, setshowHide] = useState('Show');

    const [category, setcategory] = useState('Loading...');
    const categoryTheme = 'info'; //[categoryTheme, setcategoryTheme] = useState('info');
    const author = ' Anonymous'; //[author, setauthor] = useState(' Anonymous');
    const title = 'GaryAI | Copywriting & Idea Generation'; //[title, settitle] = useState('GaryAI | Copywriting & Idea Generation');
    const [body, setBody] = useState('Loading...');
    // const [edited, setedited] = useState(false);

    // const imageURL = "https://img1.niftyimages.com/ao8/ggeo/j6vo?description="+body; //[imageURL, setimageURL] = useState("https://img1.niftyimages.com/ao8/ggeo/j6vo?description="+body);
    // const [backgroundImage, setbackgroundImage] = useState(imageURL);
    //const [backgroundImage, setbackgroundImage] = useState("../images/content-management/1.jpeg");

    // const [inputTitle, setinputTitle ] = useState('Tea Pot');
    // const [inputIdeas, setinputIdeas ] = useState('ornate, antique, chipped, sings, white');

    // const [date,setdate ] = useState('');
    // const [authorAvatar, setauthorAvatar] = useState("../images/avatars/1.jpg");
    const authorAvatar = require('../images/avatars/6.png'); //[authorAvatar, setauthorAvatar] = useState(require("../images/avatars/6.png"));
    // require("../../images/avatars/3.jpg")

    // const [authorUrl, setauthorUrl] = useState('#');

    const [userId, setUserId] = useState(undefined);
    const [snippetId, setSnippetId] = useState('loading');
    const [index, setIndex] = useState(0);

    const [errorText, setErrorText] = useState(0);

    let handleLoad = (params) => {
      // setshowHide('Show');

      /** const params = qs.parse(this.props.location.search); // THIS IS HOW YOU GET ?id=value parameters */
      // console.log('URL handle', this.props.match.params);

      // const params = this.props.match.params;
      let uid = _.get(params, 'userId', undefined);
      setUserId(uid);
      let sid = _.get(params, 'saveOrResultId', undefined);
      let inx = _.get(params, 'arrayIndex', 0);
      console.log('sid', sid);

      if (sid) {
        // if the first three characters are 'cmpl'
        if (sid.substring(0, 4) === 'cmpl') {
          //below is a query firestore for the snippet in the users results collection where o_id = sid
          // console.log('sid', sid);
          // console.log('uid', uid);
          console.log('lookup snippet v2');
          return firebase
            .firestore()
            .collection('users')
            .doc(uid)
            .collection('results')
            .where('o_id', '==', sid)
            .get()
            .then((snapshot) => {
              console.log('snapshot', snapshot);

              return snapshot.forEach((doc) => {
                // console.log('doc', doc.data());
                let data = doc.data();
                console.log('data', data);
                // console.log('data', data);
                setSnippetId(data.o_id);
                // setIndex(inx);
                setcategory(data.type);
                setBody(data.value[0]);
                console.log('set body', data.value[0]);
                return data;
              });
            });
        }
      }
      setSnippetId(sid);

      /** These values dont seem to update immediately */
      console.log('params:', params);
      // setUserId(uid);
      // setSnippetId(sid);
      setIndex(inx);
      // window.alert(`uid: ${JSON.stringify(params)}, sid:${this.state.snippetId}`);

      if (uid !== undefined && sid !== undefined && inx !== undefined) {
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              'Bearer BPUaMaUcXE51SsEq4lEQi3GUEg9ND3wezQLdtR4zcva8nc4V8FSKBpBmSNE064UV',
          },
          body: JSON.stringify({ uid: uid, sid: sid }), //inx: inx
        };

        fetch(`${apiAddress}/v1/db/share`, requestOptions)
          .then((response) => {
            console.log('response status: ', response.status);
            if (response.status >= 400 && response.status < 500) {
              setErrorText('Not found');
              // window.alert("Not found");
            } else if (response.status === 500) {
              setErrorText('Internal Error');
              // window.alert("");
            } else if (response.status === 204) {
              setErrorText('No Content');
            } else {
              console.log('Asked for share page specifics');
              console.log(response);

              response.json().then((body) => {
                console.log('body: ', body);

                let prompt = JSON.parse(_.get(body, 'item.prompt', '{}')); //  --> Then update the other inputs & change the visible input fields depending on what is available based on the prompt type.

                const inputsKeyHumanReadableMapping = {
                  //TODO: NEED A CONFIG SO I CAN ADD THIS IN 1 PLACE
                  company: 'Company',
                  description: 'Description',
                  industry: 'Business Industry',
                  businessUrl: 'Business Url',
                  businessIndustry: 'Business Industry',
                  details: 'Details',
                  product_name: 'Product Name',
                  product_description: 'Product Description',
                  product_details: 'Product Details',
                };
                let printInputs = [];
                _.forEach(prompt, (value, key) => {
                  if (key !== 'promptId') {
                    if (key in inputsKeyHumanReadableMapping) {
                      printInputs.push([
                        inputsKeyHumanReadableMapping[key],
                        value,
                      ]);
                    } else {
                      printInputs.push([key, value]);
                    }
                  }
                });

                console.log('print prompt:', prompt);
                setInputs(printInputs);
                // setInputs(['hello','world','hi']);
                console.log('print inputs:', inputs);

                // setTimeout(()=>{ console.log('new state inputs:', inputs); }, 5000);
                // console.log('print inputs:', inputs);

                console.log('Set Body Prompt', prompt);
                let localBody = '';
                if (typeof _.get(body, 'item.value') == 'string') {
                  localBody = _.get(body, 'item.value', '');
                } else {
                  localBody = _.get(body, `item.value[${index}]`, '');
                }

                // localBody = localBody.replace(/(?:\r\n|\r|\n)/g, '<br />');
                setBody(localBody);

                let itemType = _.get(body, `item.type`, '');
                const categoryHumanReadableMapping = {
                  //TODO: NEED A CONFIG SO I CAN ADD THIS IN 1 PLACE
                  attentionInterestDesireAction:
                    'ATTENTION-INTEREST-DESIRE-ACTION',
                  facebookAd: 'FACEBOOK AD',
                  headline: 'HEADLINE',
                  productDescription: 'PRODUCT DESCRIPTION',
                  painAgitateSolution: 'PAIN-AGITATE-SOLUTION',
                };
                if (itemType in categoryHumanReadableMapping) {
                  setcategory(categoryHumanReadableMapping[itemType]);
                } else {
                  setcategory(itemType);
                }
              });
            }
          })
          .catch((e) => {
            this.state.error('An Error Occurred');
            // console.log('Error occurred while accessing shared snippet', e);
          });
      } else {
        console.log('Load Share Error');
        //Undefined Sid or Pid
      }
    };

    let currentSnippetId = 'none';
    useEffect(() => {
      console.log('PAGE LOADED', Date.now());
      // TODO: Make sure this only fires once when the page is loaded (I have a feeling it's fired more than that)
      // console.log("use effect loaded");
      // if(snippetId === false){
      //   setPageLoaded(true);
      //   handleLoad(params);
      // }
      console.log('use effect loaded snipped id', currentSnippetId);
      if (
        currentSnippetId != _.get(params, 'saveOrResultId', 'none') &&
        _.get(params, 'saveOrResultId', 'none') != 'none'
      ) {
        console.log('use effect loaded');
        handleLoad(params);
        //This value is clearly not getting updated fast enough. Can I just use a local variable not a state variable.
        currentSnippetId = _.get(params, 'saveOrResultId', 'none');
        setSnippetId(_.get(params, 'saveOrResultId', 'none'));
      }
    }, []);

    const showInputs = () => {
      if (inputList === false) {
        setshowHide('Hide');
        setinputList(true);
        console.log('show = true');
      } else {
        setshowHide('Show');
        setinputList(false);
        console.log('show = false');
      }
    };

    const InputBlock = ({ listOfParams }) => {
      if (inputList) {
        return (
          <Row>
            <Col lg="3" md="2"></Col>
            <Col lg="6" md="8" sm="12">
              <Card small className="card-post mb-4">
                <CardBody>
                  <h4 className="card-title text-muted">Input Prompts</h4>
                  {listOfParams.map((value, index) => (
                    <div key={index}>
                      <h5 className="card-title">{value[0]}</h5>
                      <h6 className="card-title text-muted">{value[1]}</h6>
                    </div>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        );
      } else {
        return <div></div>;
      }
    };

    const makeKeywordsBold = (item, keywords) => {
      try {
        let newItem = item;

        //Quick Security: replace <script
        let re = new RegExp('<script', 'g');
        let replaceWith = '<strong';
        newItem = newItem.replace(re, replaceWith);

        function capitalCase(word) {
          let newWord = word.toLowerCase();
          let wordCapitalized =
            newWord.charAt(0).toUpperCase() + newWord.slice(1);
          return wordCapitalized;
        }

        keywords.forEach((keyword) => {
          let re = new RegExp(keyword, 'g');
          let replaceWith = '<strong>' + capitalCase(keyword) + '</strong>';
          newItem = newItem.replace(re, replaceWith);
        });

        re = new RegExp(/\n/, 'g');
        replaceWith = '<br />';
        newItem = newItem.replace(re, replaceWith);

        return newItem;
      } catch (e) {
        console.log('error: ', e);
        return;
      }
    };

    return (
      <span>
        {/*TODO: Include this when we're ready to pre-render a large SEO play - or we want custom OG tags
          <ShareSchema _title={title}
                       _description={body}/>*/}
        <Container fluid className="">
          {/* Page Header */}
          <span style={{ display: 'none' }}>{errorText}</span>

          <Row noGutters className="page-header py-4">
            <Col lg="4" md="2"></Col>
            <PageTitle
              sm="4"
              title="AI Generated Snippets"
              subtitle="Sharable"
              className="text-sm-center text-lg-center"
            />
          </Row>

          {/* First Row of Posts */}

          <Row>
            <Col lg="3" md="2"></Col>
            <Col lg="6" md="8" sm="12" className="mb-4 align-items-center">
              <Card small className="card-post">
                <div>
                  <Badge
                    pill
                    className={`card-post__category bg-${categoryTheme}`}
                  >
                    {category}
                  </Badge>
                </div>
                <CardBody>
                  <h5 className="card-title">
                    <a href="#" className="text-fiord-blue">
                      {title}
                    </a>
                  </h5>
                  <h6 className="card-text d-inline-block mb-3">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: makeKeywordsBold(body, [
                          'ATTENTION:',
                          'INTEREST:',
                          'DESIRE:',
                          'ACTION:',
                          'PROBLEM:',
                          'AGITATE:',
                          'SOLVE:',
                        ]),
                      }}
                    ></span>
                  </h6>
                </CardBody>
                <CardFooter className="border-top d-flex">
                  <div className="card-post__author d-flex">
                    <a
                      href={`https://www.garyai.com/free-trial/?utm_source=garyai&utm_campaign=sharepage&rid=${userId}`}
                      className="card-post__author-avatar card-post__author-avatar--small"
                      style={{ backgroundImage: `url('${authorAvatar}')` }}
                    >
                      Written by GaryAI
                    </a>
                    <div className="d-flex flex-column justify-content-center ml-3">
                      <span className="card-post__author-name">
                        Written by GaryAI
                      </span>
                      <small className="text-muted">
                        Inspired & edited by
                        <a
                          className="text-fiord-blue"
                          href={`https://www.garyai.com/free-trial/?utm_source=garyai&utm_campaign=sharepage&rid=${userId}`}
                        >
                          {author}
                        </a>{' '}
                      </small>
                    </div>
                  </div>
                  <div className="my-auto ml-auto">
                    <Button
                      size="sm"
                      theme="white"
                      onClick={() => showInputs()}
                    >
                      <i className="fas fa-list mr-1" /> {showHide} Inputs
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          {inputList && <InputBlock listOfParams={inputs} />}
          <Row>
            <Col lg="3" md="2"></Col>
            <Col
              lg="6"
              md="8"
              sm="12"
              className="button-box mb-5"
              style={{ textAlign: 'center' }}
            >
              <a
                href={`https://www.garyai.com/free-trial?utm_source=garyai&utm_campaign=sharepage&rid=${userId}`}
              >
                <Button size="lg" theme="primary">
                  Try GaryAI Now
                </Button>
              </a>
            </Col>
          </Row>
          <Row>
            <span></span>
          </Row>
        </Container>
      </span>
    );
  } catch (e) {
    if (e.response) {
      if (e.response.status === 404) {
        window.location.pathname = '/not-found';
      } else {
        window.location.pathname = '/error';
      }
    } else {
      throw e;
    }
  }
};

export default SharePage;
