import React from "react";
export default class UserActions extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
    <a target="_blank" href={'https://intercom.help/garyai/en/collections/2688485-getting-started-with-garyai'}>
      <div style={{position:"relative", display:"block", backgroundColor:"none", minWidth:"60px", height:"60px", lineHeight:"60px", textAlign:"center", fontSize:"30px"}}>
        <span style={{fontSize:"46px", lineHeight:"60px", backgroundColor:"none", color:"#d7d7d7" }} className="material-icons">
          help_outline
        </span>
      </div>
    </a>
    );
  }
}
