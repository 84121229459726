/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useState, useEffect } from 'react';
import Fire from '../config/fire';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormTextarea,
  ButtonGroup,
  Button,
  // CardFooter,
  // FormInput,

  // Badge,
  // Button,
  Alert,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'shards-react';
import { countNewLines } from '../utils/utils';
import PageTitle from '../components/common/PageTitle';
import { MixpanelSuper } from '../mixpanelSuper';
import '../assets/stars.css';
import '../assets/history.css';

const _ = require('lodash');

const History = () => {
  // Todo -- you need to make a dropdown variable for each array item that is imported
  // const [dropdown1, setdropdown1] = useState(false);

  const [savedItems, setsavedItems] = useState([]);
  console.log('saved Items', savedItems);
  const [loading, setLoading] = useState();
  const [displayMessage, setdisplayMessage] = useState('');
  const [dropdowns, setDropdowns] = useState([]);
  const [localStars, setLocalStars] = useState([]);
  const [expanded, setExpanded] = useState([]);

  const copyToClipboard = (text) => {
    MixpanelSuper.copySnippet('history_page');
    console.log('Copy to clipboard');
    navigator.clipboard.writeText(text);
    setdisplayMessage('Copied!');
    setTimeout(() => {
      setdisplayMessage(null);
    }, 3000);
  };

  const copyLinkToClipboard = (link) => {
    MixpanelSuper.copyShareSnippet('history_page');
    console.log('Custom link copied To clipboard');
    navigator.clipboard.writeText(link);
    setdisplayMessage('Sharable Link Copied!');
    setTimeout(() => {
      setdisplayMessage(null);
    }, 3000);
  };

  const changeDropdown = (dropdown, index) => {
    dropdown = !dropdown;
    let changeDrop = [...dropdowns];
    changeDrop[index] = dropdown;
    setDropdowns(changeDrop);
  };

  // const showCopyInputs = (index) => {
  //   console.log('Show inputs for this item', index)
  // }

  // const truncate = (what, length = 200) => {
  //   if (what.length > length) {
  //     what = what.substr(0, length);
  //     return what;
  //   }
  //   return what;
  // }

  const getUserAllCopyResults = async () => {
    console.log('Fetch user saved results');
    setLoading(true);
    // setLoading('true');
    return new Promise((resolve, reject) => {
      Fire.shared.getAllCopyResults().then((res) => {
        console.log('saved results here: ', res);
        let drops = [];
        let stars = [];
        let expand = [];
        for (let i = 0; i < res.length; i++) {
          // console.log("Item " +i, res[i])
          drops.push(false);
          expand.push(true);
          let itemStar = res[i].stars ? res[i].stars : 0;
          stars.push(itemStar);
          res[i]['stars'] = 0;
          let date = new Date(res[i].ts);
          res[i]['date'] =
            date.getMonth() +
            1 +
            '-' +
            date.getDate() +
            '-' +
            date.getFullYear() +
            ' at ' +
            date.getHours() +
            ':' +
            date.getMinutes();
          //console.log('ts',res[i].ts)
          //console.log('date',date.getMonth()+1+'-'+date.getDate()+'-'+date.getFullYear()+' at '+date.getHours()+':'+date.getMinutes())
        }
        setExpanded(expand);
        setDropdowns(drops);
        setLocalStars(stars);
        setsavedItems(res);
        console.log(savedItems);
        setLoading(false);

        // Adding the star field and the dropdown field to each saved item so they can work independently
      });
    });
  };

  const saveStars = (output, index, starCount) => {
    console.log('applied ' + starCount + ' to id ' + index);
    let changeStars = [...localStars];
    changeStars[index] = starCount;
    setLocalStars(changeStars);

    return new Promise((resolve, reject) => {
      Fire.shared
        .saveStarRating(output.id, starCount, 'results')
        .finally(() => {
          resolve();
        });
    });
  };

  const expandSection = (index, exp) => {
    let expandTemp = [...expanded];
    expandTemp[index] = !exp;
    setExpanded(expandTemp);
    console.log('expanded', index);
    console.log('expanded', expanded);
  };

  useEffect(() => {
    MixpanelSuper.trackScreenView('history');
    Fire.shared.checkAndWaitForFirebaseLoad().then(() => {
      getUserAllCopyResults();
    });
  }, []);

  const InputBlock = ({ item }) => {
    console.log('Input Object:', item);
    let promptObj = JSON.parse(item.prompt);
    const inputsKeyHumanReadableMapping = {
      //TODO: NEED A CONFIG SO I CAN ADD THIS IN 1 PLACE
      product: 'Product',
      businessUrl: 'Business Url',
      businessIndustry: 'Business Industry',
      details: 'Details',
      product_name: 'Product Name',
      product_description: 'Product Description',
      product_details: 'Product Details',
    };
    let printInputs = [];
    _.forEach(promptObj, (value, key) => {
      if (key !== 'promptId') {
        if (key in inputsKeyHumanReadableMapping) {
          printInputs.push([inputsKeyHumanReadableMapping[key], value]);
        } else {
          printInputs.push([key, value]);
        }
      }
    });
    if (printInputs !== []) {
      return (
        <span>
          {printInputs.map((value, index) => (
            <div key={index}>
              <strong>{value[0]}</strong>: {value[1]}
            </div>
          ))}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const ItemType = ({ type }) => {
    // console.log('Item Type: ', type);
    const categoryHumanReadableMapping = {
      //TODO: NEED A CONFIG SO I CAN ADD THIS IN 1 PLACE
      attentionInterestDesireAction: 'Attention-Interest-Desire-Action',
      facebookAd: 'Facebook Ad',
      headline: 'Headlines',
      headlines: 'Headlines',
      productDescription: 'Product Description',
      painAgitateSolution: 'Pain-Agitate-Solution',
      'custom-copy': 'Custom Copy',
      customCopy: 'Custom Copy',
    };
    if (type in categoryHumanReadableMapping) {
      return <span>{categoryHumanReadableMapping[type]}</span>;
    } else {
      return <span>{type}</span>;
    }
  };

  const ItemLine = ({ item }) => {
    // console.log('ItemLine Value: ', item);
    return (
      <span>
        {item}
        <br />
      </span>
    );
  };

  const ExpandContract = ({ value, idx }) => {
    // console.log('expanded[idx]',expanded[idx])
    if (expanded[idx]) {
      return (
        <span>
          <p className="card-text mb-1 bright">
            <a onClick={() => expandSection(idx, expanded[idx])}>
              Show details
            </a>
          </p>
        </span>
      );
    } else {
      return (
        <span>
          <p className="card-text mb-1 bright">
            <a onClick={() => expandSection(idx, expanded[idx])}>
              Hide details
            </a>
          </p>
          <p className="card-text mb-1">
            <em>Inputs:</em>
            <InputBlock item={value} />
          </p>
        </span>
      );
    }
  };

  const GeneratedContentBlock = ({ value }) => {
    console.log('GeneratedContentBlock Value: ', value);
    if (typeof value == 'string' && value !== undefined) {
      return (
        <span>
          {value.split('\n').map(function(item) {
            return <ItemLine item={item} />;
          })}
        </span>
      );
    } else if (typeof value == 'object') {
      return (
        <span>
          {value.map(function(item) {
            return <GeneratedContentBlock value={item} />;
          })}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const StarRating = ({ rating, item, idx }) => {
    // console.log(rating)
    // console.log(rating === 5)

    if (rating === 5) {
      return (
        <div className="rating">
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 5)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 4)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 3)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 2)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 1)}
          >
            ⭐
          </span>
        </div>
      );
    } else if (rating === 4) {
      return (
        <div className="rating">
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 5)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 4)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 3)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 2)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 1)}
          >
            ⭐
          </span>
        </div>
      );
    } else if (rating === 3) {
      return (
        <div className="rating">
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 5)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 4)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 3)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 2)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 1)}
          >
            ⭐
          </span>
        </div>
      );
    } else if (rating === 2) {
      return (
        <div className="rating">
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 5)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 4)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 3)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 2)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 1)}
          >
            ⭐
          </span>
        </div>
      );
    } else if (rating === 1) {
      return (
        <div className="rating">
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 5)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 4)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 3)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 2)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 1)}
          >
            ⭐
          </span>
        </div>
      );
    } else {
      return (
        <div className="rating">
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 5)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 4)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 3)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 2)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 1)}
          >
            ☆
          </span>
        </div>
      );
    }
  };

  return (
    <Container fluid className="main-content-container px-4">
      {displayMessage && (
        <Container
          fluid
          className="px-0"
          style={{
            zIndex: 99,
            position: 'fixed',
            right: '0px',
            width: '300px',
          }}
        >
          <Alert className="mb-0">
            <i className="fa fa-info mx-2"></i> {displayMessage}
          </Alert>
        </Container>
      )}

      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="History"
          subtitle="View"
          className="text-sm-left"
        />
      </Row>

      {loading && (
        <img
          alt=""
          src={require('../images/loading.gif')}
          height="100"
          style={{ marginLeft: '40%' }}
        ></img>
      )}

      <Row>
        {savedItems.map((item, idx) => (
          <Col lg="12" sm="12" className="mb-2" key={idx}>
            <Card small className="card-post card-post--aside card-post--1">
              <CardBody className="history">
                <Row>
                  {/* <span>{item}</span> */}
                  <Col lg="10" md="12" sm="12">
                    <span className="text-muted">
                      <ItemType type={item.type} />
                      {item.type === 'custom' ||
                        (item.type === 'custom-copy' &&
                          item.previewMode === true && (
                            <span className="text-muted">
                              {' (made during set up)'}
                            </span>
                          ))}
                    </span>
                    <FormTextarea
                      rows={countNewLines(item.value)}
                      value={item.value}
                    />
                    <ExpandContract value={item} idx={idx} />
                  </Col>
                  <Col lg="2" md="12" sm="12">
                    <p className="card-text mb-1">
                      Created: <br></br>
                      {item.date.split(' at', 1)[0]}{' '}
                    </p>
                    <StarRating
                      rating={localStars[idx]}
                      item={item}
                      idx={idx}
                    />
                    <br></br>
                    <ButtonGroup size="sm">
                      <Button
                        theme="white"
                        onClick={() => {
                          copyToClipboard(item.value);
                          console.log('Copy Snippet');
                        }}
                      >
                        Copy
                      </Button>
                      <Button
                        theme="white"
                        onClick={() =>
                          copyLinkToClipboard(
                            `https://app.garyai.com/snippet/share/${Fire.shared.uid}/0/${item.id}/`
                          )
                        }
                      >
                        Share
                      </Button>
                      {/* <Button theme="white">Save</Button> */}
                      {/* <Button theme="white">Generate More</Button> */}
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default History;
