import React, { useEffect, useState } from 'react';

import toast, { Toaster } from 'react-hot-toast';
import { Mixpanel } from '../mixpanel';
import {
  Container,
  Row,
  Col,
  ListGroupItem,
  Button,
  ListGroup,
} from 'shards-react';

import firebase from 'firebase';
import { config } from '../environments/config';
import { sendOtherSlackNotification } from '../utils/slackNotifications';
import { MixpanelSuper } from '../mixpanelSuper';

export const LoadPlan = () => {
  const [isLoading, setIsLoading] = useState(true);
  const _ = require('lodash');
  let apiAddress = _.get(config, 'backend.apiAddress');
  let currentPageUrl = window.location.href;
  if (currentPageUrl.includes('localhost')) {
    console.log('LOCALHOST');
    apiAddress = 'http://localhost:3001';
  }

  let isThisMobileDevice = window.navigator.userAgent.indexOf('Mobile') > -1;
  console.log('isThisMobileDevice', isThisMobileDevice);

  const lookupCustomerViaServer = async (sessionId) => {
    try {
      let endpoint = apiAddress + '/api/v2/stripe/get-customer-by-session-id';
      let response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sessionId: sessionId,
        }),
      });
      let data = await response.json();
      console.log('data', data);

      if (data.status === 'success') {
        console.log('success');
        console.log('data.session', data.session);
        return data.session;
      } else {
        console.log('error');
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    console.log('LoadPlan useEffect');

    console.log(window.location.href);
    var url = new URL(window.location.href);
    let plan = url.searchParams.get('plan');
    MixpanelSuper.trackScreenView('verify_load_plan');

    const startStripeCheckout = async (planName) => {
      setIsLoading(true);
      console.log('startStripeCheckout', planName);
      // https://stripe.com/docs/checkout/quickstart
      let endpoint =
        apiAddress + '/api/v2/stripe/create-annon-checkout-session';

      const body = {
        stripePlanName: planName,
        // email: email,
        // userId: userId,
      };
      try {
        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });
        console.log('response', response);
        if (response.status != 200) {
          toast.error(`Error: ${response.statusText}`);
          setIsLoading(false);
          return 'error';
        }

        let data = await response.json();
        console.log('data', data);
        window.location.href = data.url;
        setIsLoading(false);
      } catch (e) {
        console.log('error', e);
        toast.error('Error: ' + e.message);
        setIsLoading(false);
      }
    };

    if (plan && plan.includes('pro')) {
      startStripeCheckout('pro2');
    } else {
      startStripeCheckout('starter2');
    }
  }, []);

  return (
    <Container fluid>
      <div>
        <Toaster />
      </div>
      <div style={{ margin: 30 }}></div>

      <Container style={{ width: isThisMobileDevice ? '75%' : '40%' }}>
        {isLoading && (
          <center>
            <img
              src={require('../images/loading.gif')}
              style={{ height: 100, margin: 30 }}
            />

            {/* <p>Verifying purchase, please wait.</p> */}
          </center>
        )}
      </Container>
    </Container>
  );
};
