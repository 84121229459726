import React, {useState} from "react";
import PropTypes from "prop-types";
import { NavLink as RouteNavLink } from "react-router-dom";
import { NavItem, NavLink } from "shards-react";

const SidebarNavItem = ({ item, px=0 }) => {

  const hideNav = () => {
    let display = document.getElementById(`${item.title}-toggle`).style.display;
    console.log('display:',display);
    console.log('item title:',item.title);
    if(display == ''){
      document.getElementById(`${item.title}-toggle`).style.display = 'none';
    }else{
      document.getElementById(`${item.title}-toggle`).style.display = '';
    }
  }
  return (
    <span>
      <NavItem onClick={hideNav}>
        <NavLink style={{marginLeft:`${px}px`}} tag={RouteNavLink} to={item.to}>
          {item.htmlBefore && (
            <div
              className="d-inline-block item-icon-wrapper"
              dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
            />
          )}
          {item.title && <span>{item.title}</span>}
          {/*{item.subNav && <span style={{color:"Silver"}}> ({item.subNav.length})</span>}*/}
          {item.htmlAfter && (
            <div
              className="d-inline-block item-icon-wrapper"
              dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
            />
          )}
        </NavLink>

      </NavItem>

     {/*{item.subNav && <SidebarNavItem key={-1} item={{title:"toggle", htmlBefore:'<i class="material-icons">expand_more</i>', htmlAfter:'', to:'#'}} px={2} />}*/}

      {/*{item.subNav &&*/}
      {/*<NavItem>*/}
      {/*  <NavLink  style={{marginLeft:`${px}px`}} tag={RouteNavLink} to={'#'}>*/}
      {/*    {item.htmlBefore && <div className="d-inline-block item-icon-wrapper"><i className="material-icons">expand_more</i></div>}*/}
      {/*    {item.title && <span>Toggle</span>}*/}
      {/*  </NavLink>*/}
      {/*</NavItem>}*/}
      <span id={`${item.title}-toggle`}>
        {/* Would be nice to add a wrapper with a hide feature/ collapse */}
        {/*<SidebarNavItem item={{title:"toggle", htmlBefore:'<i class="material-icons">swap_vert</i>', htmlAfter:'', to:'#'}} px={2} />*/}
        {/* THIS vv CALLS subNav recursively on SidebarNavItem */}
        {item.subNav && item.subNav.map((subItem, subIndex) => (
          <SidebarNavItem key={subIndex} item={subItem} px={px+2} />
        ))}
      </span>
    </span>
)};

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

export default SidebarNavItem;
