// import React from 'react'
import { MixpanelSuper } from '../mixpanelSuper';
const _ = require('lodash');

const copyToClipboard = (id, copyType, setdisplayMessage) => {
  let textAreaValue = document.getElementById(id).value;
  console.log('Copy to clipboard');
  navigator.clipboard.writeText(textAreaValue);
  MixpanelSuper.copySnippet(copyType);
  setdisplayMessage('Copied!');
  setTimeout(() => {
    setdisplayMessage(null);
  }, 3000);
};

const _startTypewriter = (text) => {
  console.log('startTypewriter');
  let i = 0;
  let txt = text;
  const speed = 7;

  const typeWriter = () => {
    console.log('write this - ', txt);
    if (i < txt.length) {
      document.getElementById('resultTextArea0').innerHTML += txt.charAt(i);
      i++;
      setTimeout(typeWriter, speed);
    }
  };
  typeWriter();
};

const _animateResultsIn = (txt) => {
  console.log('Animate copy results in for first result');
  _startTypewriter(txt);
};

const handleCopyGenerationResponse = (
  copy,
  copyResults,
  sethasResults,
  setcopyResults,
  setloading
) => {
  console.log('handle copy response >> ', copy);
  if (copyResults.length === 0) {
    console.log('there are no copy results yet.');
    //set state initial value
    sethasResults(true);
    setcopyResults(copy);
    _animateResultsIn(_.get(copy, '[0].value[0]', ''));
  } else {
    console.log('there are SOME copy results. Push to existing list');
    // push existing items
    setcopyResults((copyResults) => [...copyResults, ...copy]);
    try {
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    } catch (e) {
      console.log('Couldnt scroll because', e);
    }
  }
  setloading(false);
};
const handleCopyGenerationResponse2 = (
  copy,
  copyResults,
  sethasResults,
  setcopyResults,
  setloading
) => {
  console.log('handle copy response >> ', copy);
  if (copyResults.length === 0) {
    console.log('there are no copy results yet.');
    //set state initial value
    sethasResults(true);
    setcopyResults(copy);
    _animateResultsIn(copy[0]);
    // _animateResultsIn(_.get(copy, '[0].value[0]', ''));
  } else {
    console.log('there are SOME copy results. Push to existing list');
    // push existing items
    setcopyResults((copyResults) => [...copyResults, ...copy]);
    try {
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    } catch (e) {
      console.log('Couldnt scroll because', e);
    }
  }
  setloading(false);
};

export {
  copyToClipboard,
  handleCopyGenerationResponse,
  handleCopyGenerationResponse2,
};
