import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  ListGroupItem,
  Form,
  FormGroup,
  FormInput,
  Button,
  ButtonGroup,
  FormTextarea,
  Alert,
  Modal,
  ModalBody,
  ModalHeader,
  Badge,
} from 'shards-react';
import {
  copyToClipboard,
  handleCopyGenerationResponse,
} from '../utils/generatorPageHelpers';
import { sendSlackNotificationForCopyGeneration } from '../utils/slackNotifications';

import Fire from '../config/fire';
import PageTitle from '../components/common/PageTitle';

import { MixpanelSuper } from '../mixpanelSuper';

import firebase from 'firebase';
import { config } from '../environments/config';
import '../assets/upgrade.css';
import '../assets/generate.css';
import { countNewLines } from '../utils/utils';
import toast, { Toaster } from 'react-hot-toast';

import { useSelector, useDispatch } from 'react-redux';

const _ = require('lodash');

let apiAddress = _.get(config, 'backend.apiAddress');
let currentPageUrl = window.location.href;

if (currentPageUrl.includes('localhost')) {
  console.log('LOCALHOST');
  apiAddress = 'http://localhost:3001';
}

console.log('generate to: ', apiAddress);

const Generate = ({ match: { params } }) => {
  /** REDUX vvv */
  // const credits = useSelector(state => state.credits);
  // const isSubscribed = useSelector(state => state.isSubscribed);
  // const daysRemaining = useSelector(state => state.daysRemaining);
  // const firebaseuserData = useSelector(state => state.firebaseuserData);
  // const dispatch = useDispatch();
  /** REDUX ^^^ */

  //NEW VARIABLES
  const [generator, setGenerator] = useState(
    'loading'
  ); /** STRING NAME, How Do I set Default? */
  const [pageTitle, setPageTitle] = useState('');
  // const [generatorExamples, setGeneratorExamples] = useState([]);
  const [generatorExists, setGeneratorExists] = useState(false);
  const [copyTypeUnderscore, setCopyTypeUnderscore] = useState(
    'default-generator'
  );
  const [copyTypeCamelCase, setCopyTypeCamelCase] = useState(
    'default-generator'
  );
  const [lastGeneratedPrompts, setLastGeneratedPrompts] = useState({});

  const [remainingCredits, setRemainingCredits] = useState(50); // Hard coding this stil, but we really shouldnt, see related issue here https://garyai.atlassian.net/browse/GAT-41?atlOrigin=eyJpIjoiOTdkOGUyZDg4NDBkNDExNDg5OWYwYTE0ZGQ2ZDQ1YjYiLCJwIjoiaiJ9
  // const [remainingCredits, setRemainingCredits] = useState(50);
  const [remainingDays, setRemainingDays] = useState(30);
  const [remaingDaysLabel, setRemaingDaysLabel] = useState(
    'days left in your free trial.'
  );
  const [
    subscriptionDetailsHaveLoaded,
    setsubscriptionDetailsHaveLoaded,
  ] = useState(false);
  const [userEmail, setuserEmail] = useState('');
  const [usersName, setusersName] = useState('');

  const [isSubscriber, setIsSubscriber] = useState(false);
  const [firebaseUserData, setFirebaseUserData] = useState({});

  const [showVideo, setShowVideo] = useState(false);
  const [showHelper, setShowHelper] = useState(true);

  const [localStars, setLocalStars] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorModalText, setShowErrorModalText] = useState('');
  const [userDetails, setUserDetails] = useState({});

  const [
    showPromptImprovementSuggestion,
    setShowPromptImprovementSuggestion,
  ] = useState(false);
  const [fieldToImprove, setFieldToImprove] = useState('inputs');

  const [showPromptBuilderModal, setShowPromptBuilderModal] = useState(false);
  const [promptBuilderValues, setPromptBuilderValues] = useState({});
  const [showGeneralInputTipModal, setShowGeneralInputTipModal] = useState(
    false
  );
  const [showProUpsell, setShowProUpsell] = useState(true);

  // const [remainingDays, setRemainingDays] = useState(undefined);
  // const [remaingDaysLabel, setRemaingDaysLabel] = useState(undefined);
  // const [remainingDays, setRemainingDays] = useState(30);
  // const [remaingDaysLabel, setRemaingDaysLabel] = useState('days left on your free trial.');

  const intercomTrackCredits = (left) => {
    if (true) {
      return new Promise((resolve, reject) => {
        try {
          window.Intercom('boot', {
            app_id: _.get(config, 'intercom.appId'),
            remainingCredits: remainingCredits,
          });
          console.log('set remaining credits');
          resolve();
        } catch (e) {
          console.log('There was an issue with intercom: ', e);
          reject(e);
        }
      });
    }
  };

  const copyLinkToClipboard = (link) => {
    MixpanelSuper.copyShareSnippet('generate_page');
    console.log('Custom link copied To clipboard');
    navigator.clipboard.writeText(link);
    setdisplayMessage('Sharable Link Copied!');
    setTimeout(() => {
      setdisplayMessage(null);
    }, 3000);
  };

  const fillPromptWithValues = (promptsArray) => {
    for (let i = 0; i < promptsArray.length; i++) {
      if (promptsArray[i]) {
        document.getElementById('inputParameter' + i).value =
          promptsArray[
            i
          ]; /** NOTE: This works for both text area and text inputs */
      }
    }
  };

  const checkSubscription = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function(idToken) {
          const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${_.get(config, 'backend.bearerToken')}`,
              firebaseidtoken: idToken,
            },
            // body: JSON.stringify()
          };
          fetch(
            `${apiAddress}/v1/stripe/get-and-update-subscription`,
            requestOptions
          )
            .then((response) => {
              // console.log("response", response);
              if (response.status === 406) {
                window.alert('');
                window.location.reload();
              }

              if (response.status === 500) {
                window.alert("Sorry we can't process your request right now.");
                window.location.reload();
              }
              if (response.status === 402) {
                return Promise.reject('Payment required to continue');
              }
              return response.json();
            })
            .then((data) => {
              resolve(data);
              console.log('Successful Subscription Check');
            });
        })
        .catch((e) => {
          reject(e);
          console.log('Error occured while accessing current firebase user', e);
        });
    });
  };

  /**
   * NOTES: ? TODO: IDK how to make this variable not change as routes change.  is there a global state or session storage I can cache to?
   * */
  /** showUserDetails being replaced by REDUX. */
  /** TODO: is it possible to abstract these functions to a helper file if I use them in multiple files.  I could pass in "dispatch"
   * TODO: Note they are functional components here and class components on the write page. "CopyOptions page"*/
  const setFirebaseUserToStateRedux = (userData) => {
    /** NO LONGER REDUX */
    setFirebaseUserData(userData); //dispatch(setFirebaseUserData(userData)); /** Redux */
  };

  const setCreditsRedux = (userData) => {
    /** NO LONGER REDUX */
    let credits =
      _.get(userData, 'smu', 0) -
      _.get(userData, 'pu', 0) +
      _.get(userData, 'ec', 0);
    if (!credits) {
      credits = 0;
    }
    setRemainingCredits(credits); //dispatch(setCredits(credits)); /** Redux */
  };

  const setDaysRemainingRedux = (userData) => {
    /** NO LONGER REDUX */
    let timeNow = Date.now();
    //If no subscription
    let se = _.get(userData, 'se', undefined);
    let te = _.get(userData, 'te', undefined);
    let days;
    if (se) {
      if (se * 1000 >= timeNow) {
        /** Note. Stripe in seconds not MS */
        days = Math.ceil((se * 1000 - timeNow) / 86400000);
      } else {
        days = 0;
      }
    } else if (te) {
      if (te >= timeNow) {
        days = Math.ceil((te - timeNow) / 86400000);
      } else {
        days = 0;
      }
    } else {
      days = '?';
    }
    setRemainingDays(days); //dispatch(setDaysRemaining(days)); /** Redux */
  };

  const setSubscribedRedux = (userData) => {
    /** NO LONGER REDUX */
    let se = _.get(userData, 'se', undefined);
    if (se > Date.now()) {
      setIsSubscriber(true); // dispatch(setSubscribed);  /** Redux */
      setRemaingDaysLabel('days left in your subscription period');
    }
    // this.props.setSubscribed(userData);
  };

  const showUserDetails = async (recursive = true) => {
    /** TODO: DO I NEED TO DO THIS EVERY TIME IF USING REDUX?????
     * Alternatively could I decrement the counter and assume
     * they're only using on 1 tab/ it wont be the end of the world if they refresh
     * */
    Fire.shared.getUserDetails().then((userData) => {
      if (!userData) {
        if (recursive) {
          setTimeout(function() {
            showUserDetails(false);
          }, 50); /** Retries Once */
        }
      } else {
        intercomTrackCredits(remainingCredits);
        /** THIS IS THE MESSIEST FUCKING LOGIC. */
        /** TODO: REMOVE AFTER ADDING REDUX */
        setFirebaseUserToStateRedux(userData); /** NO LONGER REDUX */
        if (userData) {
          setCreditsRedux(userData);
          /** NO LONGER REDUX */
          setDaysRemainingRedux(userData);
          /** NO LONGER REDUX */
          setSubscribedRedux(userData);
          /** NO LONGER REDUX */
          setsubscriptionDetailsHaveLoaded(true);
        }

        if (remainingCredits <= 0) {
          checkSubscription().then((data) => {
            /** Check if they have a subscription it's just not set in DB yet. */
            if (recursive) {
              /** Only retry once */
              showUserDetails(false);
            }
          });
        }
      }
    });
  };

  //TODO: THIS TYPE OF JSON COULD BE IN A SEPERATE CONFIG FILE THATS EASY TO UPDATE
  // TODO: Add replace feature where I can have a list of items to replace like the paragraph symbol. (replace after regex divide)

  const generators = {
    'value-propositions': {
      //GEN: hero Text and H2
      pageTitle: 'Alternative Value Propositions',
      mixpanelLegacyUnderScore: 'value_propositions',
      mixpanelLegacyCamel: 'valuePropositions',
      introWistia: '', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-product.svg'),
      prompts: [
        {
          name: 'action',
          title: '(What) Action or product:',
          // inputInstructions:'Enter your products name',
          placeHolder: 'Shopping at local retail hardware stores',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'value proposition',
          title: 'Value Proposition.  The Above Helps people...:',
          inputInstructions:
            'Finish this sentence.  The above action or product helps people...',
          placeHolder: 'find the right tool by handling multiple brands',
          textArea: false,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'Hardware Store',
          prompts: [
            'shopping at local retail hardware stores',
            'find the right tool by handling multiple brands',
          ],
        },
      ],
    },
    'what-who-why': {
      //GEN: hero Text and H2
      pageTitle: "Extract and Determine Your Project's What, Who & Why",
      mixpanelLegacyUnderScore: 'what_who_why',
      mixpanelLegacyCamel: 'whatWhoWhy',
      introWistia: '', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-product.svg'),
      prompts: [
        {
          name: 'company',
          title: 'Company or Project Name:',
          // inputInstructions:'Enter your products name',
          placeHolder: 'The Home Depot',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'description',
          title: 'Describe your company, we are:',
          inputInstructions: '1-2 sentences describing what you company does',
          placeHolder:
            'a massive retail hardware store for home improvement projects',
          textArea: true,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'WeWork',
          prompts: [
            'WeWork',
            'a co-working space company that rents flexible seating and private offices to companies of all sizes',
          ],
        },
      ],
    },
    'hero-message-and-subheadline': {
      //GEN:50 hero Text and H2
      pageTitle: 'Hero Message & Sub-Headline',
      mixpanelLegacyUnderScore: 'hero_message_and_subheadline',
      mixpanelLegacyCamel: 'heroMessageAndSubheadline',
      introWistia: '', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-product.svg'),
      prompts: [
        {
          name: 'company',
          title: 'Company or Project Name:',
          // inputInstructions:'Enter your products name',
          placeHolder: 'FCP Euro',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'what',
          title: '(What) What your company "Is":',
          inputInstructions:
            'A sentence describing the function of your company or product',
          placeHolder: 'we sell quality foreign replacement car parts',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'whom',
          title: '(Who) We serve:',
          inputInstructions: '1-2 personas you serve',
          placeHolder: 'auto shops and DIY car enthusiasts',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'why',
          title: '(Why) We exist because:',
          inputInstructions: '1-2 reasons why this exist in the world',
          placeHolder:
            'because anyone can fix their own car and auto shops cost a lot',
          textArea: false,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'Gary AI',
          prompts: [
            'Gary AI',
            'we create auto generated copy writing',
            'we serve marketers and entrepreneurs',
            'we exist because creativity takes time and experience. We outsource those tasks to a computer model.',
          ],
        },
      ],
    },
    'website-intro-paragraph': {
      //GEN: hero Text and H2
      pageTitle: 'Website Intro Paragraph',
      mixpanelLegacyUnderScore: 'website_intro_paragraph',
      mixpanelLegacyCamel: 'websiteIntroParagraph',
      introWistia: '', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-product.svg'),
      prompts: [
        {
          name: 'company',
          title: 'Company or Project Name:',
          // inputInstructions:'Enter your products name',
          placeHolder: 'The Home Depot',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'what',
          title: '(What) What your company "Is":',
          inputInstructions:
            'A sentence describing the function of your company or product',
          placeHolder:
            'a retail hardware store for DIY home improvement projects',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'whom',
          title: '(Who) We serve:',
          inputInstructions: '1-2 personas you serve',
          placeHolder: 'home owners and contractors',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'why',
          title: '(Why) We exist because:',
          inputInstructions: '1-2 reasons why this exist in the world',
          placeHolder:
            'because home owners and contractors need convenient access to large quantities of hardware in one place',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'hero message',
          title: 'Website Hero Message:',
          inputInstructions:
            'use the hero message generator, select or edit your favorite output.',
          placeHolder: 'Get the Right Tools for the Job',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'sub-headline',
          title: 'Sub-Headline:',
          inputInstructions:
            'use the hero message generator, select or edit your favorite sub-headline.',
          placeHolder:
            "With over 2,000 stores in the U.S., we're ready to help you get the right tools for every home improvement project.",
          textArea: false,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'The Home Depot',
          prompts: [
            'The Home Depot',
            'a retail hardware store for DIY home improvement projects',
            'home owners and contractors',
            'because home owners and contractors need convenient access to large quantities of hardware in one place',
            'Get the Right Tools for the Job',
            "With over 2,000 stores in the U.S., we're ready to help you get the right tools for every home improvement project.",
          ],
        },
      ],
    },
    'customer-welcome-email': {
      //GEN: hero Text and H2
      pageTitle: 'Customer Welcome Email',
      mixpanelLegacyUnderScore: 'customer_welcome_email',
      mixpanelLegacyCamel: 'customerWelcomeEmail',
      introWistia: '', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-product.svg'),
      prompts: [
        {
          name: 'company',
          title: 'Company or Project Name:',
          // inputInstructions:'Enter your products name',
          placeHolder: 'The Home Depot',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'what',
          title: '(What) What your company "Is":',
          inputInstructions:
            'A sentence describing the function of your company or product',
          placeHolder:
            'a retail hardware store for DIY home improvement projects',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'whom',
          title: '(Who) We serve:',
          inputInstructions: '1-2 personas you serve',
          placeHolder: 'home owners and contractors',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'why',
          title: '(Why) We exist because:',
          inputInstructions: '1-2 reasons why this exist in the world',
          placeHolder:
            'because home owners and contractors need convenient access to large quantities of hardware in one place',
          textArea: false,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'The Home Depot',
          prompts: [
            'The Home Depot',
            'a retail hardware store for DIY home improvement projects',
            'home owners and contractors',
            'because home owners and contractors need convenient access to large quantities of hardware in one place',
          ],
        },
      ],
    },

    'product-descriptions': {
      pageTitle: 'Generate Product Descriptions',
      mixpanelLegacyUnderScore: 'product_descriptions',
      mixpanelLegacyCamel: 'productDescriptions',
      introWistia: '5rpt6b3ddg', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-product.svg'),
      prompts: [
        {
          name: 'name',
          title: 'Product name:',
          // inputInstructions:'Enter your products name',
          placeHolder: 'Vanilla bean candle',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'features',
          title: 'Product features:',
          inputInstructions:
            'Enter a comma separated list of 2-5 specific product characteristics',
          placeHolder: 'vanilla candle, handmade, 25 hour burn time',
          textArea: false,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'Candle',
          prompts: [
            'vanilla bean candle',
            'vanilla candle, handmade, 25 hour burn time',
          ],
        },
        {
          name: 'Computer Monitor',
          prompts: ['computer monitor', '27 inches, 1080p, great picture'],
        },
        {
          name: 'Tax Software',
          prompts: ['personal tax software', 'fast, convenient, secure'],
        },
      ],
    },
    'facebook-ads': {
      pageTitle: 'Facebook Ads',
      mixpanelLegacyUnderScore: 'facebook_ads',
      mixpanelLegacyCamel: 'facebookAds',
      introWistia: 'vbqjzd128j', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-2.svg'),
      prompts: [
        {
          name: 'industry',
          title: 'What industry is your business?',
          inputInstructions: 'Enter your industry',
          placeHolder: 'beauty ecommerce website',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'product',
          title: 'What is this ad promoting?',
          inputInstructions: 'Enter a specific product name or promotion',
          placeHolder: 'anti-aging skin cream',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'company',
          title: "What's your domain?",
          // inputInstructions:'Base domain name & extension only',
          placeHolder: 'glow.com',
          textArea: false,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'GaryAI',
          prompts: [
            'AI copywriting website',
            'autogenerated Facebook ads',
            'garyai.com',
          ],
        },
        {
          name: "L'oreal Paris",
          prompts: [
            'beauty ecommerce website',
            'color vibrancy purple shampoo for color treated hair',
            'lorealparisusa.com',
          ],
        },
        {
          name: 'Ace Hardware',
          prompts: [
            'brick and mortar hardware store',
            'new cordless air compressor',
            'acehardware.com',
          ],
        },
      ],
    },
    pas: {
      pageTitle: 'Pain Agitate Solution Formula',
      mixpanelLegacyUnderScore: 'pain_agitate_solution',
      mixpanelLegacyCamel: 'painAgitateSolution',
      introWistia: 'wj8dimasb4', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-pas.svg'),
      prompts: [
        {
          name: 'company',
          title: 'Company or domain name:',
          // inputInstructions:'company\'s public name or base domain name & extension only',
          placeHolder: 'Hotels.com',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'description',
          title: 'Description:',
          inputInstructions:
            'Enter 1-2 sentences describing your product and the value it provides',
          placeHolder: 'A website that helps you find hotels faster and easier',
          textArea: true,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'Jeep',
          prompts: [
            'Jeep',
            'A SUV that helps you fit more family in the car and go on more adventures',
          ],
        },
        {
          name: 'GaryAI',
          prompts: [
            'GaryAI.com',
            'A website that helps you write better ad copy',
          ],
        },
        {
          name: 'HelloFresh',
          prompts: [
            'HelloFresh.com',
            'A website that helps families eat healthier by delivering nutritious meal kits every week',
          ],
        },
      ],
    },
    aida: {
      pageTitle: 'Attention Interest Desire Action Formula',
      mixpanelLegacyUnderScore: 'attention_interest_desire_action',
      mixpanelLegacyCamel: 'attentionInterestDesireAction',
      introWistia: 'wj8dimasb4', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-aida.svg'),
      prompts: [
        {
          name: 'company',
          title: 'Company or domain name:',
          // inputInstructions:'company\'s public name or base domain name & extension only',
          placeHolder: 'Hotels.com',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'description',
          title: 'Description:',
          inputInstructions:
            'Enter 1-2 sentences describing your product and the value it provides',
          placeHolder: 'A website that helps you find hotels faster and easier',
          textArea: true,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        // {
        //   name: 'Colgate',
        //   prompts: ['Colgate', 'A teeth whitener that turns yellow teeth into pearly whites within days.']
        // },
        // {
        //   name: 'GaryAI',
        //   prompts: ['GaryAI.com', 'A website that uses AI to help you write better ad copy']
        // },
        // {
        //   name: 'AdAgency.com',
        //   prompts: ['AdAgency.com', 'A digital marketing agency that helps restaurants generate leads with Facebook ads.']
        // }
      ],
    },
    headlines: {
      pageTitle: 'Generate Headlines',
      mixpanelLegacyUnderScore: 'headlines',
      mixpanelLegacyCamel: 'headlines',
      introWistia: 'vbqjzd128j', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-2.svg'),
      prompts: [
        {
          name: 'product',
          title: 'What kind of product are you promoting?',
          inputInstructions:
            'Enter 1 sentence describing your product and the value it provides',
          placeHolder: 'Website building tool, Dog brush to remove hair, etc',
          textArea: false,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'Golf Clubs',
          prompts: ['newest golf clubs'],
        },
        {
          name: 'Desk Chair',
          prompts: ['comfortable office desk chair'],
        },
        {
          name: 'No Code Tool',
          prompts: ['a no code website building tool'],
        },
      ],
    },
    'blog-post-title-ideas': {
      pageTitle: 'Generate Blog Post Titles',
      mixpanelLegacyUnderScore: 'blog_post_titles',
      mixpanelLegacyCamel: 'blogPostTitles',
      introWistia: 'vbqjzd128j', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-2.svg'),
      prompts: [
        {
          name: 'keyword',
          title: 'What is your blog post about?',
          placeHolder: 'keto diet recipes',
          textArea: false,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'Start a business',
          prompts: ['Start a business'],
        },
        {
          name: 'Facebook marketing tips',
          prompts: ['Facebook marketing tips'],
        },
        {
          name: 'Summer dresses',
          prompts: ['Summer dresses'],
        },
      ],
    },
    'blog-post-topic-ideas': {
      pageTitle: 'Generate Blog Post Ideas',
      mixpanelLegacyUnderScore: 'blog_post_topic_ideas',
      mixpanelLegacyCamel: 'blogPostTopicIdeas',
      introWistia: 'vbqjzd128j', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-2.svg'),
      prompts: [
        {
          name: 'keyword',
          title: 'What is something your customers would search?',
          placeHolder: 'keto supplements',
          textArea: false,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'Start a business',
          prompts: ['Start a business'],
        },
        {
          name: 'Facebook marketing tips',
          prompts: ['Facebook marketing tips'],
        },
        {
          name: 'Summer dresses',
          prompts: ['Summer dresses'],
        },
      ],
    },
    'viral-video-ideas': {
      pageTitle: 'Generate Viral Video Ideas',
      mixpanelLegacyUnderScore: 'viral_video_ideas',
      mixpanelLegacyCamel: 'viralVideoIdeas',
      introWistia: 'vbqjzd128j', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-2.svg'),
      prompts: [
        {
          name: 'audience',
          title: 'Who is your audience?',
          placeHolder: 'business owners, football players, etc',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'topic',
          title: 'Whats your audiences desired result?',
          placeHolder: 'grow their business, throw the ball further, etc',
          textArea: false,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'Fortnite',
          prompts: ['gamers', 'get better at fortnite'],
        },
        {
          name: 'Starbucks',
          prompts: ['working professionals', 'find a new coffee flavor'],
        },
      ],
    },
    'keyword-ideas': {
      pageTitle: 'Generate Keyword Ideas',
      mixpanelLegacyUnderScore: 'keyword_ideas',
      mixpanelLegacyCamel: 'keywordIdeas',
      introWistia: 'vbqjzd128j', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-2.svg'),
      prompts: [
        {
          name: 'keyword',
          title: 'What is your target keyword?',
          placeHolder: 'keto diet, waterproof headphones, etc',
          textArea: false,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'Stop hair loss',
          prompts: ['Stop hair loss'],
        },
        {
          name: 'Car insurance chicago',
          prompts: ['Car insurance chicago'],
        },
      ],
    },
    'viral-contest-ideas': {
      pageTitle: 'Generate Viral Contest Ideas',
      mixpanelLegacyUnderScore: 'contest_ideas',
      mixpanelLegacyCamel: 'contestIdeas',
      introWistia: 'vbqjzd128j', //avk9twrrbn
      introYoutube: '', //XV8uxCgKzb0
      showHelperImage: require('../images/empty-2.svg'),
      prompts: [
        {
          name: 'company',
          title: 'Company name:',
          placeHolder: 'Apple',
          textArea: false,
          defaultValue: '',
        },
        {
          name: 'product',
          title: 'What product do you want to promote?',
          placeHolder: 'Wireless headphones',
          textArea: false,
          defaultValue: '',
        },
      ],
      examples: [
        /** EXAMPLE NAME, THEN Prompt Values In Order */
        {
          name: 'Apple',
          prompts: ['Apple', 'Wireless headphones'],
        },
        {
          name: 'GaryAI',
          prompts: ['GaryAI', 'AI powered copywriting platform'],
        },
      ],
    },
    loading: {
      pageTitle: 'Loading...',
      prompts: [],
      examples: [],
    },
    notFound: {
      pageTitle: 'Generator Not Found',
      prompts: [],
      examples: [],
    },
    empty: {
      pageTitle: 'Please Specify A Generator',
      prompts: [],
      examples: [],
    },
    // 'pas':{
    //   'prompts':['company','description']
    // },
    // 'facebook':{
    //   'prompts':['company','description']
    // },
    // 'productDescriptions':{
    //   'prompts':['company','description']
    // },
  };

  //OLD VARIABLES
  // const [productName, setproductName] = useState('');
  // const [productDetails, setproductDetails] = useState('');
  const [copyResults, setcopyResults] = useState([]);
  const [hasResults, sethasResults] = useState(
    false
  ); /** TODO: could delete the sethasResults everywhere as it's fed into another funciton and not used */

  const [loading, setloading] = useState(false);
  const [displayMessage, setdisplayMessage] = useState('');
  const [likedItems, setlikedItems] = useState([]);
  const [showUpgradeModal, setshowUpgradeModal] = useState(false);

  // useEffect(() => MixpanelSuper.startGenerator('product_descriptions'), []);
  let handleLoad = (params) => {
    let gen = _.get(
      params,
      'generator',
      'empty'
    ); /** TODO: BUG: Route does not work if value not set - does not default to empty */
    if (Object.keys(generators).includes(gen)) {
      setGenerator(gen);
      setcopyResults([]);
      setPageTitle(generators[gen].pageTitle);
      setGeneratorExists(true);
      // setGeneratorExamples(_.get(generators,`${gen}`,[]));
      setShowVideo(false);
      setShowHelper(true);
    } else {
      setGenerator('notFound');
      setPageTitle('Generator Not Found');
    }

    /** Break Through: You cannot immediatly use a useState variable that you just changed. */
    setCopyTypeUnderscore(gen);
    if (generators[gen].mixpanelLegacyUnderScore !== undefined) {
      setCopyTypeUnderscore(generators[gen].mixpanelLegacyUnderScore);
    } else if (gen !== 'loading') {
      setCopyTypeUnderscore(gen);
    }

    setCopyTypeCamelCase(gen);
    if (generators[gen].mixpanelLegacyCamel !== undefined) {
      setCopyTypeCamelCase(generators[gen].mixpanelLegacyCamel);
    } else if (gen !== 'loading') {
      setCopyTypeCamelCase(gen);
    }
  };

  useEffect(() => {
    console.log('Generate UseEffect');

    // setTimeout(() => {
    //   setRemainingCredits(50);
    // }, 3000);

    firebase.auth().onAuthStateChanged(function(user) {
      console.log('changed');
      if (user) {
        console.log('generate useEffect, have user details');

        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then(function(doc) {
            if (doc.exists) {
              let data = doc.data();
              console.log('User Document data:', data);
              setUserDetails(data);
            }
          });
      }
    });

    MixpanelSuper.trackScreenView('generate');

    // old logic
    Fire.shared.checkAndWaitForFirebaseLoad().then(() => {
      if (generator != _.get(params, 'generator', 'none')) {
        console.log('use effect loaded');
        handleLoad(params);
        showUserDetails();
      }
    });
  }, []);

  const saveResultsAndHandleResponse = (data, requestPrompts) => {
    // this function handles UI to display results, and saves all results to firebase.
    if (typeof data == 'string') {
      data = [data];
    }

    showUserDetails(); //TODO: ****************** USE DATA RETURNED FROM API INSTEAD OF CALLING FIREBASE AGAIN.
    /** NOTE: Data Input Should Be An Array */
    handleCopyGenerationResponse(
      data,
      copyResults,
      sethasResults,
      setcopyResults,
      setloading
    );

    /** FUTURE: Wouldnt it be better to save to firebase from back end and send path to firebase object so they can look it up. Google does this. */
    // try {
    //   const resultToSave = {
    //     type: copyTypeCamelCase,
    //     value: data,
    //     prompt: JSON.stringify(requestPrompts)
    //   }
    //   return new Promise((resolve, reject) => {
    //     Fire.shared.addCopyResult(resultToSave)
    //     .then(res => {
    //         console.log('Saved api results to db');
    //     })
    //   })
    // }catch(e){
    //   console.log('Error occured with adding to firebase in saveResultsAndHandleResponse(). Details: ', e);
    // }
  };

  const handleCopyGenerationV2 = async (requestDetails) => {
    const { firebaseJwtToken, requestBody, requestPrompts } = requestDetails;

    console.log('handleCopyGenerationV2. requestPrompts: ', requestPrompts);
    // console.log('POST BODY', requestBody);
    // return;
    // make request to api
    let generateEndpoint = `${apiAddress}/api/v2/generate`;
    const response = await fetch(generateEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${_.get(config, 'backend.bearerToken')}`,
        firebaseidtoken: firebaseJwtToken,
      },
      body: JSON.stringify(requestBody),
    });
    console.log('response', response);
    // response.status === 200 ? console.log('success') : console.log('error');
    // response.status 401 is unauthorized -- and data.message will include details. (Invalid bearer creendials)

    if (response.status === 200) {
      const resp = await response.json();
      console.log('resp', resp);
      let copyArray = resp.data.data;
      console.log('copyArray', copyArray);
      // set copy results.
      saveResultsAndHandleResponse(copyArray, requestPrompts);

      if (resp.promptScore && resp.promptScore.status === 'success') {
        // console.log('resp.promptScore', resp.promptScore);
        let promptScoreNumber = resp.promptScore.result;
        console.log('promptScoreNumber >>> ', promptScoreNumber);
        if (promptScoreNumber <= 5) {
          console.log('BAD PROMPT!!!!');
          setFieldToImprove(resp.promptScore.field);
          // show prompt improvement suggestion
          setShowPromptImprovementSuggestion(true);
          MixpanelSuper.promptSuggestionSeen(generator);
        }
      }
    } else {
      // handle error
      console.log('Response.status != 200... handle error');
      console.log('response statusText', response.statusText);
      console.log('response status', response.status);
      // if no valid subscription...
      if (response.status === 402) {
        // toast.error(`You need an active trial or subscription to do that`);
        toast.error(
          (t) => (
            <span>
              Oops. You will need to pick a plan or start a free trial do that.
              Please:
              <br />
              <br />
              <center>
                <Button
                  theme="accent"
                  onClick={() =>
                    (window.location.href =
                      'https://app.garyai.com/pick-a-plan')
                  }
                >
                  Go to plans
                </Button>
              </center>
            </span>
          ),
          { duration: 9000 }
        );
      } else {
        toast.error(`Error ${response.status}: ` + response.statusText);
      }
      // alert(`Error ${response.status}: ` + response.statusText);
      setloading(false);
    }
  };

  const generateCopyV2 = async () => {
    // this function handles the generation of the copy.
    let currentGenerator = generators[generator];
    let prompts = currentGenerator.prompts;
    let promptsLength = prompts.length;
    let requestPrompts = {};
    for (let i = 0; i < promptsLength; i++) {
      let inputValue = document.getElementById('inputParameter' + i).value; //NOTE: Hope this works for both text area and text inputs
      if (inputValue === '') {
        // window.alert(`Input "${prompts.title}" Must Not Be Empty.`); TODO: prompt.title comes up as undefined
        window.alert(`Form Fields Must Not Be Empty.`);
        MixpanelSuper.noInputsError(copyTypeUnderscore);
        return;
      }
      // valuesArray.push(inputValue);
      requestPrompts[prompts[i].name] = inputValue;
    }
    setLastGeneratedPrompts(requestPrompts);

    MixpanelSuper.generateSnippet(copyTypeUnderscore); //NOTE: Should still be the same if on the same generator page for generate more like this.
    console.log('make new request to', apiAddress);
    setShowHelper(false);
    setShowVideo(false);
    setloading(true);

    // get JWT token from firebase. pass true to force refresh.
    const firebaseJwtToken = await firebase.auth().currentUser.getIdToken();
    const userId = await firebase.auth().currentUser.uid;
    const userDocRef = await firebase
      .firestore()
      .collection('users')
      .doc(userId);

    // get user data from firebase
    const userDoc = await userDocRef.get();
    const userData = userDoc.data();
    console.log('userData', userData);
    console.log('firebaseJwtToken', firebaseJwtToken);

    const requestBody = {
      generator: generator,
      prompts: requestPrompts,
      userId: userId,
      userData: userData,
    };

    console.log('Request body:', requestBody);

    if (userDetails.forceCc) {
      console.log('This is a user that signed up with forceCc.. Use API v2');

      try {
        await handleCopyGenerationV2({
          requestBody,
          firebaseJwtToken,
          requestPrompts,
        });
      } catch (e) {
        console.log('e', e);
        toast.error(`Error: ${e.message}`);
        setloading(false);
      }

      // send slack notifications
      try {
        let promptString = JSON.stringify(requestBody.prompts);
        var newchar = "'";
        promptString = promptString.split('"').join(newchar);

        let linkToFirebase =
          'https://console.firebase.google.com/project/copywriting-db92b/firestore/data/~2Fusers~2F' +
          userId;
        let message =
          userData.e +
          ' just generated ' +
          requestBody.generator +
          ' content with prompt below (' +
          '<' +
          linkToFirebase +
          '|details>' +
          '): ```' +
          promptString +
          '```';

        console.log('slackmessage sending:', message);
        await sendSlackNotificationForCopyGeneration(message);
      } catch (e) {
        console.log(
          'Could not send a slack notification about this generation because ',
          e
        );
      }
    } else {
      console.log('This is an old user. generate copy with old API');
      try {
        generateCopy();
      } catch (e) {
        console.log('caught error', e);
        toast.error('Error' + e.message);
        setloading(false);
      }
    }
  };

  // old / deprecate this eventually
  const generateCopy = () => {
    //Is this being set as loading for some reason????
    // setCopyTypeUnderscore(generator);
    // if(generators[generator].mixpanelLegacyUnderScore != undefined){
    //   setCopyTypeUnderscore(generators[generator].mixpanelLegacyUnderScore);
    // }else if(generator != 'loading'){
    //   setCopyTypeUnderscore(generator);
    // }
    //
    // setCopyTypeCamelCase(generator);
    // if(generators[generator].mixpanelLegacyCamel != undefined){
    //   setCopyTypeCamelCase(generators[generator].mixpanelLegacyCamel);
    // }else if(generator != 'loading'){
    //   setCopyTypeCamelCase(generator);
    // }

    // get all the variable names by id in array and match to variable name in object
    let currentGenerator = generators[generator];
    let prompts = currentGenerator.prompts;
    let promptsLength = prompts.length;
    // let valuesArray = [];
    let requestPrompts = {};
    for (let i = 0; i < promptsLength; i++) {
      let inputValue = document.getElementById('inputParameter' + i).value; //NOTE: Hope this works for both text area and text inputs
      if (inputValue === '') {
        // window.alert(`Input "${prompts.title}" Must Not Be Empty.`); TODO: prompt.title comes up as undefined
        window.alert(`Form Fields Must Not Be Empty.`);
        MixpanelSuper.noInputsError(copyTypeUnderscore);
        return;
      }
      // valuesArray.push(inputValue);
      requestPrompts[prompts[i].name] = inputValue;
    }
    setLastGeneratedPrompts(requestPrompts);
    let requestBody = {
      generator: generator,
      prompts: requestPrompts,
    };

    console.log('request body:', requestBody);
    MixpanelSuper.generateSnippet(copyTypeUnderscore); //NOTE: Should still be the same if on the same generator page for generate more like this.

    console.log('make new request to', apiAddress);
    setShowHelper(false);
    setShowVideo(false);
    setloading(true);
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function(idToken) {
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${_.get(config, 'backend.bearerToken')}`,
            firebaseidtoken: idToken,
          },
          body: JSON.stringify(requestBody),
        };

        fetch(
          `${apiAddress}/v1/garys-innermost-thoughts/generate`,
          requestOptions
        )
          .then((response) => {
            // console.log("response", response);
            try {
              // double strings in json stringify is causing issues
              let promptString = JSON.stringify(requestBody.prompts);
              var newchar = "'";
              promptString = promptString.split('"').join(newchar);
              const slackMessage = `[${remainingCredits},${remainingDays}] ${_.get(
                firebaseUserData,
                'fn',
                'undefined'
              )} ${_.get(firebaseUserData, 'ln', 'undefined')} ( ${_.get(
                firebaseUserData,
                'e',
                'undefined'
              )} ) just generated copy for ${
                requestBody.generator
              } with prompt: ${promptString}`; // 'useremail just generated copyType. prompt ='
              console.log('slackmessage sending:', slackMessage);
              sendSlackNotificationForCopyGeneration(slackMessage);
            } catch (e) {
              console.log(
                'Could not send a slack notification about this generation because ',
                e
              );
            }
            if (response.status === 406) {
              window.alert(
                "Your input text seems to contain sensitive, politically charged, or vulgar content.  Please rephrase your inputs.  If you believe you've received this message in error please screen shot your prompts and contact our team via the feedback link."
              );
              window.location.reload();
            }

            if (response.status === 500) {
              window.alert("Sorry we can't process your request right now.");
              window.location.reload();
            }
            if (response.status === 402) {
              setshowUpgradeModal(true);
              setloading(false);
              MixpanelSuper.viewPaymentPrompt(copyTypeCamelCase);
              // Break next promise
              return Promise.reject('Payment required to continue');
            }
            return response.json();
          })
          // expecting array in next function, so parsing object and turning into array
          .then((data) => {
            console.log('data object', data);
            if (data.code && data.code === 400) {
              toast.error(
                'Sorry, request failed because you are not a customer. Please pick a plan'
              );
              setloading(false);
              return null;
            }
            console.log('data here', data.data);
            /** TODO: Use data responses to update global variables like remaining credits.  Can I pass that to a wrapping class? (up to main nav) */
            saveResultsAndHandleResponse(data.data, requestPrompts);
          })
          .catch((error) => {
            console.log('Error: ', error);
            setloading(false);
            // Dont show error modal if its a server error saying payment required - seperate modal for that
            if (
              !error
                .toString()
                .toLowerCase()
                .includes('payment')
            ) {
              setShowErrorModal(true);
              setShowErrorModalText(error.toString());
            }
          });
      })
      .catch((e) => {
        console.log('Error occured while accessing current firebase user', e);
      });
  };

  const generateCopyWithDefaults = (defaultPrompts) => {
    defaultPrompts = JSON.parse(defaultPrompts);

    let currentGenerator = generators[generator];
    let prompts = currentGenerator.prompts;
    let promptsLength = prompts.length;

    for (let i = 0; i < promptsLength; i++) {
      document.getElementById('inputParameter' + i).value =
        defaultPrompts[prompts[i].name]; //Assuming using same generator
    }
    // generateCopy();
    generateCopyV2();
  };

  /** For saving results to saved table.  Eventually we will have 1 table "results" and it will be updates with tags like "saved" "rating", and it already has a field for "original" response */
  const saveResultToFirebase = (
    id,
    index,
    copyTypeUnderscoreVar,
    copyTypeCamelCaseVar,
    lastGeneratedPromptsVar
  ) => {
    //TODO: RETURN SAVED ID AND SAVE SOMEWHERE IN THE DOM SO YOU CAN SAVE NEW VALUES TO IT/ UPDATE
    let output = document.getElementById(id).value;
    MixpanelSuper.saveSnippet(copyTypeUnderscoreVar);
    console.log('saveResultToFirebase');
    // setsaveCopyResultInProgress(true)

    if (Array.isArray(output)) {
      console.log('This is an array');
      const stringifyOutput = output[0];
      output = stringifyOutput;
    }
    const resultToSave = {
      type: copyTypeCamelCaseVar,
      value: output,
      prompt: JSON.stringify(lastGeneratedPromptsVar),
    };

    return new Promise((resolve, reject) => {
      Fire.shared.addSavedCopyResult(resultToSave).then((res) => {
        console.log('res >> ', res);
        // setsaveCopyResultInProgress(false)
        setdisplayMessage('Added to your saved items');
        // Push this index item to an array, so we can visually display liked items already
        if (likedItems.length === 0) {
          setlikedItems([index]);
        } else {
          setlikedItems((likedItems) => [...likedItems, index]);
        }

        setTimeout(() => {
          setdisplayMessage(null);
        }, 3000);
      });
    });
  };

  /** STAR RATINGS */
  const saveStars = (output, index, starCount) => {
    console.log('applied ' + starCount + ' to id ' + index);
    let changeStars = [...localStars];
    changeStars[index] = starCount;
    setLocalStars(changeStars);

    return new Promise((resolve, reject) => {
      Fire.shared
        .saveStarRating(output.id, starCount, 'results')
        .finally(() => {
          resolve();
        });
    });
  };

  const StarRating = ({ rating, item, idx }) => {
    // console.log(rating)
    // console.log(rating === 5)

    if (rating === 5) {
      return (
        <div className="rating">
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 5)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 4)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 3)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 2)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 1)}
          >
            ⭐
          </span>
        </div>
      );
    } else if (rating === 4) {
      return (
        <div className="rating">
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 5)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 4)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 3)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 2)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 1)}
          >
            ⭐
          </span>
        </div>
      );
    } else if (rating === 3) {
      return (
        <div className="rating">
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 5)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 4)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 3)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 2)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 1)}
          >
            ⭐
          </span>
        </div>
      );
    } else if (rating === 2) {
      return (
        <div className="rating">
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 5)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 4)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 3)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 2)}
          >
            ⭐
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 1)}
          >
            ⭐
          </span>
        </div>
      );
    } else if (rating === 1) {
      return (
        <div className="rating">
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 5)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 4)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 3)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 2)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Gold Star"
            onClick={() => saveStars(item, idx, 1)}
          >
            ⭐
          </span>
        </div>
      );
    } else {
      return (
        <div className="rating">
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 5)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 4)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 3)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 2)}
          >
            ☆
          </span>
          <span
            role="img"
            aria-label="Grey Star"
            onClick={() => saveStars(item, idx, 1)}
          >
            ☆
          </span>
        </div>
      );
    }
  };

  const SetVideoTrue = () => {
    setShowVideo(true);
    setShowHelper(false);
  };

  const SetVideoFalse = () => {
    setShowVideo(false);
    setShowHelper(true);
  };

  return (
    <Container fluid className="main-content-container px-4">
      <div>
        <Toaster />
      </div>

      {displayMessage && (
        <Container
          fluid
          className="px-0"
          style={{
            zIndex: 99,
            position: 'fixed',
            right: '0px',
            width: '300px',
          }}
        >
          <Alert className="mb-0">
            <i className="fa fa-info mx-2"></i> {displayMessage}
          </Alert>
        </Container>
      )}

      <Modal open={showUpgradeModal}>
        <ModalHeader>Please Upgrade Your Account</ModalHeader>
        <ModalBody>
          <span>
            <span role="img" aria-label="hand waving">
              👋
            </span>{' '}
            &nbsp; Hey there! We really appreciate you using our product, but in
            order to continue usage you need to select a plan. Thanks in advance
            for the support!
          </span>
          <br></br>
          <br></br>
          {remainingCredits > 40 && (
            <span>
              <span role="img" aria-label="clock emoji">
                ⏰
              </span>{' '}
              &nbsp; Didn't have time to use your credits? Contact us about via
              intercom in the bottom right about adding 2 days to your free
              trial.<br></br>
              <br></br>
            </span>
          )}
          <Button
            onClick={() => {
              window.location.href = '/pricing';
            }}
          >
            Select a plan
          </Button>
        </ModalBody>
      </Modal>

      <Modal open={showErrorModal}>
        <ModalHeader>Oops. Something went wrong.</ModalHeader>
        <ModalBody>
          <span>
            Error Occured. Please try again later or contact support if this
            issue persists. Message: {showErrorModalText}
          </span>
          <br></br>
          <Button
            style={{ marginTop: 20 }}
            onClick={() => {
              setShowErrorModal(false);
            }}
          >
            Ok
          </Button>
        </ModalBody>
      </Modal>

      <Row noGutters className="page-header py-4">
        <PageTitle
          title={pageTitle}
          subtitle="Create Content"
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
        <span style={{ display: 'none' }}>
          {copyTypeUnderscore}
          {copyTypeCamelCase}doesnt update unless in the dom
        </span>
      </Row>
      {generatorExists && ( //generatorExists
        <div>
          {/* Display upgrade prompt ONLY after firebase loaded subscription details and they DONT have a subscription already
          {subscriptionDetailsHaveLoaded && ( // subscriptionDetailsHaveLoaded
            <div className="upgradeWidget fade-in">
              <p className="text-muted">
                You have {remainingCredits} credits left, and {remainingDays}{' '}
                {remaingDaysLabel}
              </p>
              <p
                className="text-muted"
                onClick={() =>
                  copyLinkToClipboard(
                    `http://app.garyai.com/register?utm_source=garyai&utm_campaign=giveget&rid=${Fire.shared.uid}`
                  )
                }
              >
                Give 25 => Get 25{' '}
                <i
                  style={{ color: 'blue', cursor: 'pointer' }}
                  className="material-icons mr-1"
                >
                  content_copy
                </i>
              </p>
              {!isSubscriber && (
                <a
                  className="upgradeButton"
                  onClick={() => {
                    window.open('/pricing?utm_source=upgradeWidget', '_blank');
                  }}
                >
                  Upgrade now
                </a>
              )}
            </div>
          )} */}
        </div>
      )}
      <Row>
        <Col lg="4" className="pb-5">
          {/* Inputs to generate copy */}

          {generatorExists && (
            <Card
              small
              style={{
                marginBottom: '10px',
                boxShadow: '0px 0px 10px #e6e6e6',
              }}
            >
              {/* <PromptInputForm/> */}
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form>
                        {generators[generator].prompts.map(
                          (generatorPrompt, index) => (
                            <FormGroup key={'inputParameterFormGroup' + index}>
                              <label htmlFor="type">
                                {generatorPrompt.title}
                              </label>
                              {generatorPrompt.inputInstructions && (
                                <span
                                  style={{ fontSize: '12px', color: '#b8b8b8' }}
                                >
                                  <br />
                                  {generatorPrompt.inputInstructions}
                                </span>
                              )}
                              {generatorPrompt.textArea && (
                                <FormTextarea
                                  id={'inputParameter' + index}
                                  // type="text"
                                  rows="3"
                                  defaultValue={generatorPrompt.defaultValue}
                                  placeholder={generatorPrompt.placeHolder}
                                />
                              )}
                              {!generatorPrompt.textArea && (
                                <FormInput
                                  id={'inputParameter' + index}
                                  type="text"
                                  defaultValue={generatorPrompt.defaultValue}
                                  placeholder={generatorPrompt.placeHolder}
                                />
                              )}
                            </FormGroup>
                          )
                        )}
                      </Form>
                      {generators[generator].examples.length >
                        0 /** Examples not empty */ && (
                        <em>
                          Example:
                          {generators[generator].examples.map(
                            (generatorExamples, index) => (
                              <span>
                                <a
                                  style={{
                                    paddingLeft: '4px',
                                    fontStyle: 'italic',
                                    whiteSpace: 'nowrap',
                                  }}
                                  href="#" /** TODO: Could make this into a button with the same styling */
                                  onClick={() =>
                                    fillPromptWithValues(
                                      generatorExamples.prompts
                                    )
                                  }
                                >
                                  {generatorExamples.name}
                                </a>
                                {index + 1 <
                                  generators[generator].examples.length && (
                                  <span>,</span>
                                ) /** If not end of list */}
                              </span>
                            )
                          )}
                          {/*<button style={{color:"#2b7bff", float:"left", fontStyle:"italic", cursor:"pointer", border:"none", backgroundColor: 'rgba(52, 52, 52, 0.0)' }} onClick={fillPromptWithValues('AdAgency.com', 'A digital marketing agency that helps restaurants generate leads with Facebook ads.')}>Contact support</button>,*/}
                          {/*<button style={{color:"#2b7bff", float:"left", fontStyle:"italic", cursor:"pointer", border:"none", backgroundColor: 'rgba(52, 52, 52, 0.0)' }} onClick={fillPromptWithValues('AdAgency.com', 'A digital marketing agency that helps restaurants generate leads with Facebook ads.')}>Contact support</button>*/}
                        </em>
                      )}
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
              {!loading && remainingCredits > 0 && (
                <Button
                  theme="accent"
                  className="fade-in"
                  onClick={generateCopyV2}
                >
                  Generate
                </Button>
              )}
              {/* {!loading && remainingCredits > 0 && (
                <Button
                  theme="accent"
                  className="fade-in"
                  onClick={generateCopy}
                >
                  Generate v1
                </Button>
              )} */}
              {loading && remainingCredits > 0 && (
                <Button theme="secondary" className="disabled">
                  Generate
                </Button>
              )}
              {remainingCredits <= 0 && (
                <div style={{ width: '100%' }}>
                  <Button
                    theme="info"
                    style={{ width: '100%' }}
                    onClick={() => {
                      window.location.href = '/settings?upgradeInfo=true';
                    }}
                  >
                    <i style={{ color: '#FFFFFF' }} className="material-icons">
                      grade
                    </i>{' '}
                    Upgrade to Generate
                  </Button>
                  <center>
                    <em>
                      Please upgrade. Youve used all of your credits allowed for
                      your plan
                    </em>
                  </center>
                </div>
              )}
            </Card>
          )}

          {showProUpsell && userDetails && userDetails.smu <= 776 && (
            <div
              style={{
                // textAlign: 'center',
                marginTop: 20,
                color: '#93A2BF',
                // maxWidth: 300,
                padding: 12,
                opacity: 0.8,
                backgroundColor: '#FAFBFD',
                borderRadius: '4px',
                border: '1px solid #ECF0F5',
              }}
            >
              <span
                style={{
                  backgroundColor: 'white',
                  cursor: 'pointer',
                  borderRadius: 20,
                  position: 'absolute',
                  // top: 10,
                  right: 30,
                }}
                onClick={() => {
                  console.log('clicked hide pro upsell!');
                  setShowProUpsell(false);
                }}
              >
                x
              </span>
              <h6>
                Write 5x faster with <span>Docs</span> (Pro)
              </h6>
              <p>
                - Google docs-like editor for long-form content (blog posts){' '}
                <br></br>- Super Fast Insert <br></br>- Unlimited credits
                <br></br>
                {/* - Free Gift: 7 Custom Templates */}
              </p>
              <Button
                theme={'white'}
                onClick={() => {
                  window.location.href = '/settings?upgradeInfo=true';
                }}
              >
                Learn more
              </Button>
            </div>
          )}
        </Col>

        {!showVideo && copyResults.length == 0 && showHelper && (
          <Col lg="8" className="pb-5">
            {/* Inputs to generate copy */}
            <div style={{ textAlign: 'center' }}>
              <img
                className="pb-4"
                alt=""
                src={
                  generators[generator].showHelperImage
                    ? generators[generator].showHelperImage
                    : require('../images/empty-2.svg')
                }
                width="400em"
              ></img>
              <Col>
                <h5>Start by learning how Gary thinks</h5>
                <p>
                  Gary expects a specific format when reading your inputs.{' '}
                  <br />
                  Watch this video to ensure the most intelligent results.
                </p>
                <Button
                  outline
                  className="readable"
                  theme="dark"
                  onClick={SetVideoTrue}
                >
                  <i className="fa fa-play" aria-hidden="true"></i> Play Video
                </Button>
              </Col>
            </div>
          </Col>
        )}

        <Col lg="8">
          {/* TODO FUTURE: Could add a loop and array and have multiple

      s load */}
          {showVideo &&
            copyResults.length == 0 &&
            (generators[generator].introWistia ||
              generators[generator].introYoutube) && (
              <Card small className="mb-4">
                {/*WISTIA JS*/}
                {/*<script src="//fast.wistia.com/embed/medias/j38ihh83m5.jsonp" async></script>*/}
                {/*<script src="//fast.wistia.com/assets/external/E-v1.js" async></script>*/}
                {/*<div className="wistia_embed wistia_async_j38ihh83m5" style={{width:"100%", aspectRatio: "620/349", backgroundColor:"pink", margin:"auto"}}>&nbsp;</div>*/}

                {/*YOUTUBE*/}
                {/*<iframe style={{width:"100%", aspectRatio: "620/349", backgroundColor:"#2b7bff", margin:"auto"}}*/}
                {/*src="https://www.youtube.com/embed/tgbNymZ7vqY">*/}
                {/*</iframe>*/}
                {generators[generator].introYoutube && (
                  <span className="">
                    <Button
                      outline
                      style={{ align: 'left' }}
                      className="readable pb-2"
                      theme="light"
                      onClick={SetVideoFalse}
                    >
                      <i className="fas fa-times"></i>
                    </Button>
                    <iframe
                      style={{
                        width: '100%',
                        aspectRatio: '620/349',
                        backgroundColor: '#f2f4f6',
                        margin: 'auto',
                      }}
                      src={`https://www.youtube.com/embed/${generators[generator].introYoutube}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </span>
                )}

                {/*<iframe width="560" height="315" src="https://www.youtube.com/embed/XV8uxCgKzb0" frameBorder="0"*/}
                {/*        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
                {/*        allowFullScreen></iframe>*/}

                {/*WISTIA FALL BACK*/}
                {generators[generator].introWistia && (
                  <span>
                    <Button
                      outline
                      style={{ align: 'left' }}
                      className="readable pb-2"
                      theme="light"
                      onClick={SetVideoFalse}
                    >
                      <i className="fas fa-times"></i>
                    </Button>
                    <iframe
                      src={`//fast.wistia.net/embed/iframe/${generators[generator].introWistia}`}
                      allowTransparency="true"
                      frameBorder="0"
                      scrolling="no"
                      className="wistia_embed"
                      name="wistia_embed"
                      allowFullScreen
                      mozallowfullscreen
                      webkitallowfullscreen
                      oallowfullscreen
                      msallowfullscreen
                      style={{
                        width: '100%',
                        height: '37em',
                        backgroundColor: '#f2f4f6',
                        margin: 'auto',
                      }}
                    ></iframe>{' '}
                    {/*aspectRatio: "620/349",*/}
                    <script
                      src="//fast.wistia.net/assets/external/E-v1.js"
                      async
                    ></script>
                  </span>
                )}
              </Card>
            )}

          {/* Description builder here */}
          <Modal
            open={showPromptBuilderModal}
            style={{ backgroundColor: '#F4F5F7' }}
          >
            <ModalHeader>Description Builder</ModalHeader>
            <ModalBody style={{ backgroundColor: '#F4F5F7' }}>
              <span style={{ marginBottom: 20 }}>
                Gary gives better results when you provide a good description.
                Let us help you build one by answer the following....
              </span>
              <br></br>
              <Form style={{ marginTop: 20 }}>
                {' '}
                <FormGroup>
                  <label for="prompt">What is your product?</label>
                  <FormInput
                    type="text"
                    name="what"
                    onChange={(e) => {
                      setPromptBuilderValues({
                        ...promptBuilderValues,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                  <p
                    style={{ color: '#667085', paddingTop: 5, paddingLeft: 3 }}
                  >
                    Ex: A website, a marketing agency, an ebook, etc.
                  </p>
                </FormGroup>
                <FormGroup>
                  <label for="prompt">Who is it for?</label>
                  <FormInput
                    type="text"
                    name="who"
                    autoComplete="off"
                    onChange={(e) => {
                      setPromptBuilderValues({
                        ...promptBuilderValues,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                  <p
                    style={{ color: '#667085', paddingTop: 5, paddingLeft: 3 }}
                  >
                    Ex: Women under 40, small business owners, parents, etc.
                  </p>
                </FormGroup>
                <FormGroup>
                  <label for="prompt">What is the desired outcome?</label>
                  <FormInput
                    type="text"
                    name="how"
                    onChange={(e) => {
                      setPromptBuilderValues({
                        ...promptBuilderValues,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                  <p
                    style={{ color: '#667085', paddingTop: 5, paddingLeft: 3 }}
                  >
                    Ex: Lose weight without exercising, increase sales revenue,
                    etc.
                  </p>
                </FormGroup>
              </Form>

              <Button
                theme="white"
                style={{ marginRight: 20 }}
                onClick={() => setShowPromptBuilderModal(false)}
              >
                Cancel
              </Button>

              <Button
                theme="primary"
                disabled={
                  !promptBuilderValues.what ||
                  !promptBuilderValues.who ||
                  !promptBuilderValues.how
                }
                onClick={() => {
                  console.log('promptBuilderValues', promptBuilderValues);
                  var stringPrompt =
                    promptBuilderValues.what +
                    ' for ' +
                    promptBuilderValues.who +
                    ' who want to ' +
                    promptBuilderValues.how;
                  console.log('stringPrompt', stringPrompt);
                  setShowPromptBuilderModal(false);

                  fillPromptWithValues([null, stringPrompt]);
                  setShowPromptImprovementSuggestion(false);
                  toast.success(
                    'New description set. Click generate to see it in action'
                  );
                  MixpanelSuper.trackButtonClick('descriptionPromptBuilder');
                }}
              >
                Continue
              </Button>
            </ModalBody>
          </Modal>

          {/* showGeneralInputTipModal */}
          <Modal open={showGeneralInputTipModal}>
            {/* <ModalHeader></ModalHeader> */}
            <ModalBody>
              <center>
                <i
                  className="material-icons mr-1"
                  style={{
                    paddingTop: 1,
                    fontSize: 30,
                    color: '#039855',
                    backgroundColor: '#D1FADF',
                    padding: '12px',
                    borderRadius: '50%',
                  }}
                >
                  info
                </i>

                <h6 style={{ marginTop: 10, marginBottom: 20 }}>
                  Follow the same format as the examples!
                </h6>
                <p>
                  Please read the helper text by the Generate button. Gary will
                  give you better results if you write something similar to the
                  examples
                </p>

                <div
                  style={{
                    backgroundColor: '#039855',
                    padding: 2,
                    borderRadius: 10,
                  }}
                >
                  <img
                    src={require('../images/follow-tips.gif')}
                    style={{
                      width: '100%',
                      borderRadius: 6,
                    }}
                  />
                </div>
                <Button
                  theme="white"
                  style={{ marginRight: 20, marginTop: 20 }}
                  onClick={() => setShowGeneralInputTipModal(false)}
                >
                  Got it
                </Button>
                <br></br>
              </center>
            </ModalBody>
          </Modal>

          {/* prompt improvement suggestion */}
          {showPromptImprovementSuggestion && (
            <Card
              small
              className="mb-4"
              style={{
                boxShadow: '0px 0px 10px #e6e6e6',
                // boxShadow: '0px 0px 0px #e6e6e6',
                backgroundColor: '#FFFAEB',
              }}
            >
              <Button
                outline
                style={{
                  align: 'right',
                  color: '#BE5E25',
                  position: 'absolute',
                  right: '0',
                  top: '0',
                  zIndex: 100,
                }}
                className="readable pb-2"
                theme="light"
                onClick={() => {
                  setShowPromptImprovementSuggestion(false);
                }}
              >
                X
              </Button>
              <ListGroup flush>
                <ListGroupItem
                  className="p-3"
                  style={{ backgroundColor: '#FFFAEB' }}
                >
                  <Row>
                    <Col md="12">
                      <Badge
                        pill
                        theme="primary"
                        style={{
                          backgroundColor: '#DC6803',
                          marginRight: 10,
                        }}
                      >
                        Suggestion
                      </Badge>
                      <strong style={{ color: '#BE5E25' }}>
                        Your results could be better. Try improving what you
                        entered in your {fieldToImprove}.
                      </strong>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="8"></Col>
                    <Col md="4" style={{ textAlign: 'right' }}>
                      <ButtonGroup size="sm">
                        <Button
                          theme="white"
                          onClick={() => {
                            // generator aida and pas have descriptions, so use description builder for those. otherwise show general tutorial gif.
                            if (generator === 'aida' || generator === 'pas') {
                              setShowPromptBuilderModal(true);
                            } else {
                              setShowGeneralInputTipModal(true);
                            }
                          }}
                        >
                          Show me how
                        </Button>
                        {/* <Button
                          theme="white"
                          onClick={() => setShowGeneralInputTipModal(true)}
                        >
                          Show me how 2
                        </Button> */}
                      </ButtonGroup>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          )}

          {/* List of results */}
          {copyResults.map((copyResult, index) => (
            <Card
              small
              className="mb-4"
              key={index}
              style={{ boxShadow: '0px 0px 10px #e6e6e6' }}
            >
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form>
                        <Row form>
                          {/* Description */}
                          <Col md="12" className="form-group">
                            {index === 0 && (
                              <FormTextarea
                                id={'resultTextArea' + index}
                                rows={countNewLines(copyResult.value[0])}
                              />
                            )}
                            {index !== 0 && (
                              <FormTextarea
                                id={'resultTextArea' + index}
                                rows={countNewLines(copyResult.value[0])}
                                defaultValue={copyResult.value[0]}
                              />
                            )}
                          </Col>
                        </Row>

                        <Row>
                          <Col md="8">
                            {!(remainingCredits <= 0) && (
                              <Button
                                outline
                                theme="primary"
                                className="mb-2 mr-1"
                                onClick={() =>
                                  generateCopyWithDefaults(copyResult.prompt)
                                }
                              >
                                Generate More Like This
                              </Button>
                            )}
                          </Col>
                          <Col md="4" style={{ textAlign: 'right' }}>
                            <ButtonGroup size="sm">
                              <Button
                                theme="white"
                                onClick={() =>
                                  copyToClipboard(
                                    'resultTextArea' + index,
                                    copyTypeUnderscore,
                                    setdisplayMessage
                                  )
                                }
                              >
                                Copy
                              </Button>
                              {likedItems.includes(index) && (
                                <Button theme="secondary">Saved</Button>
                              )}
                              {!likedItems.includes(index) && (
                                <Button
                                  theme="white"
                                  onClick={() =>
                                    saveResultToFirebase(
                                      'resultTextArea' + index,
                                      index,
                                      copyTypeUnderscore,
                                      copyTypeCamelCase,
                                      lastGeneratedPrompts
                                    )
                                  }
                                >
                                  Save
                                </Button>
                              )}
                              <Button
                                theme="white"
                                onClick={() =>
                                  copyLinkToClipboard(
                                    `https://app.garyai.com/snippet/share/${copyResult.uid}/0/${copyResult.id}/`
                                  )
                                }
                              >
                                Share
                              </Button>
                            </ButtonGroup>
                            <br></br>
                            <div
                              style={{
                                paddingTop: '5px',
                                textAlign: 'right !important',
                              }}
                            >
                              <StarRating
                                rating={localStars[index]}
                                item={copyResult}
                                idx={index}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          ))}
          {copyResults.length > 0 && (
            <div>
              {!loading && remainingCredits > 0 && (
                <Button outline theme="accent" onClick={generateCopyV2}>
                  Generate More
                </Button>
              )}
              {loading && remainingCredits > 0 && (
                <Button theme="white" className="disabled">
                  Generate More
                </Button>
              )}
              {remainingCredits <= 0 && (
                <Button
                  theme="info"
                  onClick={() => {
                    window.location.href = '/settings?upgradeInfo=true';
                  }}
                >
                  <i style={{ color: '#FFFFFF' }} className="material-icons">
                    grade
                  </i>
                  Upgrade to Generate More
                </Button>
              )}
            </div>
          )}

          {loading && (
            <img
              alt=""
              src={require('../images/loading.gif')}
              height="100"
              style={{ marginLeft: '40%' }}
            ></img>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Generate;
