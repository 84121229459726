import React, { useEffect } from 'react';
import { setFirebaseUserData, setUser } from '../actions';
import toast, { Toaster } from 'react-hot-toast';

import {
  Card,
  Container,
  Row,
  Col,
  ListGroupItem,
  Form,
  FormInput,
  Button,
  ListGroup,
  FormGroup,
  FormSelect,
  FormCheckbox,
} from 'shards-react';
import { Mixpanel } from '../mixpanel';
import { MixpanelSuper } from '../mixpanelSuper';
import firebase from 'firebase';
import { config } from '../environments/config';
import { connect } from 'react-redux';
import { sendOtherSlackNotification } from '../utils/slackNotifications';
import { addToList } from '../utils/mailchimpHelpers';

export const TryItFree = () => {
  let isThisMobileDevice = window.navigator.userAgent.indexOf('Mobile') > -1;
  console.log('isThisMobileDevice', isThisMobileDevice);

  // JS HERE
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [userFullFb, setUserFullFb] = React.useState({});

  const initiateMixpanel = async () => {
    try {
      Mixpanel.identify(email);
      Mixpanel.track('successful_signup', {
        user_unique_id: email,
      });
      const signupDate = new Date();
      // console.log(signupDate);

      Mixpanel.people.set({
        $email: email,
        $first_name: firstName,
        $last_name: lastName,
        $name: firstName + ' ' + lastName,
        sign_up_date: signupDate, // Send dates in ISO timestamp format (e.g. "2020-01-02T21:07:03Z")
        registration_method: 'email',
        generator_started_count: 0,
        snippet_generated_count: 0,
        snippet_copied_count: 0,
        snippet_saved_count: 0,
        login_count: 0,
        // plan_type: 'free',
      });
      console.log('Set mixpanel for new user');
    } catch (e) {
      console.log('something went wrong initiated mixpanel. Error', e);
    }
  };

  const initiateCrisp = async () => {
    try {
      console.log('initiateCrisp. Crisp set');
      window.$crisp.push(['set', 'user:email', email]);
      window.$crisp.push([
        'set',
        'user:nickname',
        [`${firstName} ${lastName}`],
      ]);
      window.$crisp.push(['set', 'session:event', ['user:welcome']]);
      // set session data.
      window.$crisp.push(['set', 'session:data', ['entered_cc', 'false']]);
    } catch (e) {
      console.log('something went wrong initiating crisp. Error', e);
    }
  };

  const registerUserWithFirebase = async (email, password) => {
    console.log('registerUserWithFirebase');

    // this function registers the user with firebase.
    try {
      const result = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .catch(function(error) {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log('err', errorCode);
          console.log('errMessage', errorMessage);
          toast.error(`Error: ${errorMessage}`);
        });
      console.log('result that should include user', result);

      if (result) {
        initiateMixpanel();

        // add to mailchimp
        try {
          addToList(email, firstName, lastName);
          console.log('added to mailchimp');
        } catch (e) {
          console.log('error adding to mailchimp', e);
        }

        // old state setting functions
        try {
          console.log('set state fore user now');
          setUser(result);
          setUserFullFb(result);
        } catch (e) {
          console.log('error setting state', e);
        }
      }
      return result ? result : 'error';
    } catch (e) {
      console.log(e);
      toast.error(`Error: ${e.message}`);
      return 'error';
    }
  };

  const validateFields = () => {
    console.log('validateFields');
    // this function validates the fields and returns true if all fields are valid.
    if (firstName.length < 1) {
      toast.error('First name is required');
      return false;
    }
    if (lastName.length < 1) {
      toast.error('Last name is required');
      return false;
    }
    if (email.length < 1) {
      toast.error('Email is required');
      return false;
    }
    if (password.length < 1) {
      toast.error('Password is required');
      return false;
    }
    return true;
  };

  const sendEmailVerificationTwo = async () => {
    // send email verification with firebase
    console.log('sendEmailVerificationTwo');
    try {
      firebase
        .auth()
        .currentUser.sendEmailVerification()
        .then(function() {
          // Email sent.
          console.log('email sent');
        })
        .catch(function(error) {
          // An error happened.
          console.log('error sending email', error);
        });
    } catch (e) {
      console.log('caught error sending email', e);
    }
  };

  const sendEmailVerification = async () => {
    console.log('sendEmailVerification. email:', email);

    var actionCodeSettings = {
      // url: 'https://localhost:3000/onboarding-1?onboarding=true&verified=true',
      url: 'https://app.garyai.com/onboarding-1?verified=true',
      // This must be true.
      handleCodeInApp: true,
    };
    //debug
    console.log(
      'sendEmailVerification. actionCodeSettings:',
      actionCodeSettings
    );
    return firebase
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        console.log('email should be sent');
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email);
        return 'success';
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log('err', errorCode);
        console.log('errMessage', errorMessage);
        return 'error';
      });
  };

  const getAllUrlParameters = () => {
    try {
      let queryString = window.location.search;
      let urlParams = new URLSearchParams(queryString);
      let allUrlParams = {};
      for (let p of urlParams) {
        allUrlParams[p[0]] = p[1];
      }
      console.log('getall url params', allUrlParams);
      //convert to string
      let allUrlParamsString = JSON.stringify(allUrlParams);
      // replace all double quotes with ^
      allUrlParamsString = allUrlParamsString.replace(/"/g, '^');
      console.log('allUrlParamsString', allUrlParamsString);
      console.log('type of allUrlParamsString', typeof allUrlParamsString);
      // if there are none, return empty string
      if (allUrlParamsString === '{}') {
        return false;
      }
      return allUrlParamsString;
    } catch (e) {
      console.log('Error getting url parameters', e);
      return '';
    }
  };

  const checkForEmailInUrlParameters = () => {
    // On the register page, when it loads, check for email and set state
    const urlParams = new URLSearchParams(window.location.search);
    const emailValue = urlParams.get('email');
    const referralId = urlParams.get('rid');
    // console.log('urlParams', urlParams);
    // console.log('emailValue', emailValue);
    if (emailValue) {
      console.log('Email value', emailValue);
      // this.setState({ email: emailValue });
    }
    if (referralId) {
      console.log('Referral Id value', referralId);
      // this.setState({ referralId: referralId });
    }
  };

  const createAccount = async (e) => {
    console.log('createAccount');
    e.preventDefault(); // prevent form from being cleared after submit clicked
    setIsLoading(true);

    let areFieldsValid = validateFields();

    if (!areFieldsValid) {
      setIsLoading(false);
      return;
    }
    // this function sets the user doc in firebase.
    let newRegResponse = await registerUserWithFirebase(email, password);
    console.log('newRegResponse', newRegResponse);

    // dont continue if it failed
    if (newRegResponse === 'error') {
      setIsLoading(false);
      return 'error';
    }

    const user = {
      email: newRegResponse.user.email,
      uid: newRegResponse.user.uid,
      firstName: firstName,
      lastName: lastName,
      // verifiedEmail: false,
    };

    console.log('user res', user);
    let now = Date.now();
    let fiveDays = 86400000 * 5;

    const userDoc = {
      pu: 0,
      lu: 0,
      smu: 200,
      ts: now,
      te: now + fiveDays,
      fn: firstName,
      ln: lastName,
      forceCc: true,
      uid: newRegResponse.user.uid,
      verifiedEmail: false,
      e: newRegResponse.user.email,
      trafficSource: '',
    };
    try {
      let urlParams = getAllUrlParameters();
      if (urlParams) {
        userDoc.trafficSource = urlParams;
      }
    } catch (e) {
      console.log('error getting url parameters', e);
    }

    // e: newRegResponse.user.email,
    // accessCode: userDetails.accessCode,
    // trafficSource: userDetails.trafficSource,

    // set the user in the firestore
    console.log('set user in firestore now');
    const userRef = firebase
      .firestore()
      .collection('users')
      .doc(user.uid);
    await userRef.set(userDoc);
    console.log('saved to firestore');

    // await sendEmailVerification(); // not working
    await sendEmailVerificationTwo();

    // send internal slack notification
    try {
      let urlParams = getAllUrlParameters();
      let linkToFirebase =
        'https://console.firebase.google.com/project/copywriting-db92b/firestore/data/~2Fusers~2F' +
        userDoc.uid;
      let message =
        'New account created (in forceCc flow): ' +
        firstName +
        ' ' +
        lastName +
        ' ( ' +
        userDoc.e +
        ' ).  <' +
        linkToFirebase +
        '|Details.>';

      if (urlParams) {
        message += ' Url params: ```' + urlParams + '```';
      }
      //  Url Params: ```' +
      // getAllUrlParameters() +
      // '```';
      await sendOtherSlackNotification(message);
    } catch (e) {
      console.log('Could not send slack message because ', e);
    }

    // old state setting functions
    try {
      console.log('set state fore firebaseUserData now');
      setFirebaseUserData(userDoc);
    } catch (e) {
      console.log('error setting state', e);
    }

    try {
      window.fbq('track', 'Lead');
    } catch (e) {
      console.log('Could not fire pixel event on new signup because', e);
    }

    // show success // removed. unnecessary.
    // toast(`Welcome ${firstName}, let's get this party started!`, {
    //   icon: '👏',
    // });

    initiateCrisp();

    try {
      console.log('Identify in full story');
      window.FS.identify(userDoc.uid, {
        displayName: firstName + ' ' + lastName,
        email: userDoc.e,
      });
    } catch (e) {
      console.log('Could not set full story because', e);
    }

    // go to next page.
    setTimeout(() => {
      // removing email verification from onboarding to improve conversion rate.
      // window.location.href = '/verify-email?email=' + email;
      window.location.href = '/onboarding-1';
      // setIsLoading(false);
    }, 2000);

    return 'success';
  };

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'email') {
      setEmail(value);
    }
    if (name === 'password') {
      setPassword(value);
    }
    if (name === 'firstName') {
      setFirstName(value);
    }
    if (name === 'lastName') {
      setLastName(value);
    }
  };

  useEffect(() => {
    MixpanelSuper.trackScreenView('try_it_free');

    setUserFullFb({});
    console.log('userFullFb', userFullFb);
  }, []);

  return (
    <div
      style={{
        backgroundColor: isThisMobileDevice ? null : '#fff',
        height: '100vh',
      }}
    >
      <div>
        <Toaster />
      </div>

      {/* Desktop version below */}
      {!isThisMobileDevice && (
        <Container
          style={{
            width: '100%',
            height: '100%',
            padding: '0px',
            margin: '0px',
            overflow: 'auto',
            maxWidth: '100%',
            backgroundImage: `url(${require('../images/gradient_bg.png')})`,
            backgroundSize: 'cover',
          }}
        >
          <Row
            style={{
              width: '100%',
              height: '100%',
              padding: 0,
              margin: 0,
            }}
          >
            <Col
              lg={5}
              style={{
                height: '100%',
                backgroundColor: 'white',
                margin: 0,
                padding: '18vh 84px 18vh 84px',
              }}
            >
              <img
                style={{
                  width: '70px',
                  height: '70px',
                  padding: 8,
                  backgroundColor: 'white',
                  borderRadius: 12,
                  marginBottom: 10,
                }}
                src={require('../images/gradientIcon.png')}
              ></img>
              <h4 className="m-0">
                <strong>Create your account</strong>
              </h4>
              <p>You'll be up and running in 2 minutes</p>
              <ListGroup flush>
                <ListGroupItem
                  className="p-3"
                  style={{
                    boxShadow: '0px 1px 4px 0px rgba(109, 110, 111, 0.38)',
                  }}
                >
                  <Form>
                    <Row form>
                      <Col md="6" className="form-group">
                        <label htmlFor="feEmailAddress">First name</label>
                        <FormInput
                          id="firstName"
                          name="firstName"
                          value={firstName}
                          type="text"
                          onChange={inputsHandler}
                        />
                      </Col>

                      <Col md="6" className="form-group">
                        <label htmlFor="feEmailAddress">Last name</label>
                        <FormInput
                          id="lastName"
                          name="lastName"
                          type="text"
                          value={lastName}
                          onChange={inputsHandler}
                        />
                      </Col>
                    </Row>

                    <FormGroup>
                      <label htmlFor="email">Work Email</label>
                      <FormInput
                        id="email"
                        type="email"
                        name="email"
                        onChange={inputsHandler}
                      />
                    </FormGroup>

                    <FormGroup>
                      <label htmlFor="fePassword">Password</label>
                      <FormInput
                        id="fePassword"
                        type="password"
                        placeholder="Password"
                        name="password"
                        onChange={inputsHandler}
                      />
                    </FormGroup>

                    {isLoading ? (
                      <button className={'mb-2 btn btn-sm btn-white mr-1'}>
                        Loading... {'   '}
                        <img
                          alt=""
                          src={require('../images/loading.gif')}
                          height="20"
                        ></img>
                      </button>
                    ) : (
                      <Button
                        style={{ width: '100%' }}
                        onClick={async (e) =>
                          // console.log(email, password, firstName, lastName);
                          await createAccount(e)
                        }
                      >
                        Continue
                      </Button>
                    )}
                  </Form>
                </ListGroupItem>
              </ListGroup>
              <div style={{ margin: 20 }}>
                <center>
                  <p>
                    Already have an account? <a href="/login">Login </a>
                  </p>
                </center>
              </div>
            </Col>
            <Col
              lg={7}
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <center style={{ paddingLeft: 20 }}>
                <h4 style={{ color: 'white' }}>
                  <strong>
                    Join over 3,900 leading brands, agencies and content
                    writers.
                  </strong>
                </h4>
                <div>
                  <img
                    style={{
                      width: '100%',
                      height: 'auto',
                      maxWidth: '100%',
                      maxHeight: '100%',
                      opacity: 0.7,
                    }}
                    src={require('../images/logos-white.png')}
                  ></img>
                </div>
              </center>
            </Col>
          </Row>
        </Container>
      )}

      {/* Mobile version below */}
      {isThisMobileDevice && (
        <div style={{ margin: 30 }}>
          <center>
            <img
              style={{
                width: '70px',
                height: '70px',
                padding: 8,
                backgroundColor: 'white',
                borderRadius: 12,
                marginBottom: 10,
              }}
              src={require('../images/gradientIcon.png')}
            ></img>
            <h4 className="m-0">Create your account</h4>
            <p>You'll be up and running in 2 minutes</p>

            {/* <div className="d-flex justify-content-center">
            <div className="align-self-center mr-3">
              <span>✔️ 5 day free trial</span>
            </div>
            <div className="align-self-center mr-3">
              <span>✔️ Satisfaction guaranteed</span>
            </div>
            <div className="align-self-center mr-3">
              <span>✔️ Cancel anytime</span>
            </div>
          </div> */}
          </center>
          <Container
            style={{
              width: '100%',
            }}
          >
            <ListGroup flush>
              <ListGroupItem
                className="p-3"
                style={{
                  boxShadow: '0px 1px 4px 0px rgba(109, 110, 111, 0.38)',
                }}
              >
                <Row>
                  <Col>
                    <Form>
                      <Row form>
                        <Col md="6" className="form-group">
                          <label htmlFor="feEmailAddress">First name</label>
                          <FormInput
                            id="firstName"
                            name="firstName"
                            value={firstName}
                            type="text"
                            onChange={inputsHandler}
                          />
                        </Col>

                        <Col md="6" className="form-group">
                          <label htmlFor="feEmailAddress">Last name</label>
                          <FormInput
                            id="lastName"
                            name="lastName"
                            type="text"
                            value={lastName}
                            onChange={inputsHandler}
                          />
                        </Col>
                      </Row>

                      <FormGroup>
                        <label htmlFor="email">Work Email</label>
                        <FormInput
                          id="email"
                          type="email"
                          name="email"
                          onChange={inputsHandler}
                        />
                      </FormGroup>

                      <FormGroup>
                        <label htmlFor="fePassword">Password</label>
                        <FormInput
                          id="fePassword"
                          type="password"
                          placeholder="Password"
                          name="password"
                          onChange={inputsHandler}
                        />
                      </FormGroup>

                      {isLoading ? (
                        <button className={'mb-2 btn btn-sm btn-white mr-1'}>
                          Loading... {'   '}
                          <img
                            alt=""
                            src={require('../images/loading.gif')}
                            height="20"
                          ></img>
                        </button>
                      ) : (
                        <Button
                          style={{
                            float: isThisMobileDevice ? 'right' : null,
                          }}
                          onClick={async (e) =>
                            // console.log(email, password, firstName, lastName);
                            await createAccount(e)
                          }
                        >
                          Continue
                        </Button>
                      )}
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Container>
        </div>
      )}
    </div>
  );
};
