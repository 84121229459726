import React, { useState } from "react";
import { Container, Row, Col, ListGroup, ListGroupItem, Form, FormInput, FormGroup, Button, FormTextarea, ButtonGroup, Alert} from "shards-react";
import Fire from "../config/fire";
import PageTitle from "../components/common/PageTitle";


const AddNewProfile = () => {
  const [profileType, setprofileType] = useState('Company');
  const [profileName, setprofileName] = useState('');
  const [profileDescription, setprofileDescription] = useState('');
  const [profileBenefits, setprofileBenefits] = useState('');
  const [loading, setLoading] = useState('')
  const [profileCreatedSuccessfully, setprofileCreatedSuccessfully] = useState('');

  const saveProfile = () => {
    setLoading('true');
    console.log('profileType:', profileType);
    console.log('profileName:', profileName);
    console.log('profileDescription:', profileDescription);
    console.log('profileBenefits:', profileBenefits);

    const profileObject = {
      type: profileType,
      name: profileName,
      description: profileDescription,
      benefits: profileBenefits
    }
    console.log('profileObject', profileObject)

    return new Promise((resolve, reject) => {
      Fire.shared.addCopyProfile(profileObject)
      .then(res => {
          console.log("res >> ", res)
          setprofileCreatedSuccessfully('true')
          // setLoading('false')
          setTimeout(() => {
            window.location = "/profiles"
          }, 1000);

      })
  })

  }
  return(
      <Container fluid className="main-content-container px-4">
        {profileCreatedSuccessfully &&
          <Container fluid className="px-0">
            <Alert className="mb-0">
              <i className="fa fa-info mx-2"></i> Profile created! Redirecting now...
            </Alert>
          </Container>
        }

      <Row noGutters className="page-header py-4">
        <PageTitle title="Profile" subtitle="Add" md="12" className="ml-sm-auto mr-sm-auto" />
      </Row>
      <Row>

        <Col lg="12">
          <ListGroup flush>
          <ListGroupItem className="p-3">
            <Row>
              <Col>
                <Form>

                  <FormGroup>
                    <strong htmlFor="feFirstName">What do you want to write copy for?</strong>
                    <br></br><br></br>
                    <ButtonGroup className="mb-2">
                      <Button theme={profileType === 'Company' ? 'primary' : 'white'} onClick={ e => setprofileType('Company')  }>Company</Button>
                      <Button theme={profileType === 'Company' ? 'white' : 'primary'} onClick={ e => setprofileType('Product')  }>Specific Product</Button>
                    </ButtonGroup>
                  </FormGroup>

                  <FormGroup>
                    <Row form>
                      <label htmlFor="feEmail">What is your {profileType}'s name?</label>
                      <FormInput
                        type="text"
                        placeholder="Acme"
                        onChange={ e => setprofileName(e.target.value) }
                      />
                    </Row>
                  </FormGroup>

                  <Row form>
                    {/* Description */}
                    <Col md="12" className="form-group">
                      <label htmlFor="feDescription">{profileType} description</label>
                      <FormTextarea id="feDescription" onChange={ e => setprofileDescription(e.target.value) } rows="5" />
                    </Col>
                  </Row>

                  <FormGroup>
                      <strong>Fill in the blank</strong>
                      <br></br>
                      <strong class="text-muted d-block mb-2">My {profileType} will help people who are struggling to:</strong>
                      <FormInput
                        type="text"
                        placeholder="lose weight, build muscle, learn how to draw"
                        onChange={ e => setprofileBenefits(e.target.value) }
                      />
                  </FormGroup>

                  {!loading && <Button theme="accent" onClick={saveProfile}>Create Profile</Button>}
                  {loading && <img alt="loading spiral gif" src={require("../images/loading.gif")} height="40" ></img> }
                </Form>

              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Col>
     </Row>
    </Container>
  )
};

export default AddNewProfile;
