import React, { useEffect, useState } from 'react';
import { setUser } from '../actions';
import { MixpanelSuper } from '../mixpanelSuper';
import {
  Card,
  Container,
  Row,
  Col,
  ListGroupItem,
  Form,
  FormInput,
  Button,
  ListGroup,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  FormTextarea,
  Alert,
} from 'shards-react';

import firebase from 'firebase';
import { config } from '../environments/config';
import PageTitle from '../components/common/PageTitle';
import { connect } from 'react-redux';
import { increment } from '../actions';
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { sendSlackNotificationForCopyGeneration } from '../utils/slackNotifications';
import { countNewLines } from '../utils/utils';

const _ = require('lodash');

let apiAddress = _.get(config, 'backend.apiAddress');
let currentPageUrl = window.location.href;

if (currentPageUrl.includes('localhost')) {
  console.log('LOCALHOST');
  apiAddress = 'http://localhost:3001';
}

export const CustomNew = () => {
  const [email, setEmail] = useState('');
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [showStepOne, setShowStepOne] = useState(true);
  const [showStepTwo, setShowStepTwo] = useState(false);
  const [showStepThree, setShowStepThree] = useState(false);

  // const [showVideo, setShowVideo] = useState(false);
  const [copyTitle, setCopyTitle] = useState('');
  const [examples, setExamples] = useState(['']);
  const [examplesCompleted, setExamplesCompleted] = useState([]);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [hasPreviewResults, setHasPreviewResults] = useState(false);
  const [adCopyResults, setadCopyResults] = useState([]);
  const [saveIsLoading, setSaveIsLoading] = useState(false);

  const [
    friendlyCustomGeneratorName,
    setfriendlyCustomGeneratorName,
  ] = useState('');
  const [customGeneratorDescription, setcustomGeneratorDescription] = useState(
    ''
  );
  const [saveModal, setSaveModal] = useState(false);

  let isThisMobileDevice = window.navigator.userAgent.indexOf('Mobile') > -1;
  // console.log('isThisMobileDevice', isThisMobileDevice);

  useEffect(() => {
    console.log('Custom2 useEffect');
    // log current url
    console.log(window.location.href);
    var url = new URL(window.location.href);
    var emailInUrl = url.searchParams.get('email');
    MixpanelSuper.trackScreenView('custom2');

    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        console.log('user is signed in');
        console.log(user);
        setEmail(user.email);

        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then(function(doc) {
            if (doc.exists) {
              let data = doc.data();
              console.log('user data from custom2', data);
              setUserData(data);
            }
          });
      }
    });
  }, []);

  const startTypewriter = (text) => {
    console.log('startTypewriter');
    let i = 0;
    let txt = text;
    const speed = 7;

    const typeWriter = () => {
      console.log('write this - ', txt);
      if (i < txt.length) {
        document.getElementById('firstResult').innerHTML += txt.charAt(i);
        i++;
        setTimeout(typeWriter, speed);
      }
    };

    typeWriter();
  };

  const saveResultsAndHandleResponse = (arrayOfTextCompletions) => {
    let copy = arrayOfTextCompletions;
    console.log('handle copy response', copy);
    if (copy.length > 0) {
      setHasPreviewResults(true);
    }
    if (adCopyResults.length === 0) {
      // sethasResults(true);
      setadCopyResults(copy);

      console.log('adCopyResults now >> ', adCopyResults.length);
      setTimeout(() => {
        console.log('adCopyResults now >> ', adCopyResults.length);
        // Only animate first result in.
        startTypewriter(copy[0]);
      }, 500);
    } else {
      console.log('push existing items');

      for (let i = 0; i < copy.length; i++) {
        let newCopy = copy[i];
        setadCopyResults((adCopyResults) => [...adCopyResults, newCopy]);
      }
      // Scroll page to bottom
      try {
        window.scrollTo({
          left: 0,
          top: document.body.scrollHeight,
          behavior: 'smooth',
        });
      } catch (e) {
        console.log('Couldnt scroll because', e);
      }
    }

    setPreviewLoading(false);
  };

  const saveCustomCopyPrompt = () => {
    console.log('saveCustomCopyPrompt');
    if (!userData.uid) {
      console.log('userData.uid is null.. do not proceed');
      return;
    }
    setSaveIsLoading(true);
    let currentTimestamp = Date.now();

    firebase
      .firestore()
      .collection('customPrompts')
      .doc(userData.uid)
      .collection('saved')
      .add({
        uid: userData.uid,
        ts: currentTimestamp,
        created: currentTimestamp,
        ue: userData.e,
        v: 'v2',
        title: copyTitle, // intro of prompt
        type: 'classic', // classic or inputOutput
        examples: examples,
        description: customGeneratorDescription,
        name: friendlyCustomGeneratorName,
        //   type: customPromptDetails.customCopyType,
      })
      .then(function(docRef) {
        console.log('Document written with ID: ', docRef.id);
        // setSaveIsLoading(false);
        // show toast success and redirect
        toast.success(
          'Saved! Redirecting now. You can find your example content in the History page.',
          {
            duration: 4000,
          }
        );

        setTimeout(() => {
          window.location.href = '/custom';
        }, 4500);
      });
  };

  const generateCustomPreview = async () => {
    let currentGenerator = 'custom-classic'; //generators[generator];
    // MixpanelSuper.generateSnippet(currentGenerator);
    console.log('make new request to', apiAddress);

    setPreviewLoading(true);

    const firebaseJwtToken = await firebase.auth().currentUser.getIdToken();

    console.log('userData', userData);
    console.log('firebaseJwtToken', firebaseJwtToken);
    let userId = userData.uid;
    const requestBody = {
      copyTitle,
      examples,
      userData,
      firebaseJwtToken,
      userId,
      previewMode: true,
    };

    console.log('Request body:', requestBody);

    if (userData.forceCc) {
      // console.log('This is a user that signed up with forceCc.. Use API v2');
      try {
        let generateEndpoint = `${apiAddress}/api/v2/generate-custom`;
        const response = await fetch(generateEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${_.get(config, 'backend.bearerToken')}`,
            firebaseidtoken: firebaseJwtToken,
          },
          body: JSON.stringify(requestBody),
        });
        console.log('response', response);

        if (response.status == 200) {
          const resp = await response.json();
          console.log('resp', resp);
          let copyArray = resp.data;
          console.log('copyArray', copyArray);
          // set copy results.
          saveResultsAndHandleResponse(copyArray);
        }
        // handle 402 error code
        if (response.status === 402) {
          console.log('402 error');
          toast.error(
            (t) => (
              <span>
                Oops. You will need to pick a plan or start a free trial do
                that. Please:
                <br />
                <br />
                <center>
                  <Button
                    theme="accent"
                    onClick={() =>
                      (window.location.href =
                        'https://app.garyai.com/pick-a-plan')
                    }
                  >
                    Go to plans
                  </Button>
                </center>
              </span>
            ),
            { duration: 9000 }
          );
        }

        // handle results success..... animate in..
      } catch (e) {
        console.log('e', e);
        toast.error(`Error: ${e.message}`);
        setPreviewLoading(false);
      }

      // send slack notifications
      try {
        let message =
          userData.e +
          ' is previewing results for a custom generator: ' +
          copyTitle;

        console.log('slackmessage sending:', message);
        await sendSlackNotificationForCopyGeneration(message);
      } catch (e) {
        console.log(
          'Could not send a slack notification about this generation because ',
          e
        );
      }
    } else {
      console.log('This is an old user. generate copy with old API');
      window.alert(
        'Hello! You are a legacy user. Please message support to get access to this new feature'
      );
    }
  };

  return (
    <Container fluid>
      <div>
        <Toaster />
      </div>
      <Row
        noGutters
        className="page-header py-6"
        style={{ marginTop: 30, paddingLeft: 15 }}
      >
        <PageTitle
          sm="4"
          title="New Custom Content Generator"
          className="text-sm-left"
          subtitle={'Create a...'}
        />
      </Row>

      {/* Step 1/ What do you want to write about */}

      {showStepOne && (
        <Container style={{ marginTop: 40 }}>
          <ListGroup flush>
            <ListGroupItem
              className="p-3"
              style={{
                borderWidth: 1,
                borderColor: '#e6e6e6',
                borderStyle: 'solid',
                boxShadow: '0px 0px 10px #e6e6e6',
              }}
            >
              <Row>
                <Col>
                  <div
                    style={{
                      display: 'inline-block',
                      width: '100%',
                    }}
                  >
                    <p>Step 1. Please complete the following sentance...</p>
                    <h5>
                      I want to teach Gary how to write
                      <input
                        type="text"
                        placeholder="tweets about marketing tips"
                        style={{
                          marginLeft: isThisMobileDevice ? 0 : 10,
                          width: isThisMobileDevice ? '100%' : '400px',
                          marginRight: 3,
                          marginBottom: 5,
                          overflow: 'hidden',
                          // change the opacity of the placeholder text
                          opacity: copyTitle.length > 0 ? 1 : 0.7,
                        }}
                        onChange={(e) => {
                          e.preventDefault();
                          setCopyTitle(e.target.value);
                          console.log('copyTitle:', e.target.value);
                        }}
                      />
                      .
                    </h5>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    className="mb-2 btn btn-primary mr-2"
                    style={{ marginTop: 10 }}
                    disabled={isLoading || copyTitle === ''}
                    onClick={() => {
                      // toast.success('Copied to clipboard');
                      setShowStepOne(false);
                      setShowStepTwo(true);
                    }}
                  >
                    Continue
                  </Button>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Container>
      )}

      {/* Step 2 Give examples */}
      {showStepTwo && (
        <Container style={{ marginTop: 40 }}>
          <ListGroup flush>
            <ListGroupItem
              className="p-3"
              style={{
                borderWidth: 1,
                borderColor: '#e6e6e6',
                borderStyle: 'solid',
                boxShadow: '0px 0px 10px #e6e6e6',
              }}
            >
              <Row>
                <Col>
                  <p>Step 2. Train Gary...</p>
                  <h5>
                    Give three examples of good{' '}
                    <span style={{ backgroundColor: 'rgb(233, 240, 253)' }}>
                      "{copyTitle}"
                    </span>
                  </h5>

                  {/* for each item in examples array */}
                  {examples.map((example, index) => {
                    return (
                      <div>
                        <FormGroup
                          style={{
                            backgroundColor: '#e9f0fd',
                            padding: '20px',
                            borderRadius: '5px',
                          }}
                        >
                          {/* TODO LATER  - add delete button. Its not updating UI for examples rn
                        {index >= 3 && (
                          <Row style={{ position: 'relative', padding: 0 }}>
                            <Button
                              className="mb-2 btn btn-white mr-2 small"
                              onClick={() => {
                                // remove index from array
                                console.log('removing example:', index);
                                let newExamples = examples;
                                newExamples.splice(index, 1);
                                console.log('newExamples:', newExamples);
                                setExamples(newExamples);
                              }}
                              style={{
                                position: 'absolute',
                                right: '0px',
                                top: '-15px',
                              }}
                            >
                              X
                            </Button>
                          </Row>
                        )} */}

                          <strong>Example {index + 1}</strong>

                          <FormTextarea
                            // value={examples[index]}
                            rows="5"
                            onChange={(e) => {
                              // e.preventDefault();
                              console.log(
                                'example changed',
                                index,
                                e.target.value
                              );
                              // update examples array index 0
                              let newExamples = [...examples];
                              newExamples[index] = e.target.value;
                              setExamples(newExamples);
                            }}
                          />
                        </FormGroup>
                        {/* Add button */}
                        <Button
                          className="mb-2 btn btn-primary mr-2"
                          style={{
                            marginTop: 10,
                            display:
                              examplesCompleted.includes(index) || index >= 2
                                ? 'none'
                                : 'block',
                          }}
                          disabled={examples[index] === ''}
                          onClick={() => {
                            console.log(
                              'clicked add example for index ',
                              index
                            );
                            // push empty string to examples array
                            let newExamples = [...examples];
                            newExamples.push('');
                            setExamples(newExamples);
                            // hide this button:
                            let existingExamplesCompleted = [
                              ...examplesCompleted,
                            ];
                            existingExamplesCompleted.push(index);
                            setExamplesCompleted(existingExamplesCompleted);
                            // scroll window all the way down
                            setTimeout(() => {
                              window.scrollTo(0, document.body.scrollHeight);
                            }, 100);
                          }}
                        >
                          Add
                        </Button>
                      </div>
                    );
                  })}
                </Col>
              </Row>

              {examplesCompleted.length >= 2 && (
                <Row>
                  <Col>
                    <Button
                      className="mb-2 btn btn-white mr-2"
                      style={{ marginTop: 0, float: 'right' }}
                      onClick={() => {
                        console.log('add example');
                        // push empty string to examples array
                        let newExamples = [...examples];
                        newExamples.push('');
                        setExamples(newExamples);
                      }}
                    >
                      Add another example (optional)
                    </Button>
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  {/* <Button
                    className="mb-2 btn btn-white mr-2"
                    onClick={() => {
                      console.log('go back');
                      setShowStepTwo(false);
                      setShowStepOne(true);
                    }}
                  >
                    Go back
                  </Button> */}

                  {previewLoading ? (
                    <img
                      alt=""
                      src={require('../images/loading.gif')}
                      height="30"
                    ></img>
                  ) : (
                    <Button
                      className="mb-2 btn btn-primary mr-2"
                      style={{
                        display:
                          examplesCompleted.length > 1 ? 'block' : 'none',
                      }}
                      disabled={examples[2] === '' || copyTitle === ''}
                      isLoading={previewLoading}
                      onClick={() => {
                        console.log('preview results');
                        setShowStepThree(true);
                        generateCustomPreview();
                        setTimeout(() => {
                          window.scrollTo({
                            left: 0,
                            top: document.body.scrollHeight,
                            behavior: 'smooth',
                          });
                        }, 100);
                      }}
                    >
                      Preview results
                    </Button>
                  )}
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Container>
      )}

      {/* Step 3 Preview results */}
      {showStepThree && (
        <Container style={{ marginTop: 40, marginBottom: 100 }}>
          <ListGroup flush>
            <ListGroupItem
              className="p-3"
              style={{
                border: '0.5px solid black',
              }}
            >
              <p>Step 3. Preview results</p>

              {previewLoading && (
                <img
                  alt=""
                  src={require('../images/loading.gif')}
                  height="50"
                ></img>
              )}

              {adCopyResults.map((copyResult, index) => (
                <Row>
                  <FormGroup
                    style={{
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      borderRadius: '5px',
                      width: '100%',
                    }}
                  >
                    {index === 0 && (
                      <FormTextarea
                        id={'firstResult'}
                        rows={countNewLines(copyResult)}
                        // value={copyResult}
                        // defaultValue={copyResult}
                      />
                    )}
                    {index !== 0 && (
                      <FormTextarea
                        id={'resultTextArea' + index}
                        rows={countNewLines(copyResult)}
                        value={copyResult}
                        defaultValue={copyResult}
                      />
                    )}
                  </FormGroup>
                </Row>
              ))}

              {/* Button Group with two buttons. Go back and Save */}
              <Row>
                <Col>
                  <Button
                    className="mb-2 mr-1 btn btn-secondary btn-sm"
                    // style={{ marginTop: 0, float: 'left' }}
                    onClick={() => {
                      console.log('go back');
                      setShowStepThree(false);
                      setadCopyResults([]);
                      toast(
                        'If you want better results, change your examples and try again!',
                        {
                          icon: '🔁',
                        }
                      );
                    }}
                  >
                    Go back to editing
                  </Button>

                  {saveIsLoading ? (
                    <img
                      alt=""
                      src={require('../images/loading.gif')}
                      height="30"
                    ></img>
                  ) : (
                    <Button
                      className="mb-2 btn btn-primary mr-2"
                      // style={{ marginTop: 0, float: 'right' }} saveIsLoading
                      disabled={copyTitle === ''}
                      onClick={() => {
                        console.log('save');
                        setfriendlyCustomGeneratorName(copyTitle);
                        //  open modal
                        setSaveModal(true);
                        // saveCustomCopyPrompt();
                      }}
                    >
                      Save
                    </Button>
                  )}
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Container>
      )}

      {/* Save Modal */}
      <Modal open={saveModal}>
        <ModalHeader>Ready to save?</ModalHeader>
        <ModalBody>
          <span>
            Save your custom content generator so you can re-use it later.
          </span>
          <br></br>
          <Row>
            <center style={{ width: '100%', padding: 15 }}>
              <Form
                style={{
                  width: '90%',
                  paddingBottom: 20,
                }}
              >
                <FormGroup
                  style={{
                    textAlign: 'left',
                  }}
                >
                  <label for="name">Name</label>
                  <FormInput
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    value={friendlyCustomGeneratorName}
                    onChange={(e) => {
                      setfriendlyCustomGeneratorName(e.target.value);
                    }}
                  />
                </FormGroup>

                <FormGroup
                  style={{
                    textAlign: 'left',
                  }}
                >
                  <label for="description">Description</label>
                  <FormTextarea
                    rows="3"
                    id="description"
                    placeholder="Description"
                    value={customGeneratorDescription}
                    onChange={(e) => {
                      setcustomGeneratorDescription(e.target.value);
                    }}
                  />
                </FormGroup>
              </Form>
              {saveIsLoading ? (
                <img
                  alt=""
                  src={require('../images/loading.gif')}
                  height="30"
                ></img>
              ) : (
                <div>
                  <Button
                    className="btn btn-secondary"
                    onClick={() => {
                      console.log('go back');
                      // close modal
                      setSaveModal(false);
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      console.log('save func here');
                      saveCustomCopyPrompt();
                    }}
                  >
                    Save
                  </Button>
                </div>
              )}
            </center>
          </Row>
        </ModalBody>
      </Modal>
    </Container>
  );
};
