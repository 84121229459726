const userReducer = (state = {}, action) => {
  //TODO: Can I just track the entire firebase user object here? Probably dont want to update here. Still need api to reduce usage counter
  switch(action.type){
    case 'SET_USER':
      return state = action.payload; //TODO: Pass Users Information here
    case 'UNSET_USER':
      return state = {};
    default:
      return state;
  }
}
export default userReducer;
