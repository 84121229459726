import React, { useEffect } from 'react';

import toast, { Toaster } from 'react-hot-toast';
import { MixpanelSuper } from '../mixpanelSuper';
import {
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  Card,
  CardBody,
  CardTitle,
  CardText,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalHeader,
} from 'shards-react';
import firebase from 'firebase';
import { config } from '../environments/config';

const FrequentlyAskedQuestions = () => (
  <div style={{ marginTop: 50, paddingBottom: 60 }}>
    <h4 className="m-0" style={{ paddingBottom: 10 }}>
      Frequently asked questions
    </h4>

    <p>
      Here are some things you may be wondering about. If there's something you
      cant find,{' '}
      <a
        style={{
          color: '#1972F5',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={() => {
          window.$crisp.push(['do', 'chat:toggle']);
        }}
      >
        send us a message
      </a>
      .
    </p>
    <h6>What is a credit?</h6>
    <p>
      Any time you click 'Generate', that is one credit. Each run generates
      multiple content options for you to pick and choose from. Unlike some
      competitors, we dont charge on a per word basis. Our generous pricing
      model is one of the many reasons thousands are switching to GaryAI.com
    </p>
    <h6>What if I go over my limit?</h6>
    <p>
      We will contact you and ask if you want to upgrade. All of your content
      will be saved.
    </p>

    <h6>Is the content original and plagirism-free?</h6>
    <p>
      Yes, all content is completely unique and plagirism free. Get started and
      try it out for yourself, you wont see the same thing twice.
    </p>
  </div>
);

export const PickPlan2 = () => {
  //   const [email, setEmail] = React.useState('');
  // setEmail
  const [email, setEmail] = React.useState();
  const [userData, setUserData] = React.useState();
  const [userId, setUserId] = React.useState();

  const [selectedPlan, setSelectedPlan] = React.useState('starter');
  const [isLoading, setIsLoading] = React.useState(false);
  const [exitIntentModalShown, setExitIntentModalShown] = React.useState(false);

  const [showProPlansOnly, setShowProPlansOnly] = React.useState(false);
  // TEST PRO ONLY

  const [
    isMonthlyOptionsSelected,
    setisMonthlyOptionsSelected,
  ] = React.useState(true);

  const _ = require('lodash');
  let apiAddress = _.get(config, 'backend.apiAddress');
  // let usersEmail = ''; //firebase?.auth()?.currentUser?.email;

  let currentPageUrl = window.location.href;

  if (currentPageUrl.includes('localhost')) {
    console.log('LOCALHOST');
    apiAddress = 'http://localhost:3001';
  }

  console.log('api address ', apiAddress);

  const startStripeCheckout = async (planName) => {
    setIsLoading(true);
    console.log('startStripeCheckout', planName);
    // https://stripe.com/docs/checkout/quickstart
    let endpoint = apiAddress + '/api/v2/stripe/create-checkout-session';
    // we set global window variable with email.. may be avail depennding on how fast they click this.
    // todo: set up proper state management for user.
    let email;
    if (window.Gaiue) {
      email = window.Gaiue;
    }
    const body = {
      stripePlanName: planName,
      email: email,
      userId: userId,
    };
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      console.log('response', response);
      if (response.status != 200) {
        toast.error(`Error: ${response.statusText}`);
        setIsLoading(false);
        return 'error';
      }

      let data = await response.json();
      console.log('data', data);
      window.location.href = data.url;
      setIsLoading(false);
    } catch (e) {
      console.log('error', e);
      toast.error('Error: ' + e.message);
      setIsLoading(false);
    }

    // let response = await request.json();
    // console.log('response', response);
  };
  // exit intent pop up  ....

  const CookieService = {
    setCookie(name, value, days) {
      let expires = '';

      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
      }

      document.cookie = name + '=' + (value || '') + expires + ';';
    },

    getCookie(name) {
      const cookies = document.cookie.split(';');

      for (const cookie of cookies) {
        if (cookie.indexOf(name + '=') > -1) {
          return cookie.split('=')[1];
        }
      }

      return null;
    },
  };

  const mouseEvent = (e) => {
    const shouldShowExitIntent =
      !e.toElement && !e.relatedTarget && e.clientY < 10;

    if (shouldShowExitIntent && !CookieService.getCookie('exitIntentShown')) {
      setExitIntentModalShown(true);
      console.log('shouldShowExitIntent');
      MixpanelSuper.trackScreenView('exit_intent_popup_offer');
      CookieService.setCookie('exitIntentShown', true, 30);
      try {
        window.$crisp.push([
          'do',
          'message:show',
          ['text', `Hi ${userData.fn} do you need any help?`],
        ]);
      } catch (e) {
        console.log('error', e);
      }
    }
  };
  // end of exit intent pop up

  let isThisMobileDevice = window.navigator.userAgent.indexOf('Mobile') > -1;
  console.log('isThisMobileDevice', isThisMobileDevice);

  useEffect(() => {
    if (!CookieService.getCookie('exitIntentShown')) {
      console.log('exist intent listener');
      setTimeout(() => {
        window.document.addEventListener('mouseout', mouseEvent);
      }, 0);
    }
    // setExitIntentModalShown(true); //DEV UNDO

    // if pt (price test) is set in cookies from landing page , set showProPlansOnly to true
    // console.log('Get Cookie: ', CookieService.getCookie('pt'));
    // if (CookieService.getCookie('pt')) {
    //   console.log('~~ pro only');
    //   setShowProPlansOnly(true);
    //   MixpanelSuper.trackScreenView('pick_plan_pro_only_test');
    // }

    console.log('PricingContainer useEffect');
    MixpanelSuper.trackScreenView('pick_plan');
    // get current firebase user
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        console.log('user is signed in');
        console.log(user);
        setEmail(user.email);
        setUserId(user.uid);

        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then(function(doc) {
            if (doc.exists) {
              let data = doc.data();
              console.log('Document data:', data);
              setUserData(data);
            }
          });
      }
    });
  }, []);

  return (
    <Container fluid>
      <Modal open={exitIntentModalShown}>
        <ModalHeader>🛑 Wait!</ModalHeader>
        <ModalBody>
          <span
            style={{
              // top right corner
              position: 'absolute',
              top: '0',
              right: '10px',
              pointer: 'cursor',
            }}
            onClick={() => {
              setExitIntentModalShown(false);
              MixpanelSuper.trackButtonClick('exit_intent_popup_offer_close');
            }}
          >
            X
          </span>
          {/* <center>
            <span>Special Offer:</span>
            <br></br>
          </center> */}
          <h5>
            Look. If you're not leveraging AI in 2022 you are WAY behind...
          </h5>

          <br></br>
          <p>
            Your biggest competitors are already leveraging AI to create content
            10x faster. Now, its your turn. <br></br>
            <br></br>Watch the quick demo to see how GaryAI enables 4000+ people
            to create content 10x faster and grow their business! <br></br>(Free
            gift at the end of the video.)
          </p>
          <br></br>
          <center>
            <iframe
              height="315"
              width={'100%'}
              src="https://www.youtube.com/embed/z-35SdMFtP0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>

            <br></br>
            <br></br>

            <Button
              onClick={() => {
                toast.success(
                  'Bonus: Your account will have double the usage credits (applied automatically) when you click Start Free trial!',
                  { duration: 5000 }
                );
                // TODO add logic to double free credits
                MixpanelSuper.trackButtonClick('exit_intent_popup_offer_yes');
                setExitIntentModalShown(false);
              }}
            >
              Show me free trial details
            </Button>
          </center>
        </ModalBody>
      </Modal>

      <div>
        <Toaster />
      </div>
      <div
        style={{
          width: '100%',
          backgroundColor: 'rgb(64, 117, 251)',
          minHeight: 50,
        }}
      >
        <center>
          <h4 style={{ padding: 10, color: 'white', paddingBottom: 10 }}>
            <strong>LIMITED TIME ONLY: 50% OFF For Life</strong>
          </h4>
        </center>
      </div>
      <img
        src={require('../images/gradientIcon.png')}
        alt="GaryAI"
        style={{
          width: '50px',
          height: '50px',
          padding: 8,
          backgroundColor: 'white',
          borderRadius: 12,
          marginTop: 15,
          marginLeft: 5,
        }}
      />
      {/* Normal Pricing page content */}
      {!showProPlansOnly && (
        <>
          {' '}
          <div style={{ margin: 10 }}>
            <center>
              <h3 className="m-0">
                <strong>Try It Free For 5 days</strong>
              </h3>
              <p style={{ paddingTop: 15, marginBottom: 0, paddingBottom: 0 }}>
                A plan for every business size. <br></br>
                {/* Switch to yearly
                billing to get 2 months free (Save 16%) */}
              </p>
            </center>
          </div>
          <Container style={{ width: isThisMobileDevice ? '100%' : '60%' }}>
            <ListGroup flush>
              {/* Center two buttons that say Monthly or Yearly */}

              {/* <center style={{ marginTop: 5, marginBottom: 30 }}>
                <ButtonGroup size="sm">
                  <Button
                    theme={'white'}
                    style={{
                      width: 100,
                      marginRight: 5,
                      opacity: !isMonthlyOptionsSelected ? 0.5 : 1,
                    }}
                    size="sm"
                    onClick={() => {
                      setisMonthlyOptionsSelected(true);
                    }}
                  >
                    Monthly
                  </Button>

                  <Button
                    theme={'white'}
                    style={{
                      width: 100,
                      opacity: isMonthlyOptionsSelected ? 0.5 : 1,
                    }}
                    size="sm"
                    onClick={() => {
                      console.log('set isMonthlyOptionsSelected to false');
                      setisMonthlyOptionsSelected(false);
                    }}
                    disabled={isLoading}
                  >
                    Yearly
                  </Button>
                </ButtonGroup>
              </center> */}

              {/* monthly */}
              {isMonthlyOptionsSelected && (
                <Row
                  style={{
                    padding: 10,
                    //center
                    justifyContent: 'center',
                  }}
                >
                  <Col
                    lg={'5'}
                    onClick={() => {
                      setSelectedPlan('starter');
                    }}
                    style={{
                      backgroundColor: 'white',
                      borderRadius: 5,
                      padding: 20,
                      marginRight: isThisMobileDevice ? 0 : 10,
                      marginBottom: isThisMobileDevice ? 10 : 0,
                      borderWidth: selectedPlan === 'starter' ? 2 : 0,
                      // 1px border solid
                      borderStyle: 'solid',
                      borderColor: '#1972F5',
                      boxShadow: '0px 1px 4px 0px rgba(109, 110, 111, 0.38)',
                    }}
                  >
                    <p
                      style={{
                        backgroundColor: '#1972f536',
                        borderRadius: 5,
                        width: 55,
                        textAlign: 'center',
                        color: '#1972F5',
                      }}
                    >
                      Starter
                    </p>

                    <p
                      style={{
                        textDecoration: 'line-through',
                        color: '#818181',
                        fontSize: '1.2rem',
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      Normally $99/mo
                    </p>

                    <h3 className="card-title">
                      $49
                      <span
                        style={{
                          color: 'lightgrey',
                          fontSize: 20,
                          marginTop: -8,
                          paddingLeft: 5,
                        }}
                      >
                        /mo
                      </span>
                    </h3>
                    <p>For hobbyists just getting started</p>
                    <p>
                      <i className="check fas fa-check"> </i> 200 credits per
                      month
                    </p>
                    <p>
                      <i className="check fas fa-check"> </i> Access to most
                      content generators
                    </p>
                    <p>
                      <i className="check fas fa-check"> </i> Build custom
                      generators{' '}
                    </p>
                    <p>
                      <i className="check fas fa-check"> </i> Self serve support
                    </p>

                    {isLoading ? (
                      <img
                        alt=""
                        src={require('../images/loading.gif')}
                        height="20"
                      ></img>
                    ) : (
                      <button
                        type="button"
                        style={{ width: '100%' }}
                        class={
                          selectedPlan === 'starter'
                            ? 'mb-2 btn btn-primary mr-2'
                            : 'mb-2 btn btn-sm btn-white mr-1'
                        }
                        onClick={async () => {
                          MixpanelSuper.trackButtonClick('start_plan_starter');
                          try {
                            window.fbq('track', 'ViewContent', {
                              value: '49.99',
                            });
                          } catch (e) {
                            console.log(
                              'Could not fire ViewContent pixel event because',
                              e
                            );
                          }
                          await startStripeCheckout('starter2');
                        }}
                      >
                        Start Free Trial
                        <i
                          className="material-icons mr-1"
                          style={{ marginLeft: 5 }}
                        >
                          arrow_forward
                        </i>
                      </button>
                    )}
                  </Col>
                  <Col
                    onClick={() => {
                      setSelectedPlan('pro');
                    }}
                    lg={'5'}
                    style={{
                      backgroundColor: 'white',
                      borderRadius: 5,
                      // margin: 3,
                      padding: 20,
                      borderWidth: selectedPlan === 'pro' ? 2 : 0,
                      // 1px border solid
                      borderStyle: 'solid',
                      borderColor: '#1972F5',
                      boxShadow: '0px 1px 4px 0px rgba(109, 110, 111, 0.38)',
                    }}
                  >
                    <Row>
                      <Col xs={6}>
                        <p
                          style={{
                            backgroundColor: '#1972f536',
                            borderRadius: 5,
                            width: 35,
                            textAlign: 'center',
                            color: '#1972F5',
                          }}
                        >
                          Pro
                        </p>
                      </Col>
                      <Col xs={6}>
                        <p
                          style={{
                            backgroundColor: '#3D5170',
                            borderRadius: 5,
                            width: 105,
                            padding: 3,
                            textAlign: 'center',
                            color: 'white',
                            position: 'absolute',
                            right: 10,
                          }}
                        >
                          Most popular
                        </p>
                      </Col>
                    </Row>
                    <p
                      style={{
                        textDecoration: 'line-through',
                        color: '#818181',
                        fontSize: '1.2rem',
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      Normally $159/mo
                    </p>
                    <h3 className="card-title">
                      $79
                      <span
                        style={{
                          color: 'lightgrey',
                          fontSize: 20,
                          marginTop: -8,
                          paddingLeft: 5,
                        }}
                      >
                        /mo
                      </span>
                    </h3>
                    <p>Good for the bloggers and businesses</p>
                    <p>
                      <i className="check fas fa-check"> </i>{' '}
                      <span
                        style={{
                          textDecoration: 'line-through',
                          color: 'lightgrey',
                        }}
                      >
                        500
                      </span>{' '}
                      Unlimited credits per month
                    </p>
                    <p>
                      <i className="check fas fa-check"> </i> Access to all
                      content generators
                    </p>
                    <p>
                      <i className="check fas fa-check"> </i> Build custom
                      generators (with white glove support)
                    </p>
                    <p>
                      <i className="check fas fa-check"> </i> 24/7 priority
                      support
                    </p>

                    {isLoading ? (
                      <img
                        alt=""
                        src={require('../images/loading.gif')}
                        height="20"
                      ></img>
                    ) : (
                      <button
                        type="button"
                        style={{ width: '100%' }}
                        class={
                          selectedPlan === 'pro'
                            ? 'mb-2 btn btn-primary mr-2'
                            : 'mb-2 btn btn-sm btn-white mr-1'
                        }
                        onClick={async () => {
                          MixpanelSuper.trackButtonClick('start_plan_pro');
                          try {
                            window.fbq('track', 'ViewContent', {
                              value: '79.99',
                            });
                          } catch (e) {
                            console.log(
                              'Could not fire ViewContent pixel event because',
                              e
                            );
                          }
                          await startStripeCheckout('pro2');
                        }}
                      >
                        Start Free Trial
                        <i
                          className="material-icons mr-1"
                          style={{ marginLeft: 5 }}
                        >
                          arrow_forward
                        </i>
                      </button>
                    )}
                  </Col>
                </Row>
              )}

              {/* yearly options */}
              {!isMonthlyOptionsSelected && (
                <Row
                  style={{
                    padding: 10,
                    justifyContent: 'center',
                  }}
                >
                  <Col
                    lg={'5'}
                    onClick={() => {
                      setSelectedPlan('starter');
                    }}
                    style={{
                      backgroundColor: 'white',
                      borderRadius: 5,
                      marginRight: isThisMobileDevice ? 0 : 10,
                      marginBottom: isThisMobileDevice ? 10 : 0,
                      padding: 20,
                      borderWidth: selectedPlan === 'starter' ? 2 : 0,
                      // 1px border solid
                      borderStyle: 'solid',
                      borderColor: '#1972F5',
                      boxShadow: '0px 1px 4px 0px rgba(109, 110, 111, 0.38)',
                    }}
                  >
                    <p
                      style={{
                        backgroundColor: '#1972f536',
                        borderRadius: 5,
                        width: 55,
                        textAlign: 'center',
                        color: '#1972F5',
                      }}
                    >
                      Starter
                    </p>
                    <h3 className="card-title">
                      $24
                      <span
                        style={{
                          color: 'lightgrey',
                          fontSize: 20,
                          marginTop: -8,
                          paddingLeft: 5,
                        }}
                      >
                        /mo
                      </span>
                    </h3>
                    <strong style={{ color: 'lightgrey' }}>
                      billed yearly
                    </strong>
                    <p style={{ marginTop: 15 }}>
                      For hobbyists just getting started
                    </p>
                    <p>
                      <i className="check fas fa-check"> </i> 200 credits per
                      month
                    </p>
                    <p>
                      <i className="check fas fa-check"> </i> Access to most
                      content generators
                    </p>
                    <p>
                      <i className="check fas fa-check"> </i> Build custom
                      generators{' '}
                    </p>
                    <p>
                      <i className="check fas fa-check"> </i> Self serve support
                    </p>

                    {isLoading ? (
                      <img
                        alt=""
                        src={require('../images/loading.gif')}
                        height="20"
                      ></img>
                    ) : (
                      <button
                        type="button"
                        style={{ width: '100%' }}
                        class={
                          selectedPlan === 'starter'
                            ? 'mb-2 btn btn-primary mr-2'
                            : 'mb-2 btn btn-sm btn-white mr-1'
                        }
                        onClick={async () => {
                          MixpanelSuper.trackButtonClick(
                            'start_plan_starter_annual'
                          );
                          try {
                            window.fbq('track', 'ViewContent', {
                              value: '288.00',
                            });
                          } catch (e) {
                            console.log(
                              'Could not fire ViewContent pixel event because',
                              e
                            );
                          }
                          await startStripeCheckout('starter-annual');
                        }}
                      >
                        Start Free Trial
                        <i
                          className="material-icons mr-1"
                          style={{ marginLeft: 5 }}
                        >
                          arrow_forward
                        </i>
                      </button>
                    )}
                  </Col>
                  <Col
                    lg={'5'}
                    onClick={() => {
                      setSelectedPlan('pro');
                    }}
                    style={{
                      backgroundColor: 'white',
                      borderRadius: 5,
                      // margin: 10,
                      padding: 20,
                      borderWidth: selectedPlan === 'pro' ? 2 : 0,
                      // 1px border solid
                      borderStyle: 'solid',
                      borderColor: '#1972F5',
                      boxShadow: '0px 1px 4px 0px rgba(109, 110, 111, 0.38)',
                    }}
                  >
                    <Row>
                      <Col xs={6}>
                        <p
                          style={{
                            backgroundColor: '#1972f536',
                            borderRadius: 5,
                            width: 35,
                            textAlign: 'center',
                            color: '#1972F5',
                          }}
                        >
                          Pro
                        </p>
                      </Col>
                      <Col xs={6}>
                        {/* <p
                  style={{
                    backgroundColor: '#3D5170',
                    borderRadius: 5,
                    width: 105,
                    padding: 3,
                    textAlign: 'center',
                    color: 'white',
                    position: 'absolute',
                    right: 10,
                  }}
                >
                  Most popular
                </p> */}
                      </Col>
                    </Row>

                    <h3 className="card-title">
                      $42
                      <span
                        style={{
                          color: 'lightgrey',
                          fontSize: 20,
                          marginTop: -8,
                          paddingLeft: 5,
                        }}
                      >
                        /mo
                      </span>
                    </h3>
                    <strong style={{ color: 'lightgrey' }}>
                      billed yearly
                    </strong>
                    <p style={{ marginTop: 15 }}>
                      Good for the bloggers and businesses
                    </p>
                    <p>
                      <i className="check fas fa-check"> </i>{' '}
                      <span
                        style={{
                          textDecoration: 'line-through',
                          color: 'lightgrey',
                        }}
                      >
                        500
                      </span>
                      Unlimited credits per month
                    </p>
                    <p>
                      <i className="check fas fa-check"> </i> Access to all
                      content generators
                    </p>
                    <p>
                      <i className="check fas fa-check"> </i> Build custom
                      generators (with white glove support)
                    </p>
                    <p>
                      <i className="check fas fa-check"> </i> 24/7 priority
                      support
                    </p>

                    {isLoading ? (
                      <img
                        alt=""
                        src={require('../images/loading.gif')}
                        height="20"
                      ></img>
                    ) : (
                      <button
                        type="button"
                        style={{ width: '100%' }}
                        class={
                          selectedPlan === 'pro'
                            ? 'mb-2 btn btn-primary mr-2'
                            : 'mb-2 btn btn-sm btn-white mr-1'
                        }
                        onClick={async () => {
                          MixpanelSuper.trackButtonClick(
                            'start_plan_pro_annual'
                          );
                          try {
                            window.fbq('track', 'ViewContent', {
                              value: '504.00',
                            });
                          } catch (e) {
                            console.log(
                              'Could not fire ViewContent pixel event because',
                              e
                            );
                          }
                          await startStripeCheckout('pro-annual');
                        }}
                      >
                        Start Free Trial
                        <i
                          className="material-icons mr-1"
                          style={{ marginLeft: 5 }}
                        >
                          arrow_forward
                        </i>
                      </button>
                    )}
                  </Col>
                </Row>
              )}
            </ListGroup>
          </Container>
        </>
      )}

      {/* Badges */}
      <Container style={{ width: isThisMobileDevice ? '100%' : '60%' }}>
        <div style={{ marginTop: 70, paddingBottom: 60 }}>
          <center>
            <h4 className="m-0" style={{ paddingBottom: 10 }}>
              Join <strong>3,989</strong> business owners, marketers and writers
              around the world.
            </h4>
            <img
              style={{
                width: '70%',
                height: 'auto',
                // maxWidth: '100%',
                // maxHeight: '100%',
                opacity: 0.6,
              }}
              src={require('../images/logos-black.png')}
            ></img>
          </center>
        </div>

        <div>
          <center>
            <h4 className="m-0" style={{ paddingBottom: 30 }}>
              What customers are saying...
            </h4>
          </center>

          <Row>
            <Col md={6}>
              <Card
                small
                style={{
                  padding: 24,
                  boxShadow: '0px 0px 1px #667085',
                  marginBottom: isThisMobileDevice ? 15 : 0,
                }}
              >
                <CardBody>
                  <CardTitle>
                    <i className="fas fa-quote-left"></i>{' '}
                    <span>
                      Helps me produce incredible content faster than I ever
                      could have on my own!
                    </span>
                  </CardTitle>
                  <CardText>
                    <p>
                      {' '}
                      Tried Gary out of curiosity & a love for tech… staying
                      because <strong>WOW</strong>. In the first 3 weeks I have
                      written about 4,000 words of ad copy, 10,000 words of
                      articles, and last weekend was able to rewrite all of our
                      landing page copy that is is now published. On top of the{' '}
                      <strong>sheer volume</strong>, I am{' '}
                      <strong>amazed at the quality</strong>.<br></br>
                      <br></br> -Mellanie Bennett (Founder)
                    </p>
                  </CardText>

                  <iframe
                    width="100%"
                    src="https://www.youtube.com/embed/7iYv8GYxn7w"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  ></iframe>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card
                small
                style={{
                  padding: 24,
                  boxShadow: '0px 0px 1px #667085',
                }}
              >
                <CardBody>
                  <CardTitle>
                    <i className="fas fa-quote-left"></i>{' '}
                    <span>
                      {' '}
                      If you're serious about saving time, this is a must!
                    </span>
                  </CardTitle>
                  <CardText>
                    <p>
                      I have a million things to do. And I kinda hate creating
                      content to be honest. This thing{' '}
                      <strong>saves me such much time</strong>
                      and makes <strong>creating content way easier</strong>.
                      Every week I put a little Spotify on and create our
                      content for the week with Gary!<br></br>
                      <br></br>-Bryan Allen (CEO)
                    </p>
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col md={6}>
              <Card
                small
                style={{
                  padding: 24,
                  boxShadow: '0px 0px 1px #667085',
                  marginBottom: isThisMobileDevice ? 15 : 0,
                }}
              >
                <CardBody>
                  <CardTitle>
                    <i className="fas fa-quote-left"></i>{' '}
                    <span>My whole month of content in 10 minutes.</span>
                  </CardTitle>
                  <CardText>
                    <p>
                      I just created enough tweets for our{' '}
                      <strong>brands monthly calendar</strong> in{' '}
                      <strong>about ten minutes</strong>. If you're still
                      creating content without this in 2022, you're insane...{' '}
                      <br></br>
                      <br></br>-Wyatt Simpson (Organic Social)
                    </p>
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card
                small
                style={{
                  padding: 24,
                  boxShadow: '0px 0px 1px #667085',
                }}
              >
                <CardBody>
                  <CardTitle>
                    <i className="fas fa-quote-left"></i>{' '}
                    <span>The best AI tool I've tried.</span>
                  </CardTitle>
                  <CardText>
                    <p>
                      I work for an agency and we have high expectations on copy
                      quality. I gave up on the other AI tools because the
                      quality was crap. This is different. This thing{' '}
                      <strong>actually produces</strong> work thats comparable
                      to mine, and I'm a professional.
                      <br></br>
                      <br></br>-Heather Ellis (Direct Response)
                    </p>
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col md={6}>
              <Card
                small
                style={{
                  padding: 24,
                  boxShadow: '0px 0px 1px #667085',
                  marginBottom: isThisMobileDevice ? 15 : 0,
                }}
              >
                <CardBody>
                  <CardTitle>
                    <i className="fas fa-quote-left"></i>{' '}
                    <span>Game changer for marketers</span>
                  </CardTitle>
                  <CardText>
                    <p>
                      This app is amazing! As a marketer, I can definitely say
                      that this will <strong>save me a lot of time</strong>{' '}
                      coming up with fresh content. I give it some basic ideas
                      and it spits out straight fire!<br></br>
                      <br></br>-Carlos Mendez (Head of Marketing)
                    </p>
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card
                small
                style={{
                  padding: 24,
                  boxShadow: '0px 0px 1px #667085',
                }}
              >
                <CardBody>
                  <CardTitle>
                    <i className="fas fa-quote-left"> </i>{' '}
                    <span>More than 10x faster tbh!!</span>
                  </CardTitle>
                  <CardText>
                    <p>
                      If you're struggling to get started with your website
                      copy, GaryAI can help you get it done in just a few days.
                      I was able to finish a new client project in just two days
                      with GaryAI, and you'll{' '}
                      <strong>be amazed at how quickly and easily</strong> it
                      can be done..<br></br>- Heather Ellis (Consultant)
                    </p>
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>

      <Container style={{ width: isThisMobileDevice ? '100%' : '60%' }}>
        <Card
          style={{
            marginTop: 50,
            boxShadow: '0px 0px 1px #667085',
            padding: 30,
          }}
        >
          <center>
            <img
              src={require('../images/MoneyBack.png')}
              style={{ height: 90 }}
              alt="GaryAI"
            />
            <h4 className="m-0" style={{ paddingBottom: 10 }}>
              100% No-Risk... 30 Day Money-Back Guarantee
            </h4>
            <p>
              We guarantee that you’ll love GaryAI... and if for some reason you
              decide later that you don’t want to be a member anymore, then
              we'll happily cancel your account without any penalties or fees.
            </p>
          </center>
        </Card>
      </Container>

      <Container style={{ width: isThisMobileDevice ? '100%' : '60%' }}>
        <FrequentlyAskedQuestions />
        <div>
          <center style={{ marginBottom: 50 }}>
            <Button
              outline
              onClick={() => {
                // smooth scroll to top of screen
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
              }}
            >
              Scroll to top
            </Button>
          </center>
        </div>
      </Container>
    </Container>
  );
};
