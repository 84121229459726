import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Mixpanel } from './mixpanel';

// import fire from './config/fire';

import HttpsRedirect from 'react-https-redirect';
import routes from './routes';
import withTracker from './withTracker';
import SignIn from './views/SignIn';
import SignUp from './views/SignUp';
import { TryItFree } from './views/_TryItFree';

import 'bootstrap/dist/css/bootstrap.min.css';
import './shards-dashboard/styles/shards-dashboards.1.1.0.min.css';
// import { render } from "react-dom";
import firebase from 'firebase';
import Fire from './config/fire';

import FullStory from 'react-fullstory';
import { connect } from 'react-redux';
import { setUser } from './actions';
import { partition } from 'lodash';
import toast, { Toaster } from 'react-hot-toast';

const _ = require('lodash');
// const ORG_ID = '1008EM'; // Fullstory account #1 (gmail auth with hello@garyAI) -- reached freeplan limit in Jan 2021
// const ORG_ID = 'ZZZVK'; // Fullstory account #@ (email auth with teamgaryai@gmail.com) -- started using Jan 21 2021
const ORG_ID = 'o-1CNCCB-na1'; // Fullstory account #3 (teamgaryAI+dev@gmail.com) -- Started Aug 12 2022

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
    };
  }
  componentDidMount() {
    // console.log('APP MOUNTED');
    this.authListener();
  }

  authListener() {
    console.log('App.js Auth Listener');
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log('App.js User email', user.email);
        // garyAI user email
        window.Gaiue = user.email;
        console.log('App.js User Here', user);
        Mixpanel.identify(user.email);

        // TODO handle redirects for users who didnt finish onboarding..
        // if forceCc and !paid: go to appropriate screen, based on what userDoc Data is missing...

        // set state to user
        console.log('App.js Set state for user');
        this.preventDefault();
        this.setState({
          user: user,
        });
        // set user in redux
        console.log('App.js Set state in redux for user');
        this.props.setUser(user);

        try {
          window.$crisp.push(['set', 'user:email', user.email]);

          console.log('Crisp set');
        } catch (e) {
          console.log('Cant set crisp live chat because: ', e);
        }
      } else {
        console.log('App.js Error, user not loaded or logged');

        // redirect them to sign in page, unless they are on the snippet page (or auth related pages).
        let nonRedirectPagePaths = [
          '/login',
          '/register',
          '/try-it-free',
          '/activating-plan',
          '/auth-action',
          '/verify-email',
          '/verify-plan',
          '/snippet/share',
          '/verify-trial',
          '/load-plan',
          '/verify-payment',
          '/set-up-account',
          '/reset-password',
          '/forgot-password',
        ];

        // if the current page url is in NonRedirectPagePaths, then don't redirect
        if (
          nonRedirectPagePaths.includes(window.location.pathname) ||
          window.location.pathname.includes('/share')
        ) {
          console.log(
            'User is on auth page (or share snippet), so dont redirect.. Allowed to be unauthenticathed on this page'
          );
        } else {
          toast.error('Please sign in to continue.');
          setTimeout(() => {
            window.location.href = '/login';
          }, 500);
        }
      }
    });
  }

  render() {
    return (
      <HttpsRedirect>
        <div className="App">
          <FullStory org={ORG_ID} />
          <div>
            <Toaster />
          </div>
          <Router basename={process.env.REACT_APP_BASENAME || ''}>
            <div>
              {routes.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={withTracker((props) => {
                      return (
                        <route.layout {...props}>
                          <route.component {...props} />
                        </route.layout>
                      );
                    })}
                  />
                );
              })}
            </div>
          </Router>
        </div>
      </HttpsRedirect>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    credits: state.credits,
    isSubscribed: state.isSubscribed,
    daysRemaining: state.daysRemaining,
    user: state.user,
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = {
  setUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
