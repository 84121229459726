import React, { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import {
  Card,
  Container,
  Row,
  Col,
  ListGroupItem,
  Form,
  FormInput,
  Button,
  ListGroup,
  Badge,
} from 'shards-react';
import { MixpanelSuper } from '../mixpanelSuper';
import firebase from 'firebase';

const Onboarding1Component = () => {
  const [businessName, setbusinessName] = React.useState('');
  const [businessSite, setbusinessSite] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const continueOnboarding = async () => {
    setIsLoading(true);
    console.log('continueOnboarding ');
    console.log(`businessName: ${businessName} businessSite: ${businessSite}`);

    if (businessName.length < 1) {
      toast.error('Please enter a business name');
      setIsLoading(false);
      return 'error';
    }
    // optional field
    if (businessSite.length < 1) {
      setbusinessSite('n/a');
    }

    // update firestore userdoc value with businessName and businessS
    try {
      let userId = firebase.auth().currentUser.uid;
      console.log('update for userId', userId);
      const result = await firebase
        .firestore()
        .collection('users')
        .doc(userId)
        .update({
          businessName: businessName,
          businessSite: businessSite,
        })
        .catch(function(error) {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log('err', errorCode);
          console.log('errMessage', errorMessage);
          toast.error(`Error: ${errorMessage}`);
        });
      console.log('result', result);

      setIsLoading(false);
      console.log('Success! Go to next screen.');
      window.location.href = '/setup-2';
    } catch (e) {
      console.log(e);
      toast.error(`Error: ${e.message}`);
      setIsLoading(false);
    }
  };

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'businessName') {
      setbusinessName(value);
    }
    if (name === 'businessSite') {
      setbusinessSite(value);
    }
  };

  return (
    <ListGroup flush>
      <ListGroupItem className="p-3">
        <Row>
          <Col>
            <Form>
              <Row form>
                <Col md="6" className="form-group">
                  <label htmlFor="businessName">Business Name</label>
                  <FormInput
                    id="businessName"
                    name="businessName"
                    type="text"
                    value={businessName}
                    placeholder="Gym Shark"
                    onChange={inputsHandler}
                  />
                </Col>

                <Col md="6" className="form-group">
                  <label htmlFor="businessSite">
                    Domain <span style={{ color: 'gray' }}>(recommended)</span>
                  </label>
                  <FormInput
                    type="text"
                    id="businessSite"
                    name="businessSite"
                    value={businessSite}
                    placeholder="Gymshark.com"
                    onChange={inputsHandler}
                  />
                </Col>
              </Row>
              {isLoading ? (
                <button className={'mb-2 btn btn-sm btn-white mr-1'}>
                  Loading... {'   '}
                  <img
                    alt=""
                    src={require('../images/loading.gif')}
                    height="20"
                  ></img>
                </button>
              ) : (
                <Button onClick={async () => await continueOnboarding()}>
                  Continue
                </Button>
              )}
            </Form>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  );
};

export const SetupPart1 = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState(false);

  let isThisMobileDevice = window.navigator.userAgent.indexOf('Mobile') > -1;
  console.log('isThisMobileDevice', isThisMobileDevice);

  const updateEmailVerifiedStatus = async () => {
    try {
      let userId = firebase.auth().currentUser.uid;
      console.log('update for userId', userId);
      const result = await firebase
        .firestore()
        .collection('users')
        .doc(userId)
        .update({
          verifiedEmail: true,
        })
        .catch(function(error) {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log('err', errorCode);
          console.log('errMessage', errorMessage);
          toast.error(`Error: ${errorMessage}`);
        });
      console.log('result', result);
      return result;
    } catch (e) {
      console.log('error', e);
      return 'error';
    }
  };

  useEffect(() => {
    // executed only once
    console.log('useEffect Onboarding1');
    console.log('OnboardingPart1 useEffect');

    // onboarding-1?verified=true
    console.log(window.location.href);
    var url = new URL(window.location.href);
    var vInUrl = url.searchParams.get('verified');
    console.log('waiting for firebase');
    MixpanelSuper.trackScreenView('setup_1');
    // wait for firebase
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        console.log('On Onboarding - Firebase ready and identified user');
        console.log(user.email);

        setIsLoading(false);
        // email verification is not forced apart of onboarding anymore.
        // v is short for verified
        if (vInUrl) {
          console.log('vInUrl', vInUrl);
          // update firestore user doc with verified = true
          updateEmailVerifiedStatus();
          setIsLoading(false);
          setTimeout(() => {
            toast.success('Email verified!');
          }, 2000);

          try {
            window.$crisp.push([
              'set',
              'session:data',
              ['verified_email', 'true'],
            ]);
          } catch (e) {
            console.log('Could not set session data because', e);
          }
        } else {
          console.log('Dont see V in url, thats fine. is a problem');
          // toast.error(
          //   'Unable to verify. Did you click the email on the same device you created your account?'
          // );
          // setErrorMessage(true);
          // console.log(user);
        }
      } else {
        console.log('no user identified on this device.');
        toast.error(
          'Unable to identify user. Please make sure you click the email link from same device!'
        );
      }
    });
  }, []);

  return isLoading ? (
    <div>
      <div>
        <Toaster />
      </div>
      <center style={{ marginTop: 100 }}>
        {!errorMessage && (
          <img alt="" src={require('../images/loading.gif')} height="120"></img>
        )}

        {errorMessage && (
          <div>
            <Row
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '30px',
              }}
            >
              <span>Step</span>

              <Badge
                style={{
                  backgroundColor: 'rgb(233, 240, 253)',
                  color: 'rgb(0, 123, 255)',
                  margin: 5,
                }}
              >
                1
              </Badge>

              <Badge
                style={{
                  backgroundColor: '#2F7DF6',
                  color: 'white',
                  margin: 5,
                }}
              >
                2
              </Badge>

              <Badge
                style={{
                  backgroundColor: '#D4DBE2',
                  color: '#AFB0B0',
                  margin: 5,
                }}
              >
                3
              </Badge>
            </Row>
            <h3>Unable to verify your email</h3>
            <p>Please go back or contact support.</p>
            <Button
              size="sm"
              theme="primary"
              className="mb-2 mr-1"
              onClick={() => {
                window.$crisp.push(['do', 'chat:open']);
              }}
            >
              Get help
            </Button>
          </div>
        )}
      </center>
    </div>
  ) : (
    <Container fluid>
      <div>
        <Toaster />
      </div>
      <div style={{ margin: 30 }}>
        <center>
          <Row
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '30px',
            }}
          >
            <span>Step</span>

            <Badge
              style={{
                backgroundColor: 'rgb(233, 240, 253)',
                color: 'rgb(0, 123, 255)',
                margin: 5,
              }}
            >
              1
            </Badge>

            <Badge
              style={{
                backgroundColor: '#2F7DF6',
                color: 'white',
                margin: 5,
              }}
            >
              2
            </Badge>

            <Badge
              style={{
                backgroundColor: '#D4DBE2',
                color: '#AFB0B0',
                margin: 5,
              }}
            >
              3
            </Badge>
          </Row>
          <h4 className="m-0">Tell us about your business</h4>
        </center>
      </div>

      <Container style={{ width: isThisMobileDevice ? '100%' : '40%' }}>
        <Onboarding1Component />
      </Container>
    </Container>
  );
};
