import React, { useEffect, useState } from 'react';
import { setUser } from '../actions';
import toast, { Toaster } from 'react-hot-toast';
import { Mixpanel } from '../mixpanel';
import { MixpanelSuper } from '../mixpanelSuper';
import {
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
  FormInput,
  Modal,
  ModalBody,
  ModalHeader,
  ButtonGroup,
  InputGroup,
  InputGroupAddon,
  Card,
  CardBody,
  CardFooter,
  Badge,
} from 'shards-react';
import firebase from 'firebase';
import { config } from '../environments/config';
import PageTitle from '../components/common/PageTitle';

export const Documents = () => {
  const [userData, setUserData] = useState({});
  const [userEmail, setUserEmail] = useState('');
  const [userId, setUserId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [documentTitle, setDocumentTitle] = useState('Untilted document');
  const [documentBody, setDocumentBody] = useState('');
  const [documentId, setDocumentId] = useState('');
  const [userDocs, setUserDocs] = useState([]);
  const [creationIsLoading, setCreationIsLoading] = useState(false);

  const _ = require('lodash');
  let apiAddress = _.get(config, 'backend.apiAddress');
  let currentPageUrl = window.location.href;
  if (currentPageUrl.includes('localhost')) {
    console.log('LOCALHOST');
    apiAddress = 'http://localhost:3001';
  }

  let isThisMobileDevice = window.navigator.userAgent.indexOf('Mobile') > -1;

  const getAllUsersDocs = async (thisUserId) => {
    console.log('get docs for user', thisUserId);
    let userDocs = [];
    let docsRef = firebase
      .firestore()
      .collection('docs')
      .doc(thisUserId)
      .collection('all');
    const snapshot = await docsRef.get();
    snapshot.forEach((doc) => {
      let docData = doc.data();
      docData.id = doc.id;
      userDocs.push(docData);
    });
    setUserDocs(userDocs);
    console.log('userDocs', userDocs);
    return userDocs;
  };

  const createDoc = async () => {
    console.log('createDoc');
    setCreationIsLoading(true);
    let currentTimestamp = Date.now();

    firebase
      .firestore()
      .collection('docs')
      .doc(userId)
      .collection('all')
      .add({
        uid: userId,
        ts: currentTimestamp,
        created: currentTimestamp,
        modified: currentTimestamp,
        title: documentTitle,
        body: documentBody,
        owner: userId,
      })
      .then(function(docRef) {
        console.log('Document written with ID: ', docRef.id);
        // toast.success('Document created!');
        setDocumentId(docRef.id);
        setCreationIsLoading(false);
        // go to /docs/:id
        window.location.href = `/doc?id=${docRef.id}`;
      });
  };

  const changeDocTitle = async (title) => {
    console.log('changeDocTitle');
    if (!documentId) {
      toast.error('No doc title');
    }
    let currentTimestamp = Date.now();
    // change document title in firebase
    firebase
      .firestore()
      .collection('docs')
      .doc(userId)
      .collection('all')
      .doc(documentId)
      .update({
        title: documentTitle,
        modified: currentTimestamp,
      })
      .then(function() {
        console.log('Document successfully updated!');
        toast.success('Saved');
      });
  };

  useEffect(() => {
    console.log('Settings useEffect');
    console.log('waiting for firebase');
    MixpanelSuper.trackScreenView('documents');

    setIsLoading(true);
    // wait for firebase
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // console.log('user', user);
        setUserEmail(user.email);
        setUserId(user.uid);

        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then(async function(doc) {
            if (doc.exists) {
              let data = doc.data();
              console.log('docs userData:', data);

              // if (!data.verifiedEmail) {
              //   toast.error('Please verify your email address.');
              //   setEmailVerificationNeeded(true);
              // }
              setUserData(data);
              await getAllUsersDocs(user.uid);
              setIsLoading(false);
            }
          });
      } else {
        console.log('user is not signed in');
        setIsLoading(false);
        toast.error('user not signed in');
      }
    });
  }, []);

  return (
    <Container>
      <div>
        <Toaster />
      </div>

      <Row
        noGutters
        className="page-header py-6"
        style={{ marginTop: 30, paddingLeft: 15 }}
      >
        <PageTitle sm="4" title="Documents" className="text-sm-left" />
      </Row>
      {userData.smu <= 776 && (
        <Row
          style={{
            marginLeft: 50,
            marginTop: 30,
            marginRight: 50,
            backgroundColor: 'rgba(226, 235, 255, 1)',

            padding: '19px 12px 2px 19px',
            borderRadius: 5,

            alignItems: 'center',
          }}
        >
          <Col md="8" className="form-group">
            <h6 style={{ paddingTop: 8 }}>
              <strong>Go Pro to Unlock Documents and Write 5x Faster 🚀</strong>
            </h6>
            <p>
              Unlock documents, unlimted credits, premium support and so much
              more!
            </p>
          </Col>
          <Col
            md="4"
            className="form-group"
            style={{
              // align content right
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              onClick={() => {
                console.log('go pro');
                window.location.href = '/settings?upgradeInfo=true';
                // createStripeSession();
              }}
              style={{ maxHeight: 50 }}
            >
              Learn more
            </Button>
          </Col>
        </Row>
      )}

      <Row style={{ marginTop: 30 }}>
        <Col lg="4">
          {!isLoading && (
            <Card className="mb-3" style={{ boxShadow: '0px 0px 1px #667085' }}>
              <CardBody>
                <h6>New Document</h6>
                {userData.smu <= 776 ? (
                  <Button
                    onClick={() => {
                      //go to settings page
                      window.location.href = '/settings?upgradeInfo=true';
                    }}
                  >
                    Upgrade to unlock
                  </Button>
                ) : (
                  <div>
                    <div>
                      {!creationIsLoading && (
                        <Button
                          className="mb-2"
                          theme="white"
                          onClick={async () => {
                            console.log('create new');
                            await createDoc();
                          }}
                        >
                          Create
                        </Button>
                      )}
                    </div>
                    <div>
                      {creationIsLoading && (
                        <img
                          src={require('../images/loading.gif')}
                          style={{ height: 50, margin: 30 }}
                        />
                      )}
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          )}
        </Col>
        {isLoading && (
          <div>
            <img
              src={require('../images/loading.gif')}
              style={{ height: 70, margin: 10 }}
            />
          </div>
        )}
        {!isLoading && (
          <>
            {userDocs.map((doc) => {
              return (
                <Col lg="4">
                  <Card
                    className="mb-3"
                    style={{ boxShadow: '0px 0px 1px #667085' }}
                    key={doc.id}
                  >
                    <CardBody>
                      <h6>{doc.title}</h6>
                      <p>Last edited {doc.modified}</p>

                      <Button
                        className="mb-2"
                        theme="white"
                        onClick={() => {
                          window.location.href = '/doc?id=' + doc.id;
                        }}
                      >
                        Open
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </>
        )}
      </Row>
    </Container>
  );
};
