import React, { useState, useEffect,
 // useRef
 } from "react";
import { Container, Row, Col, Card, ListGroup, Alert, ListGroupItem, Form, FormGroup, FormInput, Button, FormTextarea, Modal, ModalBody, ModalHeader,
 // FormCheckbox, FormSelect
 } from "shards-react";

import Fire from '../config/fire';
import PageTitle from "../components/common/PageTitle";
import { sendSlackNotificationForCopyGeneration } from "../utils/slackNotifications";

import firebase from "firebase";
import { MixpanelSuper } from "../mixpanelSuper"
import {config} from "../environments/config";
import "../assets/upgrade.css";

// const apiAddress = 'http://0.0.0.0:3001';
// const apiAddress = 'https://api.garyai.com'
const _ = require('lodash');
const apiAddress = _.get(config,'backend.apiAddress');

const CustomPrompt = () => {

  // https://reactjs.org/docs/hooks-state.html

  const [customCopyType, setcustomCopyType] = useState('');

  const [exampleOneInput, setexampleOneInput] = useState('');
  const [exampleOneOutput, setexampleOneOutput] = useState('');

  const [exampleTwoInput, setexampleTwoInput] = useState('');
  const [exampleTwoOutput, setexampleTwoOutput] = useState('');

  const [exampleThreeInput, setexampleThreeInput] = useState('');
  const [exampleThreeOutput, setexampleThreeOutput] = useState('');

  const [inputForGenerations, setinputForGenerations] = useState('');

  const [stepsThatAreVisible, setstepsThatAreVisible] = useState([1])
  const [showCustomCopyModal, setshowCustomCopyModal] = useState();
  const [loadingCustomCopyPrompts , setloadingCustomCopyPrompts] = useState();

  const [usersCustomCopyPrompts, setusersCustomCopyPrompts] = useState([]);
  const [userEmail, setuserEmail] = useState();

  const [firebaseDocId, setfirebaseDocId] = useState('');
  const [usersName, setusersName] = useState('');
  const [ monthlyUsageAllowance ,setmonthlyUsageAllowance] = useState('');

  const [userCanAccessCustomPrompts, setuserCanAccessCustomPrompts] = useState(false);
  // const [customCopyPromptFirebaseDocId, setcustomCopyPromptFirebaseDocId] = useState();
  // const [customCopyPromptLastUpdated, setcustomCopyPromptLastUpdated] = useState();



  // const [product, setProduct] = useState('');
  // const [businessIndustry, setbusinessIndustry] = useState('');
  // const [businessUrl, setbusinessUrl] = useState('');
  const [adCopyResults, setadCopyResults] = useState([]);
  // const [adCopyResults, setadCopyResults] = useState(['this is a test', 'and another one']); // DEV make this empty
  const [hasResults, sethasResults] = useState(false);
  const [loading, setloading] = useState(false);
  const [displayMessage, setdisplayMessage] = useState('');
  // const [saveCopyResultInProgress, setsaveCopyResultInProgress] = useState(false);
  const [likedItems, setlikedItems] = useState([]);
  // unlike adCopyresults, which is just an array of copy text values, this variable is an array of object that includes all details about adCopy, including promptVersion.
  // const [fullListOfAdCopy, setfullListOfAdCopy] = useState([]);
  const [showUpgradeModal, setshowUpgradeModal] = useState(false);


  // const doCustomGeneration = () => {
  //   console.log('customCopyType', customCopyType);
  //   console.log('-------')
  //   console.log('exampleOneInput:', exampleOneInput);
  //   console.log('exampleOneOutput:', exampleOneOutput);
  //   console.log('-------')
  //   console.log('exampleTwoInput:', exampleTwoInput);
  //   console.log('exampleTwoOutput:', exampleTwoOutput);
  //   console.log('-------')
  //   console.log('exampleThreeInput:', exampleThreeInput);
  //   console.log('exampleThreeOutput:', exampleThreeOutput);
  //   console.log('-------')
  //   console.log('inputForGenerations:', inputForGenerations);


  //   const requestOptions = {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer BPUaMaUcXE51SsEq4lEQi3GUEg9ND3wezQLdtR4zcva8nc4V8FSKBpBmSNE064UV', 'firebaseidtoken': idToken},
  //     body: JSON.stringify(
  //       { customCopyType: customCopyType,
  //         exampleOneInput: exampleOneInput,
  //         exampleOneOutput: exampleOneOutput,
  //         exampleTwoInput: exampleTwoInput,
  //         exampleTwoOutput: exampleTwoOutput,
  //         exampleThreeInput: exampleThreeInput,
  //         exampleThreeOutput: exampleThreeOutput,
  //         inputForGenerations: inputForGenerations
  //       }
  //     )
  //   };

  // };


  // const handleClick = (e) => {
  //   saySomething("element clicked");
  // };

  useEffect( () => {
    Fire.shared.checkAndWaitForFirebaseLoad().then(()=> {
      loadUserEmail();
    });
  }, []);


   const startTypewriter = (text) => {
    console.log('startTypewriter')
    let i = 0;
    let txt = text
    const speed = 7;

    const typeWriter = () => {
      console.log("write this - ", txt)
      if (i < txt.length) {
        document.getElementById("firstResult").innerHTML += txt.charAt(i);
        i++;
        setTimeout(typeWriter, speed);
      }
    }

    typeWriter()
   }

  const animateResultsIn = (txt) => {
    console.log('Animate copy results in for first result');
    startTypewriter(txt);
  }

  const handleCopyGenerationResponse = (copy) => {
    console.log('handle copy response', copy);
      if (adCopyResults.length === 0 ) {
        sethasResults(true);
        setadCopyResults(copy)

        console.log("adCopyResults now >> ", adCopyResults.length)
        // Only animate first result in.
        animateResultsIn(_.get(copy,'[0][0].value[0]',''))
      } else {
        console.log('push existing items');

        for (let i = 0; i < copy.length; i++) {
          let newCopy = copy[i];
          setadCopyResults(adCopyResults => [...adCopyResults, newCopy])
        };
        // Scroll page to bottom
        try {
          window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
        }catch(e){
          console.log('Couldnt scroll because', e)
        }
      };

    setloading(false);
  }
  // const saveCustomCopyResultToFirebase = (resultToSave) => {
  //   console.log('saveCustomCopyResultToFirebase', resultToSave)
  //   return new Promise((resolve, reject) => {
  //     Fire.shared.addCopyResult(resultToSave)
  //     .then(res => {
  //         console.log('Saved api results to db');
  //     })
  //   });
  //
  // }

  const saveResultsAndHandleResponse = (data) => {
    // this function handles UI to display results, and saves all results to firebase.
    data = [data];
    try{
      const resultToSave = {
        type: 'customCopy',
        value: data,
        prompt: JSON.stringify({ promptId: firebaseDocId, input: inputForGenerations })
      }
      // saveCustomCopyResultToFirebase(resultToSave) /** REMOVED: Saving on server side now */
    }catch(e){
      console.log('Error occured with adding to firebase in saveResultsAndHandleResponse(). Details: ', e);
    }
    handleCopyGenerationResponse(data)
  }


  const doCustomGeneration = () => {
    console.log('customCopyType', customCopyType);
    console.log('-------')
    console.log('exampleOneInput:', exampleOneInput);
    console.log('exampleOneOutput:', exampleOneOutput);
    console.log('-------')
    console.log('exampleTwoInput:', exampleTwoInput);
    console.log('exampleTwoOutput:', exampleTwoOutput);
    console.log('-------')
    console.log('exampleThreeInput:', exampleThreeInput);
    console.log('exampleThreeOutput:', exampleThreeOutput);
    console.log('-------')
    console.log('inputForGenerations:', inputForGenerations);

    console.log('make request to', apiAddress);
    setloading(true)
    firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer BPUaMaUcXE51SsEq4lEQi3GUEg9ND3wezQLdtR4zcva8nc4V8FSKBpBmSNE064UV', 'firebaseidtoken': idToken},
        body: JSON.stringify(
          { customCopyType: customCopyType,
            exampleOneInput: exampleOneInput,
            exampleOneOutput: exampleOneOutput,
            exampleTwoInput: exampleTwoInput,
            exampleTwoOutput: exampleTwoOutput,
            exampleThreeInput: exampleThreeInput,
            exampleThreeOutput: exampleThreeOutput,
            inputForGenerations: inputForGenerations
          }
        )
      };
      fetch(`${apiAddress}/v1/garys-innermost-thoughts/custom-copy`, requestOptions)
          .then(response => {
            console.log('Api response', response);
            if (response.status === 406){
              window.alert("Your input text seems to contain sensitive, politically charged, or vulgar content.  Please rephrase your inputs.  If you believe you've received this message in error please screen shot your prompts and contact our team via the feedback link.");
              window.location.reload()
            };

            if (response.status === 500){
              window.alert("Sorry we can't process your request right now. Please be sure you are avoiding political, vulgar or inappropriate topics");
              window.location.reload()
            };
            if (response.status === 422){
              window.alert("Oops! Looks like you forgot something. Please enter the needed details before generating copy");
              window.location.reload()
            };
            if (response.status === 401){
              window.alert("Error: Unauthorized. Please contact us if you think this is a mistake.");
              window.location.reload()
            };
            if (response.status === 402){
              setshowUpgradeModal(true);
              setloading(false)
              MixpanelSuper.viewPaymentPrompt('custom');
              // Break next promise
              return Promise.reject('Payment required to continue');
            }
            return response.json()
          })
          .then(data => saveResultsAndHandleResponse(data.data));
    }).catch(e =>{
      console.log('Error occured while accessing current firebase user', e);
    });

  }

  // const generateMultipleFacebookAdCopy = () => {
  //   let requestAmount = 1;
  //   generateFacebookAdCopy()
  //   for (let i = 1; i < requestAmount; i++) {
  //     setTimeout(function timer() {
  //       console.log('Another one')
  //       generateFacebookAdCopy()
  //     }, i * 4000);
  //   }
  // }

  const copyToClipboard = (text) => {
    console.log('Copy to clipboard');
    navigator.clipboard.writeText(text);
    setdisplayMessage('Copied!');
    setTimeout(() => {
      setdisplayMessage(null);
    },3000)
  };

  const delayThenScroll = (delay, elementIdToScrollTo) => {
    console.log('delay then scroll')
    if (!delay) {
      delay = 0;
    }
    setTimeout(() => {
      document.getElementById(elementIdToScrollTo).scrollIntoView({ behavior: 'smooth' });
    }, delay);

  }

  const sendSlackMessage = () => {
    if(!userCanAccessCustomPrompts){
      // if they are on free plan and cant access custom prompts, include that in slack message
      const slackMessageWithUpgradePrompt = `${usersName} ( ${userEmail} ) just started custom copy for "${customCopyType}" but they are going to see upgrade prompt since they are on free plan.`;
      sendSlackNotificationForCopyGeneration(slackMessageWithUpgradePrompt)
    }else{
      const slackMessage = `${usersName} ( ${userEmail} ) just started custom copy for ${customCopyType}`;
      sendSlackNotificationForCopyGeneration(slackMessage)
    }
    // console.log('slackmessage', slackMessage);

  }

  const loadUserEmail = () => {
    // console.log('loadUserEmail');
    Fire.shared.getUserDetails().then(res => {
      console.log('res.. ', res);
      if(res !== undefined){
        console.log('loadUserEmail:', res);
        setuserEmail(res.e);
        const usersFullName = `${res.fn} ${res.ln}`
        setusersName(usersFullName);

        try {
          setmonthlyUsageAllowance(res.smu);
          if (res.smu === 50){
            console.log('The free plan is 50 allowance usage credits. That means they are a free user and do not get access to this feature');
            setuserCanAccessCustomPrompts(false);
          } else {
            // Im assuming if there SMU is not equal to 50, they paid something.. This could be problematic if we change free plan limit.
            setuserCanAccessCustomPrompts(true);
          }
        }catch(e){
          console.log('An error occured while retrieving smu from FB: ', e);
        }
      }


    })
  }

  const updateCustomCopyDetails = (firebaseDocumentIdToUpdate) => {
    console.log('updateCustomCopyDetails')
    // console.log("inside of the next function. firebaseDocId = ", firebaseDocId); //FIXME: UNDEFINED FIXME
    console.log('state firebaseDocId', firebaseDocId);
    const promptDetails = {
      docId: firebaseDocumentIdToUpdate,
      user: userEmail,
      customCopyType: customCopyType,
      exampleOneInput: exampleOneInput,
      exampleOneOutput: exampleOneOutput,
      exampleTwoInput: exampleTwoInput,
      exampleTwoOutput: exampleTwoOutput,
      exampleThreeInput: exampleThreeInput,
      exampleThreeOutput: exampleThreeOutput,
      inputForGenerations: inputForGenerations
    }
    // const promptDetailsStringified = JSON.stringify(promptDetails)
    console.log('update promptDetails', promptDetails)

      return new Promise((resolve, reject) => {
        Fire.shared.updateUserCustomCopyPrompt(promptDetails)
        .then(res => {
            console.log("Updated.");
        })
      })

  };


  const createCustomCopyDetails = () => {
    // console.log('createCustomCopyDetails');

    const promptDetails = {
      user: userEmail,
      customCopyType: customCopyType,
      exampleOneInput: exampleOneInput,
      exampleOneOutput: exampleOneOutput,
      exampleTwoInput: exampleTwoInput,
      exampleTwoOutput: exampleTwoOutput,
      exampleThreeInput: exampleThreeInput,
      exampleThreeOutput: exampleThreeOutput,
      inputForGenerations: inputForGenerations
      // extraExamples?
      // value: output
    };

    console.log('test >> ', promptDetails)

    return new Promise((resolve, reject) => {
      Fire.shared.createUserCustomCopyPrompt(promptDetails)
      .then(res => {
          console.log("Saved, res >> ", res);
          // console.log('docRef id', res.docRef.id);
          console.log('firebase doc id', res.docRef.id);
          const docId = res.docRef.id;
          setfirebaseDocId(docId)
          // startAutoUpdate(docId);
          return docId
       })
  })

  }
  // const printSOmething = () => {
  //   console.log('firebaseDocId STATE ???? ', firebaseDocId);
  // }



  // const startAutoUpdate = (firebaseDocumentIdToUpdate) => {
  //   console.log('Starting autoupdate every 30 seconds');
  //   // setfirebaseDocId(firebaseDocumentIdToUpdate); // FIXME: THIS DOESNT SEEM TO WORK!
  //   // setStarted(true);
  //   setInterval(function(){
  //       try {
  //         updateCustomCopyDetails(firebaseDocumentIdToUpdate)
  //       }catch(e){
  //         console.log('could not autosave update because', e);
  //       }
  //   }, 30000);
  // }

  const getUsersCustomCopyPrompts = () => {
    // getAllUsersCustomCopyPrompts
    setloadingCustomCopyPrompts(true)
    console.log("Fetch user custom copy results");
    // setLoading(true)
    // setLoading('true');
    return new Promise((resolve, reject) => {
        Fire.shared.getAllUsersCustomCopyPrompts()
        .then(res => {
          console.log("custom copy prompt results here: ", res);
          // let drops = [];
          // let stars = [];
          // let expand = [];
          for (let i = 0; i < res.length; i++) {
            let date = new Date(res[i].ts);
            res[i]['date'] = date.getMonth() + 1 + '-' +date.getDate() + '-' + date.getFullYear();
          }
          console.log('res with date', res)
          setusersCustomCopyPrompts(res)
          setloadingCustomCopyPrompts(false)

        })
      })

  }

  const loadExistingCustomCopyPrompt = (prompt) => {
    console.log('user loaded custom prompt', prompt);
    setexampleOneInput(prompt.exampleOneInput);
    setexampleOneOutput(prompt.exampleOneOutput);

    setexampleTwoInput(prompt.exampleTwoInput)
    setexampleTwoOutput(prompt.exampleTwoOutput)

    setexampleThreeInput(prompt.exampleThreeInput);
    setexampleThreeOutput(prompt.exampleThreeOutput);

    setfirebaseDocId(prompt.id);
    setcustomCopyType(prompt.type);

    setinputForGenerations(prompt.inputForGenerations)

    // close modal;
    setshowCustomCopyModal(false);

    //make sure all sections are visible
    setstepsThatAreVisible([1,2,3])
  }

  const saveResultToFirebase = (output, index) => {
    console.log('saveResultToFirebase', output);
    // setsaveCopyResultInProgress(true)

    if (Array.isArray(output)){
      console.log('This is an array')
      const stringifyOutput = output[0];
      output = stringifyOutput;
    }

    const resultToSave = {
      type: 'customCopy',
      value: output,
      prompt: JSON.stringify({ promptId: firebaseDocId, input: inputForGenerations })
    }

    return new Promise((resolve, reject) => {
      Fire.shared.addSavedCopyResult(resultToSave)
      .then(res => {
          console.log("res >> ", res);
          // setsaveCopyResultInProgress(false)
          setdisplayMessage("Added to your saved items");

          // Push this index item to an array, so we can visually display liked items already
          if (likedItems.length === 0) {
            setlikedItems([index]);
          } else {
            setlikedItems(likedItems => [...likedItems, index])
          };

          setTimeout(() => {
            setdisplayMessage(null);
          },3000)

      })
  })
  }

  return (

    <Container fluid className="main-content-container px-4">
      {displayMessage && <Container fluid className="px-0" style={{ zIndex:99, position:'fixed', right:'0px', width:'300px'}}>
        <Alert className="mb-0">
          <i className="fa fa-info mx-2"></i> {displayMessage}
        </Alert>
      </Container>}

      <Modal open={showUpgradeModal} >
        <ModalHeader>Please Upgrade Your Account</ModalHeader>
        <ModalBody >
          <span><span role="img" aria-label="hand waving">👋</span> Hey there! We really appreciate you using our product, but in order to continue usage you need to select a plan. Thanks in advance for the support!</span>
          <br></br><br></br>
          <Button onClick={() => { window.location.href='/pricing' }}>Select a plan</Button>
        </ModalBody>
      </Modal>


      <Modal open={showCustomCopyModal} >
        <ModalHeader>Custom Copy Types</ModalHeader>
        <ModalBody>
          {!loadingCustomCopyPrompts &&
            <ListGroup style={{ maxHeight: 420, overflow: 'auto' }}>

              { usersCustomCopyPrompts.map((item, index) => (
                <ListGroupItem key={item.ts} style={{borderTop: '1px solid rgba(0,0,0,.125)'}}>
                  <Row>
                    <Col md="8" style={{paddingTop:'7px'}}>
                      {item.type} <span>(from {item.date})</span>
                    </Col>
                    <Col md="4">
                      <Button outline theme="secondary" onClick={() => { loadExistingCustomCopyPrompt(item) }} >Open</Button>
                    </Col>
                  </Row>
                </ListGroupItem>
                )
              )}
            </ListGroup>
          }


          { (!loadingCustomCopyPrompts && usersCustomCopyPrompts.length === 0) && <Row>
            <span>Nothing's here yet. Close this popup and create your first custom copy! We'll save it so you can come back here later.</span>
          </Row>}

          {loadingCustomCopyPrompts &&
            <Row>
              <img alt="loading spiral gif" style={{ margin: '0 auto' }} src={require("../images/loading.gif")} height="40" ></img>
            </Row>
          }

          <br></br>

          <Row>
            <Button onClick={() => {  setshowCustomCopyModal(false) }} className="btn btn-white btn-sm" style={{ margin: '0 auto' }}>Close</Button>
          </Row>

        </ModalBody>

      </Modal>


    <Row noGutters className="page-header py-4">
      <PageTitle title="Custom Copy" subtitle="Create" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>

    <Row style={{position: 'relative'}}>
      <Button onClick={() => {  setshowCustomCopyModal(true); getUsersCustomCopyPrompts() }} className="btn-outline-secondary" style={{position: 'absolute',right: '40px', top: '-30px'}}>Open Recent</Button>

      {/* <Button onClick={() => {  createCustomCopyDetails() }} className="btn-outline-secondary" style={{position: 'absolute',left: '40px', top: '-30px'}}>DEV test CREATE</Button> */}
      {/* <Button onClick={() => {  updateCustomCopyDetails() }} className="btn-outline-primary" style={{position: 'absolute',left: '40px', top: '15px'}}>DEV test UPDATE</Button> */}
      {/* <Button onClick={() => {  printSOmething() }} className="btn-outline-primary" style={{position: 'absolute',left: '80px', top: '15px'}}>DEV JDKDJKD UPDATE</Button> */}


    </Row>

    {/* poor spacing fix */}
    <Row style={{display: 'inline-block'}}></Row>


    {/* Step one; copy type */}
    <Row>
      <Card style={{margin: '20px'}}>
        <ListGroup flush>
          <ListGroupItem className="p-3">
            <Row>
              <Col>
                <Form>
                  <h5>Step 1. Tell Gary What You Want To Write About...</h5>
                  <br></br>

                  <FormGroup>
                    <label htmlFor="copyType">I want to write copy for...</label>
                    <FormInput id="copyType" placeholder="Instagram captions, Meta Descriptions, Twitter Posts, Etc." value={customCopyType} onChange={e => setcustomCopyType(e.target.value)}/>
                    <em style={{paddingTop: '15px'}}>Example: Instagram captions</em>
                  </FormGroup>

                  <br></br>
                  <Button onClick={() => { createCustomCopyDetails(); setstepsThatAreVisible(stepsThatAreVisible => [...stepsThatAreVisible, 2]); delayThenScroll(100, "addExamples"); sendSlackMessage()} }>Continue</Button>
                  {/* setstepsThatAreVisible(stepsThatAreVisible => [...stepsThatAreVisible, 2]) */}
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
      </ListGroup>
    </Card>
    </Row>

   {userCanAccessCustomPrompts &&
    <Container>
      <Row id="addExamples">
        { stepsThatAreVisible.includes(2) &&
        <Card style={{margin: '20px'}}>
          <ListGroup flush>
            <ListGroupItem className="p-3" >
              <Row>
                <Col>
                  <Form>
                    <h5>Step 2. Give Gary 3 Examples of {customCopyType}...</h5>
                    <br></br>
                    <em>Pro tip: <a href="https://intercom.help/garyai/en/articles/4820507-custom-copy-generator" target="_blank">Follow these tutorials</a> to learn how to give good examples!</em>
                    <FormGroup style={{backgroundColor: "#e9f0fd", padding: "20px", borderRadius: "5px"}}>
                    <h4>Example 1</h4>
                      <label htmlFor="">Input: </label>
                      <FormInput id="copyType" value={exampleOneInput} onChange={ e => setexampleOneInput(e.target.value) }/>

                      <br></br>
                      <label htmlFor="">Output: </label>
                      <FormTextarea id="example" value={exampleOneOutput} rows="5" onChange={ e => setexampleOneOutput(e.target.value) } />
                    </FormGroup>

                    <FormGroup style={{backgroundColor: "#e9f0fd", padding: "20px", borderRadius: "5px"}}>
                    <h4>Example 2</h4>
                      <label htmlFor="">Input: </label>
                      <FormInput id="copyType" value={exampleTwoInput} onChange={ e => setexampleTwoInput(e.target.value) }/>

                      <br></br>
                      <label htmlFor="">Output: </label>
                      <FormTextarea value={exampleTwoOutput} rows="5" onChange={ e => setexampleTwoOutput(e.target.value) } />
                    </FormGroup>

                    <FormGroup style={{backgroundColor: "#e9f0fd", padding: "20px", borderRadius: "5px"}}>
                    <h4>Example 3</h4>
                      <label htmlFor="">Input: </label>
                      <FormInput id="" value={exampleThreeInput} onChange={ e => setexampleThreeInput(e.target.value) }/>

                      <br></br>
                      <label htmlFor="">Output: </label>
                      <FormTextarea value={exampleThreeOutput} rows="5" onChange={ e => setexampleThreeOutput(e.target.value) }/>
                    </FormGroup>

                    <br></br>
                    <Button onClick={() => {setstepsThatAreVisible(stepsThatAreVisible => [...stepsThatAreVisible, 3]); updateCustomCopyDetails(firebaseDocId); delayThenScroll(100, "generateMore"); }} >Continue</Button>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
        </ListGroup>
      </Card>}
      </Row>
      <br></br>
      { stepsThatAreVisible.includes(3) && <Row>
        <Col lg="4" id="generateMore">
          {/* Inputs to generate copy */}

          <Card small>
            {/* <PromptInputForm/> */}
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                  <strong>Set up complete? Type in your next input and click generate!</strong>
                  <br></br><br></br>
                    <Form>
                      <FormGroup>
                        <label htmlFor="type">Input:</label>
                        <FormInput
                          type="text"
                          value={inputForGenerations}
                          placeholder=""
                          onChange={ e => setinputForGenerations(e.target.value) }
                        />
                      </FormGroup>



                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
            { !loading && <Button theme="accent" onClick={() => { doCustomGeneration(); updateCustomCopyDetails(firebaseDocId) }}>Generate</Button>}
            {/* <Button theme="accent" onClick={doCustomGeneration}>Generate 2</Button> */}
            { loading && <Button theme="secondary" className="disabled">Generate</Button>}
          </Card>

        </Col>
        <Col lg="8">

        {/* List of results */}
          {adCopyResults.map((adCopyResult, index) => (
              <Card small className="mb-4" key={index}>
                <ListGroup flush>
                  <ListGroupItem className="p-3">
                    <Row>
                      <Col>
                        <Form>

                        <Row form>
                          {/* Description */}
                          <Col md="12" className="form-group">
                            {index === 0 && <FormTextarea key={"formTextArea"+index} id="firstResult" rows="5" />}
                            {index !== 0 && <FormTextarea key={"formTextArea"+index} rows="5" defaultValue={adCopyResult[0].value[0]} />}
                          </Col>
                        </Row>

                        { likedItems.includes(index) && <Button theme="primary" className="mb-2 mr-1 disabled" >Saved</Button>}
                        { !likedItems.includes(index) && <Button outline theme="primary" className="mb-2 mr-1" onClick={() => saveResultToFirebase(adCopyResult[0].value[0], index)}>Save</Button>}

                        <Button outline theme="success" className="mb-2 mr-1" onClick={() => copyToClipboard(adCopyResult[0].value[0])}>Copy</Button>

                        </Form>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </Card>
          ))}

          { hasResults &&
            <div>

              {!loading && <Button outline theme="accent" onClick={() => { doCustomGeneration(); updateCustomCopyDetails(firebaseDocId) }}>Generate More</Button> }
              {loading && <Button theme="white" className="disabled">Generate More</Button> }
            </div>
          }

          {loading && <img alt="" src={require("../images/loading.gif")} height="100" style={{marginLeft: "40%"}} ></img> }

        </Col>
      </Row>}
    </Container>}

    {!userCanAccessCustomPrompts && stepsThatAreVisible.includes(2) &&
    // {!userCanAccessCustomPrompts &&
    <Row>
      {/* Just adding this css id to prevent error */}
      <Card id="addExamples">
        <div style={{textAlign: 'center', paddingTop:'30px'}}>
        <i className="material-icons" style={{fontSize: '35px'}} >lock</i>
          <h5>This Custom Copywriting technology is reserved for paying customers only</h5>
          <span style={{textAlign: 'left', display: 'block', padding:'20px'}}>What if you could train to GaryAI (the most advanced AI technology around) to write ANY copy — in your exact tone and style?
<br></br><br></br>Well, now you can.<br></br><br></br>With our Custom Copy generator.<br></br><br></br>It’ll help you write incredible copy for:<br></br>
<br></br>-Social media posts<br></br>-Emails<br></br>-Specific website copy<br></br>-More technical things like meta descriptions<br></br>And virtually anything you could think of!<br></br><br></br>How it works:
<br></br><br></br>Step 1 - Tell Gary What You Want To Write (eg. Instagram Captions)<br></br>Step 2 - Give Gary Some Examples (eg. Past Instagram Captions that fit your tone/brand)<br></br>Step 3 - Click Generate - It’s that easy!<br></br><br></br>And the results are astonishing.<br></br><br></br>Custom Copy is a feature for paid customers only so upgrade your account and give it a try today!
</span>
        </div>
        <div style={{margin: '0 auto'}}>
        <a className="upgradeButton" onClick={() => { window.open('/pricing?utm_source=unlockCustom')}}>Unlock this feature now</a>
        </div>
      </Card>


      {/* <p>You gotta pay</p> */}
    </Row>}
  </Container>
  );
};

export default CustomPrompt;
