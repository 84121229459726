var mixpanel = require('mixpanel-browser');
mixpanel.init("3b34e652b0f22a5aebdfacb437ac0ce4");

let env_check = true; // process.env.NODE_ENV === 'production';

let actions = {
  identify: (id) => {
    if (env_check) {
      mixpanel.identify(id);
      console.log(id);
    }
  },
  alias: (id) => {
    if (env_check) {
      mixpanel.alias(id);
      console.log(id);
    }
  },
  track: (name, props) => {
    if (env_check) {
      mixpanel.track(name, props);
      console.log(name);
      console.log(props);
    }
  },
  increment: (name, amount) => {
    if (env_check) {
      mixpanel.people.increment(name, amount);
      console.log('Increment '+name+' by '+amount);
      console.log(name);
    }
  },
  people: {
    set: (props) => {
      if (env_check) {
        mixpanel.people.set(props);
        console.log(props);
      }
    },
  },
  register_once: {
    set: (props) => {
      if (env_check) {
        mixpanel.register_once(props);
        console.log(props);
      }
    },
  },
};

export let Mixpanel = actions;