import firebase from 'firebase';
import { config } from '../environments/config';
require('firebase/auth');

const _ = require('lodash');
const apiAddress = _.get(config, 'backend.apiAddress');

const FirebaseKeys = _.get(config, 'firebaseKeys');

class Fire {
  constructor() {
    firebase.initializeApp(FirebaseKeys);
  }

  // Save basic info like name and email in firestore. Dont use this for anywhere other than register as originally designed (where they have no data stored yet).
  saveGeneralUserInfo = async (userDetails) => {
    console.log('saveGeneralUserInfo', userDetails);
    let now = Date.now();
    let oneWeek = 86400000 * 7;
    return new Promise((res, rej) => {
      let userData = {
        pu: 0,
        lu: 0,
        smu: 21,
        ts: now,
        te: now + oneWeek,
        fn: userDetails.first,
        ln: userDetails.last,
        e: userDetails.email,
        accessCode: userDetails.accessCode,
        trafficSource: userDetails.trafficSource,
        // forceCc: true
      };
      this.firestore
        .collection('users')
        .doc(this.uid)
        .set(userData)
        .then((ref) => {
          console.log('Done');
          this.giveReferralCredits(userDetails.rid)
            .then(() => {
              res({ ref: ref, userData: userData });
            })
            .catch((err) => {
              console.log('referral credits error:', err);
              res({ ref: ref, userData: userData });
            });
        })
        .catch((error) => {
          console.log('Error', error);
          rej(error);
        });
    });
  };

  giveReferralCredits = async (referralId = '') => {
    return new Promise((resolve, reject) => {
      if (referralId == '' || referralId == undefined) {
        resolve();
      } else {
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then(function(idToken) {
            let requestBody = {
              rid: referralId,
            };
            const requestOptions = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${_.get(config, 'backend.bearerToken')}`,
                firebaseidtoken: idToken,
              },
              body: JSON.stringify(requestBody),
            };
            fetch(`${apiAddress}/v1/db/give-referral-credits`, requestOptions)
              .then(() => {
                resolve();
              })
              .catch((err) => {
                console.log('fetch referral update error:', err);
                reject(); //Ok because resolved with original data
              });
          })
          .catch((err) => {
            console.log(
              'Error occured while accessing current firebase user',
              err
            );
          });
      }
    });
  };

  addCopyProfile = async (profileObject) => {
    console.log('addCopyProfile', profileObject);
    return new Promise((res, rej) => {
      this.firestore
        .collection('users')
        .doc(this.uid)
        .collection('profiles')
        .add({
          uid: this.uid,
          created: this.timestamp,
          type: profileObject.type,
          name: profileObject.name,
          desc: profileObject.description,
          benefits: profileObject.benefits,
        })
        .then((ref) => {
          console.log('Done');
          res({ ref: ref });
        })
        .catch((error) => {
          console.log('Error', error);
          rej(error);
        });
    });
  };

  getUserCopyProfiles = async () => {
    console.log('getUserCopyProfiles');
    return new Promise(async (res, rej) => {
      const snapshot = await this.firestore
        .collection('users')
        .doc(this.uid)
        .collection('profiles')
        .get();
      const userProfiles = snapshot.docs.map((doc) => doc.data());
      res(userProfiles);
    });
  };

  getUserDetails = async () => {
    console.log('getUserDetails', this.uid);
    return new Promise(async (res, rej) => {
      const snapshot = await this.firestore
        .collection('users')
        .doc(this.uid)
        .get();
      const userDetails = snapshot.data();
      res(userDetails);
    });
  };

  saveStarRating = async (sid, stars, savedOrResults = 'results') => {
    console.log('saveStarRating', sid);
    return new Promise((resolve, reject) => {
      this.firestore
        .collection('users')
        .doc(this.uid)
        .collection(savedOrResults)
        .doc(sid)
        .set(
          {
            stars: stars,
          },
          { merge: true }
        )
        .then((ref) => {
          console.log('Done');
          resolve({ ref: ref });
        })
        .catch((error) => {
          console.log('Error', error);
          reject(error);
        });
    });
  };

  // this is for storing copy snippets that are saved by user
  addSavedCopyResult = async (copyDetails) => {
    console.log('addSavedCopyResult', copyDetails);

    return new Promise((res, rej) => {
      let objectToSave = {
        type: copyDetails.type,
        value: copyDetails.value,
        prompt: copyDetails.prompt,
        ts: this.timestamp,
      };
      if (copyDetails.type == 'custom') {
        objectToSave.title = copyDetails.title;
        objectToSave.customGeneratorId = copyDetails.id;
      }
      this.firestore
        .collection('users')
        .doc(this.uid)
        .collection('saved')
        .add(objectToSave)
        .then((ref) => {
          console.log('Done');
          res({ ref: ref });
        })
        .catch((error) => {
          console.log('Error', error);
          rej(error);
        });
    });
  };
  // this is for storing ANY copy snippets result that is returned by GPT3
  addCopyResult = async (copyDetails) => {
    console.log('addCopyResult', copyDetails);
    return new Promise((res, rej) => {
      this.firestore
        .collection('users')
        .doc(this.uid)
        .collection('results')
        .add({
          type: copyDetails.type,
          value: copyDetails.value,
          prompt: copyDetails.prompt,
          ts: this.timestamp,
          // rId: copyDetails.requestId // GPT3 requestId
        })
        .then((ref) => {
          console.log('Done');
          res({ ref: ref });
        })
        .catch((error) => {
          console.log('Error', error);
          rej(error);
        });
    });
  };

  getSavedCopyResults = async () => {
    console.log('getSavedCopyResults');
    return new Promise(async (res, rej) => {
      const snapshot = await this.firestore
        .collection('users')
        .doc(this.uid)
        .collection('saved')
        .get();
      // const savedCopyResults = snapshot.docs.map(doc => doc.data());
      const savedCopyResults = snapshot.docs.map((doc) => {
        // console.log('doc',doc);
        let output = doc.data();
        output['id'] = doc.id;
        return output;
      });
      let copyResultsSorted = _.sortBy(savedCopyResults, ['ts']).reverse();
      res(copyResultsSorted);
    });
  };

  getAllCopyResults = async () => {
    console.log('getSavedCopyResults');
    return new Promise(async (res, rej) => {
      const snapshot = await this.firestore
        .collection('users')
        .doc(this.uid)
        .collection('results')
        .get();
      const copyResults = snapshot.docs.map((doc) => {
        // console.log('doc',doc);
        let output = doc.data();
        output['id'] = doc.id;
        return output;
      });
      // console.log('Copy Results:',copyResults);
      let copyResultsSorted = _.sortBy(copyResults, ['ts']).reverse();
      res(copyResultsSorted);
    });
  };

  createUserCustomCopyPrompt = async (customPromptDetails) => {
    console.log('createUserCustomCopyInfo', customPromptDetails);
    return new Promise((res, rej) => {
      this.firestore
        .collection('customPrompts')
        .doc(this.uid)
        .collection('saved')
        .add({
          uid: this.uid,
          ts: this.timestamp,
          created: this.timestamp,
          ue: customPromptDetails.user,
          type: customPromptDetails.customCopyType,
          exampleOneInput: customPromptDetails.exampleOneInput,
          exampleOneOutput: customPromptDetails.exampleOneOutput,
          exampleTwoInput: customPromptDetails.exampleTwoInput,
          exampleTwoOutput: customPromptDetails.exampleTwoOutput,
          exampleThreeInput: customPromptDetails.exampleThreeInput,
          exampleThreeOutput: customPromptDetails.exampleThreeOutput,
          inputForGenerations: customPromptDetails.inputForGenerations,
          //autosave: true false?
        })
        .then((docRef) => {
          console.log('Done');
          res({ docRef: docRef });
        })
        .catch((error) => {
          console.log('Error', error);
          rej(error);
        });
    });
  };

  updateUserCustomCopyPrompt = async (customPromptDetails) => {
    console.log('updateUserCustomCopyPrompt');

    return new Promise((res, rej) => {
      this.firestore
        .collection('customPrompts')
        .doc(this.uid)
        .collection('saved')
        .doc(customPromptDetails.docId)
        .update({
          uid: this.uid,
          ts: this.timestamp,
          // created: this.timestamp,
          // ue: customPromptDetails.user,
          type: customPromptDetails.customCopyType,
          exampleOneInput: customPromptDetails.exampleOneInput,
          exampleOneOutput: customPromptDetails.exampleOneOutput,
          exampleTwoInput: customPromptDetails.exampleTwoInput,
          exampleTwoOutput: customPromptDetails.exampleTwoOutput,
          exampleThreeInput: customPromptDetails.exampleThreeInput,
          exampleThreeOutput: customPromptDetails.exampleThreeOutput,
          inputForGenerations: customPromptDetails.inputForGenerations,
        })
        .then((docRef) => {
          console.log('Done');
          res({ Updated: true });
        })
        .catch((error) => {
          console.log('Error', error);
          rej(error);
        });
    });
  };

  getAllUsersCustomCopyPrompts = async () => {
    console.log('getAllUsersCustomCopyPrompts');
    return new Promise(async (res, rej) => {
      const snapshot = await this.firestore
        .collection('customPrompts')
        .doc(this.uid)
        .collection('saved')
        .get();
      // const savedCopyResults = snapshot.docs.map(doc => doc.data());
      const savedCustomCopyPrompts = snapshot.docs.map((doc) => {
        // console.log('doc',doc);
        let output = doc.data();
        output['id'] = doc.id;
        return output;
      });
      let copyResultsSorted = _.sortBy(savedCustomCopyPrompts, [
        'ts',
      ]).reverse();
      res(copyResultsSorted);
    });
  };

  /**
   * The problem was that the website laoded before firebase user existed and then it loaded again once the user existed.
   *
   * The fix was to check if they have a firebase user saved in redux and load the page if we know they do.  The problem is that firebase isnt loaded when the page loads
   * We need to wait for firebase to load. (takes ~ 1 second).
   *
   * Q: does it only take 1 second to load on login or on every page load?  I think it's every page laod.  This might be the reason to see if I can set firebase user from Redux
   * Q: why dont history or saved page seem to be affected?
   *
   * I could wrap every on page load function insides inside a function that checks for firebase id before fireing any functions.  Doesnt seem like a DRY solution.
   *
   * Could add a loading page... only shows if firebase is not defined....
   *
   * */

  /**
   * Debugging shows this function is only called on page refresh/ 1st load.  not on route change.
   * Problems arise when page is refreshed mid app.
   * */

  /** vvv SOLUTION: Wait for Firebase uid to load. Does not take long. */

  /** Recursively Call and Check if this.uid is still undefined */
  checkAndWaitForFirebaseLoad = async (recursive = true) => {
    return new Promise(async (res, rej) => {
      if (this.uid === undefined) {
        setTimeout(() => {
          this.checkAndWaitForFirebaseLoad().then(() => {
            /** POTENTIAL Issue if firebase error is Infinite recursion stack depth. (Not a problem until its a problem) */
            res();
          });
        }, 500);
      } else {
        res(); /** Base Case */
      }
    });
  };

  get firestore() {
    return firebase.firestore();
  }

  get uid() {
    return (firebase.auth().currentUser || {}).uid;
  }

  get timestamp() {
    return Date.now();
  }
}

Fire.shared = new Fire();
export default Fire;
