/** USED TO SIZE TEXT AREAS */
const countNewLines = (text) => {
    let textString = String(text);
    // console.log('textString', textString);
    let lines = textString.split("\n");
    let linesLength = lines.length;
    // console.log('LinesLength', linesLength);
    if(linesLength == 0){linesLength = 1;}
    return  linesLength;
  }


export {
  countNewLines
};
