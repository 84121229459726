import React, { useEffect, useState } from 'react';
import { setUser } from '../actions';
import { MixpanelSuper } from '../mixpanelSuper';
import {
  Card,
  Container,
  Row,
  Col,
  ListGroupItem,
  Form,
  FormInput,
  Button,
  ListGroup,
  Modal,
  ModalBody,
  ModalHeader,
  CardHeader,
  CardBody,
  Badge,
} from 'shards-react';

import firebase from 'firebase';
import { config } from '../environments/config';
import PageTitle from '../components/common/PageTitle';
import { connect } from 'react-redux';
import { increment } from '../actions';
import toast, { Toaster } from 'react-hot-toast';

export const Dashboard = () => {
  const [email, setEmail] = useState('');
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showVideo, setShowVideo] = useState(false);
  const [showGeneralInputTipModal, setShowGeneralInputTipModal] = useState(
    false
  );

  const [hasSavedItems, setHasSavedItems] = useState(false);
  const [hasHistoryItems, setHasHistoryItems] = useState(false);
  const [savedItemCount, setSavedItemCount] = useState(0);
  const [historyItemCount, setHistoryItemCount] = useState(0);

  const [recentSavedItems, setRecentSavedItems] = useState([]);
  const [recentHistoryItems, setRecentHistoryItems] = useState([]);
  const [retrievedUserStats, setRetrievedUserStats] = useState(false);
  const complimentsArray = [
    'Your content is amazing!',
    'These words are magic!',
    'You are on fire!',
    'This writing is mesmerizing!',
    'You are a wordsmith!',
    'You have a gift for writing!',
    'Your writing is beautiful!',
    'You are a talented writer!',
    'Your writing is very insightful!',
    'You have a great writing style!',
  ];

  let isThisMobileDevice = window.navigator.userAgent.indexOf('Mobile') > -1;
  console.log('isThisMobileDevice', isThisMobileDevice);

  const getUserSavedCollection = async (userId) => {
    let usersSavedCollection = firebase
      .firestore()
      .collection('users')
      .doc(userId)
      .collection('saved');
    let userResults = await usersSavedCollection.get();

    setSavedItemCount(userResults.docs.length);
    if (userResults.docs.length > 0) {
      // get the last three items
      let lastThreeItems = userResults.docs.slice(
        userResults.docs.length - 3,
        userResults.docs.length
      );
      let lastThreeItemsArray = [];
      lastThreeItems.forEach((item) => {
        lastThreeItemsArray.push(item.data());
      });

      setRecentSavedItems(lastThreeItemsArray);
      setHasSavedItems(true);
    }
  };
  const getUserResultsCollection = async (userId) => {
    let userResultsCollection = firebase
      .firestore()
      .collection('users')
      .doc(userId)
      .collection('results');
    let userResults = await userResultsCollection.get();

    setHistoryItemCount(userResults.docs.length);
    if (userResults.docs.length > 0) {
      // get the last three items
      let lastThreeItems = userResults.docs.slice(
        userResults.docs.length - 3,
        userResults.docs.length
      );
      let lastThreeItemsArray = [];
      lastThreeItems.forEach((item) => {
        lastThreeItemsArray.push(item.data());
      });

      console.log('lastThreeItemsArray', lastThreeItemsArray);
      setRecentHistoryItems(lastThreeItemsArray);
      setHasHistoryItems(true);
    }
  };

  useEffect(() => {
    console.log('Dashboard useEffect');
    // log current url
    console.log(window.location.href);
    var url = new URL(window.location.href);
    var emailInUrl = url.searchParams.get('email');
    MixpanelSuper.trackScreenView('dashboard');

    // get current firebase user
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        console.log('user is signed in');
        console.log(user);
        setEmail(user.email);

        // user.email
        //
        // user.uid
        // load document from firestore
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then(function(doc) {
            if (doc.exists) {
              let data = doc.data();
              console.log('Document data:', data);
              setUserData(data);
              // setUser(doc.data());
              // setIsLoading(false);

              // check if user has any saved items

              try {
                console.log('dooes user have history?');
                getUserResultsCollection(user.uid);
                console.log('dooes user have saved history?');
                getUserSavedCollection(user.uid);
                setRetrievedUserStats(true);
              } catch (e) {
                console.log('error getting saved and history', e);
              }
            }
          });
      }
    });
  }, []);

  return (
    <Container fluid>
      {/* Page Header */}
      <div>
        <Toaster />
      </div>
      <Row
        noGutters
        className="page-header py-6"
        style={{ marginTop: 30, paddingLeft: 15 }}
      >
        <PageTitle sm="4" title="Dashboard" className="text-sm-left" />
      </Row>
      {/* Welcome Video Modal */}
      <Modal open={showVideo} className={'modal-lg'}>
        <ModalHeader>Welcome Video</ModalHeader>
        <ModalBody>
          <iframe
            width="100%"
            height="500"
            src="https://www.youtube.com/embed/KyYq2ZjtwSk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>

          <p>
            Watch in seperate tab{' '}
            <a
              href="https://www.youtube.com/watch?v=KyYq2ZjtwSk"
              target="_blank"
            >
              here
            </a>
          </p>
          <center>
            <button
              className="mb-2 btn btn-primary mr-2"
              onClick={() => {
                setShowVideo(false);
              }}
            >
              Close video
            </button>
          </center>
          {/* <button>go to demo</button> */}
        </ModalBody>
      </Modal>

      {/* showGeneralInputTipModal */}
      <Modal open={showGeneralInputTipModal}>
        <ModalHeader>Quick tip before the demo</ModalHeader>
        <ModalBody>
          <center>
            <i
              className="material-icons mr-1"
              style={{
                paddingTop: 1,
                fontSize: 30,
                color: '#039855',
                backgroundColor: '#D1FADF',
                padding: '12px',
                borderRadius: '50%',
              }}
            >
              info
            </i>

            <h6 style={{ marginTop: 10, marginBottom: 20 }}>
              Take note of the examples we give you.
            </h6>
            <p>
              Creating content is so easy - all you do is tell Gary what to
              write about!
              <br />
              But, when you are typing in what you want to write,{' '}
              <strong style={{ backgroundColor: '#D1FADF' }}>
                structure your sentences like the examples we provide
              </strong>{' '}
              for the best results!
            </p>

            <div
              style={{
                backgroundColor: '#039855',
                padding: 2,
                borderRadius: 10,
              }}
            >
              <img
                src={require('../images/follow-tips.gif')}
                style={{
                  width: '100%',
                  borderRadius: 6,
                }}
              />
            </div>
            <Button
              theme="primary"
              style={{ marginTop: 20 }}
              onClick={() => {
                // setShowGeneralInputTipModal(false);
                console.log('Go to write');
                window.location.href = '/write';
              }}
            >
              Ok, let's do the demo!
            </Button>
            <br></br>
          </center>
        </ModalBody>
      </Modal>

      {/* welcome content */}
      <Container
        style={{
          marginTop: 40,
        }}
      >
        <ListGroup flush>
          <ListGroupItem
            className="p-3"
            style={{
              borderWidth: 1,
              borderColor: '#e6e6e6',
              borderStyle: 'solid',
              // dropshadow
              boxShadow: '0px 0px 10px #e6e6e6',
              backgroundColor: '#0E172F',
            }}
          >
            <Row>
              <Col>
                <h4 style={{ color: 'white' }}>
                  Welcome {userData.fn && userData.fn} 👋
                </h4>
                <p style={{ color: 'white' }}>
                  We made a welcome package for you to get started:
                </p>
              </Col>
            </Row>
            <Row style={{ paddingBottom: 25 }}>
              <Col
                style={{
                  marginLeft: 50,
                  marginRight: 50,
                  paddingTop: 10,
                  width: 100,
                  cursor: 'pointer',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#354364',
                    padding: 29,
                    borderRadius: 5,
                  }}
                >
                  <center
                    onClick={() => {
                      console.log('open new tab');
                      setShowVideo(true);
                    }}
                  >
                    <img
                      src={require('../images/playIcon.png')}
                      style={{ height: 45, paddingBottom: 5, color: 'white' }}
                    />
                    <h6 style={{ color: '#E4E6E9' }}>Getting started video</h6>
                  </center>
                </div>
              </Col>

              <Col
                style={{
                  marginLeft: 50,
                  marginRight: 50,
                  paddingTop: 10,
                  width: 100,
                  cursor: 'pointer',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#354364',
                    padding: 29,
                    borderRadius: 5,
                  }}
                >
                  <center
                    onClick={() => {
                      setShowGeneralInputTipModal(true);
                      // window.open('https://www.garyai.com/bootcamp/', '_blank');
                    }}
                  >
                    <img
                      src={require('../images/demo.png')}
                      style={{ height: 45, paddingBottom: 5 }}
                    />
                    <h6 style={{ color: '#E4E6E9' }}>Try the demo</h6>
                  </center>
                </div>
              </Col>
              <Col
                style={{
                  marginLeft: 50,
                  marginRight: 50,
                  width: 100,
                  cursor: 'pointer',
                  paddingTop: 10,
                }}
              >
                <div
                  style={{
                    backgroundColor: '#354364',
                    padding: 29,
                    borderRadius: 5,
                  }}
                >
                  <center
                    onClick={() => {
                      window.$crisp.push(['do', 'chat:open']);
                    }}
                  >
                    <img
                      src={require('../images/chatIcon2.png')}
                      style={{ height: 45, paddingBottom: 5 }}
                    />
                    {/* <h6>Gary AI Bootcamp (coming soon)</h6> */}
                    <h6 style={{ color: '#E4E6E9' }}>Say hello to support</h6>
                  </center>
                </div>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Container>

      {/* Give Get Credits */}
      <Container style={{ marginTop: 40 }}>
        <Card
          small
          className="mb-3"
          style={{ boxShadow: '0px 0px 10px #e6e6e6' }}
        >
          <CardHeader className="border-bottom">
            <h6 className="m-0">Give credits, get credits!</h6>
          </CardHeader>

          <CardBody className="p-0">
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <p>
                  You can give credits to your friends and family to get
                  credits. Use your invite code below and automatically get 25
                  each when they sign up!
                </p>
                <FormInput
                  type="text"
                  style={{ width: '30%' }}
                  value={`https://app.garyai.com/try-it-free?rid=${userData.uid ||
                    ''}&utm_source=garyai&utm_campaign=giveget`}
                />
                <button
                  className="mb-2 btn btn-primary mr-2"
                  style={{ marginTop: 10 }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://app.garyai.com/try-it-free?utm_source=garyai&utm_campaign=giveget&rid=${userData.uid}`
                    );
                    toast.success('Copied to clipboard');
                  }}
                >
                  Copy link
                </button>
              </ListGroupItem>
            </ListGroup>
          </CardBody>
        </Card>
      </Container>

      {/* Tempates */}
      <Container style={{ marginTop: 40 }}>
        <Card
          small
          className="mb-3"
          style={{ boxShadow: '0px 0px 10px #e6e6e6' }}
        >
          <CardHeader className="border-bottom">
            <h6 className="m-0">Create amazing content with templates</h6>
            <a
              href="/write"
              style={{
                position: 'absolute',
                right: '20px',
                top: '20px',
              }}
            >
              View all
            </a>
          </CardHeader>

          <CardBody className="p-0">
            <ListGroup flush>
              <ListGroupItem className="p-3">
                {/* <p> Currently trending... </p> */}
                {/* 3 cards per row */}
                <Row>
                  <Col>
                    <Card
                      className="mb-3"
                      style={{ boxShadow: 'none', border: '1px solid #e1e5eb' }}
                    >
                      <CardBody>
                        <h6>"AIDA" Framework</h6>
                        <p>
                          Stop your readers in their tracks with the popular
                          copywriting framework Attention Interest Desire Action
                          formula!
                        </p>

                        <Button
                          className="mb-2"
                          theme="white"
                          onClick={() => {
                            window.location.href = '/generate/aida';
                          }}
                        >
                          View
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card
                      className="mb-3"
                      style={{ boxShadow: 'none', border: '1px solid #e1e5eb' }}
                    >
                      <CardBody>
                        <h6>"PAS" Framework</h6>
                        <p>
                          Take your audience on a journey of pain and agony to
                          highlight the importance of your solution with the
                          Pain Agitate Solution template.
                        </p>

                        <Button
                          className="mb-2"
                          theme="white"
                          onClick={() => {
                            window.location.href = '/generate/pas';
                          }}
                        >
                          View
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card
                      className="mb-3"
                      style={{ boxShadow: 'none', border: '1px solid #e1e5eb' }}
                    >
                      <CardBody>
                        <h6>Product Descriptions</h6>
                        <p>
                          Easily turn a few words into beautiful product
                          descriptions for your ecommerce store
                        </p>

                        <Button
                          theme="white"
                          onClick={() => {
                            // navigate to new url
                            window.location.href =
                              '/generate/product-descriptions';
                          }}
                        >
                          View
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </CardBody>
        </Card>
      </Container>

      {/* Past work */}
      {retrievedUserStats && (
        <Container style={{ marginTop: 40 }}>
          <Row>
            {/* Saved */}
            <Col>
              <Card
                small
                className="mb-3"
                style={{ boxShadow: '0px 0px 10px #e6e6e6' }}
              >
                <CardHeader className="border-bottom">
                  <h6 className="m-0">View saved items</h6>
                  <a
                    href="/saved"
                    style={{
                      position: 'absolute',
                      right: '20px',
                      top: '20px',
                    }}
                  >
                    View saved
                  </a>
                </CardHeader>

                <CardBody className="p-0">
                  <ListGroup flush>
                    <ListGroupItem className="p-3">
                      {hasSavedItems ? (
                        <div>
                          <p style={{ marginBottom: 5 }}>
                            {savedItemCount} saved items so far.{' '}
                            {
                              complimentsArray[
                                Math.floor(
                                  Math.random() * complimentsArray.length
                                )
                              ]
                            }
                          </p>
                          <ListGroup small flush className="list-group-small">
                            {recentSavedItems.map((item, idx) => (
                              <ListGroupItem
                                key={idx}
                                className="d-flex px-3"
                                style={{ border: '1px solid #e1e5eb' }}
                              >
                                <Badge
                                  pill
                                  theme="info"
                                  style={{
                                    backgroundColor: 'rgb(233, 240, 253)',
                                    color: '#007bff',
                                  }}
                                >
                                  {item.type}
                                </Badge>
                                <span style={{ paddingLeft: 5 }}>
                                  {item.value.length > 45 ? (
                                    <span>
                                      {item.value.substring(0, 45)}
                                      ...
                                    </span>
                                  ) : (
                                    item.value
                                  )}
                                </span>
                              </ListGroupItem>
                            ))}
                          </ListGroup>
                        </div>
                      ) : (
                        <span>
                          Your saved content will appear. Go{' '}
                          <a href="/write">create</a> and save something.
                        </span>
                      )}
                    </ListGroupItem>
                  </ListGroup>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card
                small
                className="mb-3"
                style={{ boxShadow: '0px 0px 10px #e6e6e6' }}
              >
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Browse content history</h6>
                  <a
                    href="/history"
                    style={{
                      position: 'absolute',
                      right: '20px',
                      top: '20px',
                    }}
                  >
                    View history
                  </a>
                </CardHeader>

                <CardBody className="p-0">
                  <ListGroup flush>
                    <ListGroupItem className="p-3">
                      {hasSavedItems ? (
                        <div>
                          <p style={{ marginBottom: 5 }}>
                            You have {historyItemCount} items in history so far.{' '}
                            {
                              complimentsArray[
                                Math.floor(
                                  Math.random() * complimentsArray.length
                                )
                              ]
                            }
                          </p>
                          <ListGroup small flush className="list-group-small">
                            {recentHistoryItems.map((item, idx) => (
                              <ListGroupItem
                                key={idx}
                                className="d-flex px-3"
                                style={{ border: '1px solid #e1e5eb' }}
                              >
                                <Badge
                                  pill
                                  theme="info"
                                  style={{
                                    backgroundColor: 'rgb(233, 240, 253)',
                                    color: '#007bff',
                                  }}
                                >
                                  {item.type}
                                </Badge>
                                <span style={{ paddingLeft: 5 }}>
                                  {item.value[0].length > 45 ? (
                                    <span>
                                      {item.value[0].substring(0, 45)}
                                      ...
                                    </span>
                                  ) : (
                                    item.value[0]
                                  )}
                                </span>
                              </ListGroupItem>
                            ))}
                          </ListGroup>
                        </div>
                      ) : (
                        <span>
                          Your past content will appear. Go{' '}
                          <a href="/write">create</a> some first.
                        </span>
                      )}

                      {/* <p>Nice job, you have X items... View them here.</p> */}
                    </ListGroupItem>
                  </ListGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}

      <Container style={{ marginTop: 40 }}>
        <Card
          small
          className="mb-3"
          style={{ boxShadow: '0px 0px 10px #e6e6e6' }}
        >
          <CardHeader className="border-bottom">
            <Row>
              <Col md="1" style={{ maxWidth: 45 }}>
                <Badge pill theme="primary" style={{}}>
                  Beta
                </Badge>
              </Col>
              <Col md="11">
                <h6 className="m-0">
                  New: Create Custom Content with Gary's New Feature!
                </h6>
              </Col>
            </Row>
            <a
              href="/custom"
              style={{
                position: 'absolute',
                right: '20px',
                top: '20px',
              }}
            >
              Learn more
            </a>
          </CardHeader>

          <CardBody className="p-0">
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <p>
                  {' '}
                  Imagine if you could train our cutting-edge AI to write custom
                  content for ANY medium? In ANY style? Well... Now you can!{' '}
                </p>
                <Button
                  className="mb-2"
                  theme="white"
                  onClick={() => {
                    window.location.href = '/custom';
                  }}
                >
                  Learn more
                </Button>
              </ListGroupItem>
            </ListGroup>
          </CardBody>
        </Card>
      </Container>
    </Container>
  );
};
