export const increment = () => {
  return {
    type: 'INCREMENT'
  };
};

export const setUser = (user) => {
  return {
    type: 'SET_USER',
    payload: user
  };
};

export const setFirebaseUserData = (userData) => {
  return {
    type: 'SET_FIREBASE_USER_DATA',
    payload: userData
  };
};

export const setSubscribed = () => {
  return {
    type: 'SET_SUBSCRIBED'
  };
};

export const setCredits = (credits) => {
  return {
    type: 'SET_CREDITS',
    payload: credits
  };
};

export const setDaysRemaining = (days) => {
  return {
    type: 'SET_PERIOD',
    payload: days
  };
};

export const decrementCredits = (credits) => {
  return {
    type: 'DECREMENT_CREDITS'
  };
};

export const userLogout = () => {
  return {
    type: 'USER_LOGOUT'
  };
};
