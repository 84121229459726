import React, { useEffect } from 'react';
import { MixpanelSuper } from '../mixpanelSuper';
import {
  Card,
  Container,
  Row,
  Col,
  ListGroupItem,
  Form,
  FormInput,
  Button,
  ListGroup,
  FormGroup,
} from 'shards-react';

import firebase from 'firebase';

const createAccount = async () => {
  console.log('createAccount');
};

const SignUpForm = () => (
  <ListGroup flush>
    <ListGroupItem className="p-3">
      <Row>
        <Col>
          <Form>
            <Row form>
              <Col md="6" className="form-group">
                <label htmlFor="feEmailAddress">First name</label>
                <FormInput id="firstName" type="text" />
              </Col>

              <Col md="6" className="form-group">
                <label htmlFor="feEmailAddress">Last name</label>
                <FormInput id="feEmailAddress" type="email" />
              </Col>
            </Row>

            <FormGroup>
              <label htmlFor="email">Email</label>
              <FormInput id="email" type="email" />
            </FormGroup>

            <FormGroup>
              <label htmlFor="fePassword">Password</label>
              <FormInput
                id="fePassword"
                type="password"
                placeholder="Password"
              />
            </FormGroup>

            <Button onClick={() => createAccount()}>Create New Account</Button>
          </Form>
        </Col>
      </Row>
    </ListGroupItem>
  </ListGroup>
);

export const OnboardingPart3 = () => {
  //   const [email, setEmail] = React.useState('');
  // ...

  let isThisMobileDevice = window.navigator.userAgent.indexOf('Mobile') > -1;
  console.log('isThisMobileDevice', isThisMobileDevice);

  useEffect(() => {
    MixpanelSuper.trackScreenView('onboarding_3');
  }, []);
  return (
    <Container fluid>
      <div style={{ margin: 30 }}>
        <center>
          <h4 className="m-0">Step 3</h4>
          <p>You'll be up and running in 2 minutes</p>
        </center>
      </div>
      <Container style={{ width: isThisMobileDevice ? '100%' : '40%' }}>
        <SignUpForm />
      </Container>
      <div style={{ margin: 20 }}>
        <center>
          <p>
            Ready <a href="/pick-a-plan">Continue </a>
          </p>
        </center>
      </div>
    </Container>
  );
};
