import React, { useEffect, useState } from 'react';

import toast, { Toaster } from 'react-hot-toast';
import { MixpanelSuper } from '../mixpanelSuper';
import {
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  Card,
  FormRadio,
  FormCheckbox,
  Badge,
} from 'shards-react';
import PageTitle from '../components/common/PageTitle';
import firebase from 'firebase';
import { config } from '../environments/config';

const PricingContainer = () => {
  const [selectedPlan, setSelectedPlan] = React.useState('starter');
  const [isLoading, setIsLoading] = React.useState(false);

  const _ = require('lodash');
  let apiAddress = _.get(config, 'backend.apiAddress');
  let currentPageUrl = window.location.href;

  if (currentPageUrl.includes('localhost')) {
    console.log('LOCALHOST');
    apiAddress = 'http://localhost:3001';
  }

  console.log('api address ', apiAddress);

  const startStripeCheckout = async (planName) => {
    setIsLoading(true);
    console.log('startStripeCheckout', planName);
    // https://stripe.com/docs/checkout/quickstart
    let endpoint = apiAddress + '/api/v2/stripe/create-checkout-session';

    let email;
    if (window.Gaiue) {
      email = window.Gaiue;
    }
    const body = {
      stripePlanName: planName,
      email: email,
    };
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      console.log('response', response);
      if (response.status != 200) {
        toast.error(`Error: ${response.statusText}`);
        setIsLoading(false);
        return 'error';
      }

      let data = await response.json();
      console.log('data', data);
      window.location.href = data.url;
      setIsLoading(false);
    } catch (e) {
      console.log('error', e);
      toast.error('Error: ' + e.message);
      setIsLoading(false);
    }

    // let response = await request.json();
    // console.log('response', response);
  };

  useEffect(() => {
    console.log('PricingContainer useEffect');
    MixpanelSuper.trackScreenView('pick_plan');
  }, []);

  return (
    <ListGroup flush>
      <Row>
        <Col
          onClick={() => {
            setSelectedPlan('starter');
          }}
          style={{
            backgroundColor: 'white',
            borderRadius: 5,
            margin: 10,
            padding: 20,
            borderWidth: selectedPlan === 'starter' ? 2 : 0,
            // 1px border solid
            borderStyle: 'solid',
            borderColor: '#1972F5',
            boxShadow: '0px 1px 4px 0px rgba(109, 110, 111, 0.38)',
          }}
        >
          <p
            style={{
              backgroundColor: '#1972f536',
              borderRadius: 5,
              width: 55,
              textAlign: 'center',
              color: '#1972F5',
            }}
          >
            Starter
          </p>
          <h3 className="card-title">$29/month</h3>
          <p>For hobbyists just getting started</p>
          <p>
            <i className="check fas fa-check"> </i> 200 runs per month
          </p>
          <p>
            <i className="check fas fa-check"> </i> Access to most content
            generators
          </p>
          <p>
            <i className="check fas fa-check"> </i> Build custom generators{' '}
          </p>
          <p>
            <i className="check fas fa-check"> </i> Self serve support
          </p>

          {isLoading ? (
            <img
              alt=""
              src={require('../images/loading.gif')}
              height="20"
            ></img>
          ) : (
            <button
              type="button"
              style={{ width: '100%' }}
              class={
                selectedPlan === 'starter'
                  ? 'mb-2 btn btn-primary mr-2'
                  : 'mb-2 btn btn-sm btn-white mr-1'
              }
              onClick={async () => {
                MixpanelSuper.trackButtonClick('start_plan_starter');
                try {
                  window.fbq('track', 'ViewContent', { value: '29.99' });
                } catch (e) {
                  console.log(
                    'Could not fire ViewContent pixel event because',
                    e
                  );
                }
                await startStripeCheckout('starter');
              }}
            >
              Continue
            </button>
          )}
        </Col>
        <Col
          onClick={() => {
            setSelectedPlan('pro');
          }}
          style={{
            backgroundColor: 'white',
            borderRadius: 5,
            margin: 10,
            padding: 20,
            borderWidth: selectedPlan === 'pro' ? 2 : 0,
            // 1px border solid
            borderStyle: 'solid',
            borderColor: '#1972F5',
            boxShadow: '0px 1px 4px 0px rgba(109, 110, 111, 0.38)',
          }}
        >
          <p
            style={{
              backgroundColor: '#1972f536',
              borderRadius: 5,
              width: 35,
              textAlign: 'center',
              color: '#1972F5',
            }}
          >
            Pro
          </p>
          <h3 className="card-title">$49/month</h3>
          <p>Good for the best</p>
          <p>
            <i className="check fas fa-check"> </i> Unlimited runs per month
          </p>
          <p>
            <i className="check fas fa-check"> </i> Access to all content
            generators
          </p>
          <p>
            <i className="check fas fa-check"> </i> Build custom generators
          </p>
          <p>
            <i className="check fas fa-check"> </i>24/7 priority support
          </p>

          {isLoading ? (
            <img
              alt=""
              src={require('../images/loading.gif')}
              height="20"
            ></img>
          ) : (
            <button
              type="button"
              style={{ width: '100%' }}
              class={
                selectedPlan === 'pro'
                  ? 'mb-2 btn btn-primary mr-2'
                  : 'mb-2 btn btn-sm btn-white mr-1'
              }
              onClick={async () => {
                MixpanelSuper.trackButtonClick('start_plan_pro');
                try {
                  window.fbq('track', 'ViewContent', { value: '49.99' });
                } catch (e) {
                  console.log(
                    'Could not fire ViewContent pixel event because',
                    e
                  );
                }
                await startStripeCheckout('pro');
              }}
            >
              Continue
            </button>
          )}
        </Col>
      </Row>
    </ListGroup>
  );
};

export const PlansSettings = () => {
  const [userData, setUserData] = useState(null);
  const [email, setEmail] = useState(null);

  const [isYearlySelected, setIsYearlySelected] = useState(false); // toggle switch
  const [isProPlanSelected, setIsProPlanSelected] = useState(false); // controls selected plan

  const [usersCurrentBillingPlan, setUsersCurrentBillingPlan] = useState({
    friendlyName: '',
    stripePlanId: '',
    inTrial: false,
    trialEnd: null,
    trialStart: null,
    isActive: false,
    // stripeSessionId: null,
  });

  let isThisMobileDevice = window.navigator.userAgent.indexOf('Mobile') > -1;
  console.log('isThisMobileDevice', isThisMobileDevice);

  const reviewBillingChanges = async () => {
    console.log('review billing changes');

    let newSelectedPlanName = isProPlanSelected ? 'pro' : 'starter';
    let newSelectedPlanBillingType = isYearlySelected ? 'yearly' : 'monthly';

    console.log('New plan: ', newSelectedPlanName, newSelectedPlanBillingType);
  };

  useEffect(() => {
    console.log('Dashboard useEffect');

    MixpanelSuper.trackScreenView('plans');

    // get current firebase user
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        console.log('user is signed in');
        console.log(user);
        setEmail(user.email);

        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then(function(doc) {
            if (doc.exists) {
              let data = doc.data();
              console.log('Document data:', data);
              setUserData(data);
            }
          });
      }
    });
  }, []);

  return (
    <Container fluid>
      <div>
        <Toaster />
      </div>
      <Row
        noGutters
        className="page-header py-6"
        style={{ marginTop: 30, paddingLeft: 15 }}
      >
        <PageTitle sm="4" title="Settings > Plans" className="text-sm-left" />
      </Row>

      <Container
        style={{ width: isThisMobileDevice ? '100%' : '90%', marginTop: 40 }}
      >
        <Row>
          <Col md="9">
            <Card
              small
              style={{
                padding: 24,
                boxShadow: '0px 0px 1px #667085',
                // PROPER DROPDSHADOW
              }}
            >
              <div
                style={{
                  paddingBottom: 20,
                }}
              >
                <span>Current plan is here</span>
                <Badge
                  pill
                  theme="info"
                  style={{
                    backgroundColor: 'rgb(207 249 193)',
                    color: 'rgb(72 200 1)',
                    marginLeft: 10,
                  }}
                >
                  Active
                </Badge>
              </div>
              <h5>Choose your new plan</h5>

              <fieldset>
                <div
                  style={{
                    height: 300,
                    display: 'flex',
                    // justifyContent: 'left',
                    alignItems: 'center',
                    paddingLeft: 8,
                    borderRadius: 4,
                    border: !isProPlanSelected
                      ? '2px solid #1972F5'
                      : '1px solid #ccc',
                  }}
                  onClick={() => {
                    setIsProPlanSelected(false);
                  }}
                >
                  <Row>
                    <Col md="2">
                      <FormRadio
                        checked={!isProPlanSelected}
                        onChange={() => {
                          console.log('changed Starter');
                          setIsProPlanSelected(false);
                        }}
                        onClick={() => {
                          console.log('clicked Starter');
                          setIsProPlanSelected(false);
                        }}
                      ></FormRadio>
                    </Col>
                    <Col md="10">
                      <h6>Starter </h6>
                      <div>
                        {/* <p>Here is some content inside</p> */}
                        <p>For hobbyists just getting started</p>
                        <p>
                          <i className="check fas fa-check"> </i> 200 runs per
                          month
                        </p>
                        <p>
                          <i className="check fas fa-check"> </i> Access to most
                          content generators
                        </p>
                        <p>
                          <i className="check fas fa-check"> </i> Build custom
                          generators{' '}
                        </p>
                        <p>
                          <i className="check fas fa-check"> </i> Self serve
                          support
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div
                  style={{
                    height: 300,
                    display: 'flex',
                    // justifyContent: 'left',
                    alignItems: 'center',
                    paddingLeft: 8,
                    borderRadius: 4,
                    marginTop: 7,
                    border: isProPlanSelected
                      ? '2px solid #1972F5'
                      : '1px solid #ccc',
                  }}
                  onClick={() => {
                    setIsProPlanSelected(true);
                  }}
                >
                  <Row>
                    <Col md="2">
                      <FormRadio
                        checked={isProPlanSelected}
                        onChange={() => {
                          console.log('changed pro');
                          setIsProPlanSelected(true);
                        }}
                        onClick={() => {
                          console.log('clicked pro');
                          setIsProPlanSelected(true);
                        }}
                      ></FormRadio>
                    </Col>
                    <Col md="10">
                      <h6>Pro </h6>
                      <div>
                        {/* <p>Here is some content inside</p> */}
                        <p>For hobbyists just getting started</p>
                        <p>
                          <i className="check fas fa-check"> </i> Unlimited runs
                          per month
                        </p>
                        <p>
                          <i className="check fas fa-check"> </i> Access to all
                          content generators
                        </p>
                        <p>
                          <i className="check fas fa-check"> </i> Build custom
                          generators
                        </p>
                        <p>
                          <i className="check fas fa-check"> </i>24/7 priority
                          support
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </fieldset>
            </Card>
          </Col>

          <Col md="3">
            <Card
              small
              style={{
                padding: 24,
                boxShadow: '0px 0px 1px #667085',
                // PROPER DROPDSHADOW
                marginBottom: 24,
              }}
            >
              <fieldset
                style={{
                  display: 'flex',
                  paddingTop: 10,

                  justifyContent: 'center',
                }}
              >
                <p style={{ paddingTop: 1, paddingRight: '0.8rem' }}>
                  Monthly{' '}
                </p>

                <FormCheckbox
                  toggle
                  checked={isYearlySelected}
                  onChange={() => {
                    console.log('changed to ', !isYearlySelected);
                    setIsYearlySelected(!isYearlySelected);
                  }}
                >
                  Yearly
                </FormCheckbox>
              </fieldset>
            </Card>

            <Card
              small
              style={{
                padding: 24,
                boxShadow: '0px 0px 1px #667085',
                // PROPER DROPDSHADOW
              }}
            >
              <h5>Review changes</h5>

              <Button onClick={reviewBillingChanges}>Confirm changes</Button>
            </Card>
          </Col>
        </Row>
      </Container>

      <PricingContainer />
    </Container>
  );
};
