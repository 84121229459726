import React, { useEffect, useState } from 'react';
import { setUser } from '../actions';
import toast, { Toaster } from 'react-hot-toast';
import { Mixpanel } from '../mixpanel';
import { MixpanelSuper } from '../mixpanelSuper';
import {
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
  FormInput,
  Modal,
  ModalBody,
  ModalHeader,
  ButtonGroup,
  InputGroup,
  InputGroupAddon,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from 'shards-react';
import firebase from 'firebase';
import { config } from '../environments/config';
import PageTitle from '../components/common/PageTitle';
import { connect } from 'react-redux';
import { increment } from '../actions';
import { addToList, removeFromList } from '../utils/mailchimpHelpers';
import { sendOtherSlackNotification } from '../utils/slackNotifications';

const createAccount = async () => {
  console.log('createAccount');
};

export const Settings = () => {
  const [userData, setUserData] = useState({});
  const [userEmail, setUserEmail] = useState('');
  const [userId, setUserId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [cancellationIsLoading, setCancellationIsLoading] = useState(false);
  const [cancellationStatusMessage, setCancellationStatusMessage] = useState(
    false
  );
  const [emailVerificationNeeded, setEmailVerificationNeeded] = useState(false);
  const [stripeSessionLoading, setStripeSessionLoading] = useState(false);
  const [showBillingOptions, setShowBillingOptions] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const _ = require('lodash');
  let apiAddress = _.get(config, 'backend.apiAddress');
  let currentPageUrl = window.location.href;
  if (currentPageUrl.includes('localhost')) {
    console.log('LOCALHOST');
    apiAddress = 'http://localhost:3001';
  }

  let isThisMobileDevice = window.navigator.userAgent.indexOf('Mobile') > -1;

  const createStripeSession = async () => {
    try {
      console.log('createStripeSession');
      setStripeSessionLoading(true);

      // post to server
      let endpoint =
        apiAddress + '/api/v2/stripe/create-customer-portal-session';
      let response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userId,
          userEmail: userData.e,
        }),
      });
      let data = await response.json();
      console.log('data', data);

      if (data.status === 'success') {
        console.log('success');
        let redirectUrl = data.url;
        window.location.href = redirectUrl;
      } else {
        toast.error(data.message);
      }
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong. Please contact support');
    }
  };

  const cancelSubscription = async () => {
    console.log('cancelSubscription');
    setCancellationIsLoading(true);

    // post to server
    let endpoint = apiAddress + '/api/v2/stripe/cancel-subscription';
    let response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: userId,
        userEmail: userData.e,
      }),
    });
    let data = await response.json();
    console.log('data', data);

    if (data.status === 'success') {
      toast.success('Cancellation successful');
      setCancellationStatusMessage('Cancellation complete.');

      // update firebase userId paid to false. add cancelled timestamp to billing activity
      let userRef = firebase
        .firestore()
        .collection('users')
        .doc(userId);

      userRef.update({
        paid: false,
        cancelled: new Date(),
      });
      console.log('userRef updated with paid false too');
    } else {
      toast.error(data.message);
      // setCancellationStatusMessage(data.message);
    }

    setCancellationIsLoading(false);
  };

  const handlePlanUpgrade = async () => {
    try {
      console.log('handlePlanUpgrade');
      // post request to server
      let endpoint = apiAddress + '/api/v2/stripe/get-customers-plan';
      let response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userId,
          userEmail: userData.e,
        }),
      });
      let data = await response.json();
      console.log('data', data);

      if (data.status === 'success') {
        let updatedObject = {
          planChange: true,
          planChangeDate: new Date(),
          planId: data.planId,
        };
        // Doesnt include discounted annual pro plan though!
        let proPlanIds = [
          'price_1LANhCJJqTQzMdtHlyteqqlH',
          'price_1LNjKpJJqTQzMdtHGJtHqCCb',
        ];
        if (proPlanIds.includes(data.planId)) {
          // this is a change to pro plan
          sendOtherSlackNotification(
            `${userData.e} changed to a pro plan ${data.planId}!`
          );
          updatedObject.smu = 777;
        } else {
          sendOtherSlackNotification(
            `${userData.e} changed to a non-pro plan ${data.planId}!`
          );
        }
        console.log('updatedObject', updatedObject);
        let userId = firebase.auth().currentUser.uid;
        console.log('userId', userId);
        firebase
          .firestore()
          .collection('users')
          .doc(userId)
          .update(updatedObject)
          .then(function() {
            console.log('Success! Document updated with new plan details!');
          })
          .catch(function(error) {
            console.error('Error updating document: ', error);
            sendOtherSlackNotification(
              `${userData.e} hit error updating firebase after upgrade`
            );
          });
        return true;
      } else {
        toast.error(data.message);
        return false;
      }
    } catch (e) {
      console.log('Something went wrong', e);
      return false;
    }
  };
  const updateEmailVerifiedStatus = async () => {
    try {
      let userId = firebase.auth().currentUser.uid;
      console.log('update for userId', userId);
      const result = await firebase
        .firestore()
        .collection('users')
        .doc(userId)
        .update({
          verifiedEmail: true,
        })
        .catch(function(error) {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log('err', errorCode);
          console.log('errMessage', errorMessage);
          toast.error(`Error: ${errorMessage}`);
        });
      console.log('result', result);
      return result;
    } catch (e) {
      console.log('error', e);
      return 'error';
    }
  };

  useEffect(() => {
    console.log('Settings useEffect');
    console.log('waiting for firebase');
    MixpanelSuper.trackScreenView('settings');

    setIsLoading(true);

    let upgradePromptInUrl = window.location.href.includes('upgradeInfo=true');
    // wait for firebase
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // console.log('user', user);
        setUserEmail(user.email);
        setUserId(user.uid);

        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then(function(doc) {
            if (doc.exists) {
              let data = doc.data();
              console.log('sertting2 userData:', data);

              if (!data.verifiedEmail && !upgradePromptInUrl) {
                toast('Please verify your email address.', {
                  icon: '👋',
                });
                setEmailVerificationNeeded(true);
              }
              setUserData(data);
              setIsLoading(false);
            }
          });
      } else {
        console.log('user is not signed in');
        setIsLoading(false);
        toast.error('user not signed in');
      }
    });

    let verifiedInUrl = window.location.href.includes('verified');
    if (verifiedInUrl) {
      toast.success('Email verified');
      setEmailVerificationNeeded(false);

      try {
        window.$crisp.push(['set', 'session:data', ['verified_email', 'true']]);
      } catch (e) {
        console.log('Could not set session data because', e);
      }
      updateEmailVerifiedStatus();
    }

    let billingUpdatedInUrl = window.location.href.includes(
      'billingUpdated=true'
    );
    if (billingUpdatedInUrl) {
      // toast.success('Billing updated');
      // get plan from server (stripe) and update firebase
      toast.promise(handlePlanUpgrade(), {
        loading: 'updating...',
        success: <b>Settings saved!</b>,
        error: <b>Could not save.</b>,
      });
    }

    if (upgradePromptInUrl) {
      console.log('Show upgrade details...');
      setShowUpgradeModal(true);
    }
  }, []);

  return (
    <Container fluid>
      <div>
        <Toaster />
      </div>
      <Row
        noGutters
        className="page-header py-6"
        style={{ marginTop: 30, paddingLeft: 15 }}
      >
        <PageTitle sm="4" title="Settings" className="text-sm-left" />
      </Row>

      <Container style={{ width: isThisMobileDevice ? '100%' : '60%' }}>
        {isLoading ? (
          <center>
            <img
              src={require('../images/loading.gif')}
              style={{ height: 100, margin: 30 }}
            />
          </center>
        ) : (
          <ListGroup
            flush
            style={{
              boxShadow: '0px 1px 4px 0px rgba(109, 110, 111, 0.38)',
            }}
          >
            <ListGroupItem className="p-3">
              <h5>Profile</h5>

              <Row form>
                <Col md="6" className="form-group">
                  <label htmlFor="firstName">First Name</label>
                  <FormInput
                    id="firstName"
                    name="firstName"
                    type="text"
                    value={userData.fn}
                    className="disabled"
                    disabled
                  />
                </Col>

                <Col md="6" className="form-group">
                  <label htmlFor="lastName">Last Name</label>
                  <FormInput
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={userData.ln}
                    disabled
                    className="disabled"
                  />
                </Col>
              </Row>

              <Row form>
                <Col md="12" className="form-group">
                  <label htmlFor="email">Email</label>
                  {/* <FormInput /> */}

                  <InputGroup seamless className="mb-3">
                    <FormInput
                      id="email"
                      name="email"
                      type="text"
                      value={userEmail}
                      // className={
                      //   emailVerificationNeeded
                      //     ? 'is-invalid disabled'
                      //     : 'disabled'
                      // }
                      disabled
                    />
                    <InputGroupAddon type="append">
                      {/* <Button theme="white">Change email</Button> */}

                      {emailVerificationNeeded && (
                        <Button
                          theme="warning"
                          onClick={() => {
                            try {
                              MixpanelSuper.trackButtonClick(
                                'resend_email_in_settings'
                              );

                              firebase
                                .auth()
                                .currentUser.sendEmailVerification()
                                .then(function() {
                                  console.log(
                                    'Email verification sent to user:',
                                    userData.e
                                  );
                                  toast.success(
                                    `Email verification sent to ${userData.e}`
                                  );
                                  // setShowResendButton(false);
                                });
                            } catch (e) {
                              console.log(e);
                              toast.error('Error sending email verification');
                            }
                          }}
                        >
                          Verify email
                        </Button>
                      )}
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>
              <p style={{ paddingLeft: '0px' }}>
                Need to change your email, name, or password?{' '}
                <strong>
                  <button
                    style={{
                      textDecoration: 'underline',
                      color: 'blue',
                      cursor: 'pointer',
                      border: 'none',
                      backgroundColor: 'rgba(52, 52, 52, 0.0)',
                    }}
                    onClick={() => {
                      window.$crisp.push(['do', 'chat:open']);
                    }}
                  >
                    Contact support
                  </button>
                </strong>
              </p>
              <p>
                Credits: {userData.pu} of{' '}
                {userData.smu < 200 ? userData.smu : 'unlimited'} used this
                month.
              </p>
            </ListGroupItem>

            <ListGroupItem className="p-3">
              <h5>Billing</h5>

              {/* <Button
                onClick={() => {
                  toast.promise(handlePlanUpgrade(), {
                    loading: 'updating...',
                    success: <b>Settings saved!</b>,
                    error: <b>Could not save.</b>,
                  });
                }}
              >
                Test handlePlanUpgrade
              </Button> */}

              {userData.lifetimeAccess && (
                <Row
                  style={{
                    width: '100%',
                    backgroundColor: 'rgba(226, 235, 255, 1)',
                    margin: 3,
                    padding: 8,
                    borderRadius: 5,
                  }}
                >
                  <h6 style={{ paddingTop: 8 }}>
                    You have a lifetime access pass 😎
                  </h6>
                  <p></p>
                </Row>
              )}

              {userData.smu <= 200 && !userData.lifetimeAccess && (
                <Row
                  style={{
                    backgroundColor: 'rgba(226, 235, 255, 1)',
                    margin: 3,
                    padding: '19px 12px 2px 19px',
                    borderRadius: 5,
                    // justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Col md="8" className="form-group">
                    <h6 style={{ paddingTop: 8 }}>
                      <strong>Go Pro - Write 5x Faster 🚀</strong>
                    </h6>
                    <p>
                      Unlock documents, unlimted credits, premium support and so
                      much more!
                    </p>
                  </Col>
                  <Col
                    md="4"
                    className="form-group"
                    style={{
                      // align content right
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {!stripeSessionLoading && (
                      <Button
                        onClick={() => {
                          MixpanelSuper.trackButtonClick(
                            'learn_about_pro_from_settings_banner'
                          );
                          createStripeSession();
                        }}
                      >
                        Continue
                      </Button>
                    )}
                    {stripeSessionLoading && (
                      <Button theme="white">Loading...</Button>
                    )}
                  </Col>
                </Row>
              )}

              <Row form>
                {userData.paid ? (
                  <Col md="6" className="form-group">
                    <label htmlFor="plan">Plan</label>
                    <p>You have active trial or subscription.</p>
                    {userData.stripeSessionId &&
                      userData.purchaseStartTimeReadable && (
                        <div>
                          <FormInput
                            id="plan"
                            name="plan"
                            type="text"
                            value={`Active Plan ${userData.stripeSessionId
                              .substr()
                              .slice(-10)} since ${
                              userData.purchaseStartTimeReadable
                            }`}
                            disabled
                          />

                          {/* <Button
                            size="sm"
                            theme="white"
                            className="mb-2 mr-1"
                            style={{ marginTop: 20 }}
                            onClick={() => {
                              setShowBillingModal(true);
                              MixpanelSuper.trackButtonClick(
                                'opened_cancellation_modal'
                              );
                            }}
                          >
                            Cancel
                          </Button> */}
                          <div style={{ paddingTop: 12 }}>
                            <Dropdown
                              open={showBillingOptions}
                              toggle={() => {
                                setShowBillingOptions(!showBillingOptions);
                              }}
                              addonType="append"
                            >
                              <DropdownToggle
                                caret
                                theme="white"
                                // style={{
                                //   color: 'darkgrey',
                                //   backgroundColor: 'white',
                                //   border: '1px solid lightgrey',
                                // }}
                              >
                                Manage
                              </DropdownToggle>
                              <DropdownMenu small>
                                <DropdownItem
                                  onClick={() => {
                                    MixpanelSuper.trackButtonClick(
                                      'change_plan_from_dropdown'
                                    );
                                    createStripeSession();
                                  }}
                                >
                                  Change plan
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    MixpanelSuper.trackButtonClick(
                                      'upgrade_plan_from_dropdown'
                                    );
                                    createStripeSession();
                                  }}
                                >
                                  Upgrade plan{' '}
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    MixpanelSuper.trackButtonClick(
                                      'edit_billing_info_plan_from_dropdown'
                                    );
                                    createStripeSession();
                                  }}
                                >
                                  Edit billing info{' '}
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    setShowBillingModal(true);
                                    MixpanelSuper.trackButtonClick(
                                      'opened_cancellation_modal'
                                    );
                                  }}
                                >
                                  Cancel plan
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </div>
                      )}
                  </Col>
                ) : (
                  <div>
                    <p style={{ padding: 8 }}>
                      You dont currently have active subscription or trial. Get
                      one below
                    </p>

                    <Button
                      onClick={() => {
                        // open /try-it-free path
                        window.location.href = '/pick-a-plan';
                      }}
                    >
                      Pick a plan
                    </Button>
                  </div>
                )}
              </Row>
            </ListGroupItem>
          </ListGroup>
        )}
      </Container>

      <Modal open={showBillingModal}>
        <ModalHeader>
          {!cancellationStatusMessage ? `Cancel subscription?` : 'Result'}
        </ModalHeader>
        <ModalBody>
          {!cancellationIsLoading && !cancellationStatusMessage && (
            <Row>
              <span>Are you sure you want to change your subscription?</span>
              <br></br>
              <br></br>
              <br></br>

              <Button
                size="sm"
                theme="primary"
                className="mb-2 mr-1"
                style={{ marginRight: 20 }}
                onClick={() => {
                  setShowBillingModal(false);
                  MixpanelSuper.trackButtonClick('closed_cancellation_modal');
                }}
              >
                No, I want to keep my account.
              </Button>
              <Button
                size="sm"
                theme="danger"
                className="mb-2 mr-1"
                onClick={async () => {
                  try {
                    MixpanelSuper.trackButtonClick('cancel_my_account');
                    await cancelSubscription();
                  } catch (e) {
                    toast.error(e.message);
                    setCancellationIsLoading(false);
                  }
                }}
              >
                Yes, I want to cancel account
              </Button>

              <em style={{ marginTop: 20 }}>
                We'd hate to see you go! Is there anything we can do to keep you
                as a customer?{' '}
                <a
                  href="/settings"
                  onClick={() => {
                    window.$crisp.push(['do', 'chat:open']);
                  }}
                >
                  Please reach out
                </a>
                .
              </em>
            </Row>
          )}

          {cancellationIsLoading && (
            <center>
              <img
                src={require('../images/loading.gif')}
                style={{ height: 50, margin: 30 }}
              />
            </center>
          )}

          {cancellationStatusMessage && (
            <center>
              <p>{cancellationStatusMessage}</p>
              <Button
                size="sm"
                theme="primary"
                className="mb-2 mr-1"
                onClick={() => {
                  window.$crisp.push(['do', 'chat:open']);
                }}
              >
                Share feedback
              </Button>
              <Button
                size="sm"
                theme="white"
                className="mb-2 mr-1"
                onClick={() => {
                  setShowBillingModal(false);
                  // reload window
                  window.location.reload();
                }}
              >
                Close
              </Button>
              <br></br>
              <br></br>
              <em style={{ marginTop: 20 }}>
                Why did you cancel? Please click share feedback and tell us how
                we could improve our product.
              </em>
            </center>
          )}
        </ModalBody>
      </Modal>

      <Modal open={showUpgradeModal}>
        <ModalBody>
          <center>
            <i
              className="material-icons mr-1"
              style={{
                paddingTop: 1,
                fontSize: 30,
                color: '#039855',
                backgroundColor: '#D1FADF',
                padding: '12px',
                borderRadius: '50%',
              }}
            >
              star_border
            </i>

            <h6 style={{ marginTop: 10, marginBottom: 20 }}>
              Upgrade to Pro and Write 5x Faster With Documents
            </h6>
            <p>
              Upgrade to Pro to get access to Documents, All Generators,
              Unlimited Credits, Premium support and more!
            </p>
            {/* TODO ADD VIDEO LATER. Also follow up with email of people that click learn more about pro but dont do it... */}

            {stripeSessionLoading ? (
              <div>
                <img
                  src={require('../images/loading.gif')}
                  style={{ height: 50, margin: 30 }}
                />
              </div>
            ) : (
              <Button
                theme="primary"
                style={{ marginBottom: 50 }}
                onClick={() => {
                  MixpanelSuper.trackButtonClick(
                    'learn_about_pro_from_upgrade_modal'
                  );
                  createStripeSession();
                }}
              >
                Continue
              </Button>
            )}
            <br></br>
            <span
              style={{ color: 'lightgray' }}
              onClick={() => {
                setShowUpgradeModal(false);
                MixpanelSuper.trackButtonClick('closed_upgrade_modal');
              }}
            >
              Close
            </span>
          </center>
        </ModalBody>
      </Modal>
    </Container>
  );
};
