import React, { useEffect, useState } from 'react';
import { setUser } from '../actions';

import { Mixpanel } from '../mixpanel';
import { MixpanelSuper } from '../mixpanelSuper';
import {
  Container,
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Button,
} from 'shards-react';

import firebase from 'firebase';
import { config } from '../environments/config';
import toast, { Toaster } from 'react-hot-toast';

export const VerifyEmail = () => {
  const [email, setEmail] = useState('');
  const [showResendButton, setShowResendButton] = useState(false);
  let isThisMobileDevice = window.navigator.userAgent.indexOf('Mobile') > -1;

  useEffect(() => {
    console.log('VerifyEmail useEffect');

    // log current url
    console.log(window.location.href);
    var url = new URL(window.location.href);
    var emailInUrl = url.searchParams.get('email');

    if (emailInUrl) {
      setEmail(emailInUrl);
    }

    console.log('Check to see if user is here.... ');
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        console.log('user is here.');
        setShowResendButton(true);
      } else {
        console.log('user is not here.');
      }
    });

    MixpanelSuper.trackScreenView('verify_email');
  }, []);

  return (
    <Container fluid>
      <div>
        <Toaster />
      </div>

      <div style={{ margin: 30 }}>
        <center>
          {/* Image */}
          <img
            style={{
              width: '70px',
              height: '70px',
              padding: 8,
              backgroundColor: 'white',
              borderRadius: 12,
              marginBottom: 10,
            }}
            src={require('../images/gradientIcon.png')}
          ></img>

          <h4 className="m-0">Verify your email</h4>
          <p style={{ marginTop: 10 }}>
            <strong>Check spam folder</strong> if you don't see the email.
          </p>
        </center>
      </div>

      <Container style={{ width: isThisMobileDevice ? '100%' : '40%' }}>
        <ListGroup flush>
          <ListGroupItem
            className="p-3"
            style={{
              boxShadow: '0px 1px 4px 0px rgba(109, 110, 111, 0.38)',
            }}
          >
            <Row>
              <Col>
                <center>
                  <h3>📬</h3>
                  <p>
                    Please click the link we just sent{' '}
                    {email ? `to ${email}` : 'you'}{' '}
                  </p>

                  {showResendButton && (
                    <div>
                      <Button
                        onClick={() => {
                          try {
                            MixpanelSuper.trackButtonClick('resend_email');

                            firebase
                              .auth()
                              .currentUser.sendEmailVerification()
                              .then(function() {
                                console.log('Email verification sent to user');
                                toast.success(
                                  'Email verification sent to user'
                                );
                                setShowResendButton(false);
                              });
                          } catch (e) {
                            console.log(e);
                            toast.error('Error sending email verification');
                          }
                        }}
                      >
                        Resend email
                      </Button>
                    </div>
                  )}
                </center>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Container>

      <div style={{ margin: 20 }}>
        <center>
          <p>
            Have an issue? <a href="/try-it-free">Go back </a>
          </p>
        </center>
      </div>
    </Container>
  );
};
