import React, { useEffect, useState } from 'react';
import { setUser } from '../actions';
import toast, { Toaster } from 'react-hot-toast';
import { Mixpanel } from '../mixpanel';
import { MixpanelSuper } from '../mixpanelSuper';
import {
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
  FormInput,
  Modal,
  ModalBody,
  ModalHeader,
  ButtonGroup,
  InputGroup,
  InputGroupAddon,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from 'shards-react';
import firebase from 'firebase';
import { config } from '../environments/config';
import PageTitle from '../components/common/PageTitle';
import { connect } from 'react-redux';
import { increment } from '../actions';
import { addToList, removeFromList } from '../utils/mailchimpHelpers';
import { sendOtherSlackNotification } from '../utils/slackNotifications';

export const SubscriptionCancelConfirmation = () => {
  const [userData, setUserData] = useState({});
  const [userEmail, setUserEmail] = useState('');
  const [userId, setUserId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [cancellationIsLoading, setCancellationIsLoading] = useState(false);
  const [cancellationStatusMessage, setCancellationStatusMessage] = useState(
    false
  );
  const [emailVerificationNeeded, setEmailVerificationNeeded] = useState(false);
  const [stripeSessionLoading, setStripeSessionLoading] = useState(false);
  const [showBillingOptions, setShowBillingOptions] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const _ = require('lodash');
  let apiAddress = _.get(config, 'backend.apiAddress');
  let currentPageUrl = window.location.href;
  if (currentPageUrl.includes('localhost')) {
    console.log('LOCALHOST');
    apiAddress = 'http://localhost:3001';
  }

  let isThisMobileDevice = window.navigator.userAgent.indexOf('Mobile') > -1;

  const createStripeSession = async () => {
    try {
      console.log('createStripeSession');
      setStripeSessionLoading(true);

      // post to server
      let endpoint =
        apiAddress + '/api/v2/stripe/create-customer-portal-session';
      let response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userId,
          userEmail: userData.e,
        }),
      });
      let data = await response.json();
      console.log('data', data);

      if (data.status === 'success') {
        console.log('success');
        let redirectUrl = data.url;
        window.location.href = redirectUrl;
      } else {
        toast.error(data.message);
      }
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong. Please contact support');
    }
  };

  useEffect(() => {
    console.log('Sub Cacnel useEffect');
    console.log('waiting for firebase');

    MixpanelSuper.trackScreenView('subscription_cancel_confirm');

    setIsLoading(true);

    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // console.log('user', user);
        setUserEmail(user.email);
        setUserId(user.uid);

        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then(function(doc) {
            if (doc.exists) {
              let data = doc.data();
              console.log('subscriptionCancelConfirmation userData:', data);

              setUserData(data);
              setIsLoading(false);
            }
          });
      } else {
        console.log('user is not signed in');
        setIsLoading(false);
        toast.error('user not signed in');
      }
    });
  }, []);

  return (
    <Container fluid>
      <div>
        <Toaster />
      </div>
      <Row
        noGutters
        className="page-header py-6"
        style={{ marginTop: 30, paddingLeft: 15 }}
      >
        <PageTitle sm="4" title="Settings" className="text-sm-left" />
      </Row>

      <Container style={{ width: isThisMobileDevice ? '100%' : '60%' }}>
        {isLoading ? (
          <center>
            <img
              src={require('../images/loading.gif')}
              style={{ height: 100, margin: 30 }}
            />
          </center>
        ) : (
          <ListGroup
            flush
            style={{
              boxShadow: '0px 1px 4px 0px rgba(109, 110, 111, 0.38)',
            }}
          >
            <ListGroupItem className="p-3">
              <h5>Cancel Plan Here</h5>

              <Button
                onClick={() => {
                  createStripeSession();
                }}
              >
                Continue
              </Button>
            </ListGroupItem>
          </ListGroup>
        )}
      </Container>
    </Container>
  );
};
