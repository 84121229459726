const creditsReducer = (state = '-', action) => {
  switch(action.type){
    case 'SET_CREDITS':
      return state = action.payload;
    case 'INCREMENT_CREDITS':
      return state+1;
    case 'DECREMENT_CREDITS':
      return state-1;
    default:
      return state;
  }
}
export default creditsReducer;
