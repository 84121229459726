import React, { useEffect } from 'react';
import { setFirebaseUserData, setUser } from '../actions';
import toast, { Toaster } from 'react-hot-toast';

import {
  Card,
  Container,
  Row,
  Col,
  ListGroupItem,
  Form,
  FormInput,
  Button,
  ListGroup,
  FormGroup,
  FormSelect,
  FormCheckbox,
} from 'shards-react';
import { Mixpanel } from '../mixpanel';
import { MixpanelSuper } from '../mixpanelSuper';
import firebase from 'firebase';
import { config } from '../environments/config';
import { connect } from 'react-redux';
import { sendOtherSlackNotification } from '../utils/slackNotifications';
import { addToList } from '../utils/mailchimpHelpers';

export const ResetPassword = () => {
  let isThisMobileDevice = window.navigator.userAgent.indexOf('Mobile') > -1;
  console.log('isThisMobileDevice', isThisMobileDevice);

  // JS HERE
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [userFullFb, setUserFullFb] = React.useState({});

  const initiateMixpanel = async () => {
    try {
      Mixpanel.identify(email);
      Mixpanel.track('successful_signup', {
        user_unique_id: email,
      });
      const signupDate = new Date();
      // console.log(signupDate);

      Mixpanel.people.set({
        $email: email,
        $first_name: firstName,
        $last_name: lastName,
        $name: firstName + ' ' + lastName,
        sign_up_date: signupDate, // Send dates in ISO timestamp format (e.g. "2020-01-02T21:07:03Z")
        registration_method: 'email',
        generator_started_count: 0,
        snippet_generated_count: 0,
        snippet_copied_count: 0,
        snippet_saved_count: 0,
        login_count: 0,
        // plan_type: 'free',
      });
      console.log('Set mixpanel for new user');
    } catch (e) {
      console.log('something went wrong initiated mixpanel. Error', e);
    }
  };

  const initiateCrisp = async () => {
    try {
      console.log('initiateCrisp. Crisp set');
      window.$crisp.push(['set', 'user:email', email]);
      window.$crisp.push([
        'set',
        'user:nickname',
        [`${firstName} ${lastName}`],
      ]);
      window.$crisp.push(['set', 'session:event', ['user:welcome']]);
      // set session data.
      window.$crisp.push(['set', 'session:data', ['entered_cc', 'false']]);
    } catch (e) {
      console.log('something went wrong initiating crisp. Error', e);
    }
  };

  const registerUserWithFirebase = async (email, password) => {
    console.log('registerUserWithFirebase');

    // this function registers the user with firebase.
    try {
      const result = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .catch(function(error) {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log('err', errorCode);
          console.log('errMessage', errorMessage);
          toast.error(`Error: ${errorMessage}`);
        });
      console.log('result that should include user', result);

      if (result) {
        initiateMixpanel();

        // add to mailchimp
        try {
          addToList(email, firstName, lastName);
          console.log('added to mailchimp');
        } catch (e) {
          console.log('error adding to mailchimp', e);
        }

        // old state setting functions
        try {
          console.log('set state fore user now');
          setUser(result);
          setUserFullFb(result);
        } catch (e) {
          console.log('error setting state', e);
        }
      }
      return result ? result : 'error';
    } catch (e) {
      console.log(e);
      toast.error(`Error: ${e.message}`);
      return 'error';
    }
  };

  const validateFields = () => {
    console.log('validateFields');

    if (email.length < 1) {
      toast.error('Email is required');
      return false;
    }
    if (password.length < 1) {
      toast.error('Password is required');
      return false;
    }
    return true;
  };

  const sendEmailVerificationTwo = async () => {
    // send email verification with firebase
    console.log('sendEmailVerificationTwo');
    try {
      firebase
        .auth()
        .currentUser.sendEmailVerification()
        .then(function() {
          // Email sent.
          console.log('email sent');
        })
        .catch(function(error) {
          // An error happened.
          console.log('error sending email', error);
        });
    } catch (e) {
      console.log('caught error sending email', e);
    }
  };

  const resetPassword = (e) => {
    console.log('reset password');
    e.preventDefault(); // prevent form from being cleared after submit clicked
    setIsLoading(true);
    let areFieldsValid = validateFields();

    if (!areFieldsValid) {
      setIsLoading(false);
      return;
    }
    // look for 'oobCode' in current url
    const params = new URLSearchParams(window.location.search);
    const code = params.get('oobCode');
    if (!code) {
      console.log('no code found in url');
      toast.error(
        'No code found in url. Please make sure you clicked the link in the email we sent you.'
      );
      return;
    }

    firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(function() {
        // Success
        toast.success(
          'Password reset successfully. Please log in with this new password'
        );
        setIsLoading(false);
        //redirect to login
        window.location.href = '/login';
      })
      .catch(function() {
        // Invalid code
        toast.error('Invalid code. Please try again');
        setIsLoading(false);
      });
  };

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'email') {
      setEmail(value);
    }
    if (name === 'password') {
      setPassword(value);
    }
    if (name === 'firstName') {
      setFirstName(value);
    }
    if (name === 'lastName') {
      setLastName(value);
    }
  };

  useEffect(() => {
    MixpanelSuper.trackScreenView('reset_password');

    setUserFullFb({});
    console.log('userFullFb', userFullFb);
  }, []);

  return (
    <div
      style={{
        backgroundColor: isThisMobileDevice ? null : '#fff',
        height: '100vh',
      }}
    >
      <div>
        <Toaster />
      </div>

      {/* Desktop version below */}
      {!isThisMobileDevice && (
        <Container
          style={{
            width: '100%',
            height: '100%',
            padding: '0px',
            margin: '0px',
            overflow: 'auto',
            maxWidth: '100%',
            backgroundImage: `url(${require('../images/gradient_bg.png')})`,
            backgroundSize: 'cover',
          }}
        >
          <Row
            style={{
              width: '100%',
              height: '100%',
              padding: 0,
              margin: 0,
            }}
          >
            <Col
              lg={5}
              style={{
                height: '100%',
                backgroundColor: 'white',
                margin: 0,
                padding: '18vh 84px 18vh 84px',
              }}
            >
              <img
                style={{
                  width: '70px',
                  height: '70px',
                  padding: 8,
                  backgroundColor: 'white',
                  borderRadius: 12,
                  marginBottom: 10,
                }}
                src={require('../images/gradientIcon.png')}
              ></img>
              <h4 className="m-0" style={{ paddingBottom: 10 }}>
                <strong>Reset Password</strong>
              </h4>
              <Container
                style={{
                  width: '100%',
                }}
              >
                <ListGroup flush>
                  <ListGroupItem
                    className="p-3"
                    style={{
                      boxShadow: '0px 1px 4px 0px rgba(109, 110, 111, 0.38)',
                    }}
                  >
                    <Row>
                      <Col>
                        <Form>
                          <FormGroup>
                            <label htmlFor="email">Email</label>
                            <FormInput
                              id="email"
                              type="email"
                              name="email"
                              onChange={inputsHandler}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label htmlFor="fePassword">New Password</label>
                            <FormInput
                              id="fePassword"
                              type="password"
                              placeholder="Password"
                              name="password"
                              onChange={inputsHandler}
                            />
                          </FormGroup>

                          {isLoading ? (
                            <button
                              className={'mb-2 btn btn-sm btn-white mr-1'}
                            >
                              Loading... {'   '}
                              <img
                                alt=""
                                src={require('../images/loading.gif')}
                                height="20"
                              ></img>
                            </button>
                          ) : (
                            <Button
                              style={{
                                float: isThisMobileDevice ? 'right' : null,
                              }}
                              onClick={async (e) => {
                                console.log(email, password);
                                await resetPassword(e);
                                // await createAccount(e)
                              }}
                            >
                              Reset Password
                            </Button>
                          )}
                        </Form>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </Container>

              <div style={{ margin: 20 }}>
                <center>
                  <p>
                    Want to log in? <a href="/login">Login </a>
                  </p>
                </center>
              </div>
            </Col>
            <Col
              lg={7}
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <center style={{ paddingLeft: 20 }}>
                <h4 style={{ color: 'white' }}>
                  <strong>
                    Join over 3,900 leading brands, agencies and content
                    writers.
                  </strong>
                </h4>
                <div>
                  <img
                    style={{
                      width: '100%',
                      height: 'auto',
                      maxWidth: '100%',
                      maxHeight: '100%',
                      opacity: 0.7,
                    }}
                    src={require('../images/logos-white.png')}
                  ></img>
                </div>
              </center>
            </Col>
          </Row>
        </Container>
      )}

      {/* Mobile version below */}
      {isThisMobileDevice && (
        <div style={{ margin: 30 }}>
          <center>
            <img
              style={{
                width: '70px',
                height: '70px',
                padding: 8,
                backgroundColor: 'white',
                borderRadius: 12,
                marginBottom: 10,
              }}
              src={require('../images/gradientIcon.png')}
            ></img>
            <h4 className="m-0" style={{ paddingBottom: 10 }}>
              Reset password
            </h4>
          </center>
          <Container
            style={{
              width: '100%',
            }}
          >
            <ListGroup flush>
              <ListGroupItem
                className="p-3"
                style={{
                  boxShadow: '0px 1px 4px 0px rgba(109, 110, 111, 0.38)',
                }}
              >
                <Row>
                  <Col>
                    <Form>
                      <FormGroup>
                        <label htmlFor="email">Email</label>
                        <FormInput
                          id="email"
                          type="email"
                          name="email"
                          onChange={inputsHandler}
                        />
                      </FormGroup>

                      <FormGroup>
                        <label htmlFor="fePassword">New Password</label>
                        <FormInput
                          id="fePassword"
                          type="password"
                          placeholder="Password"
                          name="password"
                          onChange={inputsHandler}
                        />
                      </FormGroup>

                      {isLoading ? (
                        <button className={'mb-2 btn btn-sm btn-white mr-1'}>
                          Loading... {'   '}
                          <img
                            alt=""
                            src={require('../images/loading.gif')}
                            height="20"
                          ></img>
                        </button>
                      ) : (
                        <Button
                          style={{
                            float: isThisMobileDevice ? 'right' : null,
                          }}
                          onClick={async (e) => {
                            console.log(email, password);
                            await resetPassword(e);
                            // await createAccount(e)
                          }}
                        >
                          Reset Password
                        </Button>
                      )}
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Container>
        </div>
      )}
    </div>
  );
};
