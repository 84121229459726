/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Badge,
  Button,
} from 'shards-react';

import '../assets/upgrade.css';
import PageTitle from '../components/common/PageTitle';
import Fire from '../config/fire';
import { config } from '../environments/config';
import {
  setCredits,
  setDaysRemaining,
  setFirebaseUserData,
  setSubscribed,
  setUser,
} from '../actions';
import { connect } from 'react-redux';

const _ = require('lodash');

class CopyOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // List of copy options
      CopyTypeOptions: [
        {
          title: 'AIDA',
          body:
            'Stop your readers in their tracks with the Attention Interest Desire Action formula!',
          iconImage: require('../images/icons/pencil.png'),
          urlSlug: '/generate/aida',
        },
        {
          title: 'Product Descriptions',
          body:
            'Write high converting product descriptions for your ecommerce store!',
          iconImage: require('../images/icons/cart.png'),
          urlSlug: '/generate/product-descriptions',
        },
        {
          title: 'Facebook Ads',
          body:
            'Write compelling ad copy to drive more conversions and higher ROI!',
          iconImage: require('../images/icons/fb.png'),
          urlSlug: '/generate/facebook-ads',
        },
        {
          title: 'Pain Agitate Solution',
          body:
            'Write compelling copy with the legendary Pain Agitate Solution copy formula',
          iconImage: require('../images/icons/pain.png'),
          urlSlug: '/generate/pas',
        },
        {
          title: 'Content Improver',
          body:
            'Improve an existing piece of content to make it more engaging, interesting and persuasive!',
          icon: 'brush',
          urlSlug: '/templates/improver',
          showNewBadge: true,
        },
        {
          title: 'Paragraph Writer',
          body: 'Write captivating paragraphs that your readers will love!',
          icon: 'short_text',
          urlSlug: '/templates/paragraph',
          showNewBadge: true,
        },
        {
          title: 'Alternative Value Propositions',
          body:
            'Quickly generate multiple reasons people may use your service or product',
          urlSlug: '/generate/value-propositions',
          iconImage: require('../images/icons/keyword.png'),
          // showProOnly: false
        },
        {
          title: 'Viral Video Ideas',
          body: 'Get viral video ideas for any business or product instantly!',
          urlSlug: '/generate/viral-video-ideas',
          iconImage: require('../images/icons/video.png'),
          // showProOnly: true
        },
        {
          title: 'Blog Post Title Ideas',
          body:
            'Make the most of your blog posts with this epic title generator.',
          urlSlug: '/generate/blog-post-title-ideas',
          iconImage: require('../images/icons/type.png'),
          // showProOnly: true
        },
        {
          title: 'Blog Post Topics',
          body:
            'Find great topics to blog about that your customers will actually be searching for!',
          urlSlug: '/generate/blog-post-topic-ideas',
          iconImage: require('../images/icons/lightbulb.png'),
          // showProOnly: true
        },
        {
          title: 'Headlines',
          body:
            'High converting headlines to maximize your web pages, emails and social posts!',
          iconImage: require('../images/icons/web.png'),
          urlSlug: '/generate/headlines',
          // showProOnly: true
        },
        {
          title: 'Keyword Ideas',
          body:
            'Easily find keywords your customers are searching for with this keyword ideas tool.',
          urlSlug: '/generate/keyword-ideas',
          iconImage: require('../images/icons/keyword.png'),
          // showProOnly: true
        },
        {
          title: 'Viral Contest Ideas',
          body: "Get social media contest ideas that'll spread like wildfire!",
          urlSlug: '/generate/viral-contest-ideas',
          iconImage: require('../images/icons/trophy.png'),
          // showProOnly: true
        },

        {
          title: 'What, Who, Why',
          body:
            'Easily extract your project or companies key attributes from simple language.',
          urlSlug: '/generate/what-who-why',
          iconImage: require('../images/icons/keyword.png'),
          // showProOnly: true
        },

        {
          title: 'Website Hero Message & Sub-Headline',
          body: 'Use your What, Who & Why to start building out a website',
          urlSlug: '/generate/hero-message-and-subheadline',
          iconImage: require('../images/icons/keyword.png'),
          // showProOnly: true
        },
        {
          title: 'Website Intro Paragraph',
          body:
            'Use your What, Who, Why & Hero Message to craft a related intro paragraph.',
          urlSlug: '/generate/website-intro-paragraph',
          iconImage: require('../images/icons/keyword.png'),
          // showProOnly: true
        },
        {
          title: 'Customer Welcome Email',
          body:
            'Use your What, Who & Why to craft a welcome email for new sign ups.',
          urlSlug: '/generate/customer-welcome-email',
          iconImage: require('../images/icons/keyword.png'),
          // showProOnly: true
        },
        {
          title: 'Custom Content',
          body:
            'What if you could train our cutting-edge AI to write custom copy for ANY medium? Well, now you can!',
          urlSlug: '/custom',
          iconImage: require('../images/icons/wrench.png'),
          showNewBadge: true,
        },

        {
          title: 'Something else?',
          body: "Request something else and we'll build it for you in 48 hours",
          intercom: true,
          iconImage: require('../images/icons/mail.png'),
          urlSlug: 'mailto:hello@garyai.com',
        },
        // {
        //   title: "Value Propositions",
        //   body:
        //     "People don't buy products. They buy solutions to their problems. Write value propositions that sell! Great for product descriptions, landing pages and more!",
        //   // urlSlug: "/facebook"
        // },
        // {
        //   title: "Features to benefits",
        //   body:
        //     "People don't buy products",
        //   // urlSlug: "/facebook"
        // },
        // {
        //   title: "PAG",
        //   body:
        //     "People don't buy products",
        //   // urlSlug: "/facebook"
        // },

        // {
        //   title: "Campaign Ideas",
        //   body:
        //     "Generate campaign ideas like Buy one get one free, free shipping, etc.",
        //   // urlSlug: "/facebook"
        // },
      ],
    };
  }

  // componentDidMount() {
  //   console.log('load user data');
  //   this.loadUserData()
  // }

  setFirebaseUserToState = (userData) => {
    this.props.setFirebaseUserData(userData); /** Redux */
  };

  setCredits = (userData) => {
    let credits = _.get(userData, 'smu', 0) - _.get(userData, 'pu', 0);
    // if(credits){
    //   credits = 0;
    // }
    this.props.setCredits(credits); /** Redux */
  };

  setDaysRemaining = (userData) => {
    let timeNow = Date.now();
    //If no subscription
    let se = _.get(userData, 'se', undefined);
    let te = _.get(userData, 'te', undefined);
    let days;
    if (se) {
      if (se * 1000 >= timeNow) {
        /** Note. Stripe in seconds not MS */
        days = Math.ceil((se * 1000 - timeNow) / 86400000);
        this.props.setSubscribed();
      } else {
        days = 0;
      }
    } else if (te) {
      if (te >= timeNow) {
        days = Math.ceil((te - timeNow) / 86400000);
      } else {
        days = 0;
      }
    } else {
      days = '?';
    }
    this.props.setDaysRemaining(days); /** Redux */
  };

  setSubscribed = (userData) => {
    let se = _.get(userData, 'se', undefined);
    if (se) {
      this.props.setSubscribed(); /** Redux */
    }
    // this.props.setSubscribed(userData);
  };

  loadFirebaseUser() {
    if (Fire.shared.uid === undefined) {
      setTimeout(() => {
        this.loadFirebaseUser();
      }, 500);
    } else {
      Fire.shared
        .getUserDetails()
        .then((userData) => {
          console.log('USER DATA RESPONSE:', userData);
          if (userData !== undefined) {
            this.loadUserData(userData);
            this.setFirebaseUserToState(userData);
            this.setCredits(userData);
            this.setDaysRemaining(userData);
            this.setSubscribed(userData);
          }
        })
        .catch((e) => {
          console.log('Fetch From Firebase Error:', e);
        });
    }
  }

  componentDidMount() {
    Fire.shared.checkAndWaitForFirebaseLoad().then(() => {
      this.loadFirebaseUser();
    });
  }

  launchIntercom() {
    let dateNow = Date.now() / 1000;
    window.intercomSettings = {
      app_id: _.get(config, 'intercom.appId'),
      url: window.location.href,
      platform: 'garyAI',
      email: this.state.email, // Email address
    };

    window.Intercom('boot', window.intercomSettings);
    window.Intercom(
      'showNewMessage',
      'Please build me a custom copywriting tool: Here are my requirements ... '
    );
  }

  /** TODO: redo what dom did with new redux variables */
  loadUserData = (res) => {
    // console.log('loadUserEmail');
    if (res !== undefined) {
      console.log('res', res);
      this.setState({ email: res.e });
      const usersFullName = `${res.fn} ${res.ln}`;
      this.setState({ name: usersFullName });
      // try {
      //   if (res.smu === 50){
      //     console.log('The free plan is 50 allowance usage credits. That means they are a free user and do not get access to this feature');
      //     // setuserCanAccessCustomPrompts(false);
      //     this.setState({plan: 'free'});
      //   } else {
      //     // Im assuming if there SMU is not equal to 50, they paid something.. This could be problematic if we change free plan limit.
      //     console.log('This user is a paying customer')
      //     this.setState({plan: 'paid'});
      //   }
      // }catch(e){
      //   console.log('An error occured while retrieving smu from FB: ', e);
      // }
    }
  };

  render() {
    const { CopyTypeOptions } = this.state;

    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Write Copy"
            subtitle="Get Started"
            className="text-sm-left"
          />
        </Row>

        {/* upgrade prompt */}

        {/* TODO: TALK TO DOM ABOUT IF THIS SHOULD REMAIN */}
        {/*{ !this.props.isSubscribed && <div className="upgradeWidget">*/}
        {/*  <p className="text-muted">Want full access to our suite of powerful tools? </p>*/}
        {/*  <a className="upgradeButton" onClick={() => { window.open('/pricing?utm_source=upgradeWidget2', '_blank');*/}
        {/*  }}>Upgrade now</a>*/}
        {/*  </div>*/}
        {/*}*/}

        {(_.get(this, 'props.firebaseUserData.lu') == 0 ||
          _.get(this, 'props.firebaseUserData.lu') == undefined) && (
          <Row>
            <Col lg="4">
              <Card small className="card-post mb-4">
                <CardBody>
                  {true && (
                    <Badge
                      pill
                      outline
                      theme="primary"
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '10px',
                      }}
                    >
                      Tutorial
                    </Badge>
                  )}
                  <Row style={{ paddingBottom: '10px' }}>
                    <Col md="2" style={{ padding: '0px' }}>
                      <img
                        alt=""
                        style={{ height: '50px', paddingLeft: '15px' }}
                        src={require('../images/icons/pencil.png')}
                      ></img>
                    </Col>
                    <Col md="10" style={{ paddingTop: '15px' }}>
                      <h5 className="card-title">
                        Use "AIDA" to unlock More Generators
                      </h5>
                    </Col>
                  </Row>
                  <p className="card-text text-muted">
                    Stop your readers in their tracks with the Attention
                    Interest Desire Action formula!
                  </p>
                </CardBody>
                <CardFooter className="d-flex">
                  <div className="my-auto ml-auto">
                    <Button size="sm" theme="primary" href="/generate/aida">
                      Get Started
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        )}

        <Row>
          {CopyTypeOptions.map((copyType, idx) => (
            <Col lg="4" key={idx}>
              <Card
                small
                className="card-post mb-4"
                style={{ boxShadow: '0px 0px 1px #667085' }}
              >
                <CardBody>
                  {copyType.showNewBadge && (
                    <Badge
                      pill
                      theme="primary"
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '10px',
                      }}
                    >
                      New
                    </Badge>
                  )}
                  {copyType.showProOnly && (
                    <Badge
                      pill
                      theme="light"
                      style={{
                        border: '1px solid #cacedb',
                        position: 'absolute',
                        right: '10px',
                        top: '10px',
                      }}
                    >
                      Pro
                    </Badge>
                  )}
                  <Row style={{ paddingBottom: '10px' }}>
                    <Col md="2" style={{ padding: '0px' }}>
                      {copyType.iconImage && (
                        <img
                          alt=""
                          style={{ height: '50px', paddingLeft: '15px' }}
                          src={copyType.iconImage}
                        ></img>
                      )}
                      {/* new: use icons instead of custom images. TODO convert the rest. */}
                      {copyType.icon && (
                        <i
                          className="material-icons"
                          style={{
                            height: 50,
                            width: 50,
                            padding: 10,
                            fontSize: 28,
                            fontWeight: 'bold',
                            borderRadius: '50%',
                            textAlign: 'center',
                            backgroundColor: 'rgb(233, 240, 253)',
                            color: 'rgb(0, 123, 255)',
                            marginLeft: '10px',
                          }}
                        >
                          {copyType.icon}
                        </i>
                      )}
                    </Col>
                    <Col md="10" style={{ paddingTop: '15px' }}>
                      <h5 className="card-title">{copyType.title}</h5>
                    </Col>
                  </Row>

                  <p className="card-text text-muted">{copyType.body}</p>
                </CardBody>
                <CardFooter className="d-flex">
                  {/* Start button, when this isnt intercom, and this isnt a pro only feature */}
                  {_.get(this, 'props.firebaseUserData.lu') == 0 && true && (
                    <div className="my-auto ml-auto">
                      <Button
                        size="sm"
                        outline
                        theme="dark"
                        href="/generate/aida"
                      >
                        Use AIDA To Unlock
                      </Button>
                    </div>
                  )}
                  {_.get(this, 'props.firebaseUserData.lu') > 0 &&
                    !copyType.intercom &&
                    copyType.urlSlug &&
                    !(copyType.showProOnly && !this.props.isSubscribed) && (
                      <div className="my-auto ml-auto">
                        <Button
                          size="sm"
                          outline
                          theme="dark"
                          href={copyType.urlSlug}
                          style={{ borderColor: '#cfcbcb' }}
                        >
                          Start
                        </Button>
                      </div>
                    )}
                  {/* if paid and user is on free plan */}
                  {!_.get(this, 'props.firebaseUserData.lu') == 0 &&
                    copyType.showProOnly &&
                    !this.props.isSubscribed && (
                      <div className="my-auto ml-auto">
                        <Button
                          size="sm"
                          outline
                          theme="dark"
                          onClick={() => {
                            window.open(
                              '/pricing?utm_source=unlock_' + copyType.title
                            );
                          }}
                        >
                          Upgrade
                        </Button>
                      </div>
                    )}

                  {/* Coming soon */}
                  {!_.get(this, 'props.firebaseUserData.lu') == 0 &&
                    !copyType.intercom &&
                    !copyType.urlSlug && (
                      <div className="my-auto ml-auto">
                        <Button
                          size="sm"
                          outline
                          theme="dark"
                          className="disabled"
                        >
                          <i className="far fa-clock mr-1" /> Coming sooon
                        </Button>
                      </div>
                    )}
                  {/* contact us */}
                  {!_.get(this, 'props.firebaseUserData.lu') == 0 &&
                    copyType.intercom && (
                      <div className="my-auto ml-auto">
                        <Button
                          size="sm"
                          outline
                          theme="dark"
                          onClick={() => this.launchIntercom()}
                        >
                          <i className="far fa-envelope mr-1" /> Contact Us
                        </Button>
                      </div>
                    )}
                </CardFooter>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    credits: state.credits,
    isSubscribed: state.isSubscribed,
    daysRemaining: state.daysRemaining,
    user: state.user,
    firebaseUserData: state.firebaseUserData,
  };
};

const mapDispatchToProps = {
  setUser,
  setCredits,
  setFirebaseUserData,
  setDaysRemaining,
  setSubscribed,
};

export default connect(mapStateToProps, mapDispatchToProps)(CopyOptions);
