const productionEnvironment = {
  backend: {
    apiAddress: 'https://garyaiapi.herokuapp.com', //"https://api.garyai.com"
  },
  stripe: {
    publicKey:
      'pk_live_51I0tULJJqTQzMdtH6ZGvxwPkCY3P9c6sdLMtYauSenofqlOdjTgTaHqhe5MU6SkIfkpqOGJc2g7qP9nSvZv8LJ1G00rBEJnZTZ',
  },
};

export { productionEnvironment };
