import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Button, ButtonGroup
  // CardHeader
} from "shards-react";
import Fire from "../config/fire";
import PageTitle from "../components/common/PageTitle";

const Profiles = () => {
  const [copyProfiles, setcopyProfiles] = useState([]);
  const [loading, setLoading] = useState(true);



  const getUserCopyProfiles = async() => {
    console.log("Fetch user profiles");
    setLoading('true');
    return new Promise((resolve, reject) => {
        Fire.shared.getUserCopyProfiles()
        .then(res => {
            console.log("Copy profiles here: ", res)
            setLoading(false)
            setcopyProfiles(res);
        //     this.setState({
        //         defaultImage: res[0].img
        //     })
        })
    })
  }

  useEffect(() => {
    Fire.shared.checkAndWaitForFirebaseLoad().then(()=> {
      getUserCopyProfiles()
    });
  }, [])



  return(
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Copy Profiles" subtitle="Manage" className="text-sm-left" />
      </Row>

      {/* Default Light Table */}
      <Row>
        <Col>
          {loading && <img alt="" src={require("../images/loading.gif")} height="100" style={{marginLeft: "40%"}} ></img> }
          {!loading && <Card small className="mb-4">
            <CardBody className="p-0 pb-3">
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      Name
                    </th>
                    <th scope="col" className="border-0">
                      Type
                    </th>
                    <th scope="col" className="border-0">
                      Description
                    </th>
                    <th scope="col" className="border-0">
                      Benefits
                    </th>
                    <th scope="col" className="border-0">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>

                {copyProfiles.map((profile, idx) => (
                  <tr key={idx}>
                    <td>{profile.name}</td>
                    <td>{profile.type}</td>
                    <td><p style={{width: '150px',whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{profile.desc}</p></td>
                    <td>{profile.benefits}</td>
                    <td>
                      {/* TODO Set up functionality for edit and delete */}
                      <ButtonGroup className="mb-2">
                          <Button outline theme="secondary">Edit</Button>
                          <Button outline theme="danger">Delete</Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}

                    {/* <td>Ali</td>
                    <td>Kerry</td>
                    <td>Russian Federation</td>
                    <td>Gdańsk</td>
                    <td>
                      <ButtonGroup className="mb-2">
                          <Button outline theme="secondary">Edit</Button>
                          <Button outline theme="danger" >Delete</Button>
                      </ButtonGroup>
                    </td> */}

                </tbody>
              </table>
            </CardBody>
          </Card>
          }
        </Col>
      </Row>

    </Container>
  )
};

export default Profiles;
